<template>
	<div class="pagination-contain" v-if="!hideOnSinglePage || model.pageCount > 1">
		<div class="mr-third" v-if="!hidePreviousNext">
			<button
				v-if="model.hasPreviousPage"
				class="btn-prev"
				type="button"
				@click="updatePageNumber(model.pageNumber - 1)"
			></button>
			<span class="btn-prev disabled" v-else></span>
		</div>
		<span class="semi-bold">Page</span>
		<input
			class="mx-third text-center bold"
			ref="input"
			type="number"
			:value="model.pageNumber"
			required
			min="1"
			:max="model.pageCount"
			step="1"
			:style="{ width: model.pageNumber.toString().length + 5 + 'ch' }"
			@beforeinput="onBeforeInput"
			@input="onInputChange"
			@blur="onInputBlur"
		/>
		<span class="semi-bold">of {{ model.pageCount.toLocaleString() }}</span>
		<div class="ml-third" v-if="!hidePreviousNext">
			<button
				v-if="model.hasNextPage"
				class="btn-next"
				type="button"
				@click="updatePageNumber(model.pageNumber + 1)"
			></button>
			<span class="btn-next disabled" v-else></span>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
const props = defineProps({
	model: {
		type: Object,
		required: true,
	},
	slidingRange: {
		type: Number,
		required: false,
		default: 4,
	},
	hideOnSinglePage: {
		type: Boolean,
		required: false,
		default: true,
	},
	hidePreviousNext: {
		type: Boolean,
		required: false,
		default: false,
	},
});

const emit = defineEmits(['update:pageNumber']);

function onBeforeInput(event: InputEvent) {
	if (event && event.data && /[^\d]/g.test(event.data)) {
		event.preventDefault();
		event.stopPropagation();
	}
}
function onInputChange(event: Event) {
	const input = event.target as HTMLInputElement;
	if (!input) {
		return;
	}
	let value = input.value;
	if (value.length > 0) {
		const pattern = /[^\d]/g;
		if (pattern.test(value)) {
			const index = value.search(pattern);
			value = value.replace(pattern, '');
			input.value = value;
			input.setSelectionRange(index, index);
		}
	}
	if (value.length > 0) {
		updatePageNumber(parseInt(value, 10));
	}
}

function onInputBlur(event: Event) {
	const input = event.target as HTMLInputElement;
	if (!input) {
		return;
	}
	if (input.value.length === 0) {
		input.value = props.model.pageNumber.toString();
	}
}

function updatePageNumber(pageNumber: number) {
	emit('update:pageNumber', pageNumber);
}
</script>
