/* eslint-disable no-unused-vars */
export interface CalculateRecommendedProps {
    items: [];
    GET_ID: string;
    GET_CEIL: (index: number) => number;
    SET_ID: string;
    GETFN: (value: number, getId: string, callback: void) => number;
    SETFN: (setId: string, value: number, index: number) => void;
    GETUNITCALLBACK: (index: number) => void;
}

interface EstimateValuesItem {
    fieldId: string;
    quantity: number;
}

export const calculateEstimateValues = (items: EstimateValuesItem[], fieldId: string = '1.58') => {
    return items.filter((x) => x.fieldId === fieldId).reduce((sum, x) => sum + x.quantity, 0);
};

export const calculateRecommended = (props: CalculateRecommendedProps) => {
    const { items, GET_ID, GET_CEIL, SET_ID, GETFN, SETFN, GETUNITCALLBACK } = props;
    if (!Array.isArray(items)) return [];

    return items.map((x, i) => {
        const feetPerUnit = GETFN(1, GET_ID, GETUNITCALLBACK(i));
        if (feetPerUnit === 0) return 0;
        const recommended = Math.ceil(GET_CEIL(i) / feetPerUnit);
        SETFN(SET_ID, recommended, i);
        return recommended;
    });
};
