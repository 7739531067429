import EstimateWorkflows from '@/models/EstimateWorkflows';
import { parseIntProp } from '../_helpers';

export default {
    path: '/estimator',
    component: () => import('@/views/estimator/_EstimatorApp.vue'),
    meta: { requiresAuth: true, requiresUser: true, appName: 'estimator' },
    children: [
        {
            path: '',
            redirect: '/estimator/leads',
        },
        {
            path: 'leads',
            component: () => import('@/views/estimator/LeadsPage.vue'),
            meta: { title: 'Leads' },
        },
        {
            path: 'leads/:id(-?\\d+)/details',
            component: () => import('@/views/estimator/LeadDetailsPage.vue'),
            props: (route: { params: { id: string } }) => ({ id: parseIntProp(route.params.id) }),
            meta: { title: 'Lead Details', checkLocalId: 'leads' },
        },
        {
            path: 'leads/:id(-?\\d+)/edit',
            component: () => import('@/views/estimator/LeadEditPage.vue'),
            props: (route: { params: { id: string } }) => ({ id: parseIntProp(route.params.id) }),
            meta: { title: 'Lead Edit', checkLocalId: 'leads' },
        },
        {
            path: 'estimate/:id(-?\\d+)',
            component: () => import('@/views/estimator/estimate/_EstimateApp.vue'),
            props: (route: { params: { id: string } }) => ({ id: parseIntProp(route.params.id) }),
            meta: { checkLocalId: 'estimates' },
            children: [
                {
                    path: 'quick_estimate_roofing',
                    component: () =>
                        import('@/views/estimator/estimate/quick_estimate/EstimatorQuickEstimateRoofing.vue'),
                    meta: { title: 'Roofing - Quick estimate', estimateSteps: true },
                },
                {
                    path: 'quick_estimate_siding',
                    component: () =>
                        import('@/views/estimator/estimate/quick_estimate/EstimatorQuickEstimateSiding.vue'),
                    meta: { title: 'Siding - Quick estimate', estimateSteps: true },
                },
                {
                    path: 'quick_estimate_gutters',
                    component: () =>
                        import('@/views/estimator/estimate/quick_estimate/EstimatorQuickEstimateGutters.vue'),
                    meta: { title: 'Gutters - Quick estimate', estimateSteps: true },
                },
                {
                    path: 'quick_estimate_insulation',
                    component: () =>
                        import('@/views/estimator/estimate/quick_estimate/EstimatorQuickEstimateInsulation.vue'),
                    meta: { title: 'Gutters - Quick estimate', estimateSteps: true },
                },
                {
                    path: 'quick_estimate_trim',
                    component: () =>
                        import('@/views/estimator/estimate/quick_estimate/EstimatorQuickEstimateSoffitFascia.vue'),
                    meta: { title: 'Trim - Quick estimate', estimateSteps: true },
                },
                {
                    path: 'quick_estimate_repairs',
                    component: () =>
                        import('@/views/estimator/estimate/quick_estimate/EstimatorQuickEstimateRepairs.vue'),
                    meta: { title: 'Repair - Quick estimate', estimateSteps: true },
                },
                {
                    path: 'quick_estimate_by_window/:windowId(-?\\d+)',
                    component: () =>
                        import('@/views/estimator/estimate/quick_estimate/EstimatorQuickEstimateWindows.vue'),
                    meta: { title: 'Window - Quick estimate', estimateSteps: true },
                },
                {
                    path: 'quick_estimate_windows',
                    component: () =>
                        import('@/views/estimator/estimate/quick_estimate/EstimatorQuickEstimateListWindows.vue'),
                    meta: { title: 'Window - Quick estimate', estimateSteps: true },
                },
                {
                    path: '',
                    component: () => import('@/views/estimator/estimate/EstimateDashboardPage.vue'),
                    meta: { title: 'Estimate Summary', hideDashboardLink: true, showLeadName: true },
                },
                {
                    path: 'summary/:workflowId',
                    component: () => import('@/views/estimator/estimate/EstimateSummaryPage.vue'),
                    props: (route: { params: { workflowId: string } }) => ({
                        workflowId: parseIntProp(route.params.workflowId),
                    }),
                    meta: { title: 'Estimate Summary', hideDashboardLink: true, showLeadName: true },
                },
                {
                    path: 'roofing',
                    component: () => import('@/views/estimator/estimate/_EstimateWorkflowApp.vue'),
                    props: { workflowId: EstimateWorkflows.roofing },
                    meta: { estimateSteps: true },
                    children: [
                        {
                            path: '1',
                            component: () =>
                                import('@/views/estimator/estimate/roofing/01RoofingQuestionnairePage.vue'),
                            meta: { title: 'Questionnaire - Roofing Estimate' },
                        },
                        {
                            path: '2',
                            component: () => import('@/views/estimator/estimate/roofing/02RoofingDetailsPage.vue'),
                            meta: { title: 'Details - Roofing Estimate' },
                        },
                        {
                            path: '3',
                            component: () =>
                                import('@/views/estimator/estimate/roofing/03RoofingDeckUnderlaymentPage.vue'),
                            meta: { title: 'Decking & Underlayment - Roofing Estimate' },
                        },
                        {
                            path: '4',
                            component: () => import('@/views/estimator/estimate/roofing/04RoofingFlashingPage.vue'),
                            meta: { title: 'Flashing - Roofing Estimate' },
                        },
                        {
                            path: '5',
                            component: () => import('@/views/estimator/estimate/roofing/05RoofingChimneysPage.vue'),
                            meta: { title: 'Chimneys - Roofing Estimate' },
                        },
                        {
                            path: '6',
                            component: () => import('@/views/estimator/estimate/roofing/06RoofingSkylightPage.vue'),
                            meta: { title: 'Skylight - Roofing Estimate' },
                        },
                        {
                            path: '7',
                            component: () => import('@/views/estimator/estimate/roofing/07RoofingVentilationPage.vue'),
                            meta: { title: 'Ventilation - Roofing Estimate' },
                        },
                        {
                            path: '8',
                            component: () => import('@/views/estimator/estimate/roofing/08RoofingFlatRoofPage.vue'),
                            meta: { title: 'Flat Roof - Roofing Estimate' },
                        },
                        {
                            path: '9',
                            component: () => import('@/views/estimator/estimate/roofing/09RoofingSoffitPage.vue'),
                            meta: { title: 'Soffit - Roofing Estimate' },
                        },
                        {
                            path: '10',
                            component: () => import('@/views/estimator/estimate/roofing/10RoofingFasciaPage.vue'),
                            meta: { title: 'Fascia - Roofing Estimate' },
                        },
                        {
                            path: '11',
                            component: () =>
                                import('@/views/estimator/estimate/roofing/11RoofingExteriorLightingPage.vue'),
                            meta: { title: 'Exterior Lighting - Roofing Estimate' },
                        },
                        {
                            path: '12',
                            component: () => import('@/views/estimator/estimate/roofing/12RoofingGuttersPage.vue'),
                            meta: { title: 'Gutters - Roofing Estimate' },
                        },
                        {
                            path: '13',
                            component: () => import('@/views/estimator/estimate/roofing/13RoofingExtrasPage.vue'),
                            meta: { title: 'Extras - Roofing Estimate' },
                        },
                        {
                            path: '14',
                            component: () => import('@/views/estimator/estimate/roofing/14RoofingPackagePage.vue'),
                            meta: { title: 'Package - Roofing Estimate' },
                        },
                    ],
                },
                {
                    path: 'windows',
                    component: () => import('@/views/estimator/estimate/_EstimateWorkflowApp.vue'),
                    props: { workflowId: EstimateWorkflows.windows },
                    meta: { estimateSteps: true },
                    children: [
                        {
                            path: '1',
                            component: () => import('@/views/estimator/estimate/windows/01WindowQuestionnairePage.vue'),
                            meta: { title: 'Questionnaire - Window Estimate' },
                        },
                        {
                            path: '2',
                            redirect: (to: { fullPath: string }) =>
                                to.fullPath + (to.fullPath.endsWith('/') ? '' : '/') + '0/0',
                        },
                        {
                            path: '2/:estimateItemId(-?\\d+)/:sourceEstimateItemId(-?\\d+)',
                            component: () => import('@/views/estimator/estimate/windows/02WindowDetailPage.vue'),
                            props: (route: {
                                params: {
                                    estimateItemId: string | undefined;
                                    sourceEstimateItemId: string | undefined;
                                };
                            }) => ({
                                id: parseIntProp(route.params.estimateItemId),
                                sourceId: parseIntProp(route.params.sourceEstimateItemId),
                            }),
                            meta: { title: 'Window Details - Window Estimate', showWindowsList: true },
                        },
                        {
                            path: '3',
                            component: () => import('@/views/estimator/estimate/windows/03WindowTrimPage.vue'),
                            meta: { title: 'Trim - Window Estimate' },
                        },
                        {
                            path: '4',
                            component: () => import('@/views/estimator/estimate/windows/04WindowProjectSpecsPage.vue'),
                            meta: { title: 'Project Specifications - Window Estimate' },
                        },
                        {
                            path: '5',
                            component: () => import('@/views/estimator/estimate/windows/05WindowListPage.vue'),
                            meta: {
                                title: 'Window List - Window Estimate',
                                hideEstimateSteps: true,
                                isWindowPackagesStep: true,
                            },
                        },
                    ],
                },
                {
                    path: 'siding',
                    component: () => import('@/views/estimator/estimate/_EstimateWorkflowApp.vue'),
                    props: { workflowId: EstimateWorkflows.siding },
                    meta: { estimateSteps: true },
                    children: [
                        {
                            path: '1',
                            component: () => import('@/views/estimator/estimate/siding/01SidingQuestionnairePage.vue'),
                            meta: { title: 'Questionnaire - Siding Estimate' },
                        },
                        {
                            path: '2',
                            component: () => import('@/views/estimator/estimate/siding/02SidingDetailsPage.vue'),
                            meta: { title: 'Details - Siding Estimate' },
                        },
                        {
                            path: '3',
                            component: () => import('@/views/estimator/estimate/siding/03SidingHouseWrapPage.vue'),
                            meta: { title: 'House Wrap - Siding Estimate' },
                        },
                        {
                            path: '4',
                            component: () => import('@/views/estimator/estimate/siding/04SidingAccessoriesPage.vue'),
                            meta: { title: 'Accessories - Siding Estimate' },
                        },
                        {
                            path: '5',
                            component: () => import('@/views/estimator/estimate/siding/05SidingCornersPage.vue'),
                            meta: { title: 'Corners - Siding Estimate' },
                        },
                        {
                            path: '6',
                            component: () => import('@/views/estimator/estimate/siding/06SidingWrapsPage.vue'),
                            meta: { title: 'Wraps - Siding Estimate' },
                        },
                        {
                            path: '7',
                            component: () => import('@/views/estimator/estimate/siding/07SidingSoffitPage.vue'),
                            meta: { title: 'Soffit - Siding Estimate' },
                        },
                        {
                            path: '8',
                            component: () => import('@/views/estimator/estimate/siding/08SidingFasciaPage.vue'),
                            meta: { title: 'Fascia - Siding Estimate' },
                        },
                        {
                            path: '9',
                            component: () => import('@/views/estimator/estimate/siding/09SidingTrimPage.vue'),
                            meta: { title: 'Trim - Siding Estimate' },
                        },
                        {
                            path: '10',
                            component: () => import('@/views/estimator/estimate/siding/10SidingShuttersPage.vue'),
                            meta: { title: 'Shutters - Siding Estimate' },
                        },
                        {
                            path: '11',
                            component: () => import('@/views/estimator/estimate/siding/11SidingAccentsPage.vue'),
                            meta: { title: 'Accents - Siding Estimate' },
                        },
                        {
                            path: '12',
                            component: () => import('@/views/estimator/estimate/siding/12SidingDoorsPage.vue'),
                            meta: { title: 'Doors - Siding Estimate' },
                        },
                        {
                            path: '13',
                            component: () => import('@/views/estimator/estimate/siding/13SidingPaintPage.vue'),
                            meta: { title: 'Painting - Siding Estimate' },
                        },
                        {
                            path: '14',
                            component: () =>
                                import('@/views/estimator/estimate/siding/14SidingExteriorLightingPage.vue'),
                            meta: { title: 'Exterior Lighting - Siding Estimate' },
                        },
                        {
                            path: '15',
                            component: () => import('@/views/estimator/estimate/siding/15SidingGuttersPage.vue'),
                            meta: { title: 'Gutters - Siding Estimate' },
                        },
                        {
                            path: '16',
                            component: () => import('@/views/estimator/estimate/siding/16SidingExtrasPage.vue'),
                            meta: { title: 'Extras - Siding Estimate' },
                        },
                        {
                            path: '17',
                            component: () => import('@/views/estimator/estimate/siding/17SidingPackagePage.vue'),
                            meta: { title: 'Package - Siding Estimate' },
                        },
                    ],
                },
                {
                    path: 'gutters',
                    component: () => import('@/views/estimator/estimate/_EstimateWorkflowApp.vue'),
                    props: { workflowId: EstimateWorkflows.gutters },
                    meta: { estimateSteps: true },
                    children: [
                        {
                            path: '1',
                            component: () => import('@/views/estimator/estimate/gutters/01GutterDetailsPage.vue'),
                            meta: { title: 'Details - Gutters Estimate' },
                        },
                        {
                            path: '2',
                            component: () => import('@/views/estimator/estimate/gutters/02GutterGuttersPage.vue'),
                            meta: { title: 'Gutters - Gutters Estimate' },
                        },
                        {
                            path: '3',
                            component: () =>
                                import('@/views/estimator/estimate/gutters/03GutterExteriorLightingPage.vue'),
                            meta: { title: 'Exterior Lighting - Gutters Estimate' },
                        },
                        {
                            path: '4',
                            component: () => import('@/views/estimator/estimate/gutters/04GutterExtrasPage.vue'),
                            meta: { title: 'Extras - Gutters Estimate' },
                        },
                    ],
                },
                {
                    path: 'trim',
                    component: () => import('@/views/estimator/estimate/_EstimateWorkflowApp.vue'),
                    props: { workflowId: EstimateWorkflows.trim },
                    meta: { estimateSteps: true },
                    children: [
                        {
                            path: '1',
                            component: () => import('@/views/estimator/estimate/trim/01TrimDetailsPage.vue'),
                            meta: { title: 'Details - Trim Estimate' },
                        },
                        {
                            path: '2',
                            component: () => import('@/views/estimator/estimate/trim/02TrimSoffitPage.vue'),
                            meta: { title: 'Soffit - Trim Estimate' },
                        },
                        {
                            path: '3',
                            component: () => import('@/views/estimator/estimate/trim/03TrimFasciaPage.vue'),
                            meta: { title: 'Fascia - Trim Estimate' },
                        },
                        {
                            path: '4',
                            component: () => import('@/views/estimator/estimate/trim/04TrimCompositePage.vue'),
                            meta: { title: 'Composite Trim - Trim Estimate' },
                        },
                        {
                            path: '5',
                            component: () => import('@/views/estimator/estimate/trim/05TrimWrapsPage.vue'),
                            meta: { title: 'Wraps - Trim Estimate' },
                        },
                        {
                            path: '6',
                            component: () => import('@/views/estimator/estimate/trim/06TrimPaintPage.vue'),
                            meta: { title: 'Paint - Trim Estimate' },
                        },
                        {
                            path: '7',
                            component: () => import('@/views/estimator/estimate/trim/07TrimExteriorLightingPage.vue'),
                            meta: { title: 'Exterior Lighting - Trim Estimate' },
                        },
                        {
                            path: '8',
                            component: () => import('@/views/estimator/estimate/trim/08TrimGuttersPage.vue'),
                            meta: { title: 'Gutters - Trim Estimate' },
                        },
                        {
                            path: '9',
                            component: () => import('@/views/estimator/estimate/trim/09TrimExtrasPage.vue'),
                            meta: { title: 'Extras - Trim Estimate' },
                        },
                    ],
                },
                {
                    path: 'repairs',
                    component: () => import('@/views/estimator/estimate/_EstimateWorkflowApp.vue'),
                    props: { workflowId: EstimateWorkflows.repairs },
                    meta: { estimateSteps: true },
                    children: [
                        {
                            path: '1',
                            component: () => import('@/views/estimator/estimate/repair/01RepairRoofPage.vue'),
                            meta: { title: 'Roof - Repair Estimate' },
                        },
                        {
                            path: '2',
                            component: () => import('@/views/estimator/estimate/repair/02RepairFlashingPage.vue'),
                            meta: { title: 'Flashing - Repair Estimate' },
                        },
                        {
                            path: '3',
                            component: () => import('@/views/estimator/estimate/repair/03RepairChimneyPage.vue'),
                            meta: { title: 'Chimney - Repair Estimate' },
                        },
                        {
                            path: '4',
                            component: () => import('@/views/estimator/estimate/repair/04RepairFlatRoofPage.vue'),
                            meta: { title: 'Flat Roof - Repair Estimate' },
                        },
                        {
                            path: '5',
                            component: () => import('@/views/estimator/estimate/repair/05RepairSkylightPage.vue'),
                            meta: { title: 'Skylight - Repair Estimate' },
                        },
                        {
                            path: '6',
                            component: () => import('@/views/estimator/estimate/repair/06RepairVentilationPage.vue'),
                            meta: { title: 'Ventilation - Repair Estimate' },
                        },
                        {
                            path: '7',
                            component: () => import('@/views/estimator/estimate/repair/07RepairPaintPage.vue'),
                            meta: { title: 'Paint - Repair Estimate' },
                        },
                        {
                            path: '8',
                            component: () => import('@/views/estimator/estimate/repair/08RepairExtrasPage.vue'),
                            meta: { title: 'Extras - Repair Estimate' },
                        },
                    ],
                },
                {
                    path: 'insulation',
                    component: () => import('@/views/estimator/estimate/_EstimateWorkflowApp.vue'),
                    props: { workflowId: EstimateWorkflows.insulation },
                    meta: { estimateSteps: true },
                    children: [
                        {
                            path: '1',
                            component: () =>
                                import('@/views/estimator/estimate/insulation/01InsulationQuestionnairePage.vue'),
                            meta: { title: 'Questionnaire - Insulation Estimate' },
                        },
                        {
                            path: '2',
                            component: () =>
                                import('@/views/estimator/estimate/insulation/02InsulationPreTestPage.vue'),
                            meta: { title: 'Pre-Test - Insulation Estimate' },
                        },
                        {
                            path: '3',
                            component: () =>
                                import('@/views/estimator/estimate/insulation/03InsulationAirSealPage.vue'),
                            meta: { title: 'Air Seal - Insulation Estimate' },
                        },
                        {
                            path: '4',
                            component: () =>
                                import('@/views/estimator/estimate/insulation/04InsulationDetailsPage.vue'),
                            meta: { title: 'Details - Insulation Estimate' },
                        },
                        {
                            path: '5',
                            component: () => import('@/views/estimator/estimate/insulation/05InsulationExtrasPage.vue'),
                            meta: { title: 'Extras - Insulation Estimate' },
                        },
                    ],
                },
                {
                    path: 'repairs',
                    component: () => import('@/views/estimator/estimate/_EstimateWorkflowApp.vue'),
                    props: { workflowId: EstimateWorkflows.repairs },
                    meta: { title: 'Repair Estimate', estimateSteps: true },
                },
                {
                    path: 'contract/create/:sourceId(-?\\d+)?',
                    component: () => import('@/views/estimator/estimate/documents/ContractCreatePage.vue'),
                    meta: { title: 'Create Contract', showFooter: true, hideCompanyName: true, isDocument: true },
                    props: (route: { params: { sourceId: string } }) => ({
                        sourceId: parseIntProp(route.params.sourceId),
                    }),
                },
                {
                    path: 'change-order/create',
                    component: () => import('@/views/estimator/estimate/documents/ChangeOrderCreatePage.vue'),
                    meta: { title: 'Create Change Order', showFooter: true, hideCompanyName: true, isDocument: true },
                },
                {
                    path: 'labor-bill/create/:sourceId(-?\\d+)',
                    component: () => import('@/views/estimator/estimate/documents/LaborBillCreatePage.vue'),
                    meta: { title: 'Create Purchase Order', showFooter: true, hideCompanyName: true, isDocument: true },
                    props: (route: { params: { sourceId: string } }) => ({
                        sourceId: parseIntProp(route.params.sourceId),
                    }),
                },
                {
                    path: 'purchase-order/create/:sourceId(-?\\d+)?',
                    component: () => import('@/views/estimator/estimate/documents/PurchaseOrderCreatePage.vue'),
                    meta: { title: 'Create Purchase Order', showFooter: true, hideCompanyName: true, isDocument: true },
                    props: (route: { params: { sourceId: string } }) => ({
                        sourceId: parseIntProp(route.params.sourceId),
                    }),
                },
                {
                    path: 'work-order/create/:sourceId(-?\\d+)?',
                    component: () => import('@/views/estimator/estimate/documents/WorkOrderCreatePage.vue'),
                    meta: { title: 'Create Work Order', showFooter: true, hideCompanyName: true, isDocument: true },
                    props: (route: { params: { sourceId: string } }) => ({
                        sourceId: parseIntProp(route.params.sourceId),
                    }),
                },
            ],
        },
    ],
};
