import { formatCurrency } from '@/helpers';
import { SectionProps, TypeEstimate } from './roofing/types';
// import { SectionProps, TypeEstimate } from './roofing/types';

export const emptyFields = [
    {
        id: 3,
        label: '',
    },
    {
        id: 4,
        label: '',
    },
    {
        id: 5,
        label: '',
    },
    {
        id: 6,
        label: '',
    },
];

export type EstimateCostValues = SectionProps['rows'][0]['columns'];
export const estimteCostValues = (fieldsEstimateItems: TypeEstimate[]): EstimateCostValues => [
    {
        id: 3,
        type: 'Cost',
        estimateField(i, item) {
            // const material = fieldsEstimateItems?.find((e: TypeEstimate) => e?.id === item?.id)?.material;
            const material = fieldsEstimateItems?.find((e) => e?.id === item?.id)?.material;

            if (!material) {
                return '';
            }
            return `${formatCurrency(material)}`;
        },
    },
    {
        id: 4,
        type: 'Cost',
        estimateField(i, item) {
            // const labor = fieldsEstimateItems?.find((e: TypeEstimate) => e?.id === item?.id)?.labor;
            const labor = fieldsEstimateItems?.find((e) => e?.id === item?.id)?.labor;

            if (!labor) {
                return '';
            }
            return `${formatCurrency(labor)}`;
        },
    },
    {
        id: 5,
        type: 'Cost',
        estimateField(i, item) {
            // const total = fieldsEstimateItems?.find((e: TypeEstimate) => e?.id === item?.id)?.total;
            const total = fieldsEstimateItems?.find((e) => e?.id === item?.id)?.total;

            if (!total) {
                return '';
            }
            return `${formatCurrency(total)}`;
        },
    },
    {
        id: 6,
        label: '',
    },
];

export const estimateTotalsWithKey = (fieldsEstimateItems: TypeEstimate[], key: string): EstimateCostValues => {
    return [
        {
            id: 3,
            type: 'Cost',
            estimateField() {
                // const material = fieldsEstimateItems?.find(
                //     (e: TypeEstimate) => e?.label?.toUpperCase() === key?.toUpperCase()
                // )?.material;
                const material = fieldsEstimateItems?.find(
                    (e) => e?.label?.toUpperCase() === key?.toUpperCase()
                )?.material;
                if (!material) {
                    return '';
                }
                return `${formatCurrency(material)}`;
            },
        },
        {
            id: 4,
            type: 'Cost',
            estimateField() {
                // const labor = fieldsEstimateItems?.find(
                //     (e: TypeEstimate) => e?.label?.toUpperCase() === key?.toUpperCase()
                // )?.labor;
                const labor = fieldsEstimateItems?.find((e) => e?.label?.toUpperCase() === key?.toUpperCase())?.labor;
                if (!labor) {
                    return '';
                }
                return `${formatCurrency(labor)}`;
            },
        },
        {
            id: 5,
            type: 'Cost',
            estimateField() {
                // const total = fieldsEstimateItems?.find(
                //     (e: TypeEstimate) => e?.label?.toUpperCase() === key?.toUpperCase()
                // )?.total;
                const total = fieldsEstimateItems?.find((e) => e?.label?.toUpperCase() === key?.toUpperCase())?.total;
                if (!total) {
                    return '';
                }
                return `${formatCurrency(total)}`;
            },
        },
        {
            id: 6,
            label: '',
        },
    ];
};

export const estimateTotalsWithKeyChild = (
    fieldsEstimateItems: TypeEstimate[],
    keyChild: string,
    keyParent?: string
): EstimateCostValues => {
    return [
        {
            id: 3,
            type: 'Cost',
            estimateField(i, item) {
                const material = fieldsEstimateItems
                    // ?.find((e: TypeEstimate) => e?.id === item?.id || e?.label === keyParent)
                    ?.find((e) => e?.id === item?.id || e?.label === keyParent)
                    ?.childItems?.find((e) => e?.label?.toUpperCase() === keyChild?.toUpperCase())?.material;
                if (!material) {
                    return '';
                }
                return `${formatCurrency(material)}`;
            },
        },
        {
            id: 4,
            type: 'Cost',
            estimateField(i, item) {
                const labor = fieldsEstimateItems
                    // ?.find((e: TypeEstimate) => e?.id === item?.id || e?.label === keyParent)
                    ?.find((e) => e?.id === item?.id || e?.label === keyParent)
                    ?.childItems?.find((e) => e?.label?.toUpperCase() === keyChild?.toUpperCase())?.labor;
                if (!labor) {
                    return '';
                }
                return `${formatCurrency(labor)}`;
            },
        },
        {
            id: 5,
            type: 'Cost',
            estimateField(i, item) {
                const total = fieldsEstimateItems
                    // ?.find((e: TypeEstimate) => e?.id === item?.id || e?.label === keyParent)
                    ?.find((e) => e?.id === item?.id || e?.label === keyParent)

                    ?.childItems?.find((e) => e?.label?.toUpperCase() === keyChild?.toUpperCase())?.total;
                if (!total) {
                    return '';
                }
                return `${formatCurrency(total)}`;
            },
        },
        {
            id: 6,
            label: '',
        },
    ];
};
