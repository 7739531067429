export const calculateStandardSofft = (obj, x) => {
  const color = x.children.standardSoffitColor.value;
  const key = color.toLowerCase();
  const depth = x.children.standardSoffitDepth.quantity / 12; // convert to feet
  const length = x.children.standardSoffitLength.quantity;

  const item =
    key in obj ? obj[key] : (obj[key] = { color: color.trim(), area: 0 });
  item.area += length * depth;
  return obj;
};

export const calculateHiddenVentArea = (obj, x) => {
  const color = x.children.hiddenVentSoffitColor.value;
  const key = color.toLowerCase();
  const depth = x.children.hiddenVentSoffitDepth.quantity / 12; // convert to feet
  const length = x.children.hiddenVentSoffitLength.quantity;

  const item =
    key in obj ? obj[key] : (obj[key] = { color: color.trim(), area: 0 });
  item.area += length * depth;
  return obj;
};

export const calculatePorchSofftArea = (obj, x) => {
  const color = x.children.porchSoffitColor.value;
  const key = color.toLowerCase();
  const depth = x.children.porchSoffitDepth.quantity;
  const length = x.children.porchSoffitLength.quantity;

  const item =
    key in obj ? obj[key] : (obj[key] = { color: color.trim(), area: 0 });
  item.area += length * depth;
  return obj;
};

export const calculateStandardQty = (x) => {
  const solidQty = Math.ceil((x.area / 12) * 0.75 * 1.1); // 10% waste
  const ventedQty = Math.ceil((x.area / 12) * 0.2 * 1.1); // 10% waste

  return {
    value:
      `${solidQty} solid ${x.color}`.trim() +
      ", " +
      `${ventedQty} vented ${x.color}`.trim(),
    solidQty,
    ventedQty,
  };
};

export const calculateHiddeenVentQty = (x) => {
  const qty = Math.ceil((x.area / 12) * 1.05); // 5% waste

  return {
    value: `${qty} hidden vent ${x.color}`.trim(),
    qty,
  };
};

export const calculatePorchSoffArea = (x) => {
  const qty = Math.ceil((x.area / 12) * 1.05); // 5% waste

  return {
    value: `${qty} porch ${x.color}`.trim(),
    qty,
  };
};

export const calculateStandardRecommend = (x, feetPerUnit) => {
  const solidQty = Math.ceil((x.area / feetPerUnit) * 0.75 * 1.1); // 10% waste
  const ventedQty = Math.ceil((x.area / feetPerUnit) * 0.2 * 1.1); // 10% waste
  return {
    solidQty,
    ventedQty,
  };
};

export const calcuateHiddenRecommend = (x, feetPerUnit) => {
  const qty = Math.ceil((x.area / feetPerUnit) * 1.05); // 5% waste
  return {
    qty,
  };
};

export const calculatePorchSoffRecommend = (x, feetPerUnit) => {
  const qty = Math.ceil((x.area / feetPerUnit) * 1.05); // 5% waste
  return {
    qty,
  };
};
