<!-- eslint-disable no-unused-vars -->
<script setup lang="ts">
    import { defineProps } from 'vue';
    // import { SectionProps } from '../quick_estimate/roofing/types';
    import { SectionProps } from '../quick_estimate/roofing/types';
    import FieldExcelError from './FieldExcelError.vue';

    const props = defineProps<{
        sections: SectionProps[];
        setModelValue: (k: string, v: number) => void;
        modelValues: Object;
        errors: {
            [key in string]: string;
        };
    }>();
</script>

<template>
    <template v-for="section in props?.sections" :key="`section-chimneys-${section.id}`">
        <template v-if="section?.showSection ?? true">
            <field-array
                v-if="section.isFieldArray"
                :name="section?.name"
                :ref="section?.refString ?? ''"
                v-slot="{ items, removeItem, addItemAfter, addItem }"
            >
                <template v-if="section?.addItem?.show">
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_row_table_item">
                            <p style="font-size: 12px; margin: 0">
                                {{ section?.addItem?.title }}
                            </p>
                        </article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>

                        <article class="quick_estimate_row_table_item">
                            <button
                                class="btn-link btn-add"
                                v-if="items.length < 1"
                                type="button"
                                @click="addItem"
                            ></button>
                        </article>
                    </section>
                </template>

                <template v-for="(item, i) in items" :key="item.key">
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_table_item">
                            <p style="font-size: 12px; margin: 0px">
                                {{ section.title }}
                            </p>
                        </article>
                        <article class="quick_estimate_table_item"></article>
                        <article class="quick_estimate_table_item"></article>

                        <article class="quick_estimate_table_item"></article>

                        <article class="quick_estimate_table_item"></article>

                        <article class="quick_estimate_table_item">
                            <div style="display: flex; flex-direction: row; gap: 12px">
                                <button class="btn-link" type="button" @click="addItemAfter(i)">
                                    <svg width="24px" height="24px" class="icon fill-dark stroke-green">
                                        <use xlink:href="#add"></use>
                                    </svg>
                                </button>
                                <button class="btn-link" type="button" v-if="items.length > 1" @click="removeItem(i)">
                                    <svg width="24px" height="24px" class="icon fill-red stroke-red">
                                        <use xlink:href="#remove"></use>
                                    </svg>
                                </button>
                                <!-- <button
                                    class="btn-link btn-remove-red"
                                    type="button"
                                    @click="
                                        () => {
                                            if (section?.clearType === 'REMOVE_ADD') {
                                                addItemAfter(0);
                                                removeItem(0);
                                                section?.clearCallback?.();
                                                // removed();
                                                return;
                                            }
                                            setModelValue?.(section.setModelValue(i), 0);
                                        }
                                    "
                                    v-else-if="section.quantity(i) > 0"
                                >
                                    Clear
                                </button> -->
                            </div>
                        </article>
                    </section>

                    <template v-for="row in section.rows" :key="`section-${section}-${section.id}-${row.id}`">
                        <section class="quick_estimate_row_table">
                            <template
                                v-for="(col, index) in row.columns"
                                :key="`section-${section}-${section.id}-${row.id}-${col.id}`"
                            >
                                <article
                                    v-if="row?.show?.(i) ?? true"
                                    :class="index === 0 ? 'quick_estimate_table_item_sub' : 'quick_estimate_table_item'"
                                >
                                    <div v-if="col?.label">
                                        <p style="font-size: 14px; margin: 0">{{ col?.label }}</p>
                                    </div>
                                    <div v-if="col?.type === 'Cost'">
                                        <p style="font-size: 14px">{{ col?.estimateField?.(i, item.value) ?? '' }}</p>
                                    </div>

                                    <div v-if="col?.type === 'Number'">
                                        <FieldExcelError
                                            :name="col.name?.(i)"
                                            :custom_class="'input_field_error'"
                                            :custom-message="'*'"
                                            :errors="props?.errors"
                                        />
                                        <input-number
                                            v-if="col?.type === 'Number'"
                                            :id="col?.id_input"
                                            :value="col?.value"
                                            :readonly="col?.readonly"
                                            :custom_class="'input_number_excel'"
                                        />
                                    </div>

                                    <div v-if="col?.type === 'Quantity'">
                                        <FieldExcelError
                                            :name="col.name?.(i)"
                                            :custom_class="'input_field_error'"
                                            :custom-message="'*'"
                                            :recommended="col.recommended?.(i)"
                                            :modelValuesProps="modelValues"
                                            :errors="props?.errors"
                                        />
                                        <field-input-quantity
                                            :name="col.name?.(i)"
                                            :disabled="col?.disabled"
                                            :recommended="col.recommended?.(i)"
                                            :modelValuesProps="modelValues"
                                            :readonly="col?.readonly"
                                            :forceStyle="col?.inputStyle"
                                            :custom_class="'input_number_excel'"
                                            :custom_select_class="'input_quantity_select_excel'"
                                            :recommendedClass="'input_quantity_recommended'"
                                            :autoSetRecommend="true"
                                        />
                                    </div>
                                    <div v-if="col?.type === 'Text'">
                                        <FieldExcelError
                                            :name="col.name?.(i)"
                                            :custom_class="'input_field_error'"
                                            :custom-message="'*'"
                                            :errors="props?.errors"
                                        />
                                        <field-input-text
                                            :name="col.name?.(i)"
                                            :recommended="col.recommended?.(i)"
                                            :modelValuesProps="modelValues"
                                            :custom_class="'input_text_excel'"
                                        />
                                    </div>
                                    <div v-if="col?.type === 'Price'">
                                        <FieldExcelError
                                            :name="col.name?.(i)"
                                            :custom_class="'input_field_error'"
                                            :custom-message="'*'"
                                            :errors="props?.errors"
                                        />
                                        <field-input-price
                                            :name="col.name?.(i)"
                                            :readonly="col?.readonly"
                                            custom_class="input_price_excel"
                                        />
                                    </div>
                                    <div v-if="col?.type === 'Checkbox'">
                                        <FieldExcelError
                                            :name="col.name?.(i)"
                                            :custom_class="'input_field_error'"
                                            :custom-message="'*'"
                                            :errors="props?.errors"
                                        />
                                        <div class="check-radio-wrap flex-center-y">
                                            <field-input-checkbox :name="col.name?.(i)" :readonly="col?.readonly" />
                                        </div>
                                    </div>
                                    <div v-if="col?.type === 'Dropdown'">
                                        <FieldExcelError
                                            :name="col.name?.(i)"
                                            :custom_class="'input_field_error'"
                                            :custom-message="'*'"
                                            :errors="props?.errors"
                                        />
                                        <field-input-dropdown
                                            :name="col.name?.(i)"
                                            :recommended="col.recommended?.(i)"
                                            :modelValuesProps="modelValues"
                                            :readonly="col?.readonly"
                                            :display="col?.display"
                                            :custom_class="'input_dropdown_excel'"
                                        />
                                    </div>
                                    <div v-if="col?.error?.(i)">
                                        <p style="color: red; font-weight: bold; font-size: 12px">
                                            {{ col?.error?.(i) }}
                                        </p>
                                    </div>
                                </article>
                            </template>
                        </section>
                    </template>
                </template>
            </field-array>
            <template v-else>
                <section class="quick_estimate_row_table">
                    <article class="quick_estimate_row_table_item">
                        <p style="font-size: 12px; margin: 0px">
                            {{ section.title }}
                        </p>
                    </article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                </section>
                <template v-for="row in section.rows" :key="`section-${section}-${section.id}-${row.id}`">
                    <section class="quick_estimate_row_table">
                        <template
                            v-for="(col, index) in row.columns"
                            :key="`section-${section}-${section.id}-${row.id}-${col.id}`"
                        >
                            <article
                                v-if="row?.show?.(null) ?? true"
                                :class="index === 0 ? 'quick_estimate_table_item_sub' : 'quick_estimate_table_item'"
                            >
                                <div v-if="col?.label">
                                    <p style="font-size: 14px; margin: 0px">{{ col?.label }}</p>
                                </div>
                                <div v-if="col?.type === 'Cost'">
                                    <p>{{ col?.estimateField?.(null, null) ?? '' }}</p>
                                </div>
                                <div v-if="col?.type === 'Number'">
                                    <FieldExcelError
                                        :name="col.name?.()"
                                        :custom_class="'input_field_error'"
                                        :custom-message="'*'"
                                        :errors="props?.errors"
                                    />
                                    <input-number
                                        v-if="col?.type === 'Number'"
                                        :id="col?.id_input"
                                        :value="col?.value"
                                        :readonly="col?.readonly"
                                        :custom_class="'input_number_excel'"
                                    />
                                </div>
                                <div v-if="col?.type === 'Text'">
                                    <FieldExcelError
                                        :name="col.name?.()"
                                        :custom_class="'input_field_error'"
                                        :custom-message="'*'"
                                        :errors="props?.errors"
                                    />
                                    <field-input-text
                                        :name="col.name?.()"
                                        :recommended="col.recommended?.()"
                                        :modelValuesProps="modelValues"
                                        :custom_class="'input_text_excel'"
                                    />
                                </div>
                                <div v-if="col?.type === 'Price'">
                                    <FieldExcelError
                                        :name="col.name?.()"
                                        :custom_class="'input_field_error'"
                                        :custom-message="'*'"
                                        :errors="props?.errors"
                                    />
                                    <field-input-price
                                        :name="col.name?.()"
                                        :readonly="col?.readonly"
                                        custom_class="input_price_excel"
                                    />
                                </div>
                                <div v-if="col?.type === 'Quantity'">
                                    <FieldExcelError
                                        :name="col.name?.()"
                                        :custom_class="'input_field_error'"
                                        :custom-message="'*'"
                                        :errors="props?.errors"
                                    />
                                    <field-input-quantity
                                        :name="col.name?.()"
                                        :disabled="col?.disabled"
                                        :recommended="col.recommended?.()"
                                        :modelValuesProps="modelValues"
                                        :readonly="col?.readonly"
                                        :forceStyle="col?.inputStyle"
                                        :custom_class="'input_number_excel'"
                                        :custom_select_class="'input_quantity_select_excel'"
                                        :recommendedClass="'input_quantity_recommended'"
                                        :autoSetRecommend="true"
                                    />
                                </div>
                                <div v-if="col?.type === 'Checkbox'">
                                    <FieldExcelError
                                        :name="col.name?.()"
                                        :custom_class="'input_field_error'"
                                        :custom-message="'*'"
                                        :errors="props?.errors"
                                    />
                                    <div class="check-radio-wrap flex-center-y">
                                        <field-input-checkbox :name="col.name?.()" :readonly="col?.readonly" />
                                    </div>
                                </div>
                                <div v-if="col?.type === 'Dropdown'">
                                    <FieldExcelError
                                        :name="col.name?.()"
                                        :custom_class="'input_field_error'"
                                        :custom-message="'*'"
                                        :errors="props?.errors"
                                    />
                                    <field-input-dropdown
                                        :name="col.name?.()"
                                        :recommended="col.recommended?.()"
                                        :modelValuesProps="modelValues"
                                        :readonly="col?.readonly"
                                        :display="col?.display"
                                        :custom_class="'input_dropdown_excel'"
                                    />
                                </div>
                                <div v-if="col?.error?.()">
                                    <p style="color: red; font-weight: bold">
                                        {{ col?.error?.() }}
                                    </p>
                                </div>
                            </article>
                        </template>
                    </section>
                </template>
            </template>
        </template>
    </template>
</template>
