import { AxiosResponse } from 'axios';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
    async get(keys: string[]) {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/info?keys=' + keys.join(','));
        } catch {
            return offlineResponse();
        }
        if (response?.status < 300 && response?.status >= 200) {
            return response.data;
        } else {
            return response;
        }
    },
};
