export const calculatePrefabricatedPieces = (props) => {
  const { GET, ID_GET, SET, VALUE_GET, AREA_GET, SET_ID, index, FEET_UNIT } =
    props;

  const squaresPerUnit = GET(1, ID_GET, VALUE_GET);
  if (squaresPerUnit === 0 || FEET_UNIT) return 0;
  const area = AREA_GET;
  const recommended = Math.ceil(area / squaresPerUnit);
  SET(SET_ID, recommended, index);
  return recommended;
};

export const calculateWallFlashing = (options, wallFlashingTypeValue) => {
  const aluminum = options.find((option) => option.id === "2.96.1");
  const show = wallFlashingTypeValue !== aluminum.id;
  return show;
};
