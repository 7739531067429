import EstimateFieldOption from './EstimateFieldOption';
import EstimateFieldVariantPrice from './EstimateFieldVariantPrice';
import EstimateFieldVariantPriceType from './EstimateFieldVariantPriceType';
import { getArrayFromDto, getArrayOfObjectsFromDto, getValueFromDto as getValue } from './_helper';

type Dto = Record<string, any>;

export default class EstimateFieldVariant {
    fieldId: string | null;
    options: any[];
    pricing: EstimateFieldVariantPrice[];
    materialCost: number;
    minMaterialCost: number;
    laborCost: number;
    materialPrice: number;
    minMaterialPrice: number;
    laborPrice: number;
    priceType: EstimateFieldVariantPriceType;
    optionsMap: Record<string, any>;

    constructor(dto: Dto) {
        this.fieldId = getValue(dto, 'fieldId', 'string', null);
        this.options = getArrayFromDto(dto, 'options');
        this.pricing = getArrayOfObjectsFromDto(dto, 'pricing', EstimateFieldVariantPrice);
        this.materialCost = getValue(dto, 'materialCost', 'number', 0);
        this.minMaterialCost = getValue(dto, 'minMaterialCost', 'number', 0);
        this.laborCost = getValue(dto, 'laborCost', 'number', 0);
        this.materialPrice = getValue(dto, 'materialPrice', 'number', 0);
        this.minMaterialPrice = getValue(dto, 'minMaterialPrice', 'number', 0);
        this.laborPrice = getValue(dto, 'laborPrice', 'number', 0);
        this.priceType = getValue(dto, 'priceType', 'number', EstimateFieldVariantPriceType.standard);

        this.optionsMap = this.options.reduce((o: Record<string, any>, x: any) => {
            o[EstimateFieldOption.getFieldId(x)] = x;
            return o;
        }, {});
    }
}
