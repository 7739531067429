<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: '',
                title: 'Questionnaire',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Years in home',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                inputStyle: 'dropdown',

                                name: () => 'yearsInHome.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Age of insulation',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                inputStyle: 'dropdown',
                                name() {
                                    return 'ageOfInsulation.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Deciding factor',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'decidingFactor.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Preferred timeline',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                display: 'dropdown',
                                name() {
                                    return 'preferredTimeline.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: 'Mold',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'mold.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: 'Ventilation',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'ventilation.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 7,
                        columns: [
                            {
                                id: 1,
                                label: 'Existing R-Value',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'existingRValue.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 8,
                        columns: [
                            {
                                id: 1,
                                label: 'Existing insulation type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'existingInsulationType.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 9,
                        columns: [
                            {
                                id: 1,
                                label: 'Recently added insulation',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name() {
                                    return 'recentlyAddedInsulation.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 10,
                        columns: [
                            {
                                id: 1,
                                label: 'Moisture present',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name() {
                                    return 'moisturePresent.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 11,
                        columns: [
                            {
                                id: 1,
                                label: 'Existing air sealing',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name() {
                                    return 'existingAirSealing.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'previousInsulationProviders',
                title: 'Previous insulation providers',
                setModelValue: (i) => `previousInsulationProviders[${i}].value`,
                quantity: (i) => props?.modelValues.previousInsulationProviders[i].value,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: '--',
                            },
                            {
                                id: 2,
                                type: 'Text',
                                name(i) {
                                    return `previousInsulationProviders[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
