import { url as urlHelper } from '@/helpers';
import FolderLog from '@/models/FolderLog';
import PaginatedList from '@/models/PaginatedList';
import { AxiosResponse } from 'axios';
import { fetchWrap, offlineResponse } from '../_helpers';

interface FolderLogQuery {
    searchQuery?: string;
    limit?: number;
    offset?: number;
}

export default {
    /**
     * Get all folderLogs
     * @returns (async) Returns a PaginatedList of folderLogs if the request was successful, otherwise a Response.
     */
    async getAll(searchQuery: string = '', limit: number = 100, offset: number = 0) {
        const query: FolderLogQuery = {
            searchQuery,
            limit,
            offset,
        };
        const url = urlHelper('/api/folderLog', query);
        let response: AxiosResponse;
        try {
            response = await fetchWrap(url);
        } catch {
            response = offlineResponse();
        }
        if (response?.status < 300 && response?.status >= 200) {
            const data = response.data;
            const model = new PaginatedList(data);
            model.data = data.data.map((x: any) => new FolderLog(x));

            return model;
        } else {
            return response;
        }
    },
};
