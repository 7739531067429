import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useField } from 'vee-validate';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputNumberField',
  props: {
	name: {
		type: String,
		required: true,
	},
	defaultValue: {
		type: Number,
		required: true,
	},
},
  setup(__props) {

const props = __props;
const { value: modelValue } = useField(computed(() => props.name));

return (_ctx: any,_cache: any) => {
  const _component_input_number = _resolveComponent("input-number")!

  return (_openBlock(), _createBlock(_component_input_number, {
    modelValue: _unref(modelValue),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(modelValue) ? (modelValue).value = $event : null)),
    defaultValue: __props.defaultValue
  }, null, 8, ["modelValue", "defaultValue"]))
}
}

})