/* eslint-disable no-unused-vars */
import { round } from '@/helpers';
import { fieldInfo } from '@/models/EstimateFields';
import { DateTime } from 'luxon';
import {
    getArrayOfObjectsFromDto as getArrayOfObjects,
    getDateTimeFromDto as getDate,
    getValueFromDto as getValue,
} from './_helper';
import { DocumentReference, DocumentType } from './Document';
import EstimateComparison from './EstimateComparison';
import EstimateField from './EstimateField';
import EstimateItem from './EstimateItem';
import EstimateWorkflows from './EstimateWorkflows';
import EstimateWorkflowSummary from './EstimateWorkflowSummary';

interface WorkflowSummaryDTO {
    workflowSummaries: { [key: string]: EstimateWorkflowSummary };
}

export default class Estimate {
    id: number;
    leadId: number | null;
    name: string;
    items: EstimateItem[];
    comparisons: EstimateComparison[];
    workflowSummaries: Record<string, EstimateWorkflowSummary>;
    modifiedTime: DateTime | null;
    createdTime: DateTime | null;
    documents: DocumentReference[];
    workflowIds: number[];
    paymentOption: number;
    promotion: number;
    downpayment: number;
    principal: number;
    monthlyRate: number;
    monthlyPayment: number;
    term: Record<string, unknown>;
    private _itemTreeByParent: Record<string, EstimateItem[]> | null;
    private _itemTreeByField: Record<string, EstimateItem[]> | null;

    constructor(dto: {}) {
        this.id = getValue(dto, 'id', 'number', 0);
        this.leadId = getValue(dto, 'leadId', 'number', null);
        this.name = getValue(dto, 'name', 'string', '');
        this.items = getArrayOfObjects(dto, 'items', EstimateItem);
        this.comparisons = getArrayOfObjects(dto, 'comparisons', EstimateComparison);
        this.workflowSummaries = {};
        this.modifiedTime = getDate(dto, 'modifiedTime', null);
        this.createdTime = getDate(dto, 'createdTime', null);
        this.documents = getArrayOfObjects(dto, 'documents', DocumentReference);
        this.workflowIds = getValue(dto, 'workflowIds', 'object', []);
        this.paymentOption = getValue(dto, 'paymentOption', 'number', 0);
        this.promotion = getValue(dto, 'promotion', 'number', 0);
        this.downpayment = getValue(dto, 'downpayment', 'number', 0);
        this.principal = getValue(dto, 'principal', 'number', 0);
        this.monthlyRate = getValue(dto, 'monthlyRate', 'number', 0);
        this.monthlyPayment = getValue(dto, 'monthlyPayment', 'number', 0);
        this.term = getValue(dto, 'term', 'object', {});
        this._itemTreeByParent = null;
        this._itemTreeByField = null;

        if (typeof dto === 'object' && dto !== null) {
            const typedDto = dto as WorkflowSummaryDTO;
            if (typeof typedDto.workflowSummaries === 'object') {
                for (const key of Object.keys(typedDto.workflowSummaries)) {
                    this.workflowSummaries[key] = new EstimateWorkflowSummary(typedDto.workflowSummaries[key]);
                }
            }
        }
    }

    get itemTreeByParent(): Record<string, EstimateItem[]> | null {
        if (!this._itemTreeByParent) {
            this.refreshItemTree();
        }
        return this._itemTreeByParent;
    }

    get itemTreeByField(): Record<string, EstimateItem[]> | null {
        if (!this._itemTreeByField) {
            this.refreshItemTree();
        }
        return this._itemTreeByField;
    }

    refreshItemTree() {
        const tree: Record<string, EstimateItem[]> = {};
        const treeByField: Record<string, EstimateItem[]> = {};
        for (const item of this.items) {
            (tree[item?.parentItemId as number] ||= []).push(item);
            (treeByField[item?.fieldId as string] ||= []).push(item);
        }
        this._itemTreeByParent = tree;
        this._itemTreeByField = treeByField;
    }

    clearItemTree() {
        this._itemTreeByParent = null;
        this._itemTreeByField = null;
    }

    get total() {
        let total = 0;
        for (const key in this.workflowSummaries) {
            if (Object.hasOwnProperty.call(this.workflowSummaries, key)) {
                total += this.workflowSummaries[key].totalWithMargin;
            }
        }
        return total;
    }

    get contracts() {
        return this.documents.filter((x) => x.type === DocumentType.contract);
    }

    get changeOrders() {
        return this.documents.filter((x) => x.type === DocumentType.changeOrder);
    }

    get purchaseOrders() {
        return this.documents.filter((x) => x.type === DocumentType.purchaseOrder);
    }

    get workOrders() {
        return this.documents.filter((x) => x.type === DocumentType.workOrder);
    }

    get costEstimates() {
        return this.documents.filter((x) => x.type === DocumentType.costEstimate);
    }

    get laborBills() {
        return this.documents.filter((x) => x.type === DocumentType.laborBill);
    }

    get finalLaborBills() {
        return this.documents.filter((x) => x.type === DocumentType.finalLaborBill);
    }

    get itemizedEstimates() {
        return this.documents.filter((x) => x.type === DocumentType.itemizedEstimate);
    }

    clone(): Estimate {
        return new Estimate(JSON.parse(JSON.stringify(this)));
    }

    updateItems(items: EstimateItem[], fieldIds: string[], fields: Record<string, any>, skipNormalize = false) {
        const groupedItems = items.reduce<Record<string, EstimateItem[]>>((obj, x) => {
            (obj[x.fieldId as string] ||= []).push(x);
            return obj;
        }, {});

        fieldIds.forEach((x) => {
            const field = fields[x];
            if (field) {
                field.descendantIds.forEach((y: string) => {
                    groupedItems[y] ||= [];
                });
            }
        });

        const workflowIds = new Set<number>();
        const itemFieldIds = new Set(Object.keys(groupedItems));
        const validFieldIds = new Set(Object.keys(fieldInfo).filter((x) => !itemFieldIds.has(x)));
        this.items = this.items.filter((x) => validFieldIds.has(x.fieldId as string));

        const permitFields = ['1.36', '2.43', '2.46', '2.841', '2.843', '2.845', '2.847'];
        for (const fieldId in groupedItems) {
            if (Object.hasOwnProperty.call(groupedItems, fieldId)) {
                const itemTemp = items.find((x) => x.fieldId === fieldId);
                const workflowId = EstimateField.getWorkflowId(fieldId);
                workflowIds.add(workflowId);

                const newItems = groupedItems[fieldId];
                for (const item of newItems) {
                    let quantity = item.quantity;

                    if (permitFields.includes(item.fieldId as string) && item.quantity <= 1) {
                        quantity = 1;
                    }

                    if (
                        item instanceof EstimateItem &&
                        quantity > 0 &&
                        item.value !== null &&
                        item.value !== undefined
                    ) {
                        this.items.push(item);
                    }
                }
            }
        }

        if (!skipNormalize) {
            this.items.sort((a, b) => (a.id < 0 && b.id < 0 ? b.id - a.id : a.id - b.id));
        }

        const itemTotals: Record<
            string,
            { nonDiscountableItemTotal: number; discountableItemTotal: number; cost: number }
        > = {};
        workflowIds.forEach(
            (x) => (itemTotals[x] = { nonDiscountableItemTotal: 0, discountableItemTotal: 0, cost: 0 })
        );

        for (const item of this.items) {
            if (item.parentItemId !== null) continue;
            const field = fields[item.fieldId as string];
            const workflowId = field.workflowId;

            if (!workflowIds.has(workflowId)) continue;
            if (field.excludeFromDiscount) {
                itemTotals[workflowId].nonDiscountableItemTotal += item.total;
            } else {
                itemTotals[workflowId].discountableItemTotal += item.total;
            }
            itemTotals[workflowId].cost += item.totalCost;
        }

        for (const workflowId of workflowIds) {
            const total = itemTotals[workflowId];
            const summary = this.getWorkflowSummary(workflowId);
            summary.nonDiscountableItemTotal = round(total.nonDiscountableItemTotal, 2);
            summary.discountableItemTotal = round(total.discountableItemTotal, 2);
            summary.totalCost = round(total.cost, 2);
        }

        this.clearItemTree();
    }

    updateComparisons(workflowId: number, comparisons: EstimateComparison[]) {
        this.comparisons = this.comparisons.filter((comp) => comp.workflowId !== workflowId);
        for (const comparison of comparisons) {
            if (
                comparison instanceof EstimateComparison &&
                comparison.workflowId === workflowId &&
                comparison.fieldOptionId
            ) {
                this.comparisons.push(comparison);
            }
        }
    }

    updatePromotion(workflowId: number, promotion: number) {
        const summary = this.getWorkflowSummary(workflowId);
        summary.promotion = round(promotion, 2);
    }

    updateFee(workflowId: number, fee: number) {
        const summary = this.getWorkflowSummary(workflowId);
        summary.fee = round(fee, 2);
    }

    getWorkflowSummary(workflowId: number) {
        const workflowKey = EstimateWorkflows.getKey(workflowId);
        if (!this.workflowSummaries[workflowKey]) {
            this.workflowSummaries[workflowKey] = new EstimateWorkflowSummary();
        }
        return this.workflowSummaries[workflowKey];
    }
    getWorkflowSummaryByKey(workflowKey: string) {
        let summary = this.workflowSummaries[workflowKey];
        if (!(summary instanceof EstimateWorkflowSummary)) {
            summary = this.workflowSummaries[workflowKey] = new EstimateWorkflowSummary();
        }
        return summary;
    }
    updatePaymentOption(
        workflowIds: number[],
        paymentOption: number,
        promotion: number,
        downpayment: number,
        principal: number,
        monthlyPayment: number,
        term: Record<string, unknown>
    ) {
        this.workflowIds = workflowIds;
        this.paymentOption = paymentOption;
        this.promotion = promotion;
        this.downpayment = downpayment;
        this.principal = principal;
        this.monthlyPayment = monthlyPayment;
        this.term = term;
    }
}
