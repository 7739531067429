import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { roundUpToStepAndClamp } from '@/helpers';
    import { computed, onMounted, ref, watch } from 'vue';

    import {
        calculateEdgeDetailRecommend,
        calculateInsulationRecommended,
        calculatePlateRecommended,
        calculateScrewsRecommended,
    } from '@/helpers/estimator/roofing/08_flatroof';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimteCostValues } from '../mocks';

    // const $store = useStore();
    // const fieldsRef = safeComputed(() => $store.state.estimator.fields);
    
export default /*@__PURE__*/_defineComponent({
  __name: 'FlatRoofingComponent',
  props: {
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    },
  setup(__props) {

    // import { getFieldValuesFromEstimate } from '@/composables/estimate';
    // import { safeComputed } from '@/composables/safeComputed';
    // import { useStore } from 'vuex';
    const isFirstRemoved = ref(false);
    const membraneWaste = ref(0);

    const props = __props;

    // const altFieldIds = ['1.43'];
    // const altModelValues = computed(() =>
    //     getFieldValuesFromEstimate(props?.estimate.itemTreeByField, fieldsRef.value, altFieldIds)
    // );

    const insulationRecommended = computed(() => {
        return calculateInsulationRecommended(
            props?.modelValues.membrane,
            props?.modelValues.insulation,
            props?.model.insulation.field,
            props?.model.insulation.field.id,
            props?.getRecommendedConversion,
            props?.setRecommendedItem
        );
    });

    const edgeDetailRecommended = computed(() => {
        return calculateEdgeDetailRecommend(
            props?.modelValues.edgeDetail,
            props?.model.edgeDetail.children.edgeDetailType.field,
            props?.model.edgeDetail.children.edgeDetailType.field.id,
            props?.getRecommendedConversion,
            props?.setRecommendedItem
        );
    });

    const plateRecommended = computed(() => {
        return calculatePlateRecommended(
            props?.modelValues.insulation,
            props?.modelValues.plate,
            props?.model.plate.field,
            props?.model.plate.field.id,
            props?.getRecommendedConversion,
            props?.setRecommendedItem
        );
    });

    const screwsRecommended = computed(() => {
        return calculateScrewsRecommended(
            props?.modelValues.insulation,
            props?.modelValues.screws,
            props?.model.screws.field,
            props?.model.screws.field.id,
            props?.getRecommendedConversion,
            props?.setRecommendedItem
        );
    });

    function updateTotalSquares() {
        for (let i = 0; i < props?.modelValues.membrane.length; i++) {
            const item = props?.modelValues.membrane[i];

            let area = item.children.membraneArea.quantity;

            // if (!isFirstRemoved.value && i == 0 && area !== 0 && installPitch) {
            //     const flatRoof = installPitch.quantity;
            //     const wasteFactor = item.children.membraneWasteFactorForMaterial.quantity;
            //     area = flatRoof / (1 + wasteFactor / 100);
            // }

            const wasteFactorForMaterial = item.children.membraneWasteFactorForMaterial.quantity;

            if (membraneWaste.value !== wasteFactorForMaterial) {
                const membraneArea = roundUpToStepAndClamp(area, props?.model.membrane.children.membraneArea.field);
                props?.setModelValue(`membrane[${i}].children.membraneArea.quantity`, membraneArea);
                membraneWaste.value = wasteFactorForMaterial;
            }

            const materialWasteFactor = 1 + wasteFactorForMaterial / 100;
            const squaresForMaterial = roundUpToStepAndClamp(
                materialWasteFactor * area,
                props?.model.membrane.children.membraneType.field
            );
            if (squaresForMaterial !== item.children.membraneType.quantity) {
                props?.setModelValue(`membrane[${i}].children.membraneType.quantity`, squaresForMaterial);
            }

            const wasteFactorForLabor = roundUpToStepAndClamp(
                wasteFactorForMaterial,
                props?.model.membrane.children.membraneWasteFactorForLabor.field
            );
            if (wasteFactorForLabor !== item.children.membraneWasteFactorForLabor.quantity) {
                props?.setModelValue(
                    `membrane[${i}].children.membraneWasteFactorForLabor.quantity`,
                    wasteFactorForLabor
                );
            }
            const laborWasteFactor = 1 + wasteFactorForLabor / 100;
            const squaresForLabor = roundUpToStepAndClamp(
                laborWasteFactor * area,
                props?.model.membrane.children.membraneLabor.field
            );
            if (squaresForLabor !== item.children.membraneLabor.quantity) {
                props?.setModelValue(`membrane[${i}].children.membraneLabor.quantity`, squaresForLabor);
            }
        }
    }
    watch(() => props?.modelValues.membrane, updateTotalSquares, { deep: true });

    function removed() {
        isFirstRemoved.value = true;
    }

    onMounted(() => {
        updateTotalSquares();
    });
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: 'membrane',
                title: 'Low Slope Details Membrane',
                isFieldArray: true,
                quantity: () => 0,
                setModelValue: () => '',
                clearType: 'REMOVE_ADD',
                clearCallback: () => {
                    removed();
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Membrane',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `membrane[${i}].children.membraneType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `membrane[${i}].children.membraneColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Area Square',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `membrane[${i}].children.membraneArea.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Waste Factor',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `membrane[${i}].children.membraneWasteFactorForMaterial.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: 'Material Total Squares',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                readonly: true,
                                name: (i) => {
                                    return `membrane[${i}].children.membraneType.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: 'Labor Total',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                readonly: true,
                                name: (i) => {
                                    return `membrane[${i}].children.membraneLabor.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'insulation',
                title: 'Recover Board - insulation',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.insulation[i].quantity,
                setModelValue: (i) => `insulation[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Insulation',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `insulation[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Area Sheets',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `insulation[${i}].quantity`;
                                },
                                recommended: (i) => {
                                    return insulationRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: 'edgeDetail',
                title: 'Flashing Details - edgeDetail',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.insulation[i].quantity,
                setModelValue: (i) => `insulation[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Edge Detail',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `edgeDetail[${i}].children.edgeDetailType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Length Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `edgeDetail[${i}].children.edgeDetailLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `edgeDetail[${i}].children.edgeDetailType.quantity`;
                                },
                                recommended: (i) => {
                                    return edgeDetailRecommended?.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: 'corner',
                title: 'Corner / T joints',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.corner[i].quantity,
                setModelValue: (i) => `corner[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Corner / T joints',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `corner[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `corner[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                name: 'pipeBoot',
                title: 'Pipe Boot',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.pipeBoot[i].quantity,
                setModelValue: (i) => `pipeBoot[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Pipe Boot',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `pipeBoot[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `pipeBoot[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                name: 'coverTape',
                title: 'Cover tape / accessories',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.coverTape[i].quantity,
                setModelValue: (i) => `coverTape[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Cover tape / accessories',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `coverTape[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `coverTape[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 7,
                name: 'drain',
                title: 'Drain',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.drain[i].quantity,
                setModelValue: (i) => `drain[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Drain',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `drain[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `drain[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 8,
                name: 'adhesive',
                title: 'Adhesives / Primes',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.adhesive[i].quantity,
                setModelValue: (i) => `adhesive[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Boding Adhesive',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `adhesive[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `adhesive[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 9,
                name: 'sealants',
                title: 'Sealants',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.sealants[i].quantity,
                setModelValue: (i) => `sealants[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Sealants',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `sealants[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `sealants[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 10,
                name: 'plate',
                title: 'Fasteners / Plates',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.plate[i].quantity,
                setModelValue: (i) => `plate[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Plate',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `plate[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `plate[${i}].quantity`;
                                },
                                recommended: (i) => {
                                    return plateRecommended?.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 11,
                name: 'screws',
                title: 'screws',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.screws[i].quantity,
                setModelValue: (i) => `screws[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Screws',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `screws[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `screws[${i}].quantity`;
                                },
                                recommended: (i) => {
                                    return screwsRecommended?.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 12,
                name: 'applicationTools',
                title: 'Application tools',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.applicationTools[i].quantity,
                setModelValue: (i) => `applicationTools[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Application tools',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `applicationTools[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => `applicationTools[${i}].quantity`,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 13,
                name: 'railingSections',
                title: 'Railings Sections',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.railingSections[i].quantity,
                setModelValue: (i) => `railingSections[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Railing Sections',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `railingSections[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `railingSections[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 14,
                name: 'railingPosts',
                title: 'Railings Posts',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.railingPosts[i].quantity,
                setModelValue: (i) => `railingPosts[${i}].quantity`,
                clearCallback: () => {},
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Railing posts',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `railingPosts[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `railingPosts[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ExcelFields, {
    modelValues: props?.modelValues,
    setModelValue: props?.setModelValue,
    sections: listSections.value,
    errors: props?.errors
  }, null, 8, ["modelValues", "setModelValue", "sections", "errors"]))
}
}

})