<script setup>
    // <script setup lang = "ts" >

    import { computed, defineProps } from 'vue';

    import { formatCurrency } from '@/helpers';
    import { calculatePrefabricatedPieces, calculateWallFlashing } from '@/helpers/estimator/siding/06_wraps';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimteCostValues } from '../mocks.ts';
    // import { SectionProps, TypeEstimate } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const showPrefabricated = computed(() => {
        if (!Array.isArray(props?.modelValues.wallFlashing)) {
            return [];
        }
        return props?.modelValues.wallFlashing.map((x, i) => {
            const options = props?.model.wallFlashing.children.wallFlashingType.field.options;
            const wallFlashingType = props?.modelValues.wallFlashing[i].children.wallFlashingType.value;

            return calculateWallFlashing(options, wallFlashingType);
        });
    });
    const prefabricatedPiecesRecommended = computed(() => {
        if (!Array.isArray(props?.modelValues.wallFlashing)) {
            return [];
        }

        // return props?.modelValues.wallFlashing.map((x: any, i: number) => {
        return props?.modelValues.wallFlashing.map((x, i) => {
            return calculatePrefabricatedPieces({
                GET: props?.getRecommendedConversion,
                ID_GET: props?.model.wallFlashing.children.wallFlashingType.field,
                VALUE_GET: props?.modelValues.wallFlashing[i].children.wallFlashingType.value,
                AREA_GET: props?.modelValues.wallFlashing[i].children.wallFlashingQuantity.quantity,
                SET: props?.setRecommendedItem,
                SET_ID: props?.model.wallFlashing.children.wallFlashingType.field.id,
                index: i,
                FEET_UNIT: showPrefabricated.value[i],
            });
        });
    });

    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Window casing',
                name: 'windowCasing',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.windowCasing[i].quantity,
                setModelValue: (i) => `windowCasing[${i}].quantity`,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'Total',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Window casing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `windowCasing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `windowCasing[${i}].children.windowCasingType.value`;
                                },
                            },
                            {
                                id: 3,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const material = props?.fieldsEstimateItems?.find(
                                    //   (e: TypeEstimate) => e?.id === item?.id
                                    // )?.childItems?.[0]?.material;
                                    const material = props?.fieldsEstimateItems?.find((e) => e?.id === item?.id)
                                        ?.childItems?.[0]?.material;
                                    if (!material) {
                                        return '--';
                                    }
                                    return `${formatCurrency(material)}`;
                                },
                            },
                            {
                                id: 4,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const labor = props?.fieldsEstimateItems?.find(
                                    //   (e: TypeEstimate) => e?.id === item?.id
                                    // )?.childItems?.[0]?.labor;
                                    const labor = props?.fieldsEstimateItems?.find((e) => e?.id === item?.id)
                                        ?.childItems?.[0]?.labor;
                                    if (!labor) {
                                        return '--';
                                    }
                                    return `${formatCurrency(labor)}`;
                                },
                            },
                            {
                                id: 5,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const total = props?.fieldsEstimateItems?.find(
                                    //   (e: TypeEstimate) => e?.id === item?.id
                                    // )?.childItems?.[0]?.total;
                                    const total = props?.fieldsEstimateItems?.find((e) => e?.id === item?.id)
                                        ?.childItems?.[0]?.total;
                                    if (!total) {
                                        return '--';
                                    }
                                    return `${formatCurrency(total)}`;
                                },
                            },
                            {
                                id: 6,
                                label: '--',
                            },
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `windowCasing[${i}].children.windowCasingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Garage door casing',
                name: 'garageDoorCasing',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.garageDoorCasing[i].quantity,
                setModelValue: (i) => `garageDoorCasing[${i}].quantity`,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'Total',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Garage door casing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `garageDoorCasing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `garageDoorCasing[${i}].children.garageDoorCasingType.value`;
                                },
                            },
                            {
                                id: 3,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const material = props?.fieldsEstimateItems?.find(
                                    //   (e: TypeEstimate) => e?.id === item?.id
                                    // )?.childItems?.[0]?.material;
                                    const material = props?.fieldsEstimateItems?.find((e) => e?.id === item?.id)
                                        ?.childItems?.[0]?.material;
                                    if (!material) {
                                        return '--';
                                    }
                                    return `${formatCurrency(material)}`;
                                },
                            },
                            {
                                id: 4,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const labor = props?.fieldsEstimateItems?.find(
                                    //   (e: TypeEstimate) => e?.id === item?.id
                                    // )?.childItems?.[0]?.labor;
                                    const labor = props?.fieldsEstimateItems?.find((e) => e?.id === item?.id)
                                        ?.childItems?.[0]?.labor;
                                    if (!labor) {
                                        return '--';
                                    }
                                    return `${formatCurrency(labor)}`;
                                },
                            },
                            {
                                id: 5,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const total = props?.fieldsEstimateItems?.find(
                                    //   (e: TypeEstimate) => e?.id === item?.id
                                    // )?.childItems?.[0]?.total;
                                    const total = props?.fieldsEstimateItems?.find((e) => e?.id === item?.id)
                                        ?.childItems?.[0]?.total;
                                    if (!total) {
                                        return '--';
                                    }
                                    return `${formatCurrency(total)}`;
                                },
                            },
                            {
                                id: 6,
                                label: '--',
                            },
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `garageDoorCasing[${i}].children.garageDoorCasingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                title: 'Garage door Jamb',
                name: 'garageDoorJamb',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.garageDoorJamb[i].quantity,
                setModelValue: (i) => `garageDoorJamb[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Garage door jamb',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `garageDoorJamb[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `garageDoorJamb[${i}].children.garageDoorJambType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `garageDoorJamb[${i}].children.garageDoorJambColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                title: 'Service door casing',
                name: 'serviceDoorCasing',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.serviceDoorCasing[i].quantity,
                setModelValue: (i) => `serviceDoorCasing[${i}].quantity`,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'Total',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Service door casing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `serviceDoorCasing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `serviceDoorCasing[${i}].children.serviceDoorCasingType.value`;
                                },
                            },
                            {
                                id: 3,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const material = props?.fieldsEstimateItems?.find(
                                    //   (e: TypeEstimate) => e?.id === item?.id
                                    // )?.childItems?.[0]?.material;
                                    const material = props?.fieldsEstimateItems?.find((e) => e?.id === item?.id)
                                        ?.childItems?.[0]?.material;
                                    if (!material) {
                                        return '--';
                                    }
                                    return `${formatCurrency(material)}`;
                                },
                            },
                            {
                                id: 4,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const labor = props?.fieldsEstimateItems?.find(
                                    //   (e: TypeEstimate) => e?.id === item?.id
                                    // )?.childItems?.[0]?.labor;
                                    const labor = props?.fieldsEstimateItems?.find((e) => e?.id === item?.id)
                                        ?.childItems?.[0]?.labor;
                                    if (!labor) {
                                        return '--';
                                    }
                                    return `${formatCurrency(labor)}`;
                                },
                            },
                            {
                                id: 5,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const total = props?.fieldsEstimateItems?.find(
                                    //   (e: TypeEstimate) => e?.id === item?.id
                                    // )?.childItems?.[0]?.total;
                                    const total = props?.fieldsEstimateItems?.find((e) => e?.id === item?.id)
                                        ?.childItems?.[0]?.total;
                                    if (!total) {
                                        return '--';
                                    }
                                    return `${formatCurrency(total)}`;
                                },
                            },
                            {
                                id: 6,
                                label: '--',
                            },
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `serviceDoorCasing[${i}].children.serviceDoorCasingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                title: 'Wall Flashing',
                name: 'wallFlashing',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.wallFlashing[i].quantity,
                setModelValue: (i) => `wallFlashing[${i}].quantity`,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'Total',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `wallFlashing[${i}].children.wallFlashingQuantity.quantity`;
                                },
                            },
                            {
                                id: 3,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const material = props?.fieldsEstimateItems
                                    //   ?.find((e: TypeEstimate) => e?.id === item?.id)
                                    //   ?.childItems?.find(
                                    //     (e: { label: string }) =>
                                    //       e?.label?.toUpperCase() === "LENGTH"
                                    //   )?.material;
                                    const material = props?.fieldsEstimateItems
                                        ?.find((e) => e?.id === item?.id)
                                        ?.childItems?.find((e) => e?.label?.toUpperCase() === 'LENGTH')?.material;
                                    if (!material) {
                                        return '--';
                                    }
                                    return `${formatCurrency(material)}`;
                                },
                            },
                            {
                                id: 4,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const labor = props?.fieldsEstimateItems
                                    //   ?.find((e: TypeEstimate) => e?.id === item?.id)
                                    //   ?.childItems?.find(
                                    //     (e: { label: string }) =>
                                    //       e?.label?.toUpperCase() === "LENGTH"
                                    //   )?.labor;
                                    const labor = props?.fieldsEstimateItems
                                        ?.find((e) => e?.id === item?.id)
                                        ?.childItems?.find((e) => e?.label?.toUpperCase() === 'LENGTH')?.labor;
                                    if (!labor) {
                                        return '--';
                                    }
                                    return `${formatCurrency(labor)}`;
                                },
                            },
                            {
                                id: 5,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const total = props?.fieldsEstimateItems
                                    //   ?.find((e: TypeEstimate) => e?.id === item?.id)
                                    //   ?.childItems?.find(
                                    //     (e: { label: string }) =>
                                    //       e?.label?.toUpperCase() === "LENGTH"
                                    //   )?.total;
                                    const total = props?.fieldsEstimateItems
                                        ?.find((e) => e?.id === item?.id)
                                        ?.childItems?.find((e) => e?.label?.toUpperCase() === 'LENGTH')?.total;

                                    if (!total) {
                                        return '--';
                                    }
                                    return `${formatCurrency(total)}`;
                                },
                            },
                            {
                                id: 6,
                                label: '--',
                            },
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `wallFlashing[${i}].children.wallFlashingType.value`;
                                },
                            },
                            {
                                id: 3,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const material = props?.fieldsEstimateItems
                                    //   ?.find((e: TypeEstimate) => e?.id === item?.id)
                                    //   ?.childItems?.find(
                                    //     (e: { label: string }) => e?.label?.toUpperCase() === "TYPE"
                                    //   )?.material;
                                    const material = props?.fieldsEstimateItems
                                        ?.find((e) => e?.id === item?.id)
                                        ?.childItems?.find((e) => e?.label?.toUpperCase() === 'TYPE')?.material;
                                    if (!material) {
                                        return '--';
                                    }
                                    return `${formatCurrency(material)}`;
                                },
                            },
                            {
                                id: 4,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const labor = props?.fieldsEstimateItems
                                    //   ?.find((e: TypeEstimate) => e?.id === item?.id)
                                    //   ?.childItems?.find(
                                    //     (e: { label: string }) => e?.label?.toUpperCase() === "TYPE"
                                    //   )?.labor;
                                    const labor = props?.fieldsEstimateItems
                                        ?.find((e) => e?.id === item?.id)
                                        ?.childItems?.find((e) => e?.label?.toUpperCase() === 'TYPE')?.labor;
                                    if (!labor) {
                                        return '--';
                                    }
                                    return `${formatCurrency(labor)}`;
                                },
                            },
                            {
                                id: 5,
                                type: 'Cost',
                                estimateField(i, item) {
                                    // const total = props?.fieldsEstimateItems
                                    //   ?.find((e: TypeEstimate) => e?.id === item?.id)
                                    //   ?.childItems?.find(
                                    //     (e: { label: string }) => e?.label?.toUpperCase() === "TYPE"
                                    //   )?.total;
                                    const total = props?.fieldsEstimateItems
                                        ?.find((e) => e?.id === item?.id)
                                        ?.childItems?.find((e) => e?.label?.toUpperCase() === 'TYPE')?.total;
                                    if (!total) {
                                        return '--';
                                    }
                                    return `${formatCurrency(total)}`;
                                },
                            },
                            {
                                id: 6,
                                label: '--',
                            },
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `wallFlashing[${i}].children.wallFlashingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        show: (i) => showPrefabricated.value[Number(i)],
                        columns: [
                            {
                                id: 1,
                                label: 'Prefabricated Pieces',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `wallFlashing[${i}].children.wallFlashingType.quantity`;
                                },
                                recommended: (i) => prefabricatedPiecesRecommended.value[Number(i)],
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                title: 'Custom',
                name: 'customWrap',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.customWrap[i].quantity,
                setModelValue: (i) => `customWrap[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Custom wrap',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `customWrap[${i}].quantity`;
                                },
                            },

                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `customWrap[${i}].children.customWrapType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `customWrap[${i}].children.customWrapColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
