import EstimateWorkflows from './EstimateWorkflows';

type EstimateWorkflowDTO = {
    id: number;
    enabled: boolean;
    alias: string;
};

class EstimateWorkflow {
    id: number;
    enabled: boolean;
    alias: string;
    sections: any[];
    loading: boolean;

    constructor(dto: Partial<EstimateWorkflowDTO>) {
        this.id = dto.id ?? 0;
        this.enabled = dto.enabled ?? false;
        this.alias = dto.alias ?? '';
        this.sections = [];
        this.loading = false;
    }

    setDynamicData(dto: Partial<EstimateWorkflowDTO>) {
        this.id = dto.id ?? 0;
        this.enabled = dto.enabled ?? false;
        this.alias = dto.alias ?? '';
        this.sections = [];
        this.loading = false;
    }

    get key() {
        return EstimateWorkflows.getKey(this.id);
    }

    get name() {
        return EstimateWorkflows.getName(this.id);
    }

    get originalName() {
        return EstimateWorkflows.getOriginalName(this.id);
    }

    get order() {
        return EstimateWorkflows.getOrder(this.id);
    }
}

export default EstimateWorkflow;
