import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosInstance from './interceptor';
import companyId from './modules/companyId';

// Base URL
export const APP_URL_ORIGIN = process.env.VUE_APP_API_BASE_URL;
export const API_USER_SELF_CACHE_NAME = 'ihs-user-self-cache';
export const API_IMAGE_CACHE_NAME = 'ihs-images-cache';

// Offline and error response utilities
export function offlineResponse() {
    return {
        data: null,
        status: 503,
        statusText: 'Offline',
        headers: {},
        config: {},
    } as AxiosResponse;
}

export function isOfflineResponse(response: AxiosResponse): boolean {
    return response.status === 503 && response.statusText === 'Offline';
}

export function abortedResponse() {
    return {
        data: null,
        status: 418,
        statusText: 'Request aborted',
        headers: {},
        config: {},
    } as AxiosResponse;
}

export function isAbortedResponse(response: AxiosResponse) {
    return response.status === 418 && response.statusText === 'Request aborted';
}

export function notFoundResponse() {
    return {
        data: null,
        status: 404,
        statusText: 'Not Found',
        headers: {},
        config: {},
    } as AxiosResponse;
}

// Axios middleware
export const fetchMiddleware = async (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
    const fullUrl = addCompanyIdToUrl(url);

    try {
        return await axiosInstance.request({
            url: fullUrl,
            ...config,
        });
    } catch (error) {
        if (!navigator.onLine) {
            return offlineResponse();
        }
        if (axios.isCancel(error)) {
            return abortedResponse();
        }
        throw error;
    }
};
// export const fetchMiddleware = (url: string, init?: RequestInit) => {
//     const accountStore = useAccountStore();
//     const { accessToken } = storeToRefs(accountStore);
//     const urlTransformed = new URL(url, APP_URL_ORIGIN);
//     return fetch(urlTransformed.href, {
//         ...init,
//         headers: {
//             ...init?.headers,
//             Authorization: `Bearer ${accessToken.value}`,
//         },
//     });
// };

// Utility function wrapping Axios calls
export function fetchWrap(url: string, init?: RequestInit): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
        method: init?.method || 'GET',
        headers: init?.headers ? Object.fromEntries(new Headers(init.headers).entries()) : undefined,
        data: init?.body,
    };
    return fetchMiddleware(url, config);
}

// Helper to add companyId to URL
export function addCompanyIdToUrl(url: string): string {
    const _companyId = companyId.get();
    if (_companyId) {
        const newUrl = new URL(url, APP_URL_ORIGIN);
        newUrl.searchParams.set('companyId', `${_companyId}`);
        return newUrl.href;
    }
    return url;
}

export default axiosInstance;
