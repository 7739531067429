<script setup>
    import { calculateOrganized } from '@/helpers/estimator/siding/09_trim';
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKeyChild, estimteCostValues } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const trimCoilFeetFasciaWrap = computed(() => {
        const fasciaColorField = props?.model.fasciaWrap.children.fasciaWrapColor.field;
        return props?.modelValues?.fasciaWrap.map((x) => {
            const length = x.quantity;
            const rowsPerFoot =
                props?.getRecommendedConversion(
                    1,
                    props?.model.fasciaWrap.children.fasciaWrapWidth.field,
                    x.children.fasciaWrapWidth.value
                ) || 1;
            const colorOptionId = x.children.fasciaWrapColor.value;
            const color = fasciaColorField.options.find((x) => x.id === colorOptionId)?.value ?? '';
            return { color, feet: length * rowsPerFoot };
        });
    });
    const trimCoilFeetRecommended = computed(() => {
        const customColorField = props?.model.customWrap.children.customWrapColor.field;
        const organized = Array.isArray(props?.modelValues.customWrap)
            ? props?.modelValues.customWrap.map((x) => {
                  const length = x.quantity;
                  const rowsPerFoot =
                      props?.getRecommendedConversion(
                          1,
                          props?.model.customWrap.children.customWrapType.field,
                          x.children.customWrapType.value
                      ) || 1;
                  const colorOptionId = x.children.customWrapColor.value;
                  const color = customColorField.options.find((x) => x.id == colorOptionId)?.value ?? '';

                  return { color, feet: length * rowsPerFoot };
              })
            : [];
        organized.push(...trimCoilFeetFasciaWrap.value);

        return calculateOrganized(organized);
    });

    const formatTrimCoilFeetRecommended = computed(() => {
        const trimCoilFeetRecommendedValue = trimCoilFeetRecommended.value;
        if (Object.keys(trimCoilFeetRecommendedValue).length === 0) {
            return '';
        }
        return Object.values(trimCoilFeetRecommendedValue)
            .map((x) => `${x.feet} ft of ${x.color}`)
            .join(', ');
    });

    const trimCoilRecommended = computed(() => {
        const combined = trimCoilFeetRecommended.value;
        const colorField = props.model.trimCoil.children.trimCoilColor.field;
        return Array.isArray(props.modelValues.trimCoil)
            ? props.modelValues.trimCoil.map((x, i) => {
                  let recommended;
                  const feetPerUnit = props.getRecommendedConversion(
                      1,
                      props.model.trimCoil.children.trimCoilType.field,
                      props.modelValues.trimCoil[i].children.trimCoilType.value
                  );
                  if (feetPerUnit === 0) {
                      recommended = 0;
                      props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                      return recommended;
                  }

                  const optionId = x.children.trimCoilColor.value;
                  const option = colorField.options.find((x) => x.id === optionId);
                  if (option) {
                      const color = option.value.toLowerCase();
                      if (color in combined) {
                          recommended = Math.ceil(combined[color].feet / feetPerUnit);
                          props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                          return recommended;
                      }
                  }

                  recommended = 0;
                  props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });

    const listSections = computed(() => {
        return [
            {
                id: '4103fe0d-8f15-49d6-ba9f-5bdd9bbb56fd',
                name: 'customWrap',
                quantity: (i) => props?.modelValues.customWrap[i].quantity,
                setModelValue: (i) => `customWrap[${i}].quantity`,
                title: 'Custom wrap',
                isFieldArray: true,
                rows: [
                    {
                        id: 'dc409b9a-1140-48b6-952e-349d23281975',
                        columns: [
                            {
                                id: '1b5bc263-1bec-4478-9436-871f05833ee4',
                                label: 'TOTAL',
                            },
                            {
                                id: '122296c8-c516-4607-8260-80a6f613e695',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 'db70f44a-18a1-4882-b20b-e67758b78cc0',
                        columns: [
                            {
                                id: 'b1e11f1e-4043-4b34-a1d6-a2c2cde5f7fe',
                                label: 'Custom wrap',
                            },
                            {
                                id: '03650c71-ab1a-4112-9b8b-d68f889dc89d',
                                type: 'Quantity',
                                name(i) {
                                    return `customWrap[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'ea07eaf3-510c-45d8-99d8-87e62aad175a',
                        columns: [
                            {
                                id: 'e78a7bd2-f743-4252-b33e-aac4ab1f42c1',
                                label: 'Type',
                            },
                            {
                                id: '985887f4-6b9c-4308-8e3e-ea31ecb21731',
                                type: 'Dropdown',
                                name(i) {
                                    return `customWrap[${i}].children.customWrapType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Type'),
                        ],
                    },
                    {
                        id: 'a583cfbc-912b-43c6-8bbc-08e93b167eaa',
                        columns: [
                            {
                                id: 'e936db59-e301-43cd-940b-64514eb81a74',
                                label: 'Color ',
                            },
                            {
                                id: '96b98bb7-7ea2-410c-9e95-acd8dc623e80',
                                type: 'Dropdown',
                                name(i) {
                                    return `customWrap[${i}].children.customWrapColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 40,
                isFieldArray: false,
                name: '',
                quantity: () => 0,
                setModelValue: () => '',
                title: `Trim Coil Need: `,
                rows: [
                    {
                        id: 'test',
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended',
                            },
                            {
                                id: 2,
                                label: `${formatTrimCoilFeetRecommended.value}`,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: '398f14e0-5bb1-4adf-9241-f9a6881ea701',
                name: 'trimCoil',
                quantity: (i) => props?.modelValues.trimCoil[i].quantity,
                setModelValue: (i) => `trimCoil[${i}].quantity`,
                title: 'Trim Coil',
                isFieldArray: true,
                rows: [
                    {
                        id: 'a1d62bfd-9e71-4aa0-a387-65c423568196',
                        columns: [
                            {
                                id: 'a60f081f-ffe0-44e1-94b9-ab4727b85adc',
                                label: 'TOTAL',
                            },
                            {
                                id: '70a954a6-c907-4685-9afd-abc880451b61',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 'abb70f46-77cf-4fb1-93f8-7ddd86ca9542',
                        columns: [
                            {
                                id: '6d9eccef-7a5f-4912-a803-e7bcbd1c1b78',
                                label: 'Trim Coil',
                            },
                            {
                                id: '9f5e74cd-274b-4c67-8a13-c9d87792e73a',
                                type: 'Quantity',
                                recommended(i) {
                                    return trimCoilRecommended?.value[Number(i)];
                                },
                                name(i) {
                                    return `trimCoil[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'f5946368-43f9-4960-ade2-2ef568888b5c',
                        columns: [
                            {
                                id: '294a04ca-cd33-49e0-be9a-afbd119f9edc',
                                label: 'Color',
                            },
                            {
                                id: '47c948fa-7a3a-4e97-987a-f3ea2e5cc5e2',
                                type: 'Dropdown',
                                name(i) {
                                    return `trimCoil[${i}].children.trimCoilColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '7009a3d2-7bed-4f98-aad0-616b09543b5e',
                        columns: [
                            {
                                id: 'ff370299-8794-40a2-813d-970bbda96a19',
                                label: 'Type',
                            },
                            {
                                id: 'b21a81ec-abcc-4744-8d61-d31afb2ec251',
                                type: 'Dropdown',
                                name(i) {
                                    return `trimCoil[${i}].children.trimCoilType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Type'),
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
