export default {
    getAll(countryId?: string) {
        return [
            { id: 'AK', name: 'Alaska', countryId: 'USA' },
            { id: 'AL', name: 'Alabama', countryId: 'USA' },
            { id: 'AR', name: 'Arkansas', countryId: 'USA' },
            { id: 'AZ', name: 'Arizona', countryId: 'USA' },
            { id: 'CA', name: 'California', countryId: 'USA' },
            { id: 'CO', name: 'Colorado', countryId: 'USA' },
            { id: 'CT', name: 'Connecticut', countryId: 'USA' },
            { id: 'DC', name: 'District of Columbia', countryId: 'USA' },
            { id: 'DE', name: 'Delaware', countryId: 'USA' },
            { id: 'FL', name: 'Florida', countryId: 'USA' },
            { id: 'GA', name: 'Georgia', countryId: 'USA' },
            { id: 'HI', name: 'Hawaii', countryId: 'USA' },
            { id: 'IA', name: 'Iowa', countryId: 'USA' },
            { id: 'ID', name: 'Idaho', countryId: 'USA' },
            { id: 'IL', name: 'Illinois', countryId: 'USA' },
            { id: 'IN', name: 'Indiana', countryId: 'USA' },
            { id: 'KS', name: 'Kansas', countryId: 'USA' },
            { id: 'KY', name: 'Kentucky', countryId: 'USA' },
            { id: 'LA', name: 'Louisiana', countryId: 'USA' },
            { id: 'MA', name: 'Massachusetts', countryId: 'USA' },
            { id: 'MD', name: 'Maryland', countryId: 'USA' },
            { id: 'ME', name: 'Maine', countryId: 'USA' },
            { id: 'MI', name: 'Michigan', countryId: 'USA' },
            { id: 'MN', name: 'Minnesota', countryId: 'USA' },
            { id: 'MO', name: 'Missouri', countryId: 'USA' },
            { id: 'MS', name: 'Mississippi', countryId: 'USA' },
            { id: 'MT', name: 'Montana', countryId: 'USA' },
            { id: 'NC', name: 'North Carolina', countryId: 'USA' },
            { id: 'ND', name: 'North Dakota', countryId: 'USA' },
            { id: 'NE', name: 'Nebraska', countryId: 'USA' },
            { id: 'NH', name: 'New Hampshire', countryId: 'USA' },
            { id: 'NJ', name: 'New Jersey', countryId: 'USA' },
            { id: 'NM', name: 'New Mexico', countryId: 'USA' },
            { id: 'NV', name: 'Nevada', countryId: 'USA' },
            { id: 'NY', name: 'New York', countryId: 'USA' },
            { id: 'OH', name: 'Ohio', countryId: 'USA' },
            { id: 'OK', name: 'Oklahoma', countryId: 'USA' },
            { id: 'OR', name: 'Oregon', countryId: 'USA' },
            { id: 'PA', name: 'Pennsylvania', countryId: 'USA' },
            { id: 'RI', name: 'Rhode Island', countryId: 'USA' },
            { id: 'SC', name: 'South Carolina', countryId: 'USA' },
            { id: 'SD', name: 'South Dakota', countryId: 'USA' },
            { id: 'TN', name: 'Tennessee', countryId: 'USA' },
            { id: 'TX', name: 'Texas', countryId: 'USA' },
            { id: 'UT', name: 'Utah', countryId: 'USA' },
            { id: 'VA', name: 'Virginia', countryId: 'USA' },
            { id: 'VT', name: 'Vermont', countryId: 'USA' },
            { id: 'WA', name: 'Washington', countryId: 'USA' },
            { id: 'WI', name: 'Wisconsin', countryId: 'USA' },
            { id: 'WV', name: 'West Virginia', countryId: 'USA' },
            { id: 'WY', name: 'Wyoming', countryId: 'USA' },
            { id: 'AB', name: 'Alberta', countryId: 'CAN' },
            { id: 'BC', name: 'British Columbia', countryId: 'CAN' },
            { id: 'MB', name: 'Manitoba', countryId: 'CAN' },
            { id: 'NB', name: 'New Brunswick', countryId: 'CAN' },
            { id: 'NL', name: 'Newfoundland and Labrador', countryId: 'CAN' },
            { id: 'NS', name: 'Nova Scotia', countryId: 'CAN' },
            { id: 'NT', name: 'Northwest Territories', countryId: 'CAN' },
            { id: 'NU', name: 'Nunavut', countryId: 'CAN' },
            { id: 'ON', name: 'Ontario', countryId: 'CAN' },
            { id: 'PE', name: 'Prince Edward Island', countryId: 'CAN' },
            { id: 'QC', name: 'Quebec', countryId: 'CAN' },
            { id: 'SK', name: 'Saskatchewan', countryId: 'CAN' },
            { id: 'YT', name: 'Yukon', countryId: 'CAN' },
        ].filter((x) => x.countryId === countryId);
    },
};
