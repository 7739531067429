import { DateTime } from 'luxon';

export type DocumentEmailHistoryDTO = {
    documentId: number | null;
    emailAddresses: string[];
    timestamp: string | null;
    status: number;
};

export class EmailStatus {
    static failure = 0;
    static success = 1;
    static pending = 2;
    static sending = 3;
    static skipped = 4;

    static options = [
        { id: this.failure, name: 'failure' },
        { id: this.success, name: 'success' },
        { id: this.pending, name: 'pending' },
        { id: this.sending, name: 'sending' },
        { id: this.skipped, name: 'skipped' },
    ];

    static getName(value: number) {
        const item = this.options.find((x) => x.id === value);
        return item ? item.name : '';
    }
}

export class DocumentEmailHistory {
    documentId: number | null;
    emailAddresses: string[];
    timestamp: DateTime | null;
    status: number;

    constructor(dto: Partial<DocumentEmailHistoryDTO>) {
        this.documentId = dto.documentId ?? null;
        this.emailAddresses = dto.emailAddresses ?? [];
        this.timestamp = dto.timestamp ? DateTime.fromISO(dto.timestamp) : null;
        this.status = dto.status ?? EmailStatus.skipped;
    }
}
