<script setup>
    import { round } from '@/helpers';
    import { computed, defineProps, onMounted, watch } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKey, estimteCostValues } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: '',
                name: '',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Kitchen exhaust',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'kitchenExhaustVent.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Kitchen exhaust'),
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Bath Exhaust Vent',
                name: 'bathExhaustVent',
                setModelValue: (i) => `bathExhaustVent[${i}].quantity`,
                quantity: (i) => props?.modelValues.bathExhaustVent[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Bathroom exhaust',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `bathExhaustVent[${i}].quantity`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `bathExhaustVent[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                title: 'Delivery Distance',
                name: 'deliveryDistance',
                setModelValue: (i) => `deliveryDistance[${i}].quantity`,
                quantity: (i) => props?.modelValues.deliveryDistance[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Delivery / Distance',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `deliveryDistance[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `deliveryDistance[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                title: 'Insulation Accessories',
                name: 'insulationAccesories',
                setModelValue: (i) => `insulationAccesories[${i}].quantity`,
                quantity: (i) => props?.modelValues.insulationAccesories[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Insulation Accesories',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `insulationAccesories[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `insulationAccesories[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                title: '',
                name: '',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Insulation Permit',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name: () => 'permit.value',
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                title: 'Add on services',
                name: 'custom',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: true,
                addItem: {
                    show: true,
                    title: 'Add on services',
                },
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Label / name',
                            },
                            {
                                id: 2,
                                type: 'Text',
                                name(i) {
                                    return `custom[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `custom[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: '$ material cost',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].materialCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: '$ labor cost',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].laborCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: '$ material price',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                readonly: true,
                                name(i) {
                                    return `custom[${i}].materialPriceTemp`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: '$ labor price',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                readonly: true,
                                name(i) {
                                    return `custom[${i}].laborPriceTemp`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });

    function updatePrices() {
        if (Array.isArray(props.modelValues.custom)) {
            for (let i = 0; i < props.modelValues.custom.length; i++) {
                const x = props.modelValues.custom[i];
                const materialMultiplier = props.getRecommendedConversion(
                    1,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                const laborMultiplier = props.getRecommendedConversion(
                    2,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                props.setModelValue(`custom[${i}].materialPriceTemp`, round(x.materialCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].materialPrice`, round(x.materialCost * materialMultiplier, 2));
                props.setModelValue(`custom[${i}].laborPriceTemp`, round(x.laborCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].laborPrice`, round(x.laborCost * laborMultiplier, 2));
            }
        }
    }

    watch(() => props.modelValues.custom, updatePrices, { deep: true });
    onMounted(async () => {
        updatePrices();
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
