import { getDb } from '@/idbInit';
import Settings from '@/models/Settings';
import { AxiosResponse } from 'axios';
import { fetchWrap, offlineResponse } from '../_helpers';

// Define the type for the patch parameter in the update method.
type Patch = Array<Record<string, any>>;

export default {
    async getSettings() {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/settings/');
        } catch {
            const idb = await getDb();
            const data = await idb.getAll('settings');
            return new Settings(data[0]);
        }
        if (response?.status >= 200 && response?.status < 300) {
            const data = response.data;
            const idb = await getDb();
            await idb.clear('settings');
            await idb.put('settings', data, 0);
            return new Settings(data);
        } else {
            return null;
        }
    },

    async getCompanyLogo() {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/settings/company-logo/');
        } catch {
            const idb = await getDb();
            const data = await idb.getAll('settings');
            return new Settings(data[0]);
        }
        if (response?.status >= 200 && response?.status < 300) {
            const data = response.data;
            return new Settings(data);
        } else {
            return null;
        }
    },

    /**
     * Update settings
     * @param {patch} Array JSON patch of updates
     * @returns (async) Returns the updated Settings if the request was successful, otherwise a Response.
     */
    async update(patch: Patch) {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/settings/', {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(patch),
            });
        } catch {
            response = offlineResponse();
        }
        if (response?.status >= 200 && response?.status < 300) {
            const data = response.data;
            const idb = await getDb();
            await idb.clear('settings');
            await idb.put('settings', data, 0);
            return new Settings(data);
        } else {
            return response;
        }
    },
};
