import { createApp } from 'vue';
import api from './api/index';
import App from './App.vue';
import { initGoogleAnalytics } from './googleAnalytics';
import './registerServiceWorker';
import $router from './router/index';
import './scss/main.scss';
import $store from './store/index';
import AccountNav from './views/partials/AccountNav.vue';
import AdminNav from './views/partials/AdminNav.vue';
import AppIcons from './views/partials/AppIcons.vue';
import AppMessages from './views/partials/AppMessages.vue';
import ButtonSubmit from './views/partials/ButtonSubmit.vue';
import ErrorSummary from './views/partials/ErrorSummary.vue';
import FieldArray from './views/partials/FieldArray.vue';
import FieldError from './views/partials/FieldError.vue';
import FieldInputCheckbox from './views/partials/FieldInputCheckbox.vue';
import FieldInputDropdown from './views/partials/FieldInputDropdown.vue';
import FieldInputPrice from './views/partials/FieldInputPrice.vue';
import FieldInputQuantity from './views/partials/FieldInputQuantity.vue';
import FieldInputRichText from './views/partials/FieldInputRichText.vue';
import FieldInputSlider from './views/partials/FieldInputSlider.vue';
import FieldInputText from './views/partials/FieldInputText.vue';
import FieldInputAutocomplete from './views/partials/FieldInputAutocomplete.vue';
import FieldLabel from './views/partials/FieldLabel.vue';
import FieldLabelSelection from './views/partials/FieldLabelSelection.vue';
import InputCheckbox from './views/partials/InputCheckbox.vue';
import InputFileImage from './views/partials/InputFileImage.vue';
import InputFileImages from './views/partials/InputFileImages.vue';
import InputNumber from './views/partials/InputNumber.vue';
import InputNumberField from './views/partials/InputNumberField.vue';
import InputTextField from './views/partials/InputTextField.vue';
import InputIconTextField from './views/partials/InputIconTextField.vue';
import InstallPrompt from './views/partials/InstallPrompt.vue';
import LoadingAnimation from './views/partials/LoadingAnimation.vue';
import LoadingIndicator from './views/partials/LoadingIndicator.vue';
import PaginationControls from './views/partials/PaginationControls.vue';
import PasswordRequirements from './views/partials/PasswordRequirements.vue';
import RefreshServiceWorker from './views/partials/RefreshServiceWorker.vue';
import TransitionHeight from './views/partials/TransitionHeight.vue';
import SectionHeader from './views/partials/SectionHeader.vue';
import { useYupExtensions } from './yupExtensions';
import { DatePicker } from 'v-calendar';
import { createPinia } from 'pinia';
import 'v-calendar/style.css';
const pinia = createPinia();

const app = createApp(App);
if ($store.state.environment === 'production') {
    initGoogleAnalytics({ router: $router });
}

useYupExtensions();
app.component('AccountNav', AccountNav);
app.component('AdminNav', AdminNav);
app.component('AppIcons', AppIcons);
app.component('AppMessages', AppMessages);
app.component('ButtonSubmit', ButtonSubmit);
app.component('ErrorSummary', ErrorSummary);
app.component('FieldArray', FieldArray);
app.component('FieldError', FieldError);
app.component('FieldInputCheckbox', FieldInputCheckbox);
app.component('FieldInputDropdown', FieldInputDropdown);
app.component('FieldInputPrice', FieldInputPrice);
app.component('FieldInputQuantity', FieldInputQuantity);
app.component('FieldInputRichText', FieldInputRichText);
app.component('FieldInputSlider', FieldInputSlider);
app.component('FieldInputText', FieldInputText);
app.component('FieldInputAutocomplete', FieldInputAutocomplete);
app.component('FieldLabel', FieldLabel);
app.component('FieldLabelSelection', FieldLabelSelection);
app.component('InputCheckbox', InputCheckbox);
app.component('InputFileImage', InputFileImage);
app.component('InputFileImages', InputFileImages);
app.component('InputNumber', InputNumber);
app.component('InputNumberField', InputNumberField);
app.component('InputTextField', InputTextField);
app.component('InputIconTextField', InputIconTextField);
app.component('InstallPrompt', InstallPrompt);
app.component('LoadingAnimation', LoadingAnimation);
app.component('LoadingIndicator', LoadingIndicator);
app.component('PaginationControls', PaginationControls);
app.component('PasswordRequirements', PasswordRequirements);
app.component('RefreshServiceWorker', RefreshServiceWorker);
app.component('TransitionHeight', TransitionHeight);
app.component('VDatePicker', DatePicker);
app.component('SectionHeader', SectionHeader);
app.use(pinia);

$store.dispatch('auth/refresh').then(() => {
    app.use($store);
    app.use($router);
    app.use(createPinia());
    app.mount('#app');
    $store.dispatch('refreshAfterAuth');
});

const darkMode: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
const swapFavicon = () => {
    const faviconLink = document.head.querySelector('link[rel="shortcut icon"]')!;
    faviconLink.setAttribute('href', darkMode.matches ? '/favicons/favicon-dark.ico' : '/favicons/favicon.ico');
};
darkMode.addEventListener('change', swapFavicon);
swapFavicon();

const setMaskIconColor = (color: string) => {
    const s = document.head.querySelector('link[rel="mask-icon"]')!;
    s.setAttribute('color', color);
};

const env: string = $store.state.environment;
if (env === 'development' || env === 'testing') {
    document.documentElement.style.setProperty('--env-logo-bg', '#ffffff');
    document.documentElement.style.setProperty('--env-logo-main', '#111111');
    setMaskIconColor('#111111');
} else if (env === 'staging') {
    document.documentElement.style.setProperty('--env-logo-bg', '#93D9EC');
    document.documentElement.style.setProperty('--env-logo-main', '#ffffff');
    setMaskIconColor('#93D9EC');
}

const updateOnlineStatus = () => {
    const online: boolean = navigator.onLine;
    $store.commit('setOnlineStatus', online);
    if (online) {
        api.localChanges.syncOfflineChanges();
    }
};
updateOnlineStatus();
window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);

const browserUiFix = () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
window.addEventListener('load', browserUiFix, false);

const isAndroid: boolean = navigator.userAgent.match(/Android/i) ? true : false;
const viewport: HTMLMetaElement = document.querySelector('meta[name=viewport]')!;
const viewportInitial: string = 'width=device-width, initial-scale=1.0';

if (isAndroid) {
    viewport.setAttribute('content', viewportInitial + ', height=' + window.innerHeight);
    let timer: ReturnType<typeof setTimeout>;
    screen.orientation.addEventListener('change', () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            viewport.setAttribute('content', viewportInitial + ', height=' + window.innerHeight);
            browserUiFix();
        }, 300);
    });
} else {
    window.addEventListener('resize', browserUiFix, false);
}

window.addEventListener('beforeinstallprompt', (e: Event) => {
    e.preventDefault();
    $store.commit('setCustomPwaBanner', true);
    $store.commit('setPwaInstallButton', true);
    $store.commit('setPwaPrompt', e);
});
