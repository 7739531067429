import LocalChangeState from './LocalChangeState';

export interface LocalChangeDto {
    storeName?: string;
    id?: number;
    state?: number;
    error?: number;
    data?: Record<string, any>;
}

class LocalChange {
    storeName: string;
    id: number;
    state: number;
    error?: number | null;
    data: Record<string, any>;

    constructor(dto: Partial<LocalChangeDto> = {}) {
        this.storeName = dto.storeName ?? '';
        this.id = dto.id ?? 0;
        this.state = dto.state ?? LocalChangeState.unchanged;
        this.error = dto.error ?? null;
        this.data = dto.data ?? {};
    }

    get key() {
        return LocalChange.getKey(this.storeName, this.id);
    }

    static getKey(storeName: string, id: number) {
        return `${storeName}:${id}`;
    }
}

export default LocalChange;
