import { IDBPDatabase } from 'idb';
import LocalIdMap from './models/LocalIdMap';

export async function getNewId(db: IDBPDatabase, storeName: string) {
    const tx = db.transaction('local-ids', 'readwrite');
    const store = tx.objectStore('local-ids');
    let localId = await store.get(storeName);
    if (isNaN(localId)) {
        localId = -1;
    }
    // localIds uses odd negative integers so other places can use even negative integers
    localId -= 2;
    await store.put(localId, storeName);
    return localId;
}

export async function addLocalIdMap(db: IDBPDatabase, storeName: string, oldId: number, newId: number) {
    if (oldId < 0 && newId > 0) {
        const map = new LocalIdMap({ storeName: storeName, oldId: oldId, newId: newId });
        await db.put('local-ids-map', map, map.key);
    }
}

export async function mapLocalId(db: IDBPDatabase, storeName: string, oldId: number) {
    if (!(oldId < 0)) {
        return oldId;
    }
    const map = await db.get('local-ids-map', LocalIdMap.getKey(storeName, oldId));
    if (map && map.newId > 0) {
        return map.newId;
    }
    return oldId;
}
