<template>
    <div class="inline-text">
        <input type="text" v-model="value" />
        <button
            type="button"
            class="btn-link"
            @click="emits('update:modelValue', value)"
        >
            <svg width="24px" height="24px" class="icon fill-green stroke-green">
                <use xlink:href="#save"></use>
            </svg>
        </button>
        <button
            type="button"
            class="btn-link"
            @click="clearField"
        >
            <svg width="24px" height="24px" class="icon fill-red stroke-red">
                <use xlink:href="#remove"></use>
            </svg>
        </button>
    </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue';

const emits = defineEmits(['update:modelValue']);
const props = defineProps({
	modelValue: {
		type: String,
		required: true,
	},
});
const value = ref(props.modelValue);

const clearField = () => {
    value.value = '';
    emits('update:modelValue', '');
};
</script>
