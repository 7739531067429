<script setup>
    import { round } from '@/helpers';
    import { computed, defineProps, watch } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKeyChild, estimteCostValues } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    function updateGutterMaterialTotal() {
        const totalGutterLength = props?.modelValues?.totalGutterLength?.quantity ?? 0;
        const totalGutterCorners = props?.modelValues?.totalGutterCorners?.quantity ?? 0;
        const cornerWasteFactor = props?.getRecommendedConversion(1, props?.model.totalGutterCorners.field);
        const gutterWasteFactorForMaterial = 1 + props?.modelValues.gutterWasteFactorForMaterial.quantity / 100;
        const materialStep = Math.round(1 / props?.model.gutterMaterialTotal.field.step);
        const gutterMaterialTotal = round(
            Math.ceil(
                (gutterWasteFactorForMaterial * totalGutterLength + cornerWasteFactor * totalGutterCorners) *
                    materialStep
            ) / materialStep,
            3
        );
        props?.setModelValue('gutterMaterialTotal.quantity', gutterMaterialTotal);
    }

    watch(
        [
            () => props?.modelValues?.totalGutterLength?.quantity,
            () => props?.modelValues?.totalGutterCorners?.quantity,
            () => props?.modelValues?.gutterWasteFactorForMaterial?.quantity,
        ],
        updateGutterMaterialTotal,
        { immediate: true }
    );
    const gutterProtectionRecommended = computed(() => {
        return Array.isArray(props?.modelValues.gutterProtection)
            ? props?.modelValues.gutterProtection.map((x, i) => {
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.gutterProtection.children.gutterProtectionType.field,
                      props?.modelValues.gutterProtection[i].children.gutterProtectionType.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const length = props?.modelValues.gutterProtection[i].children.gutterProtectionLength.quantity;
                  const recommended = Math.ceil(length / feetPerUnit);
                  props?.setRecommendedItem(
                      props?.model.gutterProtection.children.gutterProtectionType.field.id,
                      recommended
                  );
                  return recommended;
              })
            : [];
    });

    const gutterRecommended = computed(() => {
        const recommended = props?.modelValues.gutterMaterialTotal.quantity;
        return props?.modelValues.gutter.map(() => {
            props?.setRecommendedItem(props?.model.gutter.children.gutterType.field.id, recommended);
            return recommended;
        });
    });
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: '',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                title: 'Waste Calculations',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Total Gutter Length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'totalGutterLength.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Total Corners',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'totalGutterCorners.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Waste factor %',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'gutterWasteFactorForMaterial.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Material Total',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                readonly: true,
                                name() {
                                    return 'gutterMaterialTotal.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'gutter',
                title: 'Gutters',
                isFieldArray: true,
                addItem: {
                    show: true,
                    title: 'Gutters',
                },
                quantity: (i) => props?.modelValues.gutter[i].children.gutterType.quantity,
                setModelValue(i) {
                    return `gutter[${i}].children.gutterType.quantity`;
                },
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Gutter Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `gutter[${i}].children.gutterType.quantity`;
                                },
                                recommended(i) {
                                    return gutterRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gutter[${i}].children.gutterType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gutter[${i}].children.gutterColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Install Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gutter[${i}].children.gutterInstallType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Gutter install type'),
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: 'gutterProtection',
                title: 'gutter Protection',
                isFieldArray: true,
                addItem: {
                    show: true,
                    title: 'gutter Protection',
                },
                quantity: (i) => props?.modelValues.gutterProtection[i].quantity,
                setModelValue(i) {
                    return `gutterProtection[${i}].quantity`;
                },
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Gutter Protection',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gutterProtection[${i}].children.gutterProtectionType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Type'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Length Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `gutterProtection[${i}].children.gutterProtectionLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended PC',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `gutterProtection[${i}].children.gutterProtectionType.quantity`;
                                },
                                recommended(i) {
                                    return gutterProtectionRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: 'gutterCorners',
                title: 'Gutter Corners',
                isFieldArray: true,
                addItem: {
                    show: true,
                    title: 'Gutter Corners',
                },
                quantity: (i) => props?.modelValues.gutterCorners[i].quantity,
                setModelValue: (i) => `gutterCorners[${i}].quantity`,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Gutter Corners',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gutterCorners[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `gutterCorners[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                name: '',
                title: 'Downspouts',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Downpouts',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'downspoutQuantity.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                name: 'downspout',
                title: 'Downspouts',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.downspout[i].quantity,
                setModelValue: (i) => `downspout[${i}].quantity`,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Downspout length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `downspout[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `downspout[${i}].children.downspoutType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `downspout[${i}].children.downspoutColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 7,
                name: 'downspoutElbows',
                title: 'downspout Elbows',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.downspoutElbows[i].quantity,
                setModelValue: (i) => `downspoutElbows[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Downspout elbows',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `downspoutElbows[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `downspoutElbows[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 8,
                name: '',
                title: '',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Downspout locations',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'downspoutLocation.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>
<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
