<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKey, estimateTotalsWithKeyChild, estimteCostValues } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: 'dripEdge',
                title: 'Roof Repairs',
                setModelValue: (i) => `dripEdge[${i}].quantity`,
                quantity: (i) => props?.modelValues.dripEdge[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Drip Edge',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `dripEdge[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `dripEdge[${i}].children.dripEdgeColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `dripEdge[${i}].children.dripEdgeType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'gutterFlashing',
                title: 'Gutter Flashing',
                setModelValue: (i) => `gutterFlashing[${i}].quantity`,
                quantity: (i) => props?.modelValues.gutterFlashing[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Gutter Apron',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `gutterFlashing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Colors',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gutterFlashing[${i}].children.gutterFlashingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gutterFlashing[${i}].children.gutterFlashingType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: 'valleyFlashing',
                title: 'Valley Flashing',
                setModelValue: (i) => `valleyFlashing[${i}].quantity`,
                quantity: (i) => props?.modelValues.valleyFlashing[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Valleys',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `valleyFlashing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },

                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `valleyFlashing[${i}].children.valleyFlashingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `valleyFlashing[${i}].children.valleyFlashingType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: 'headFlashing',
                title: 'Head Flashing',
                setModelValue: (i) => `headFlashing[${i}].quantity`,
                quantity: (i) => props?.modelValues.headFlashing[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Head Flashing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `headFlashing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `headFlashing[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                name: 'stepFlashing',
                title: 'Step Flashing',
                setModelValue: (i) => `stepFlashing[${i}].quantity`,
                quantity: (i) => props?.modelValues.stepFlashing[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Step flashing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `stepFlashing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `stepFlashing[${i}].children.stepFlashingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `stepFlashing[${i}].children.stepFlashingType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                ],
            },
            {
                id: 6,
                name: 'counterFlashing',
                title: 'Counter Flashing',
                setModelValue: (i) => `counterFlashing[${i}].quantity`,
                quantity: (i) => props?.modelValues.counterFlashing[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Counter flashing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `counterFlashing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `counterFlashing[${i}].children.counterFlashingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Style',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `counterFlashing[${i}].children.counterFlashingType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                ],
            },
            {
                id: 7,
                name: 'pvcBoot',
                title: 'Pipe Flashing',
                setModelValue: (i) => `pvcBoot[${i}].quantity`,
                quantity: (i) => props?.modelValues.pvcBoot[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'PVC',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `pvcBoot[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `pvcBoot[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 8,
                name: 'leadBoot',
                title: 'Lead Flashing',
                setModelValue: (i) => `leadBoot[${i}].quantity`,
                quantity: (i) => props?.modelValues.leadBoot[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Lead',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `leadBoot[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `leadBoot[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 9,
                name: '',
                title: 'Zipper',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Zipper',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'zipperBoot.quantity',
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Zipper pipe flashing'),
                        ],
                    },
                ],
            },
            {
                id: 10,
                name: '',
                title: 'Exhaust flashing',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Kitchen exhaust flashing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'kitchenExhaustFlashing.quantity',
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Kitchen exhaust flashing'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Bathroom exhaust flashing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'bathExhaustFlashing.quantity',
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Bathroom exhaust flashing'),
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
