<script setup>
    import { safeComputed } from '@/composables/safeComputed';
    import { computed, defineProps, inject, onMounted, ref, watch } from 'vue';

    import { getRoofingPackagesInQuickEstimate } from '@/composables/roofingPackages';
    import { formatCurrency } from '@/helpers';
    import { calculateTotalSquaresForMembrane } from '@/helpers/estimator/roofing/12_package';
    import { mapping as fieldMapping } from '@/models/EstimateFields';
    import EstimateSections from '@/models/EstimateSections';
    import PackageSwatchBanner from '@/views/estimator/estimate/PackageSwatchBanner.vue';
    import FieldError from '@/views/partials/FieldError.vue';
    import { cloneDeep } from 'lodash';
    import { useField } from 'vee-validate';
    import { useStore } from 'vuex';
    import FieldExcelError from '../../atoms/FieldExcelError.vue';
    import { emptyFields } from '../mocks';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        getItems: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const selectedPackageId = ref(null);

    const totalSquaresForMembrane = safeComputed(() => calculateTotalSquaresForMembrane(props?.modelValues.membrane));
    const totalSquaresForMaterial = computed(() => {
        if (
            props?.modelValues.installPitch?.length === 1 &&
            props?.modelValues.installPitch?.filter((x) => x.value === '1.43.1').length > 0
        ) {
            return totalSquaresForMembrane.value;
        }
        return props?.modelValues.totalSquaresForMaterial.quantity;
    });
    const totalSquaresForLabor = safeComputed(() => props?.modelValues.totalSquaresForLabor.quantity);

    function updatePackageQuantity() {
        props?.setModelValue('package.quantity', totalSquaresForMaterial.value);
        props?.setModelValue('shingleColor.quantity', totalSquaresForMaterial.value);
        props?.setModelValue('packageInstallation.quantity', totalSquaresForLabor.value);
        if (totalSquaresForMaterial.value === 0) {
            props?.setModelValue('package.value', null);
            props?.setModelValue('shingleColor.value', null);
        }
    }

    const iceBarrierRecommended = safeComputed(() => {
        const feetPerUnit = props?.getRecommendedConversion(
            1,
            props?.model.iceBarrier.field,
            props?.modelValues.iceBarrier.value
        );
        if (feetPerUnit === 0) return 0;
        return Math.ceil(
            (props?.modelValues.roofToWallFlashingLength.quantity / 2 +
                props?.modelValues.valleyLength.quantity +
                props?.modelValues.flashingLength.quantity +
                props?.modelValues.iceBarrierRows.quantity * props?.modelValues.eaveLength.quantity) /
                feetPerUnit
        );
    });
    const underlaymentRecommended = safeComputed(() => {
        const squarePerUnit = props?.getRecommendedConversion(
            1,
            props?.model.underlayment.field,
            props?.modelValues.underlayment.value
        );
        if (squarePerUnit === 0) return 0;
        return Math.ceil(totalSquaresForMaterial.value / squarePerUnit);
    });
    const startersRecommended = safeComputed(() => {
        const feetPerUnit = props?.getRecommendedConversion(
            1,
            props?.model.starters.field,
            props?.modelValues.starters.value
        );
        if (feetPerUnit === 0) return 0;
        return Math.ceil(
            (props?.modelValues.eaveLength.quantity + props?.modelValues.rakeEdgeLength.quantity) / feetPerUnit
        );
    });
    const hipRidgeRecommended = safeComputed(() => {
        const feetPerUnit = props?.getRecommendedConversion(
            1,
            props?.model.hipRidge.field,
            props?.modelValues.hipRidge.value
        );
        if (feetPerUnit === 0) return 0;
        return Math.ceil(props?.modelValues.hipRidgeLength.quantity / feetPerUnit);
    });
    watch(
        [totalSquaresForMaterial, totalSquaresForLabor],
        () => {
            updatePackageQuantity();
        },
        {
            immediate: true,
        }
    );
    watch(
        [() => props?.modelValues.package.value, () => props?.modelValues.iceBarrier.value, iceBarrierRecommended],
        () => props?.setModelValue('iceBarrier.quantity', iceBarrierRecommended.value)
    );
    watch(
        [() => props?.modelValues.package.value, () => props?.modelValues.underlayment.value, underlaymentRecommended],
        () => props?.setModelValue('underlayment.quantity', underlaymentRecommended.value)
    );
    watch([() => props?.modelValues.package.value, () => props?.modelValues.starters.value, startersRecommended], () =>
        props?.setModelValue('starters.quantity', startersRecommended.value)
    );
    watch([() => props?.modelValues.package.value, () => props?.modelValues.hipRidge.value, hipRidgeRecommended], () =>
        props?.setModelValue('hipRidge.quantity', hipRidgeRecommended.value)
    );

    // watch(
    //     () => props?.modelValues.warranty.value,
    //     () => {
    //         const membrane = props?.modelValues.membrane
    //             .map((x: any) => x.children.membraneType.quantity)
    //             .reduce((a: any, b: any) => a + b, 0);
    //         const material = props?.modelValues.totalSquaresForMaterial.quantity;
    //         // props?.setModelValue('warranty.quantity', membrane + material);
    //     },
    //     { deep: true }
    // );

    const handleResetPackage = () => {
        selectedPackageId.value = null;
        props?.setModelValue('package.value', null);
        // props?.setModelValue('package.quantity', 0);
        // props?.setModelValue('packageInstallation.value', null);
        // props?.setModelValue('packageInstallation.quantity', 0);
        props?.setModelValue('_comparison', []);
    };

    const costValueShingleType = computed(() => {
        return props?.fieldsEstimateItems?.find((e) => e?.label?.toUpperCase() === 'SHINGLE TYPE');
    });
    const costValueRoofingInstallation = computed(() => {
        return props?.fieldsEstimateItems?.find((e) => e?.label?.toUpperCase() === 'ROOFING INSTALLATION');
    });
    const costValueUnderlayment = computed(() => {
        return props?.fieldsEstimateItems?.find((e) => e?.label?.toUpperCase() === 'UNDERLAYMENT');
    });

    const $store = useStore();
    const field = computed(() => props.model.package.field);

    const { value: quantity } = useField(computed(() => field.value.property + '.quantity'));

    const fieldIds = fieldMapping[EstimateSections.roofingPackage];

    const { validate: validateFilter, value: filter } = useField('_comparison');

    const options = ref([]);
    const estimate = safeComputed(() => $store.state.estimator.estimate);
    const fields = safeComputed(() => $store.state.estimator.fields);
    const fieldOrder = safeComputed(() => $store.state.estimator.fieldOrder[field.value.workflowId]);
    const installationValue = computed(() => props.modelValues.packageInstallation.value);
    const installationQuantity = computed(() => props.modelValues.packageInstallation.quantity);
    const comparisonOptions = ref({});
    const lastSelectedPackageId = ref(null);

    const loading = inject('loading');
    const totalPrice = ref(0);

    function updateTotalPrice() {
        const workflowId = field.value.workflowId;
        let items = [];
        props.getItems(items);
        let e = null;

        const selectedOption = options.value.find((x) => x.id === props?.modelValues.package.value);
        if (selectedOption) {
            e = selectedOption.estimate;

            if (lastSelectedPackageId.value === props?.modelValues.package.value) {
                selectedOption.modelValues = cloneDeep(props.modelValues);
            } else {
                if (selectedOption.modelValues) {
                    props.setModelValue('iceBarrier.value', selectedOption.modelValues.iceBarrier.value);
                    props.setModelValue('iceBarrier.quantity', selectedOption.modelValues.iceBarrier.quantity);
                    props.setModelValue('underlayment.value', selectedOption.modelValues.underlayment.value);
                    props.setModelValue('underlayment.quantity', selectedOption.modelValues.underlayment.quantity);
                    props.setModelValue('starters.value', selectedOption.modelValues.starters.value);
                    props.setModelValue('starters.quantity', selectedOption.modelValues.starters.quantity);
                    props.setModelValue('hipRidge.value', selectedOption.modelValues.hipRidge.value);
                    props.setModelValue('hipRidge.quantity', selectedOption.modelValues.hipRidge.quantity);
                    props.setModelValue('shingleColor.value', selectedOption.modelValues.shingleColor.value);
                    props.setModelValue('shingleColor.quantity', selectedOption.modelValues.shingleColor.quantity);
                    // props.setModelValue('warranty.value', selectedOption.modelValues.warranty.value);
                    // props.setModelValue('warranty.quantity', selectedOption.modelValues.warranty.quantity);
                }

                selectedOption.modelValues = cloneDeep(props.modelValues);

                items = [];
                props.getItems(items);
                e.updateItems(items, fieldIds, fields.value, true);
            }

            selectedOption.price = e.getWorkflowSummary(workflowId).total;
            comparisonOptions.value[props?.modelValues.package.value] = selectedOption;
            // comparisonItems.value[selectedPackageId.value] = selectedOption.modelValues;
        } else {
            e = estimate.value.clone();
            e.updateItems(items, fieldIds, fields.value, true);
        }
        totalPrice.value = e.getWorkflowSummary(workflowId).total;
        lastSelectedPackageId.value = props?.modelValues.package.value;
    }

    watch(
        [loading, () => props.modelValues, props?.modelValues.package.value, estimate],
        () => {
            updateTotalPrice();
        },
        { deep: true, immediate: true }
    );

    function updateOptions() {
        options.value = getRoofingPackagesInQuickEstimate({
            estimate: estimate.value,
            fields: fields.value,
            fieldOrder: fieldOrder.value,
            materialQty: quantity.value,
            laborOptionId: installationValue.value,
            laborQty: installationQuantity.value,
            refreshSelectedPackage: true,
            modelValues: props?.modelValues,
            comparisonOptions: comparisonOptions.value,
        });
    }

    watch(
        props?.modelValues.materialType,
        () => {
            handleResetPackage();
        },
        {
            deep: true,
            // immediate: true,
        }
    );
    watch(
        [quantity, installationValue, installationQuantity, estimate, props?.modelValues, comparisonOptions],
        updateOptions,
        {
            deep: true,
            immediate: true,
        }
    );
    updateOptions();
    // watch(
    //     [
    //         props?.modelValues.materialType.value,
    //         quantity,
    //         installationValue,
    //         installationQuantity,
    //         estimate,
    //         props?.modelValues,
    //         comparisonOptions,
    //         fieldOrder,
    //     ],
    //     () => {
    //         handleResetPackage();
    //         updateOptions();
    //     },
    //     {
    //         deep: true,
    //         immediate: true,
    //     }
    // );

    function toggleOption(optionId) {
        if (!optionId) return;
        filter.value = [optionId];
        props?.setModelValue('package.value', optionId);
        selectedPackageId.value = optionId;
        validateFilter();
    }
    const handleChangeSelectPackage = (e) => {
        if (!e.target?.value) return;
        toggleOption(e.target.value);
    };
    onMounted(() => {
        if (props?.modelValues.package.value) {
            selectedPackageId.value = props?.modelValues.package.value;
            toggleOption(props?.modelValues.package.value);
        }
    });

    onMounted(() => {
        updateOptions();
    });

    const listData = computed(() => {
        return [
            {
                id: 1,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Ice barrier',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: 'iceBarrier.value',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: 'iceBarrier.quantity',
                                recommended: iceBarrierRecommended?.value,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Underlayment',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: 'underlayment.value',
                            },
                            {
                                id: 3,
                                label: costValueUnderlayment?.value?.material
                                    ? `${formatCurrency(costValueUnderlayment?.value?.material)}`
                                    : '',
                            },
                            {
                                id: 4,
                                label: costValueUnderlayment?.value?.labor
                                    ? `${formatCurrency(costValueUnderlayment?.value?.labor)}`
                                    : '',
                            },
                            {
                                id: 5,
                                label: costValueUnderlayment?.value?.total
                                    ? `${formatCurrency(costValueUnderlayment?.value?.total)}`
                                    : '',
                            },
                            {
                                id: 1,
                                label: '',
                            },
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: 'underlayment.quantity',
                                recommended: underlaymentRecommended?.value,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Starters',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: 'starters.value',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: 'starters.quantity',
                                recommended: startersRecommended?.value,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Hip & ridge',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: 'hipRidge.value',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: 'hipRidge.quantity',
                                recommended: hipRidgeRecommended.value,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            // {
            //     id: 5,
            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Extended Warranty',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Dropdown',
            //                     name: 'warranty.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Extended Warranty Quantity',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Quantity',
            //                     name: 'warranty.quantity',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
        ];
    });
</script>

<template>
    <template v-if="totalSquaresForMaterial > 0">
        <section class="quick_estimate_row_table">
            <article class="quick_estimate_row_table_item">
                <p style="font-size: 12px">Roofing Installation</p>
            </article>
            <article class="quick_estimate_row_table_item">
                <div>
                    <FieldExcelError :name="`packageInstallation.value`" :errors="props?.errors" custom-message="*" />
                    <field-input-dropdown name="packageInstallation.value" :custom_class="'input_dropdown_excel'" />
                </div>
            </article>
            <article class="quick_estimate_row_table_item">
                {{
                    costValueRoofingInstallation?.material ? formatCurrency(costValueRoofingInstallation?.material) : ''
                }}
            </article>
            <article class="quick_estimate_row_table_item">
                {{ costValueRoofingInstallation?.labor ? formatCurrency(costValueRoofingInstallation?.labor) : '' }}
            </article>
            <article class="quick_estimate_row_table_item">
                {{ costValueRoofingInstallation?.total ? formatCurrency(costValueRoofingInstallation?.total) : '' }}
            </article>
            <article class="quick_estimate_row_table_item"></article>
        </section>
        <section class="quick_estimate_row_table">
            <article class="quick_estimate_row_table_item">
                <p style="font-size: 12px">Package</p>
            </article>
            <article class="quick_estimate_table_item input_dropdown_excel">
                <FieldExcelError :name="`_comparison`" :errors="props?.errors" custom-message="*" />

                <FieldExcelError :name="`package.value`" :errors="props?.errors" custom-message="*" />

                <select style="width: 100%" :value="selectedPackageId" @change="handleChangeSelectPackage">
                    <option disabled value="">Select an item</option>
                    <option v-for="pckg in options" :key="`package-${pckg?.id}`" :value="pckg?.id">
                        {{ pckg?.name }}
                    </option>
                </select>
            </article>
            <article class="quick_estimate_row_table_item">
                {{ costValueShingleType?.material ? formatCurrency(costValueShingleType?.material) : '' }}
            </article>
            <article class="quick_estimate_row_table_item">
                {{ costValueShingleType?.labor ? formatCurrency(costValueShingleType?.labor) : '' }}
            </article>
            <article class="quick_estimate_row_table_item">
                {{ costValueShingleType?.total ? formatCurrency(costValueShingleType?.total) : '' }}
            </article>
            <article class="quick_estimate_row_table_item"></article>
        </section>

        <template v-if="selectedPackageId">
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item">
                    <p>
                        {{ formatCurrency(options?.find?.((e) => e?.id === selectedPackageId)?.price ?? 0) }}
                    </p>
                    <div
                        class="package-details"
                        style="font-size: 12px"
                        v-html="options?.find?.((e) => e?.id === selectedPackageId)?.description"
                    ></div>
                </article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
            </section>
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_table_item_sub">
                    <field-label-selection name="shingleColor.value" removePrefixFieldName="package.value" />
                </article>
                <article class="quick_estimate_row_table_item">
                    <package-swatch-banner name="shingleColor.value" />
                    <field-input-dropdown class="constrain-img" name="shingleColor.value" display="swatch" />
                    <field-error name="shingleColor.value" />
                </article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
            </section>
            <template v-for="section in listData" :key="section?.id + 'section-package'">
                <template v-for="row in section?.rows" :key="section?.id + row?.id + 'section-package-row'">
                    <section class="quick_estimate_row_table">
                        <template
                            v-for="(col, index) in row?.columns"
                            :key="section?.id + row?.id + col?.id + 'section-package-item-col'"
                        >
                            <article
                                :class="index === 0 ? 'quick_estimate_table_item_sub' : 'quick_estimate_row_table_item'"
                            >
                                <p v-if="col?.label">
                                    {{ col?.label }}
                                </p>

                                <div v-if="col?.type === 'Quantity'">
                                    <p v-if="props?.errors?.[col?.name ?? '']" class="input_field_error">*</p>

                                    <field-input-quantity
                                        :name="col?.name"
                                        :recommended="col?.recommended"
                                        :forceStyle="col?.inputStyle"
                                        :custom_class="'input_number_excel'"
                                        :custom_select_class="'input_quantity_select_excel'"
                                        :recommendedClass="'input_quantity_recommended'"
                                        :autoSetRecommend="true"
                                    />
                                    <!-- <field-error :name="col?.name" :custom_class="'input_field_error'" /> -->
                                </div>
                                <div v-if="col?.type === 'Dropdown'">
                                    <p v-if="props?.errors?.[col?.name ?? '']" class="input_field_error">*</p>

                                    <field-input-dropdown
                                        :name="col?.name"
                                        :recommended="col?.recommended"
                                        :custom_class="'input_dropdown_excel'"
                                    />
                                    <!-- <field-error :name="col?.name" :custom_class="'input_field_error'" /> -->
                                </div>
                            </article>
                        </template>
                    </section>
                </template>
            </template>
        </template>
    </template>
    <section v-else class="quick_estimate_row_table">
        <article class="quick_estimate_row_table_item"></article>
        <article class="quick_estimate_row_table_item">
            <p style="font-size: 12px">Package is not applicable to this estimate.</p>
            <p style="font-size: 12px; font-weight: 400">
                The roof size entered on the
                <q>Roof details</q>
                step is 0 squares.
            </p>
        </article>
        <article class="quick_estimate_row_table_item"></article>
        <article class="quick_estimate_row_table_item"></article>
        <article class="quick_estimate_row_table_item"></article>
        <article class="quick_estimate_row_table_item"></article>
    </section>
</template>
<style scoped>
    .small_color_preview {
        width: 100px;
        height: 50px;
        object-fit: cover;
    }
    .container_selector_color {
        display: grid;
        grid-template-columns: 280px 1fr;
        gap: 10px;
    }
    .two_columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .row_four_columns {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
        justify-content: center;
        align-items: flex-start;
        min-height: 50px;
    }
    .selector {
        outline: none;
        border: #848484 solid 2px;
        border-radius: 5px;
        font-size: 16px;
        height: 45px;
        padding: 10px;
        transition: all 0.3s ease;
        width: 100%;
        background: white;
        position: relative;
        z-index: 1;
        min-width: 125px;
        -webkit-appearance: none;
        appearance: none;
    }
</style>
