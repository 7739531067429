<script setup>
    import { ref, nextTick, onMounted, watch } from 'vue';
    import { defineProps, defineEmits } from 'vue';

    const props = defineProps({
        modelValue: {
            type: Number,
            required: true,
        },
        defaultValue: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        custom_class: {
            type: String,
        },
    });

    const emit = defineEmits(['update:modelValue']);
    const input = ref(null);
    const value = ref('');

    async function onFocusChange() {
        if (props.modelValue === props.defaultValue) {
            value.value = '';
        } else if (typeof props.modelValue === 'number' && !isNaN(props.modelValue)) {
            value.value = props.modelValue;
        } else {
            value.value = props.defaultValue;
        }
        await nextTick();
        if (input.value) {
            input.value.value = value.value;
        }
    }

    watch(
        () => props.modelValue,
        () => {
            if (
                !(
                    input.value &&
                    input.value.value === '' &&
                    value.value === '' &&
                    props.modelValue === props.defaultValue
                )
            ) {
                value.value = props.modelValue;
            }
        }
    );

    function updateModelValue(v) {
        let numberValue = parseFloat(v);
        if (isNaN(numberValue)) {
            numberValue = props.defaultValue;
        }
        if (v === '') {
            value.value = '';
        }
        emit('update:modelValue', numberValue);
    }

    onMounted(() => {
        onFocusChange();
    });
</script>
<template>
    <input
        type="number"
        :value="value"
        @input="updateModelValue($event.target.value)"
        @focus="onFocusChange"
        @blur="onFocusChange"
        ref="input"
        :placeholder="defaultValue"
        :disabled="disabled"
        :class="props?.custom_class"
    />
</template>
