<script setup>
    import { calculatePaintMaterialRecommended } from '@/helpers/estimator/siding/13_paint';
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";
    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const paintMaterialTypeRecommended = computed(() => {
        return calculatePaintMaterialRecommended({
            items: props?.modelValues.paintMaterialType,
            GET: props?.getRecommendedConversion,
            SET: props?.setRecommendedItem,
            modelField: props?.model.paintMaterialType.field,
            modelFieldId: props?.model.paintMaterialType.field.id,
            itemFeet: props?.modelValues.laborType,
        });
    });
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Labor Type',
                name: 'laborType',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.laborType[i].quantity,
                setModelValue: (i) => `laborType[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Painting Labor Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `laborType[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Square Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `laborType[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Additional Labor Type',
                name: 'additionalLaborType',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.additionalLaborType[i].quantity,
                setModelValue: (i) => `additionalLaborType[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Additional Labor Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `additionalLaborType[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Linear FT',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `additionalLaborType[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                title: 'Paint Extras',
                name: 'paintExtras',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.paintExtras[i].quantity,
                setModelValue: (i) => `paintExtras[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Paint Extras',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `paintExtras[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `paintExtras[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                title: 'Paint Material Type',
                name: 'paintMaterialType',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.paintMaterialType[i].quantity,
                setModelValue: (i) => `paintMaterialType[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Material Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `paintMaterialType[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `paintMaterialType[${i}].quantity`;
                                },
                                recommended(i) {
                                    return paintMaterialTypeRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>
<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
