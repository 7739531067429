<script setup lang="ts">
    import { mapping as fieldMapping } from '@/models/EstimateFields';
    import EstimateSections from '@/models/EstimateSections';
    import EstimateWorkflows from '@/models/EstimateWorkflows';
    import { computed, defineProps, onMounted, watch } from 'vue';

    import { round } from '@/helpers';
    import {
        calculateFlashingCoil,
        calculateFlashingCounter,
        calculateFlashingItems,
        calculateLaborHours,
    } from '@/helpers/estimator/repair/08_extras';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKey, estimateTotalsWithKeyChild, estimteCostValues } from '../mocks';
    import { SectionProps } from '../roofing/types';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const chimneyFlashing = computed(() => {
        const colors = props?.model.chimneyFlashingColor.field.options;
        return calculateFlashingItems(props?.modelValues.chimneyFlashingColor, colors);
    });
    const headFlashing = computed(() => {
        const colors = props?.model.headFlashing.field.options;
        return calculateFlashingItems(props?.modelValues?.headFlashing, colors);
    });
    const counterFlashing = computed(() => {
        const colors = props?.model.counterFlashing.children.counterFlashingColor.field.options;

        return calculateFlashingCounter(props?.modelValues?.counterFlashing, colors);
    });
    const trimCoilFeetRecommended = computed(() => {
        const flashing = [...chimneyFlashing.value, ...headFlashing.value, ...counterFlashing.value];
        return calculateFlashingCoil(flashing);
    });
    type Caulk = {
        quantity: number;
        value: string;
        children: {
            counterFlashingType: {
                value: string;
            };
        };
    };
    const chimneyCaulk = computed(() =>
        props?.modelValues.chimneyFlashing.reduce(
            (sum: number, x: Caulk) =>
                sum + x.quantity * props?.getRecommendedConversion(2, props?.model?.chimneyFlashing.field, x.value),
            0
        )
    );
    const headCaulk = computed(() =>
        props?.modelValues.headFlashing.reduce(
            (sum: number, x: Caulk) =>
                sum + x.quantity * props?.getRecommendedConversion(2, props?.model?.headFlashing.field, x.value),
            0
        )
    );
    const counterCaulk = computed(() =>
        props?.modelValues.counterFlashing.reduce(
            (sum: number, x: Caulk) =>
                sum +
                x.quantity *
                    props?.getRecommendedConversion(
                        2,
                        props?.model?.counterFlashing.children.counterFlashingType.field,
                        x.children.counterFlashingType.value
                    ),
            0
        )
    );
    const caulkRecommended = computed(() => {
        if (!Array.isArray(props?.modelValues.caulk)) return [];
        return props?.modelValues.caulk.map((x: Caulk, i: number) => {
            const feetPerTube = props?.getRecommendedConversion(2, props?.model.caulk.field, x.value);
            if (feetPerTube === 0) return 0;
            const recommended = Math.ceil((chimneyCaulk.value + headCaulk.value + counterCaulk.value) / feetPerTube);
            props?.setRecommendedItem(props?.model.caulk.field.id, recommended, i);
            return recommended;
        });
    });

    const laborHoursRecommended = computed(() => {
        const fieldIds = fieldMapping[EstimateSections.repairExtras];

        let recommended = calculateLaborHours(
            props?.estimate.items,
            EstimateWorkflows.repairs,
            fieldIds,
            props?.getAnyRecommendedConversion
        );

        // get hours for current page
        for (const key of Object.keys(props?.model)) {
            const field = props?.model[key].field;
            if (field.allowMultiple) {
                if (Array.isArray(props?.modelValues[key])) {
                    for (let i = 0; i < props?.modelValues[key].length; i++) {
                        recommended += getHours(key, i);
                    }
                }
            } else {
                recommended += getHours(key, -1);
            }
        }

        props?.setRecommendedItem(props?.model.totalLaborHours.field.id, Math.ceil(recommended));
        return Math.ceil(recommended);
    });

    type Field = {
        dataType: string;
        allowQuantityChange: boolean;
    };

    function getQuantity(field: Field, modelValue: { value: number; quantity: number }) {
        if (field.dataType === 'boolean') {
            return modelValue.value ? 1 : 0;
        } else if (field.allowQuantityChange) {
            return modelValue.quantity >= 0 ? modelValue.quantity : 0;
        } else {
            return 1;
        }
    }

    function getHours(key: string, i: number) {
        const field = props?.model[key].field;
        const children = props?.model[key].children;
        const value = i >= 0 ? props?.modelValues[key][i] : props?.modelValues[key];
        let hours = props?.getRecommendedConversion(3, field, value.value);
        let quantity = getQuantity(field, value);
        if (typeof children === 'object' && children !== null) {
            for (const childKey of Object.keys(children)) {
                const childField = props?.model[key].children[childKey].field;
                const childValue =
                    i >= 0 ? props?.modelValues[key][i].children[childKey] : props?.modelValues[key].children[childKey];
                let childQuantity = getQuantity(childField, childValue);
                hours += childQuantity * props?.getRecommendedConversion(3, childField, childValue.value);
            }
        }
        return quantity * hours;
    }

    const trimCoilRecommended = computed(() => {
        const combined = trimCoilFeetRecommended.value;
        const colorField = props.model.trimCoil.children.trimCoilColor.field;
        return Array.isArray(props.modelValues.trimCoil)
            ? props.modelValues.trimCoil.map((x: { children: { trimCoilColor: { value: string } } }, i: number) => {
                  let recommended;
                  const feetPerUnit = props.getRecommendedConversion(
                      1,
                      props.model.trimCoil.children.trimCoilType.field,
                      props.modelValues.trimCoil[i].children.trimCoilType.value
                  );
                  if (feetPerUnit === 0) {
                      recommended = 0;
                      props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                      return recommended;
                  }

                  const optionId = x.children.trimCoilColor.value;
                  const option = colorField.options.find((x: { id: string }) => x.id === optionId);
                  if (option) {
                      const color = option.value.toLowerCase();
                      if (color in combined) {
                          recommended = Math.ceil(combined[color].feet / feetPerUnit);
                          props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                          return recommended;
                      }
                  }

                  recommended = 0;
                  props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });

    const formatTrimCoilFeetRecommended = computed(() => {
        const trimCoilFeetRecommendedValue = trimCoilFeetRecommended.value;
        if (Object.keys(trimCoilFeetRecommendedValue).length === 0) {
            return '';
        }
        return Object.values(trimCoilFeetRecommendedValue)
            .map((x) => `${x.feet} ft of ${x.color}`)
            .join(', ');
    });

    function updatePrices() {
        if (Array.isArray(props.modelValues.custom)) {
            for (let i = 0; i < props.modelValues.custom.length; i++) {
                const x = props.modelValues.custom[i];
                const materialMultiplier = props.getRecommendedConversion(
                    1,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                const laborMultiplier = props.getRecommendedConversion(
                    2,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                props.setModelValue(`custom[${i}].materialPriceTemp`, round(x.materialCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].materialPrice`, round(x.materialCost * materialMultiplier, 2));
                props.setModelValue(`custom[${i}].laborPriceTemp`, round(x.laborCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].laborPrice`, round(x.laborCost * laborMultiplier, 2));
            }
        }
    }

    watch(() => props.modelValues.custom, updatePrices, { deep: true });
    onMounted(() => {
        updatePrices();
    });

    const listSections = computed((): SectionProps[] => {
        return [
            {
                id: 1,
                name: 'paintMaterialType',
                title: 'Paint Material Type',
                setModelValue: (i) => `paintMaterialType[${i}].quantity`,
                quantity: (i) => props?.modelValues.paintMaterialType[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Repairs permit',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name() {
                                    return 'permit.value';
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Initial set-up',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name() {
                                    return 'initialSetUp.value';
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'High roof',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name() {
                                    return 'highRoof.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Steep roof',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name() {
                                    return 'steepRoof.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: '',
                title: '',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Dumpster needed?',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name() {
                                    return 'dumpsterNeeded.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: 'dumpster',
                title: 'Dumpster',
                refString: 'dumpsters',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: true,
                showSection: props?.modelValues.dumpsterNeeded.value,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Dumpster location',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `dumpster[${i}].children.dumpsterLocation.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `dumpster[${i}].children.dumpsterType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Type'),
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: '',
                title: '',
                refString: '',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                showSection: props?.modelValues.dumpsterNeeded.value,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Dumpster labor',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'dumpsterLabor.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'dumpsterLabor.quantity';
                                },
                                // disabled: true,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        show() {
                            return false;
                        },
                        columns: [
                            {
                                id: 1,
                                label: 'Dumpster weight',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'dumpsterWeight.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                name: 'roofingCement',
                title: 'Roofing Cement',
                setModelValue: (i) => `roofingCement[${i}].quantity`,
                quantity: (i) => props?.modelValues.roofingCement[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Roofing cement',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `roofingCement[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `roofingCement[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                name: 'caulk',
                title: 'Caulk',
                setModelValue: (i) => `caulk[${i}].quantity`,
                quantity: (i) => props?.modelValues.caulk[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Caulk',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `caulk[${i}].quantity`;
                                },
                                recommended(i) {
                                    return caulkRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `caulk[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                ],
            },
            {
                id: 7,
                name: 'deliveryDistance',
                title: 'Delivery Distance',
                setModelValue: (i) => `deliveryDistance[${i}].quantity`,
                quantity: (i) => props?.modelValues.deliveryDistance[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Delivery / Distance',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `deliveryDistance[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `deliveryDistance[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 40,
                isFieldArray: false,
                name: '',
                quantity: () => 0,
                setModelValue: () => '',
                title: `Trim Coil Need: `,
                rows: [
                    {
                        id: 'test',
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended',
                            },
                            {
                                id: 2,
                                label: `${formatTrimCoilFeetRecommended.value}`,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 9,
                name: 'trimCoil',
                title: 'Trim Coil',
                setModelValue: (i) => `trimCoil[${i}].quantity`,
                quantity: (i) => props?.modelValues.trimCoil[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Trim coil',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `trimCoil[${i}].quantity`;
                                },
                                recommended(i) {
                                    return trimCoilRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `trimCoil[${i}].children.trimCoilColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },

                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `trimCoil[${i}].children.trimCoilType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                ],
            },
            {
                id: 10,
                name: '',
                title: 'Labor Hours',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Total labor hours',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'totalLaborHours.quantity';
                                },
                                recommended() {
                                    return laborHoursRecommended.value;
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Total labor hours'),
                        ],
                    },
                    {
                        id: 2,
                        show() {
                            return props?.modelValues.totalLaborHours.quantity !== laborHoursRecommended.value;
                        },
                        columns: [],
                    },
                ],
            },

            {
                id: 11,
                name: 'custom',
                title: 'Add On Services',
                setModelValue: () => '',
                quantity: () => 0,
                addItem: {
                    show: true,
                    title: 'Add On Services',
                },
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Label / name',
                            },
                            {
                                id: 2,
                                type: 'Text',
                                name(i) {
                                    return `custom[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `custom[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: '$ material cost',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].materialCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: '$ labor cost',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].laborCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: '$ material price',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                readonly: true,
                                name(i) {
                                    return `custom[${i}].materialPriceTemp`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: '$ labor price',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                readonly: true,

                                name(i) {
                                    return `custom[${i}].laborPriceTemp`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
