<script setup>
    // <script setup lang = "ts" >

    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";
    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const outsideCornerPostRecommended = computed(() => {
        return Array.isArray(props?.modelValues.outsideCornerPost)
            ? props?.modelValues.outsideCornerPost.map((x, i) => {
                  const outsideCornerPostLength = x.children.outsideCornerPostLength.quantity;
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.outsideCornerPost.children.outsideCornerPostType.field,
                      x.children.outsideCornerPostType.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const recommended = Math.ceil(outsideCornerPostLength / feetPerUnit);
                  props?.setRecommendedItem(props?.model.outsideCornerPost.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });

    const insideCornerPostRecommended = computed(() => {
        return Array.isArray(props?.modelValues.insideCornerPost)
            ? props?.modelValues.insideCornerPost.map((x, i) => {
                  const insideCornerPostLength = x.children.insideCornerPostLength.quantity;
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.insideCornerPost.children.insideCornerPostType.field,
                      x.children.insideCornerPostType.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const recommended = Math.ceil(insideCornerPostLength / feetPerUnit);
                  props?.setRecommendedItem(props?.model.insideCornerPost.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Outside corner posts',
                name: 'outsideCornerPost',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.outsideCornerPost[i].quantity,
                setModelValue: (i) => `outsideCornerPost[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Outside corner post',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `outsideCornerPost[${i}].children.outsideCornerPostLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `outsideCornerPost[${i}].children.outsideCornerPostType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `outsideCornerPost[${i}].quantity`;
                                },
                                recommended(i) {
                                    return outsideCornerPostRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `outsideCornerPost[${i}].children.outsideCornerPostColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Inside corner posts',
                name: 'insideCornerPost',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.insideCornerPost[i].quantity,
                setModelValue: (i) => `insideCornerPost[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Inside corner post',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `insideCornerPost[${i}].children.insideCornerPostLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `insideCornerPost[${i}].children.insideCornerPostType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `insideCornerPost[${i}].quantity`;
                                },
                                recommended(i) {
                                    return insideCornerPostRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `insideCornerPost[${i}].children.insideCornerPostColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
