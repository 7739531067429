import api from '@/api/index';
import { cacheFiles } from '@/api/sw/cacheFiles';
import { getRandomColor, setLuxonTimeZone, setThemeColor } from '@/helpers';
import Message from '@/models/Message';
import MessageType from '@/models/MessageType';
import Settings from '@/models/Settings';
import { DateTime } from 'luxon';
import { createStore, Store } from 'vuex';
import auth from './modules/auth';
import estimator from './modules/estimator';

let env = 'production';
if (window.location.hostname === 'localhost' || /\.ngrok\.io$/.test(window.location.hostname)) {
    env = 'development';
} else if (/^ihshomebuilder-testing\.azurewebsites\.net$/.test(window.location.hostname)) {
    env = 'testing';
} else if (/^ihshomebuilder-staging\.azurewebsites\.net$/.test(window.location.hostname)) {
    env = 'staging';
}

interface State {
    [x: string]: any;
    environment: string;
    settings: Settings | null;
    isOnline: boolean;
    installPrompt: Event | null;
    showInstallBanner: boolean;
    showInstallButton: boolean;
    serviceWorkerUpdate: boolean;
    messages: Message[];
    unsyncedChanges: number;
    syncing: boolean;
    lastRefresh: DateTime | null;
}

const store: Store<State> = createStore<State>({
    strict: env === 'development' && process.env.NODE_ENV !== 'production',
    modules: {
        auth,
        estimator,
    },
    state: {
        environment: env,
        settings: null,
        isOnline: true,
        installPrompt: null,
        showInstallBanner: false,
        showInstallButton: false,
        serviceWorkerUpdate: false,
        messages: [],
        unsyncedChanges: 0,
        syncing: false,
        lastRefresh: null,
    },
    mutations: {
        setSettings(state, settings: Settings | null) {
            state.settings = settings;
            const timeZone = state.settings ? state.settings.timeZone : '';
            setLuxonTimeZone(timeZone || 'defaultTimeZone');

            let themeColor = null;
            if (state.environment === 'development' && process.env.NODE_ENV !== 'production') {
                themeColor = getRandomColor();
            } else {
                themeColor = (state.settings ? state.settings.themeColor : null) || '#2ab0da';
            }
            setThemeColor(themeColor);
        },
        setOnlineStatus(state, status: boolean) {
            state.isOnline = status;
        },
        setServiceWorkerUpdate(state, status: boolean) {
            state.serviceWorkerUpdate = status;
        },
        setPwaInstallButton(state, status: boolean) {
            state.showInstallButton = status;
        },
        setCustomPwaBanner(state, status: boolean) {
            state.showInstallBanner = status && localStorage.getItem('pwaUserChoice') !== 'no';
        },
        setPwaPrompt(state, event: Event | null) {
            state.installPrompt = event;
        },
        addMessage(state, message: Message) {
            if (state.messages.findIndex((x) => x.id === message.id) < 0) {
                state.messages.push(message);
            }
        },
        removeMessage(state, messageId: number) {
            const index = state.messages.findIndex((x) => x.id === messageId);
            if (index >= 0) {
                state.messages.splice(index, 1);
            }
        },
        setUnsyncedChanges(state, unsyncedChanges: number) {
            state.unsyncedChanges = unsyncedChanges;
        },
        setSyncing(state, syncing: boolean) {
            state.syncing = syncing;
        },
        setLastRefresh(state, timestamp: DateTime | null) {
            state.lastRefresh = timestamp;
        },
    },
    actions: {
        async clear({ dispatch, commit }) {
            commit('setSettings', null);
            await dispatch('auth/clear');
            await dispatch('estimator/clear');
        },
        async refresh({ dispatch }) {
            await dispatch('auth/refresh');
            await dispatch('refreshAfterAuth');
        },
        async refreshAfterAuth({ dispatch, commit }) {
            await Promise.all([dispatch('refreshSettings'), dispatch('estimator/refresh')]);
            commit('setLastRefresh', DateTime.now());
        },
        async refreshSettings({ getters, commit }) {
            const settings = getters['auth/isUser'] ? await api.settings.getSettings() : null;

            if (settings != null && process.env.NODE_ENV === 'production') {
                const files = [];
                if (typeof settings.contractWarrantyImage === 'string') {
                    files.push(settings.contractWarrantyImage);
                }
                if (typeof settings.logoDarkImage === 'string') {
                    files.push(settings.logoDarkImage);
                }
                if (typeof settings.logoImage === 'string') {
                    files.push(settings.logoImage);
                }
                if (settings.contractLogos != null) {
                    for (let i = 0; i < settings.contractLogos.length; i++) {
                        files.push(settings.contractLogos[i]);
                    }
                }
                cacheFiles(files);
            }
            commit('setSettings', settings);
        },
        addMessage(
            { commit, state },
            {
                message,
                type = MessageType.info,
                autoClose = true,
            }: { message: string; type?: string; autoClose?: boolean }
        ) {
            message = message.trim();
            if (!message) {
                return;
            }

            const currentId = state.messages.length > 0 ? state.messages.reduce((id, x) => Math.max(id, x.id), 0) : 0;
            const m = new Message() as Message;
            m.id = currentId + 1;
            m.type = MessageType.isValid(type) ? type : MessageType.info;
            m.message = message;
            m.autoClose = !!autoClose;

            commit('addMessage', m);
            if (autoClose) {
                setTimeout(() => {
                    commit('removeMessage', m.id);
                }, Message.AUTO_CLOSE_DELAY + 1000);
            }
        },
    },
});

export default store;
