interface OAuthUserDto {
    email: string;
    name: string;
}

export default class OAuthUser {
    email: string;
    name: string;

    constructor(dto: Partial<OAuthUserDto> = {}) {
        this.email = dto.email ?? '';
        this.name = dto.name ?? '';
    }
}
