<script setup>
    import { computed, watch } from 'vue';

    import { defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKeyChild, estimteCostValues } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    // const soffitMaterial = ref(0);

    const materialNeededRecommended = computed(() => {
        // soffitMaterial.value = 0;
        const standardArea = (props?.modelValues.standardSoffit ?? []).reduce((obj, x) => {
            const color = x.children.standardSoffitColor.value;
            const key = color.toLowerCase();
            const depth = x.children.standardSoffitDepth.quantity / 12; // convert to feet
            const length = x.children.standardSoffitLength.quantity;

            const item = key in obj ? obj[key] : (obj[key] = { color: color.trim(), area: 0 });
            item.area += length * depth;
            return obj;
        }, {});
        const hiddenVentArea = (props?.modelValues.hiddenVentSoffit ?? []).reduce((obj, x) => {
            const color = x.children.hiddenVentSoffitColor.value;
            const key = color.toLowerCase();
            const depth = x.children.hiddenVentSoffitDepth.quantity / 12; // convert to feet
            const length = x.children.hiddenVentSoffitLength.quantity;

            const item = key in obj ? obj[key] : (obj[key] = { color: color.trim(), area: 0 });
            item.area += length * depth;
            return obj;
        }, {});
        const porchSoffitArea = (props?.modelValues.porchSoffit ?? []).reduce((obj, x) => {
            const color = x.children.porchSoffitColor.value;
            const key = color.toLowerCase();
            const depth = x.children.porchSoffitDepth.quantity;
            const length = x.children.porchSoffitLength.quantity;

            const item = key in obj ? obj[key] : (obj[key] = { color: color.trim(), area: 0 });
            item.area += length * depth;
            return obj;
        }, {});
        return Object.values(standardArea)
            .map((x) => {
                const solidQty = Math.ceil((x.area / 12) * 0.75 * 1.1); // 10% waste
                const ventedQty = Math.ceil((x.area / 12) * 0.2 * 1.1); // 10% waste

                // soffitMaterial.value += solidQty + ventedQty;

                return `${solidQty} solid ${x.color}`.trim() + ', ' + `${ventedQty} vented ${x.color}`.trim();
            })
            .concat(
                Object.values(hiddenVentArea).map((x) => {
                    const qty = Math.ceil((x.area / 12) * 1.05); // 5% waste

                    // soffitMaterial.value += qty;

                    return `${qty} hidden vent ${x.color}`.trim();
                })
            )
            .concat(
                Object.values(porchSoffitArea).map((x) => {
                    const qty = Math.ceil((x.area / 12) * 1.05); // 5% waste

                    // soffitMaterial.value += qty;

                    return `${qty} porch ${x.color}`.trim();
                })
            )
            .join(', ');
    });

    const soffitRecommended = computed(() => {
        return Array.isArray(props?.modelValues.soffit)
            ? props?.modelValues.soffit.map((x, i) => {
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.soffit.children.soffitType.field,
                      props?.modelValues.soffit[i].children.soffitType.value
                  );

                  if (feetPerUnit === 0) {
                      props?.setRecommendedItem(props?.model.soffit.field.id, 0, i);
                      return 0;
                  }

                  const standardArea = (props?.modelValues.standardSoffit ?? []).reduce((obj, x) => {
                      const color = x.children.standardSoffitColor.value;
                      const key = color.toLowerCase();
                      const depth = x.children.standardSoffitDepth.quantity / 12; // convert to feet
                      const length = x.children.standardSoffitLength.quantity;

                      const item = key in obj ? obj[key] : (obj[key] = { color: color.trim(), area: 0 });
                      item.area += length * depth;
                      return obj;
                  }, {});
                  const hiddenVentArea = (props?.modelValues.hiddenVentSoffit ?? []).reduce((obj, x) => {
                      const color = x.children.hiddenVentSoffitColor.value;
                      const key = color.toLowerCase();
                      const depth = x.children.hiddenVentSoffitDepth.quantity / 12; // convert to feet
                      const length = x.children.hiddenVentSoffitLength.quantity;

                      const item = key in obj ? obj[key] : (obj[key] = { color: color.trim(), area: 0 });
                      item.area += length * depth;
                      return obj;
                  }, {});
                  const porchSoffitArea = (props?.modelValues.porchSoffit ?? []).reduce((obj, x) => {
                      const color = x.children.porchSoffitColor.value;
                      const key = color.toLowerCase();
                      const depth = x.children.porchSoffitDepth.quantity;
                      const length = x.children.porchSoffitLength.quantity;

                      const item = key in obj ? obj[key] : (obj[key] = { color: color.trim(), area: 0 });
                      item.area += length * depth;
                      return obj;
                  }, {});

                  let recommendedStandard = 0;
                  Object.values(standardArea).map((x) => {
                      const solidQty = Math.ceil((x.area / feetPerUnit) * 0.75 * 1.1); // 10% waste
                      const ventedQty = Math.ceil((x.area / feetPerUnit) * 0.2 * 1.1); // 10% waste

                      recommendedStandard += solidQty + ventedQty;
                  });

                  let recommendedHiddenVent = 0;
                  Object.values(hiddenVentArea).map((x) => {
                      const qty = Math.ceil((x.area / feetPerUnit) * 1.05); // 5% waste

                      recommendedHiddenVent += qty;
                  });

                  let recommendedPorchSoffit = 0;
                  Object.values(porchSoffitArea).map((x) => {
                      const qty = Math.ceil((x.area / feetPerUnit) * 1.05); // 5% waste

                      recommendedPorchSoffit += qty;
                  });

                  const recommended = recommendedStandard + recommendedHiddenVent + recommendedPorchSoffit;

                  props?.setRecommendedItem(props?.model.soffit.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });

    const compositeSoffitRecommended = computed(() => {
        return Array.isArray(props?.modelValues.compositeSoffit)
            ? props?.modelValues.compositeSoffit.map((x, i) => {
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.compositeSoffit.children.compositeSoffitType.field,
                      props?.modelValues.compositeSoffit[i].children.compositeSoffitType.value
                  );
                  if (feetPerUnit === 0) {
                      props?.setRecommendedItem(
                          props?.model.compositeSoffit.children.compositeSoffitType.field.id,
                          0,
                          i
                      );
                      return 0;
                  }
                  const length = x.children.compositeSoffitLength.quantity;
                  const recommended = Math.ceil(length / feetPerUnit);
                  props?.setRecommendedItem(
                      props?.model.compositeSoffit.children.compositeSoffitType.field.id,
                      recommended,
                      i
                  );
                  return recommended;
              })
            : [];
    });

    watch(
        () => props?.modelValues.porchSoffit,
        () => {
            props?.modelValues.porchSoffit.forEach((x, i) => {
                props?.setModelValue(
                    `porchSoffit[${i}].quantity`,
                    x.children.porchSoffitDepth.quantity * x.children.porchSoffitLength.quantity
                );
            });
        },
        { deep: true }
    );
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: '6932a74e-0166-4948-b27f-12cce4a52a4d',
                name: 'standardSoffit',
                quantity: (i) => props?.modelValues.standardSoffit[i].children.standardSoffitLength.quantity,
                setModelValue: (i) => `standardSoffit[${i}].children.standardSoffitLength.quantity`,
                title: 'Standard Soffit',
                isFieldArray: true,
                rows: [
                    {
                        id: '9f11acc1-feb9-4314-8237-aa7042374090',
                        columns: [
                            {
                                id: '9bae0131-6599-4a46-a3d7-018e1f05104f',
                                label: 'TOTAL',
                            },
                            {
                                id: '9ba07765-1b7d-4169-ad7e-49ade0aefa54',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: '642f6e77-8a7f-4335-be29-0124aec6200d',
                        columns: [
                            {
                                id: '7c04ef55-4333-4ab8-8d1c-6f5449f85e38',
                                label: 'Depth',
                            },
                            {
                                id: '1da067c2-71c4-4273-8acf-8e86e524ae6d',
                                type: 'Quantity',
                                name(i) {
                                    return `standardSoffit[${i}].children.standardSoffitDepth.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '7588935d-0ab9-4bbf-ab43-2ae6a5eca818',
                        columns: [
                            {
                                id: '3688310d-b104-4e12-87b0-033d910eee55',
                                label: 'Length Feet',
                            },
                            {
                                id: 'e5911273-cfa1-4ad9-9bd5-dc343437c3de',
                                type: 'Quantity',
                                name(i) {
                                    return `standardSoffit[${i}].children.standardSoffitLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '80510311-56da-4597-a81d-6258cf8ad200',
                        columns: [
                            {
                                id: '7ce5afe0-8370-436a-824f-2277aa58b7a2',
                                label: 'Color',
                            },
                            {
                                id: 'ec367e10-d8dc-49d3-8bb2-126e8b10befb',
                                type: 'Text',
                                name(i) {
                                    return `standardSoffit[${i}].children.standardSoffitColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '1fa535f1-990a-413a-9ed4-258e29308516',
                        columns: [
                            {
                                id: 'c6796917-c724-4dad-827b-ad67d927735a',
                                label: 'Labor',
                            },
                            {
                                id: 'e539b2b7-078b-443c-83b9-b5af7367f7f7',
                                type: 'Dropdown',
                                name(i) {
                                    return `standardSoffit[${i}].children.standardSoffitLabor.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Labor'),
                        ],
                    },
                ],
            },

            {
                id: '9274b7d7-f303-42a6-ad4e-537ba81f120c',
                name: 'hiddenVentSoffit',
                quantity: (i) => props?.modelValues.hiddenVentSoffit[i].children.hiddenVentSoffitLength.quantity,
                setModelValue: (i) => `hiddenVentSoffit[${i}].children.hiddenVentSoffitLength.quantity`,
                title: 'Hidden Vent Soffit',
                isFieldArray: true,

                rows: [
                    {
                        id: '46e966dc-f847-474e-bcf2-3c6b62b04ada',
                        columns: [
                            {
                                id: 'ea5c7f5e-804b-4542-89c9-a0f79f7583b6',
                                label: 'TOTAL',
                            },
                            {
                                id: '40505f50-2812-491c-855a-d432dad65a2e',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 'e3522fa0-9c44-4672-b39e-b8a4bb6a3c6e',
                        columns: [
                            {
                                id: '1dc0012d-ae0d-4019-bee6-db11ac0740d7',
                                label: 'Depth Inches',
                            },
                            {
                                id: '7f865ecf-d640-48d7-84ac-77c42b58ddc1',
                                type: 'Quantity',
                                name(i) {
                                    return `hiddenVentSoffit[${i}].children.hiddenVentSoffitDepth.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'd03420fb-dad5-4a8f-9bf6-d442eae3d1b6',
                        columns: [
                            {
                                id: '9f9fa850-41ea-421f-9a5e-691013319ecd',
                                label: 'Length Feet',
                            },
                            {
                                id: '83c13cc4-274f-4e2d-afef-02353ffc17d6',
                                type: 'Quantity',
                                name(i) {
                                    return `hiddenVentSoffit[${i}].children.hiddenVentSoffitLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '066afa63-45c6-435f-86c2-7fbdaf44734d',
                        columns: [
                            {
                                id: 'b62d1124-df41-4f36-9f9d-bdb30207c514',
                                label: 'Color Optional',
                            },
                            {
                                id: '991b5846-7b89-450f-966b-99b4319d2ee5',
                                type: 'Text',
                                name(i) {
                                    return `hiddenVentSoffit[${i}].children.hiddenVentSoffitColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '71e61803-6495-4375-80b9-a07cb9645662',
                        columns: [
                            {
                                id: '592ebebb-fd20-4408-8b95-06ebb3a0c306',
                                label: 'Labor',
                            },
                            {
                                id: '892b0b2e-4acd-4ed4-90ff-f6ab44391395',
                                type: 'Dropdown',
                                name(i) {
                                    return `hiddenVentSoffit[${i}].children.hiddenVentSoffitLabor.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Labor'),
                        ],
                    },
                ],
            },
            {
                id: '5aca44b9-1e3d-495a-9b48-b23451562cff',
                name: 'porchSoffit',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.porchSoffit[i].children.porchSoffitLength.quantity,
                setModelValue: (i) => `porchSoffit[${i}].children.porchSoffitLength.quantity`,
                title: 'Porch Soffit',
                rows: [
                    {
                        id: '66799d34-2a38-4b2c-8580-b28f029a0846',
                        columns: [
                            {
                                id: 'cd772bd4-f5bc-404c-9868-2d23d1f8ac35',
                                label: 'TOTAL',
                            },
                            {
                                id: 'c661954b-2a4d-4843-b673-48980b339308',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: '0f7ce8b7-fe9c-48ae-a793-1be35505c3c6',
                        columns: [
                            {
                                id: 'f3bf3fbb-24f0-413e-aafe-788a048b4608',
                                label: 'Depth Feet',
                            },
                            {
                                id: '3b03ddff-5234-4251-92c9-ed5d022179af',
                                type: 'Quantity',
                                name(i) {
                                    return `porchSoffit[${i}].children.porchSoffitDepth.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '89623ade-2a8e-4aa0-b305-1861c315ceff',
                        columns: [
                            {
                                id: '4a0ba8fa-c321-444c-8a82-807af39ba45d',
                                label: 'Length Feet',
                            },
                            {
                                id: '54a34022-c0c1-4e63-80a2-e8942de41f02',
                                type: 'Quantity',
                                name(i) {
                                    return `porchSoffit[${i}].children.porchSoffitLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '24e857a8-73e2-483d-91ee-0724a222545d',
                        columns: [
                            {
                                id: 'cb472226-e8bd-4a2e-a64d-de875b2899d6',
                                label: 'Color Optional',
                            },
                            {
                                id: '52987cce-20fa-40f4-894b-d3ee8b93bcaa',
                                type: 'Text',
                                name(i) {
                                    return `porchSoffit[${i}].children.porchSoffitColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'a1935bde-ea4f-473f-a4ef-dc5325b67e1f',
                        columns: [
                            {
                                id: '69cb6c2c-e009-4e62-9fde-4aca63adf114',
                                label: 'Labor',
                            },
                            {
                                id: 'da3be678-d817-4b67-968c-9d2ef4d9473c',
                                type: 'Dropdown',
                                name(i) {
                                    return `porchSoffit[${i}].children.porchSoffitLabor.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Labor'),
                        ],
                    },
                ],
            },
            {
                id: 'c300e9cd-ab63-464e-94d7-df9a51c11d3c',
                title: 'Soffit Material',
                name: '',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: '36804223-4827-42f0-a49e-51591c06171e',
                        columns: [
                            {
                                id: '116b5a6d-1d14-461e-80b2-9318bb5e7e19',
                                label: 'Material needed:',
                            },
                            {
                                id: '3e8165e9-7f48-4c3c-85e3-effa6fad9e95',
                                label: materialNeededRecommended.value,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 'f3f42170-f685-4e22-8a4f-00dd7de13127',
                title: 'soffit',
                name: 'soffit',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.soffit[i].quantity,
                setModelValue: (i) => `soffit[${i}].quantity`,
                rows: [
                    {
                        id: 'dfed6d82-4a64-4be1-b8c1-41a6b9dba0ab',
                        columns: [
                            {
                                id: 'b63e2b6f-e95e-4049-9c7e-730b08110850',
                                label: 'TOTAL',
                            },
                            {
                                id: '1a222ff1-289e-4415-93a1-464357681031',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 'ab5f0244-2f7f-4394-b58a-377902368628',
                        columns: [
                            {
                                id: '30ce5af0-b9ef-4608-a177-7d03c200fef0',
                                label: 'Soffit Quantity',
                            },
                            {
                                id: '5caf5199-b9c1-4152-89c9-fdb4e0af6616',
                                type: 'Quantity',
                                recommended(i) {
                                    return soffitRecommended.value[Number(i)];
                                },
                                name(i) {
                                    return `soffit[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '45bdecb0-6f7e-4e03-8e92-90b81b0008cc',
                        columns: [
                            {
                                id: '7934972d-27e0-4f24-ad7b-915dc3f3c4c3',
                                label: 'Type',
                            },
                            {
                                id: 'ebb61793-2572-4064-9c3c-11e56146fcdb',
                                type: 'Dropdown',
                                name(i) {
                                    return `soffit[${i}].children.soffitType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Type'),
                        ],
                    },
                    {
                        id: '850f116f-7772-4ebd-8c47-75a30b89b1f0',
                        columns: [
                            {
                                id: 'e208745c-e3e7-4136-975f-ec735fa523ef',
                                label: 'Color',
                            },
                            {
                                id: '69f34235-e1e5-4564-bf39-a782f0257f46',
                                type: 'Dropdown',
                                name(i) {
                                    return `soffit[${i}].children.soffitColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: '48a8276e-3d02-49fe-99fc-b8d084bec6e9',
                title: 'J Channel',
                name: 'jChannel',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.jChannel[i].quantity,
                setModelValue: (i) => `jChannel[${i}].quantity`,
                rows: [
                    {
                        id: '6c502fb5-3c01-4da8-9cc6-b34bf7639d12',
                        columns: [
                            {
                                id: '6230cffb-8be6-4066-8d43-06aa9a31c3e3',
                                label: 'TOTAL',
                            },
                            {
                                id: '9d4a6d5a-23b3-4528-aaae-4dbba582bec4',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 'ee1e4463-ba0f-4aee-a88e-4e8f244e461c',
                        columns: [
                            {
                                id: '6d5fab74-8b24-4a1b-bca5-51cadcd4a787',
                                label: 'J Channel',
                            },
                            {
                                id: '022502d6-a5ce-4247-b3d1-db3e6a4a4b38',
                                type: 'Quantity',
                                name(i) {
                                    return `jChannel[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '7edb0469-0df8-4cda-9fc5-a041f3f0ac60',
                        columns: [
                            {
                                id: 'f779a1c5-2946-451c-9a8c-370ce396b2a8',
                                label: 'Type',
                            },
                            {
                                id: '904167c4-afd0-4344-aff7-9fe092540e07',
                                type: 'Dropdown',
                                name(i) {
                                    return `jChannel[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: '96982351-07d2-4d91-982a-7381a6a5cec3',
                title: 'Composite Soffit',
                name: 'compositeSoffit',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.compositeSoffit[i].children.compositeSoffitType.quantity,
                setModelValue: (i) => `compositeSoffit[${i}].children.compositeSoffitType.quantity`,
                rows: [
                    {
                        id: 'fce5dbbb-1c86-4242-9233-2dccb4247af5',
                        columns: [
                            {
                                id: '8c3afc8d-2ebc-462b-909b-7fa3b6b78b9d',
                                label: 'TOTAL',
                            },
                            {
                                id: '1128d373-92a7-4fed-8c8a-1cb0618190b2',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: '9efc18ac-14e5-4ed8-8632-407d9b9be606',
                        columns: [
                            { id: '686590a5-c57f-4602-b9b3-ebfdabdb44b7', label: '--' },
                            {
                                id: 'a932656f-0669-47f1-9b48-eee5e431c180',
                                error(i) {
                                    if (
                                        (props?.modelValues.compositeSoffit[Number(i)].children.compositeSoffitLength
                                            .quantity > 0 &&
                                            props?.modelValues.compositeSoffit[Number(i)].children.compositeSoffitType
                                                .quantity === 0) ||
                                        (props?.modelValues.compositeSoffit[Number(i)].children.compositeSoffitLength
                                            .quantity === 0 &&
                                            props?.modelValues.compositeSoffit[Number(i)].children.compositeSoffitType
                                                .quantity > 0)
                                    ) {
                                        return '*';
                                    }
                                    return '';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '55d7003f-af13-4834-a427-45f6cd7d8c76',
                        columns: [
                            {
                                id: '9949f7c5-60c0-4303-9f6d-ef8deae2c623',
                                label: 'Depth inches',
                            },
                            {
                                id: '2a474b2a-d124-4009-b7d2-b3e7d84a5790',
                                type: 'Quantity',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitDepth.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '323a842a-ef0f-4cf9-9e54-d4df651038ce',
                        columns: [
                            {
                                id: '84915b9a-1185-4174-9b6b-dd99fecd98b2',
                                label: 'Length Feet',
                            },
                            {
                                id: '0af89a57-26af-4cd3-a5ab-3ac232e6777f',
                                type: 'Quantity',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitLength.quantity`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Length'),
                        ],
                    },
                    {
                        id: '62ef25d2-7270-4525-a0e8-7827b2570b4d',
                        columns: [
                            {
                                id: '33be2dae-8cb1-4fdf-b386-19d30d4b43ad',
                                label: 'Labor',
                            },
                            {
                                id: '97bcf646-38f5-4843-85ec-cb70d68c6a0f',
                                type: 'Dropdown',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitLength.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '52257955-a351-47d4-803e-5992761989ff',
                        columns: [
                            {
                                id: '3cca453b-f6d5-418f-a3a0-5f427a2348c1',
                                label: 'Quantity',
                            },
                            {
                                id: 'ef1d94c2-d113-47b0-86a1-8a73683b8527',
                                type: 'Quantity',
                                recommended(i) {
                                    return compositeSoffitRecommended.value[Number(i)];
                                },
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitType.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '41e597ee-b140-4380-862e-e599e5ae9c82',
                        columns: [
                            {
                                id: 'cdf63c4f-ab6d-45d4-8ba7-96178c24f249',
                                label: 'Type',
                            },
                            {
                                id: '969f204b-f87c-43cc-bece-f3836d9a7566',
                                type: 'Dropdown',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Type'),
                        ],
                    },
                    {
                        id: '16a74372-47ab-4584-a559-558a18c95250',
                        columns: [
                            {
                                id: 'e7770909-3929-4343-be38-39d698d70b5a',
                                label: 'Color',
                            },
                            {
                                id: '80f0c2d0-3eac-409f-814c-60cc49607e8c',
                                type: 'Dropdown',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: '964d5462-ff61-4791-bfa1-9b0a7b4e98d9',
                title: 'Cut Soffit Vent',
                name: 'cutSoffitVent',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.cutSoffitVent[i].quantity,
                setModelValue: (i) => `cutSoffitVent[${i}].quantity`,
                rows: [
                    {
                        id: 'e5950e4f-fe4f-4793-ab2c-1f2d2764d6fc',
                        columns: [
                            {
                                id: '923ce14f-302d-42c4-9078-0398de70c7db',
                                label: 'TOTAL',
                            },
                            {
                                id: '70e56177-8fb7-4dbf-afe2-8a58d23b3cd9',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 'bd0c45b9-b0d0-4098-bbed-6bf298d3de4e',
                        columns: [
                            {
                                id: 'b469a5b3-4aa9-43d2-b2dd-eb2c07ddf439',
                                label: 'Cut in soffit vent',
                            },
                            {
                                id: '9950185a-fc7a-49d0-a901-0e41b8dcd398',
                                type: 'Dropdown',
                                name(i) {
                                    return `cutSoffitVent[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '46e1a0a2-bb2a-4b45-b62a-84e2f1025c4e',
                        columns: [
                            {
                                id: 'ff96e35b-1cd6-4a8d-bf63-1cfad0becedd',
                                label: 'Quantity',
                            },
                            {
                                id: '91a6087d-2783-40d7-9f32-3a304bd37b07',
                                type: 'Quantity',
                                name(i) {
                                    return `cutSoffitVent[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
