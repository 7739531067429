<script setup >
    import { defineProps } from 'vue';
    import FieldExcelError from './FieldExcelError.vue';

    const props = defineProps({
        row:null,
        errors:null
    });
</script>

<template>
    <div v-if="props?.row.type === 'Checkbox' && props?.row.name" class="form-check-radio-group">
        <FieldExcelError
            :name="props?.row.name"
            :custom_class="'input_field_error'"
            :custom-message="'*'"
            :errors="props?.errors"
        />
        <div class="check-radio-wrap" style="display: flex; flex-direction: row; gap: 2px">
            <field-input-checkbox :name="props?.row.name" />
            <p style="font-size: 12px; margin: 0px">{{ props?.row?.title }}</p>
        </div>
        <!-- <field-error :name="props?.row.name" :custom_class="'input_field_error'" /> -->
    </div>
    <template v-if="props?.row.type === 'Dropdown' && props?.row.name">
        <FieldExcelError
            :name="props?.row.name"
            :custom_class="'input_field_error'"
            :custom-message="'*'"
            :errors="props?.errors"
        />
        <field-input-dropdown
            :name="props?.row.name"
            :display="props?.row?.display"
            :custom_class="'input_dropdown_excel'"
        />
    </template>
    <template v-if="props?.row.type === 'Quantity' && props?.row.name">
        <FieldExcelError
            :name="props?.row.name"
            :custom_class="'input_field_error'"
            :custom-message="'*'"
            :errors="props?.errors"
        />
        <field-input-quantity
            :name="props?.row.name"
            :custom_class="'input_number_excel'"
            :custom_select_class="'input_quantity_select_excel'"
            :recommendedClass="'input_quantity_recommended'"
            :autoSetRecommend="true"
        />
    </template>

    <field-error v-if="props?.row.name" :name="props?.row.name" :custom_class="'input_field_error'" />
    <template v-if="Number(props?.row?.children?.length) > 0">
        <transition name="toggle-slide-right">
            <div class="checkbox-sublist" v-show="props?.row?.showChilds" style="margin-left: 2rem">
                <template
                    v-for="child in props?.row?.children"
                    :key="`row-child-${child?.title}-${child?.name}-${child.id}`"
                >
                    <FieldRecursive :row="child" :errors="props?.errors" />
                </template>
            </div>
        </transition>
    </template>
</template>
