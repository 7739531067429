/* eslint-disable no-unused-vars */
import api from '@/api/index';
import { cacheFiles } from '@/api/sw/cacheFiles';
import { DocumentReference } from '@/models/Document';
import Estimate from '@/models/Estimate';
import EstimateFieldOption from '@/models/EstimateFieldOption';
import EstimateSection from '@/models/EstimateSection';
import EstimateWorkflow from '@/models/EstimateWorkflow';
import EstimateWorkflows from '@/models/EstimateWorkflows';
import EstimateWorkflowSummary from '@/models/EstimateWorkflowSummary';
import Lead from '@/models/Lead';
import { DateTime } from 'luxon';
import { Commit } from 'vuex';

// initial state
const state = () => ({
    workflows: [],
    fields: null,
    fieldOrder: null,
    lead: null,
    estimate: null,
    hoverJob: null,
    newContractId: null,
});

interface RootGetters {
    'auth/isUser': boolean;
}

interface Workflow {
    id?: number;
    name?: string;
    status?: string;
    sections?: any; //import sections types
    enabled: boolean;
}

interface Section {
    workflowId?: string;
    id: string;
}

interface Fields {
    [key: string]: any;
}

interface HoverJob {
    measurements?: string;
}

interface State {
    estimate?: any; //import estimate types
    hoverJob?: HoverJob;
    newContractId?: number;
    workflows: Workflow[] | any[];
    fields?: Fields;
    fieldOrder?: Fields;
    lead?: Fields;
}

interface workflowById {
    sections?: string;
}

interface FieldVariant {
    incomingEdges: Record<string, Set<string>>;
    outgoingEdges: Record<string, string[]>;
}

interface FieldVariantGraph {
    [workflowId: string]: FieldVariant;
}

interface Getters {
    getWorkflowById: (id: string) => workflowById;
    getSectionsByWorkflowId: (id: number) => any[];
    estimateSteps: (id: string) => any[];
    getLastCompleteSectionId: (id: number) => workflowById;
    getOptionalsSectionsIdsAdded: (id: number) => [];
    getCurrentStepIndex: (id: string, step: any) => number;
}

// getters
const getters = {
    getWorkflowById: (state: State) => (workflowId: string) => state.workflows.find((x) => x.id === workflowId),
    getSectionsByWorkflowId: (state: State, getters: Getters) => (workflowId: string) => {
        const workflow = getters.getWorkflowById(workflowId);
        if (!workflow) {
            return [];
        }
        return workflow.sections;
    },
    getLastCompleteSectionId: (state: State) => (workflowId: number) =>
        state.estimate
            ? (state.estimate.workflowSummaries[EstimateWorkflows.getKey(workflowId)] || new EstimateWorkflowSummary())
                  .lastCompleteSectionId
            : null,
    getOptionalsSectionsIdsAdded: (state: State) => (workflowId: number) =>
        state.estimate
            ? (state.estimate.workflowSummaries[EstimateWorkflows.getKey(workflowId)] || new EstimateWorkflowSummary())
                  .optionalsSectionsIdsAdded
            : null,
    estimateSteps: (state: State, getters: Getters) => (workflowId: number) => {
        if (!state.estimate) {
            return [];
        }
        const workflowKey = EstimateWorkflows.getKey(workflowId);
        const sections = getters.getSectionsByWorkflowId(workflowId);
        let estimateSteps = sections.filter((x) => x.enabled && x.order > 0);

        const lastCompleteSectionId = getters.getLastCompleteSectionId(workflowId);
        const optionalsSectionsIdsAdded: string[] = getters.getOptionalsSectionsIdsAdded(workflowId);
        const currentStepOrder = lastCompleteSectionId
            ? (sections.find((x) => x.id === lastCompleteSectionId) || {}).order || 0
            : 0;
        // find the index of the last enabled step with an order <= currentStepOrder
        const currentStepIndex = estimateSteps.reduceRight(
            (found, x, i) => (found < 0 && x.order <= currentStepOrder ? i : found),
            -1
        );

        estimateSteps = estimateSteps.filter((x) => !x.optional || optionalsSectionsIdsAdded.includes(x.id));

        for (let i = 0; i < estimateSteps.length; i++) {
            const step = estimateSteps[i];

            const info = {
                id: step.id,
                name: step.name,
                url: `/estimator/estimate/${state.estimate.id}/${workflowKey}/${step.order}`,
                order: step.order,
                complete: i <= currentStepIndex,
                lastComplete: i == currentStepIndex,
                current: i == currentStepIndex + 1,
                next: i === currentStepIndex + 2,
                unlocked: i <= currentStepIndex + 2,
                optional: step.optional,
                component: step?.component ?? null,
            };
            if (i === estimateSteps.length - 1 && info.complete) {
                info.current = true;
            }
            estimateSteps[i] = info;
        }
        return estimateSteps;
    },
    getCurrentStepIndex: (state: State, getters: Getters) => (workflowId: string, steps: any[]) => {
        if (!Array.isArray(steps)) {
            steps = getters.estimateSteps(workflowId);
        }
        return steps.findIndex((x) => x.current);
    },
    getCurrentStep: (state: State, getters: Getters) => (workflowId: string, steps: any[]) => {
        if (!Array.isArray(steps)) {
            steps = getters.estimateSteps(workflowId);
        }
        const index = getters.getCurrentStepIndex(workflowId, steps);
        return index >= 0 ? steps[index] : null;
    },
    isNextStep: (state: State, getters: Getters) => (workflowId: string, sectionId: string, steps: any[]) => {
        if (!Array.isArray(steps)) {
            steps = getters.estimateSteps(workflowId);
        }
        // get the current step index
        const currentStepIndex = getters.getCurrentStepIndex(workflowId, steps);
        const newIndex = steps.findIndex((x: { id: string }) => x.id === sectionId);
        return (currentStepIndex === -1 && newIndex >= 0) || (currentStepIndex >= 0 && newIndex >= currentStepIndex);
    },
    stepIsComplete: (state: State, getters: Getters) => (workflowId: string, sectionId: string, steps: any[]) => {
        if (!Array.isArray(steps)) {
            steps = getters.estimateSteps(workflowId);
        }
        return steps.findIndex((x) => x.id === sectionId && x.complete) >= 0;
    },
    getNextStep: (state: State, getters: Getters) => (workflowId: string, sectionId: string, steps: any[]) => {
        if (!Array.isArray(steps)) {
            steps = getters.estimateSteps(workflowId);
        }
        const index = steps.findIndex((x: { id: string }) => x.id === sectionId);
        if (index < 0 || index === steps.length - 1) {
            return null;
        }
        return steps[index + 1];
    },
    getHoverJob: (state: State) => {
        return state.hoverJob;
    },
};

// mutations
const mutations = {
    setWorkflows(state: State, workflows: Workflow) {
        if (Array.isArray(workflows)) {
            state.workflows = workflows;
        } else {
            state.workflows = [];
        }
    },
    setWorkflow(state: State, workflow: Workflow) {
        if (workflow !== null && workflow instanceof EstimateWorkflow && state.workflows) {
            if (workflow.id in state.workflows) {
                // const sections = state.workflows[workflow.id].sections;
                // workflow.sections = sections;
                state.workflows[workflow.id] = workflow;
            }
        }
    },
    setSection(state: State, section: Section) {
        if (section !== null && section instanceof EstimateSection && state.workflows) {
            const workflowId = section.workflowId;
            if (workflowId !== undefined && workflowId in state.workflows) {
                const sectionIndex = state.workflows[workflowId].sections.findIndex(
                    (x: { id: string }) => x.id === section.id
                );
                if (sectionIndex >= 0) {
                    state.workflows[workflowId].sections[sectionIndex] = section;
                }
            }
        }
    },
    setFields(state: State, fields: object) {
        if (fields !== null && typeof fields === 'object') {
            state.fields = fields;
        } else {
            state.fields = [];
        }
    },
    setFieldOrder(state: State, fieldOrder: object) {
        if (fieldOrder !== null && typeof fieldOrder === 'object') {
            state.fieldOrder = fieldOrder;
        } else {
            state.fieldOrder = [];
        }
    },
    setFieldOption(state: State, option: { fieldId: string; id: string } | null) {
        if (option !== null && option instanceof EstimateFieldOption && state.fields) {
            const fieldId = option.fieldId;
            if (fieldId in state.fields) {
                const optionIndex = state.fields[fieldId].options.findIndex((x: { id: string }) => x.id === option.id);
                if (optionIndex >= 0) {
                    state.fields[fieldId].options[optionIndex] = option;
                }
            }
        }
    },
    setLead(state: State, lead: object) {
        if (lead !== null && lead instanceof Lead) {
            state.lead = lead;
        } else {
            state.lead = [];
        }
    },
    setEstimate(state: State, estimate: { refreshItemTree: () => void }) {
        if (estimate !== null && estimate instanceof Estimate) {
            estimate.refreshItemTree();
            state.estimate = estimate;
        } else {
            state.estimate = null;
        }
    },
    addEstimateDocument(state: State, document: { estimateId: string; id: string }) {
        if (
            state.estimate !== null &&
            document instanceof DocumentReference &&
            document.estimateId === state.estimate.id
        ) {
            const index = state.estimate.documents.findIndex((x: { id: string }) => x.id === document.id);
            if (index >= 0) {
                state.estimate.documents.splice(index, 1, document);
            } else {
                state.estimate.documents.push(document);
            }
        }
    },
    removeEstimateDocument(state: State, documentId: string) {
        if (state.estimate !== null) {
            const index = state.estimate.documents.findIndex((x: { id: string }) => x.id === documentId);
            if (index >= 0) {
                state.estimate.documents.splice(index, 1);
            }
        }
    },
    setHoverJob(state: { hoverJob: string }, hoverJob: string) {
        state.hoverJob = hoverJob;
    },
    setHoverJobMeasurements(state: State, measurements: string) {
        if (state.hoverJob) {
            state.hoverJob.measurements = measurements;
        }
    },
    setNewContractId(state: State, id: number) {
        if (id === null || typeof id === 'number') {
            state.newContractId = id;
        }
    },
    async setOptionalSectionsAdded(state: State, obj: { value: string; id: number }) {
        const e = state.estimate;
        e.getWorkflowSummaryByKey(obj.value);

        const initial = state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded;
        const optionalSections = state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.filter(
            (x: number) => {
                if (obj.value === 'roofing' && obj.id === -1) {
                    return x === 146 || x === 147;
                }
                if (obj.value === 'siding' && obj.id === -1) {
                    return x === 144 || x === 145;
                }
                return x === obj.id;
            }
        );

        if (optionalSections.length > 0) {
            if (obj.value === 'roofing' && obj.id === -1) {
                state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded =
                    state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.filter(
                        (x: number) => x !== 146 && x !== 147
                    );
            } else if (obj.value === 'siding' && obj.id === -1) {
                state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded =
                    state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.filter(
                        (x: number) => x !== 144 && x !== 145
                    );
            } else {
                state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded =
                    state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.filter(
                        (x: number) => x !== obj.id
                    );
            }
        } else {
            if (obj.value === 'roofing' && obj.id === -1) {
                state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.push(146);
                state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.push(147);
            } else if (obj.value === 'siding' && obj.id === -1) {
                state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.push(144);
                state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.push(145);
            } else {
                state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded.push(obj.id);
            }
        }

        state.estimate.modifiedTime = DateTime.now();
        const response = await api.estimates.update(e);
        if (!(response instanceof Estimate)) {
            api.helpers.handleHttpError(response);
            state.estimate.workflowSummaries[obj.value].optionalsSectionsIdsAdded = initial;
        }
    },
};

// actions
const actions = {
    async clear({ commit }: { commit: Commit }) {
        commit('setWorkflows', null);
        commit('setFields', null);
        commit('setLead', null);
        commit('setEstimate', null);
        commit('setHoverJob', null);
        commit('setNewContractId', null);
    },
    async refresh({ commit, rootGetters }: { commit: Commit; rootGetters: RootGetters }) {
        if (rootGetters['auth/isUser']) {
            const p1 = api.estimateWorkflows.getAll();
            const p2 = api.estimateSections.getAll();
            const p3 = api.estimateFields.getAll();
            await Promise.all([p1, p2, p3]);
            const response1 = await p1;
            const workflowMap: { [key: string]: Workflow } = {};
            if (Array.isArray(response1)) {
                for (let i = 0; i < response1.length; i++) {
                    const workflow = response1[i];
                    workflowMap[workflow.id] = workflow;
                }
            }
            const response2 = await p2;
            if (Array.isArray(response2)) {
                for (const section of response2) {
                    const workflowId = section.workflowId;

                    if (workflowId && workflowMap[workflowId]) {
                        const workflow = workflowMap[workflowId];
                        section.enabled = workflow.enabled ? section.enabled : false;
                        workflow.sections.push(section);
                    }
                }
            }
            for (const [, workflow] of Object.entries(workflowMap)) {
                workflow.sections.sort((a: { order: number }, b: { order: number }) => a.order - b.order);
            }
            const response3 = await p3;
            const files = [];
            // this variant topological sorting could be saved server-side and sent to the client with the
            const fieldVariantGraph: Record<string, any> = {};
            for (const workflowId of Object.keys(EstimateWorkflows.map)) {
                fieldVariantGraph[workflowId] = {
                    incomingEdges: {},
                    outgoingEdges: {},
                };
            }

            const fields: Record<string, any> = {};
            if (Array.isArray(response3)) {
                for (let i = 0; i < response3.length; i++) {
                    const field: Record<string, any> = response3[i];
                    fields[field.id] = field;
                    if (field.variants.length > 0) {
                        const incomingEdges = fieldVariantGraph[field.workflowId].incomingEdges;
                        const outgoingEdges = (fieldVariantGraph[field.workflowId].outgoingEdges[field.id] = new Set());
                        for (const fieldId of Object.keys(field.variants[0].optionsMap)) {
                            if (fieldId && fieldId !== field.id) {
                                outgoingEdges.add(fieldId);
                                const inList =
                                    fieldId in incomingEdges
                                        ? incomingEdges[fieldId]
                                        : (incomingEdges[fieldId] = new Set());
                                inList.add(field.id);
                            }
                        }
                    }

                    if (process.env.NODE_ENV === 'production') {
                        for (let i = 0; i < field.options.length; i++) {
                            const image = field.options[i].image;
                            if (image != null) {
                                files.push(image);
                            }
                        }
                    }
                }
            }

            const fieldOrder: Record<string, string[]> = {};
            for (const workflowId of Object.keys(fieldVariantGraph)) {
                const { incomingEdges, outgoingEdges } = fieldVariantGraph[workflowId];
                const sorted = []; // Empty list that will contain the sorted elements
                const todo = Object.keys(outgoingEdges).filter((x) => !(x in incomingEdges)); // Set of all nodes with no incoming edge

                // while todo is not empty do
                while (todo.length > 0) {
                    // remove a node n from todo
                    const n = todo.shift();
                    if (n === undefined) continue;
                    sorted.push(n);
                    if (!(n in outgoingEdges)) continue;
                    for (const m of outgoingEdges[n]) {
                        // remove edge e from the graph
                        incomingEdges[m].delete(n);
                        // if m has no other incoming edges then
                        if (incomingEdges[m].size == 0) {
                            // insert m into todo
                            delete incomingEdges[m];
                            todo.push(m);
                        }
                    }
                    delete outgoingEdges[n];
                }
                // sorted is a topological sort of the graph nodes
                fieldOrder[workflowId] = sorted.reverse();
            }

            cacheFiles(files);
            const workflows = EstimateWorkflows.list.map((x) => workflowMap[x.id]);
            commit('setWorkflows', workflows);
            commit('setFields', fields);
            commit('setFieldOrder', fieldOrder);
        } else {
            commit('setWorkflows', null);
            commit('setFields', null);
            commit('setFieldOrder', null);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
