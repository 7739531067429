import { url as urlHelper } from '@/helpers';
import ZohoUser from '@/models/ZohoUser';
import { AxiosResponse } from 'axios';
import { fetchWrap, offlineResponse } from '../_helpers';

// Define los tipos de los parámetros
type ZohoUserParams = {
    active?: boolean;
};

export default {
    /**
     * Get all zoho users, filtered by the following parameters.
     * @param {Object} params
     * @param {boolean} params.active
     * @returns (async) Returns a Response if the request was made without network errors, or an array of ZohoUser.
     */
    async getAll({ active = undefined }: ZohoUserParams = {}) {
        const query: Record<string, any> = {};
        if (typeof active === 'boolean') {
            query.active = active;
        }
        const url = urlHelper('/api/zohousers', query);
        let response: AxiosResponse;
        try {
            response = await fetchWrap(url);
        } catch {
            response = offlineResponse();
        }
        if (response?.status >= 200 && response?.status < 300) {
            const data = response.data;
            return data.map((x: any) => new ZohoUser(x));
        } else {
            return response;
        }
    },
};
