export const calculatePaintMaterialRecommended = (props) => {
  const { items, GET, SET, modelField, modelFieldId, itemFeet } = props;
  if (!Array.isArray(items)) {
    return [];
  }
  return items.map((x, i) => {
    const feet = itemFeet.map((x) => x.quantity).reduce((a, b) => a + b, 0);

    const galPerSqFtWall = GET(1, modelField, items[i].value);
    if (galPerSqFtWall === 0) return 0;
    const recommended = Math.ceil(feet / galPerSqFtWall);
    SET(modelFieldId, recommended, i);
    return recommended;
  });
};
