<template>
    <div class="package-drop-area" v-on="dropAreaListeners">
        <input
            type="file"
            :id="htmlFieldName"
            ref="fileInput"
            :accept="extensions.join() + ',' + mimeTypes.join()"
            class="hidden"
            @change="onFileChange"
        />

        <div v-if="modelValue">
            <div class="form-grid mb-0 file-input">
                <div class="package-thumbnail has-img" :class="{ highlight: dragging }">
                    <img v-if="modelValue.url" :src="`${APP_URL_ORIGIN}${modelValue.url}`" />
                    <img v-else src="@/img/unknown-image.png" />
                    <div v-if="loading">uploading&hellip;</div>
                </div>
            </div>
            <div class="mt-third">
                <label class="btn-link btn-edit mr-1-half" :for="htmlFieldName">Change</label>
                <button class="btn-link btn-link-delete" type="button" @click="removeFile">Remove</button>
            </div>
        </div>
        <div v-else>
            <label :for="htmlFieldName" class="package-thumbnail no-image" :class="{ highlight: dragging }">
                <div class="label">Drag or select image</div>
                <svg width="24px" height="24px" class="icon fill-dark stroke-green">
                    <use xlink:href="#add"></use>
                </svg>
            </label>
        </div>
    </div>
</template>

<script>
    import { computed, ref } from 'vue';
    import { useStore } from 'vuex';
    import { isImageFile, resizeImage, previewImage } from '@/helpers';
    import api from '@/api/index';
    import { useDragAndDrop } from '@/composables/dragAndDrop';
    import { useField } from 'vee-validate';
    import { imageFileExtensions, imageFileMimeTypes } from '@/yupExtensions';
    import FileReference from '@/models/FileReference';
    import MessageType from '@/models/MessageType';
    import { APP_URL_ORIGIN } from '@/api/_helpers';

    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
            id: {
                type: String,
                required: false,
                default: '',
            },
        },
        setup(props) {
            const $store = useStore();
            const fileInput = ref(null);
            const loading = ref(false);
            const { value: modelValue, setErrors } = useField(computed(() => props.name));
            const htmlFieldName = computed(() => (props.id ? props.id + '_' : '') + 'fileInput');

            async function save() {
                const file = modelValue.value;
                if (!file || !file.data) return true;
                loading.value = true;
                const response = await api.files.upload(file.data);
                if (response instanceof FileReference) {
                    modelValue.value = response;
                    loading.value = false;
                    return true;
                } else {
                    if (response.status === 400) {
                        const serverErrors = await api.helpers.processValidationErrors(response, ['formFile']);
                        if (serverErrors.formFile) {
                            setErrors(serverErrors.formFile);
                        }
                    } else {
                        await api.helpers.handleHttpError(response);
                    }
                    loading.value = false;
                    return false;
                }
            }

            const onFileChange = async () => handleFiles(fileInput.value.files);

            async function handleFiles(files) {
                // loading.value = true;
                let file = files.length > 0 ? files[0] : null;
                fileInput.value.value = '';
                let fileRef = null;
                if (file) {
                    fileRef = new FileReference({
                        data: file,
                        name: file.name,
                        size: file.size,
                    });
                    if (isImageFile(file)) {
                        try {
                            file = await resizeImage(file);
                            fileRef = new FileReference({
                                data: file,
                                name: file.name,
                                size: file.size,
                                url: await previewImage(file),
                            });
                        } catch (e) {
                            console.error(e);
                            fileRef = null;
                            $store.dispatch('addMessage', {
                                message: 'Error processing image file: ' + e.message,
                                type: MessageType.error,
                            });
                        }
                    }
                }
                modelValue.value = fileRef;
                // loading.value = false;
            }

            function removeFile() {
                modelValue.value = null;
            }

            // drag and drop
            const { dragging, dropAreaListeners, addDropHandler } = useDragAndDrop();
            addDropHandler(handleFiles);

            return {
                modelValue,
                fileInput,
                loading,
                extensions: imageFileExtensions,
                mimeTypes: imageFileMimeTypes,
                htmlFieldName,
                removeFile,
                dragging,
                dropAreaListeners,
                onFileChange,
                save,
                APP_URL_ORIGIN,
            };
        },
    };
</script>
