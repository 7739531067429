import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "gray-underline-heading mt-1-half" }

import { useStore } from 'vuex';
import { computed, inject } from 'vue';
import { safeComputed } from '@/composables/safeComputed';

interface ConfirmDialog {
	[x: string]: any;
	openDialog: (message: string, options?: { type?: string; confirmText?: string }) => Promise<boolean>;
	closeDialog: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SectionHeader',
  props: {
	title: {
		type: String,
		required: false,
	},
	showTooltib: {
		type: Boolean,
		required: true,
	},
	name: {
		type: String,
		required: true,
	},
},
  setup(__props) {

const $store = useStore();
const settings = safeComputed(() => $store.state.settings, true);
const alertDialog = inject<ConfirmDialog>('alertDialog');

const props = __props;

const info = computed(() => {
	const value = settings.value.sectionsInfo.find((x: { key: string; }) => x.key === props.name)?.value;

	return value || `No description available. Please contact your administrator to configure "${props.name}" field.`;
});

const showDescription = () => {
	alertDialog?.value.openDialog(info.value, { confirmText: 'Ok' });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(__props.title) + " ", 1),
    (__props.showTooltib)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "btn-reset",
          onClick: showDescription
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("svg", {
            class: "icon fill-gray mr-half",
            height: "20px",
            width: "20px"
          }, [
            _createElementVNode("use", { "xlink:href": "#msg-info" })
          ], -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})