<script setup>
    // <script setup lang = "ts" >

    import { computed, defineProps } from 'vue';

    import { calculateSheathingRecommend } from '@/helpers/estimator/siding/02_siding';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKeyChild, estimteCostValues } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const houseWrapRecommended = computed(() => {
        if (!Array.isArray(props?.modelValues.houseWrap)) {
            return [];
        }
        // return props?.modelValues.houseWrap.map((x: any, i: number) => {
        return props?.modelValues.houseWrap.map((x, i) => {
            return calculateSheathingRecommend({
                GET: props?.getRecommendedConversion,
                ID_GET: props?.model.houseWrap.children.houseWrapType.field,
                VALUE_GET: props?.modelValues.houseWrap[i].children.houseWrapType.value,
                AREA_GET: x.children.houseWrapMaterial.quantity,
                SET: props?.setRecommendedItem,
                SET_ID: props?.model.houseWrap.children.houseWrapType.field.id,
                index: i,
            });
        });
    });

    const houseInsulationRecommended = computed(() => {
        if (!Array.isArray(props?.modelValues.houseInsulation)) {
            return [];
        }
        // return props?.modelValues.houseInsulation.map((x: any, i: number) => {
        return props?.modelValues.houseInsulation.map((x, i) => {
            return calculateSheathingRecommend({
                GET: props?.getRecommendedConversion,
                ID_GET: props?.model.houseInsulation.children.houseInsulationType.field,
                VALUE_GET: props?.modelValues.houseInsulation[i].children.houseInsulationType.value,
                AREA_GET: x.children.houseInsulationMaterial.quantity,
                SET: props?.setRecommendedItem,
                SET_ID: props?.model.houseInsulation.children.houseInsulationType.field.id,
                index: i,
            });
        });
    });

    const totalSquaresForMaterial = computed(() => {
        const recommended =
            // props?.estimate.items.find((x: any) => x.fieldId === "2.111")?.quantity ||
            props?.estimate.items.find((x) => x.fieldId === '2.111')?.quantity || 0;

        props?.setRecommendedItem(props?.model.houseWrap.children.houseWrapMaterial.field.id, recommended);
        props?.setRecommendedItem(props?.model.houseInsulation.children.houseInsulationMaterial.field.id, recommended);
        return recommended;
    });
    const totalSquaresForLabor = computed(() => {
        const recommended =
            // props?.estimate.items.find((x: any) => x.fieldId === "2.112")?.quantity ||
            props?.estimate.items.find((x) => x.fieldId === '2.112')?.quantity || 0;

        props?.setRecommendedItem(props?.model.houseWrap.children.houseWrapLabor.field.id, recommended);
        props?.setRecommendedItem(props?.model.houseInsulation.children.houseInsulationLabor.field.id, recommended);
        return recommended;
    });

    // "HOUSE WRAP"
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: 'houseWrap',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.houseWrap[i].children.houseWrapType.quantity,
                setModelValue: () => '',
                title: 'House wrap',
                clearType: 'REMOVE_ADD',
                rows: [
                    {
                        id: 'a2e132ea-bc21-46e9-857b-1a0b8389c971',
                        columns: [
                            {
                                id: 'cb6fd766-6060-41c7-890e-06ae3a0d1e94',
                                label: 'TOTAL',
                            },
                            {
                                id: '03f73218-ffc6-4cf6-b9d6-63a2423709da',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `houseWrap[${i}].children.houseWrapType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Material Squares',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `houseWrap[${i}].children.houseWrapMaterial.quantity`;
                                },
                                recommended() {
                                    return totalSquaresForMaterial?.value;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Labor Squares',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `houseWrap[${i}].children.houseWrapLabor.quantity`;
                                },
                                recommended: () => {
                                    return totalSquaresForLabor?.value;
                                },
                            },
                            // ...emptyFields,
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'LABOR'),
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `houseWrap[${i}].children.houseWrapType.quantity`;
                                },
                                recommended(i) {
                                    return houseWrapRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'houseInsulation',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.houseInsulation[i].children.houseInsulationType.quantity,
                setModelValue: () => '',
                title: 'Insulation',
                clearType: 'REMOVE_ADD',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `houseInsulation[${i}].children.houseInsulationType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Material Squares',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `houseInsulation[${i}].children.houseInsulationMaterial.quantity`;
                                },
                                recommended: () => totalSquaresForMaterial.value,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Labor Squares',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `houseInsulation[${i}].children.houseInsulationLabor.quantity`;
                                },
                                recommended: () => totalSquaresForLabor.value,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity bundles',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `houseInsulation[${i}].children.houseInsulationType.quantity`;
                                },
                                recommended: (i) => {
                                    return houseInsulationRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>
<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
