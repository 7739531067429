<script setup >
    import { computed, defineProps, watch } from 'vue';
    import FieldRecursive from '../../atoms/FieldRecursive.vue';
    // import { TableItem } from './types';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    function updateLeaks() {
        if (!props?.modelValues.roofingProblemLeaks.value) {
            props?.setModelValue('roofingProblemChimneyLeaks.value', false);
            props?.setModelValue('roofingProblemValleysLeaks.value', false);
            props?.setModelValue('roofingProblemGuttersLeaks.value', false);
            props?.setModelValue('roofingProblemSkylightsLeaks.value', false);
            props?.setModelValue('roofingProblemRoofLeaks.value', false);
            props?.setModelValue('roofingProblemOtherLeaks.value', false);
            props?.setModelValue('roofingProblemFlashingLeaks.value', false);
        }
    }

    function updateIceDams() {
        if (!props?.modelValues.roofingProblemIceDams.value) {
            props?.setModelValue('roofingProblemChimneyIceDams.value', false);
            props?.setModelValue('roofingProblemValleysIceDams.value', false);
            props?.setModelValue('roofingProblemGuttersIceDams.value', false);
            props?.setModelValue('roofingProblemSkylightsIceDams.value', false);
            props?.setModelValue('roofingProblemRoofIceDams.value', false);
            props?.setModelValue('roofingProblemOtherIceDams.value', false);
        }
    }

    watch(() => props?.modelValues.roofingProblemLeaks.value, updateLeaks);
    watch(() => props?.modelValues.roofingProblemIceDams.value, updateIceDams);

    // const listTable = computed((): TableItem[] => {
        const listTable = computed(() => {

        return [
            {
                id: 1,
                label: 'Area of Work',
                type: 'Dropdown',
                name: 'areaOfWork.value',
                display: 'dropdown',
            },
            // {
            //     id: 2,
            //     label: 'Years in home',
            //     type: 'Quantity',
            //     name: 'yearsInHome.quantity',
            // },
            {
                id: 3,
                label: '# of stories',
                type: 'Quantity',
                name: 'numberOfStories.quantity',
                isQuantitySelect: true,
            },
            // {
            //     id: 4,
            //     label: 'Age of roof (years)',
            //     type: 'Quantity',
            //     name: 'ageOfRoof.quantity',
            // },
            // {
            //     id: 5,
            //     label: 'Roof access',
            //     type: 'Dropdown',
            //     name: 'roofAccess.value',
            // },
            // {
            //     id: 6,
            //     label: 'Satellite dish',
            //     type: 'Dropdown',
            //     name: 'satelliteDish.value',
            // },
            // {
            //     id: 7,
            //     label: 'Preferred timeline',
            //     type: 'Dropdown',
            //     name: 'preferredTimeline.value',
            // },
            // {
            //     id: 1.4,
            //     title: 'Leaks',
            //     label: 'Roof Problems',
            //     name: 'roofingProblemLeaks.value',
            //     type: 'Checkbox',
            // },
            // {
            //     id: 1.41,
            //     type: 'Checkbox',
            //     name: 'roofingProblemChimneyLeaks.value',
            //     title: 'Chimney leaks',
            //     showRow: props?.modelValues.roofingProblemLeaks.value,
            // },
            // {
            //     id: 1.42,
            //     title: 'Valleys leaks',
            //     name: 'roofingProblemValleysLeaks.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemLeaks.value,
            // },
            // {
            //     id: 1.43,
            //     title: 'Gutters leaks',
            //     name: 'roofingProblemGuttersLeaks.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemLeaks.value,
            // },
            // {
            //     id: 1.44,
            //     title: 'Skylights leaks',
            //     name: 'roofingProblemSkylightsLeaks.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemLeaks.value,
            // },
            // {
            //     id: 1.45,
            //     title: 'Roof leaks',
            //     name: 'roofingProblemRoofLeaks.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemLeaks.value,
            // },
            // {
            //     id: 1.46,
            //     title: 'Other leaks',
            //     name: 'roofingProblemOtherLeaks.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemLeaks.value,
            // },
            // {
            //     id: 1.47,
            //     title: 'Flashing leaks',
            //     name: 'roofingProblemFlashingLeaks.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemLeaks.value,
            // },
            // {
            //     id: 2,
            //     label: 'Ice Dams',
            //     title: 'Ice dams',
            //     name: 'roofingProblemIceDams.value',
            //     type: 'Checkbox',
            // },
            // {
            //     id: 2.1,
            //     title: 'Chimney ice dams',
            //     name: 'roofingProblemChimneyIceDams.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemIceDams.value,
            // },
            // {
            //     id: 2.2,
            //     title: 'Valleys ice dams',
            //     name: 'roofingProblemValleysIceDams.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemIceDams.value,
            // },
            // {
            //     id: 2.3,
            //     title: 'Gutters ice dams',
            //     name: 'roofingProblemGuttersIceDams.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemIceDams.value,
            // },
            // {
            //     id: 2.4,
            //     title: 'Skylights ice dams',
            //     name: 'roofingProblemSkylightsIceDams.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemIceDams.value,
            // },
            // {
            //     id: 2.5,
            //     title: 'Roof ice dams',
            //     name: 'roofingProblemRoofIceDams.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemIceDams.value,
            // },
            // {
            //     id: 2.6,
            //     title: 'Other ice dams',
            //     name: 'roofingProblemOtherIceDams.value',
            //     type: 'Checkbox',
            //     showRow: props?.modelValues.roofingProblemIceDams.value,
            // },
            // { id: 3.5, title: 'Old shingles', name: 'roofingProblemOldShingles.value', type: 'Checkbox' },
            // { id: 4.56, title: 'Curling', name: 'roofingProblemCurling.value', type: 'Checkbox' },
            // { id: 5.2, title: 'Storm damage', name: 'roofingProblemStormDamage.value', type: 'Checkbox' },
            // { id: 6.9, title: 'Granular', name: 'roofingProblemGranular.value', type: 'Checkbox' },
            // { id: 7.5, title: 'Flashing', name: 'roofingProblemFlashing.value', type: 'Checkbox' },
            // {
            //     id: 8.2,
            //     label: 'Gutter problems',
            //     title: 'Leaking corners',
            //     name: 'gutterProblemLeakingCorners.value',
            //     type: 'Checkbox',
            // },
            // {
            //     id: 9.3,
            //     title: 'Not properly pitched',
            //     name: 'gutterProblemNotProperlyPitched.value',
            //     type: 'Checkbox',
            // },
            // {
            //     id: 10,
            //     title: 'Clogging',
            //     name: 'gutterProblemClogging.value',
            //     type: 'Checkbox',
            // },
        ];
    });
</script>

<template>
    <template v-for="row in listTable" :key="`row-table-${row.id}`">
        <div v-if="row?.showRow ?? true" class="quick_estimate_row_table">
            <div class="quick_estimate_table_item_sub">
                {{ row.label }}
            </div>
            <div class="quick_estimate_row_table_item">
                <FieldRecursive :row="row" :errors="props?.errors" />
            </div>
            <div class="quick_estimate_row_table_item"></div>
            <div class="quick_estimate_row_table_item"></div>
            <div class="quick_estimate_row_table_item"></div>
            <div class="quick_estimate_row_table_item"></div>
        </div>
    </template>
    <!-- <section class="quick_estimate_row_table">
        <field-array name="previousRoofingProviders" v-slot="{ items, removeItem, addItemAfter }">
            <template v-for="(row, i) in items" :key="`row-table-${row.id}`">
                <div class="quick_estimate_row_table_item">Previous roofing providers</div>
                <div class="quick_estimate_row_table_item">
                    <field-input-text
                        :name="`previousRoofingProviders[${i}].value`"
                        :custom_class="'input_text_excel'"
                    />
                </div>
                <div class="quick_estimate_row_table_item"></div>

                <div class="quick_estimate_row_table_item"></div>

                <div class="quick_estimate_row_table_item"></div>

                <div class="quick_estimate_row_table_item">
                    <div style="display: flex; gap: 1rem; height: 100%">
                        <button class="btn-link" type="button" @click="addItemAfter(i)">
                            <svg width="24px" height="24px" class="icon fill-dark stroke-green">
                                <use xlink:href="#add"></use>
                            </svg>
                        </button>
                        <button class="btn-link" type="button" v-if="items.length > 1" @click="removeItem(i)">
                            <svg width="24px" height="24px" class="icon fill-red stroke-red">
                                <use xlink:href="#remove"></use>
                            </svg>
                        </button>
                        <button
                            class="btn-link"
                            type="button"
                            v-else-if="modelValues.previousRoofingProviders[i].value"
                            @click="setModelValue(`previousRoofingProviders[${i}].value`, '')"
                        >
                            <svg width="24px" height="24px" class="icon fill-red stroke-red">
                                <use xlink:href="#remove"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </template>
        </field-array>
    </section> -->
</template>
