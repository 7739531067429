<!-- eslint-disable no-unused-vars -->
<!-- <script setup lang="ts"> -->
<script setup lang="ts">
    import { getFieldValuesFromEstimate } from '@/composables/estimate';
    import { safeComputed } from '@/composables/safeComputed';
    import { computed, inject, ref, watch } from 'vue';

    import { useFieldArray } from 'vee-validate';
    import { defineProps } from 'vue';
    import { useStore } from 'vuex';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
    });

    type WindowType = {
        id: number;
        children: {
            height: {
                value: number;
            };
            width: {
                value: number;
            };
            roomName: {
                value: string;
            };
        };
    };

    // function fieldHasValidOptions(i: number, propName: string) {
    function fieldHasValidOptions(i: number, propName: string) {
        if (!(i <= props?.modelValues.windows.length)) {
            return false;
        }
        const parentId = props?.modelValues.windows[i]?.children?.[propName]?.parentId;
        if (!parentId) {
            return false;
        }
        const validOptions = props?.model.windows.children[propName].validOptions[parentId] ?? [];
        return validOptions.length > 0;
    }

    // function showFootLock(i: number) {
    function showFootLock(i: number) {
        const style = getOptionText(i, 'windowStyle');
        return style && style.toLowerCase() === 'patio door';
    }

    // function getOptionText(i: number, prop: any) {
    function getOptionText(i: number, prop: string) {
        if (!(i <= props?.modelValues.windows.length)) {
            return false;
        }
        const value = props?.modelValues.windows[i]?.children?.[prop]?.value;
        if (!value) {
            return null;
        }
        const field = props?.model.windows.children[prop].field;
        // const selectedOption = value ? field.options.find((x: { id: string }) => x.id === value) : null;
        const selectedOption = value ? field.options.find((x: { id: string }) => x.id === value) : null;

        return selectedOption ? selectedOption.value : null;
    }
    type RowsProps = {
        id: string;
        title: string;
        main?: boolean;
        type?: string;
        readonly?: boolean;
        showFieldOpts?: (i: number) => string;
        value: (i: number) => string;
        text?: (item: { value: { id: number } }) => string;
    };
    const rows: RowsProps[] = [
        {
            id: '96488244-3f55-4069-9879-efaf2c44172c',
            title: 'Windows',
            main: true,
            // eslint-disable-next-line no-unused-vars

            value: (i) => {
                return ``;
            },
        },
        {
            id: '6cbeb3d2-e531-428d-89cd-64b3905457ab',
            title: 'Room name',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.roomName.value`;
            },
        },

        {
            id: '416723af-aa34-42f6-befb-647c333e9cdd',
            title: 'Room details',
            type: 'text',
            value: (i) => {
                return `windows[${i}].children.roomDetails.value`;
            },
        },
        {
            id: '419c1eed-afad-4650-9004-3636e1ad8ad3',
            title: 'Width',
            type: 'quantity',
            value: (i) => {
                return `windows[${i}].children.width.quantity`;
            },
        },
        {
            id: '20de22ba-8ec4-4300-87f8-cf3848521363',
            title: 'Height',
            type: 'quantity',
            value: (i) => {
                return `windows[${i}].children.height.quantity`;
            },
        },
        {
            id: 'cabac435-19ab-4c03-8e78-9b3b5ccf3aea',
            title: 'UI',
            type: 'quantity',
            readonly: true,
            value: (i) => {
                return `windows[${i}].children.ui.quantity`;
            },
        },
        {
            id: 'a1a9e6d1-cb6b-4fd1-bdf1-f51642af193c',
            title: 'Window type',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.windowStyle.value`;
            },
        },
        {
            id: '17eeef99-d5e2-4bc7-83c3-ad95f8a1a169',
            title: 'Type details',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.windowStyleDetails.value`;
            },
        },
        {
            id: '699e8224-a0ba-457c-a4f1-786bd6dcda7c',
            title: 'Quantity',
            type: 'quantity',
            value: (i) => {
                return `windows[${i}].quantity`;
            },
        },
        {
            id: 'f0de00e4-5fde-4470-abc3-4a9f0bc2c512',
            title: 'Model',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.windowModel.value`;
            },
        },
        {
            id: 'a6aecfdb-a3df-4f64-84cf-c618f28c12cf',
            title: 'Install Type',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.installType.value`;
            },
        },
        {
            id: 'd7e21631-7bd9-4b40-bd5d-6a7b60fd9854',
            title: 'Hardware',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.hardware.value`;
            },
        },
        {
            id: 'e3090bbd-b711-498b-957b-1b09f5a29732',
            title: 'screen ',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.screen.value`;
            },
        },
        {
            id: '87b8e6b4-4e29-4905-9cf9-82516220e5fc',
            title: 'Swing',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'swing'),
            value: (i) => {
                return `windows[${i}].children.swing.value`;
            },
        },
        {
            id: '4678069b-48c1-479c-bd8b-9151b8f60fd3',
            title: 'Operating sash',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'operatingSash'),
            value: (i) => {
                return `windows[${i}].children.operatingSash.value`;
            },
        },
        {
            id: '6c07e34b-d7e7-447d-ac11-315573e4e416',
            title: 'Obscure sash',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'obscureSash'),
            value: (i) => {
                return `windows[${i}].children.obscureSash.value`;
            },
        },
        {
            id: '75eb5a6e-c07a-46d0-9ab1-5aefd3226955',
            title: 'Jamb depth',
            type: 'quantity',
            value: (i) => {
                return `windows[${i}].children.jambDepth.quantity`;
            },
        },
        {
            id: '158be9de-521b-42f9-85a0-796518b860fe',
            title: 'Blinds',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'blinds'),
            value: (i) => {
                return `windows[${i}].children.blinds.value`;
            },
        },
        {
            id: '7474584d-a615-4f7c-85a5-5ce5e0067f41',
            title: 'Weld',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'weld'),
            value: (i) => {
                return `windows[${i}].children.weld.value`;
            },
        },
        {
            id: 'b2db964e-a1ea-4903-9009-ac0bc82be018',
            title: 'Roof option',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'roofOption'),
            value: (i) => {
                return `windows[${i}].children.roofOption.value`;
            },
        },
        {
            id: '12ac44c8-a609-499b-b169-0c991c11a71e',
            title: 'Foot lock',
            type: 'checkbox',
            showFieldOpts: (i) => showFootLock(i),
            value: (i) => {
                return `windows[${i}].children.footLock.value`;
            },
        },
        {
            id: '09eed311-0b02-4d95-a4e9-2c8eca407f80',
            title: 'Glass Section ',
            // eslint-disable-next-line no-unused-vars
            value: (i) => {
                return '';
            },
        },
        {
            id: '4ab27faa-7aa4-4cae-9677-cd39ce0b61da',
            title: 'Glass',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.glassType.value`;
            },
        },
    ];

    const $store = useStore();

    const fields = safeComputed(() => $store.state.estimator.fields);

    const windows = computed(
        () => getFieldValuesFromEstimate?.(props?.estimate.itemTreeByField, fields.value, ['3.20'], true)?.windows
    );
    const windowsCount = computed(() =>
        windows.value
            ? windows.value.reduce((i: number, window: { quantity: number }) => {
                  return i + window.quantity;
              }, 0)
            : 0
    );

    const getNewFieldArrayItem = inject('getNewFieldArrayItem') as any;

    const NameFieldArray = ref('windows');

    const { remove: removeItem, push: pushItem, insert: insertItem, fields: items } = useFieldArray(NameFieldArray);

    const handleCreateNewItem = () => {
        const payload = getNewFieldArrayItem('windows');
        pushItem(payload);
    };
    watch(
        props?.modelValues?.windows,
        () => {
            const wndows = props?.modelValues?.windows;
            wndows?.forEach((_: any, i: number) => {
                const widthQuantity = props?.modelValues?.windows[i]?.children?.width?.quantity;
                const heightQuantity = props?.modelValues.windows[i]?.children?.height?.quantity;
                props?.setModelValue(
                    `windows[${i}].children.ui.quantity`,
                    Number(widthQuantity) + Number(heightQuantity)
                );
            });
        },
        {
            deep: true,
            immediate: true,
        }
    );
</script>

<template>
    <section class="quick_estimate_row_table">
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
    </section>

    <section class="quick_estimate_row_table">
        <article class="quick_estimate_row_table_item">
            <p style="font-size: 12px; font-weight: bold">{{ windowsCount }} Total windows</p>
        </article>
        <article class="quick_estimate_row_table_item"></article>
        <article class="quick_estimate_row_table_item"></article>
        <article class="quick_estimate_row_table_item"></article>
        <article class="quick_estimate_row_table_item"></article>
        <article
            class="quick_estimate_row_table_item"
            style="align-items: center; justify-content: start; display: flex"
        >
            <button class="btn-link btn-add-gray" type="button" @click="handleCreateNewItem" :disabled="loading">
                Create window
            </button>
        </article>
    </section>

    <section class="quick_estimate_row_table">
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
    </section>

    <field-array name="windows" v-slot="{ items }">
        <template v-for="(item, i) in items" :key="item.key">
            <template v-for="row in rows" :key="row?.id">
                <section class="quick_estimate_row_table">
                    <article :class="row?.main ? 'quick_estimate_row_table_item' : 'quick_estimate_table_item_sub'">
                        {{ row?.main ? `${Number(i) + 1}. ${row?.title}` : row?.title }}
                    </article>
                    <article class="quick_estimate_row_table_item">
                        <p v-if="row?.main">
                            {{ windows?.find((e: WindowType) => e?.id === item?.value?.id)?.children?.width?.value }} x
                            {{ windows?.find((e: WindowType) => e?.id === item?.value?.id)?.children?.height?.value }}
                            --
                            {{ windows?.find((e: WindowType) => e?.id === item?.value?.id)?.children?.roomName?.value }}
                        </p>
                        <template v-if="row?.showFieldOpts?.(i)">
                            <template v-if="row?.value(i)">
                                <field-input-dropdown
                                    v-if="row?.type === 'dropdown'"
                                    :name="row?.value(i)"
                                    :readonly="row?.readonly ?? false"
                                    :custom_class="'input_dropdown_excel'"
                                />
                                <field-input-text
                                    v-if="row?.type === 'text'"
                                    :name="row?.value(i)"
                                    :readonly="row?.readonly ?? false"
                                    :custom_class="'input_text_excel'"
                                />
                                <field-input-quantity
                                    v-if="row?.type === 'quantity'"
                                    :name="row?.value(i)"
                                    :readonly="row?.readonly ?? false"
                                    :custom_class="'input_number_excel'"
                                    :custom_select_class="'input_quantity_select_excel'"
                                    :recommendedClass="'input_quantity_recommended'"
                                />
                                <field-input-checkbox
                                    v-if="row?.type === 'checkbox'"
                                    :name="row?.value(i)"
                                    :readonly="row?.readonly ?? false"
                                />
                                <field-error :name="row?.value(i)" />
                            </template>
                        </template>
                        <template v-else-if="row?.value?.(i)">
                            <field-input-dropdown
                                v-if="row?.type === 'dropdown'"
                                :name="row?.value(i)"
                                :readonly="row?.readonly ?? false"
                                :custom_class="'input_dropdown_excel'"
                            />
                            <field-input-text
                                v-if="row?.type === 'text'"
                                :name="row?.value(i)"
                                :readonly="row?.readonly ?? false"
                                :custom_class="'input_text_excel'"
                            />
                            <field-input-quantity
                                v-if="row?.type === 'quantity'"
                                :name="row?.value(i)"
                                :readonly="row?.readonly ?? false"
                                :custom_class="'input_number_excel'"
                                :custom_select_class="'input_quantity_select_excel'"
                                :recommendedClass="'input_quantity_recommended'"
                            />
                            <field-input-checkbox
                                v-if="row?.type === 'checkbox'"
                                :name="row?.value(i)"
                                :readonly="row?.readonly ?? false"
                            />
                            <field-error :name="row?.value(i)" />
                        </template>
                    </article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item">
                        <button v-if="row?.main" class="btn-link btn-remove-red" type="button" @click="removeItem(i)">
                            Remove window
                        </button>
                    </article>
                </section>
            </template>

            <field-array
                :name="`windows[${i}].children.grid`"
                v-slot="{
                    items: gridItems,
                    addItem: addItemGrid,
                    addItemAfter: addItemAfterGrid,
                    removeItem: removeItemGrid,
                }"
            >
                <template v-for="(gridItem, j) in gridItems" :key="gridItem.key">
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_row_table_item">Window grid</article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item">
                            <div style="display: flex; flex-direction: row; gap: 10px">
                                <button class="btn-link" type="button" @click="addItemAfterGrid(j)">
                                    <svg width="24px" height="24px" class="icon fill-dark stroke-green">
                                        <use xlink:href="#add"></use>
                                    </svg>
                                </button>
                                <button
                                    class="btn-link"
                                    type="button"
                                    v-if="items.length > 1"
                                    @click="removeItemGrid(j)"
                                >
                                    <svg width="24px" height="24px" class="icon fill-red stroke-red">
                                        <use xlink:href="#remove"></use>
                                    </svg>
                                </button>
                            </div>
                        </article>
                    </section>
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_table_item_sub">Grid size</article>
                        <article class="quick_estimate_row_table_item">
                            <field-input-dropdown
                                :name="`windows[${i}].children.grid[${j}].children.gridSize.value`"
                                :custom_class="'input_dropdown_excel'"
                            />
                            <field-error
                                :name="`windows[${i}].children.grid[${j}].children.gridSize.value`"
                                :custom_class="'input_field_error'"
                            />
                        </article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                    </section>
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_table_item_sub">Grid pattern</article>
                        <article class="quick_estimate_row_table_item">
                            <field-input-dropdown
                                :name="`windows[${i}].children.grid[${j}].children.gridPattern.value`"
                                :custom_class="'input_dropdown_excel'"
                            />
                            <field-error
                                :name="`windows[${i}].children.grid[${j}].children.gridPattern.value`"
                                :custom_class="'input_field_error'"
                            />
                        </article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                    </section>
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_table_item_sub">Grid color</article>
                        <article class="quick_estimate_row_table_item">
                            <field-input-dropdown
                                :name="`windows[${i}].children.grid[${j}].children.gridColor.value`"
                                :custom_class="'input_dropdown_excel'"
                            />
                            <field-error
                                :name="`windows[${i}].children.grid[${j}].children.gridColor.value`"
                                :custom_class="'input_field_error'"
                            />
                        </article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                    </section>
                </template>
                <section class="quick_estimate_row_table" v-if="gridItems.length === 0">
                    <article class="quick_estimate_row_table_item">Window grid</article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item">
                        <button class="btn-link btn-add-gray" type="button" @click="addItemGrid">Add grid</button>
                    </article>
                </section>
            </field-array>
            <!-- /// -->
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_row_table_item">Interior</article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
            </section>
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_table_item_sub">Interior color</article>
                <article class="quick_estimate_row_table_item">
                    <field-input-dropdown
                        :name="`windows[${i}].children.interiorColor.value`"
                        :custom_class="'input_dropdown_excel'"
                    />
                    <field-error
                        :name="`windows[${i}].children.interiorColor.value`"
                        :custom_class="'input_field_error'"
                    />
                </article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
            </section>
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_table_item_sub">Finished box interior</article>
                <article class="quick_estimate_row_table_item">
                    <field-input-dropdown
                        :name="`windows[${i}].children.interiorBoxFinished.value`"
                        :custom_class="'input_dropdown_excel'"
                    />
                    <field-error
                        :name="`windows[${i}].children.interiorBoxFinished.value`"
                        :custom_class="'input_field_error'"
                    />
                </article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
            </section>

            <!-- ///// -->
            <field-array
                :name="`windows[${i}].children.interiorTrim`"
                v-slot="{
                    items: interiorTrimItems,
                    addItem: addItemInteriorTrim,
                    addItemAfter: addItemAfterInteriorTrim,
                    removeItem: removeItemInteriorTrim,
                }"
            >
                <template v-for="(interiorTrimItem, j) in interiorTrimItems" :key="interiorTrimItem.key">
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_row_table_item">Interior trim type section</article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item">
                            <div style="display: flex; flex-direction: row; gap: 10px">
                                <button class="btn-link" type="button" @click="addItemAfterInteriorTrim(j)">
                                    <svg width="24px" height="24px" class="icon fill-dark stroke-green">
                                        <use xlink:href="#add"></use>
                                    </svg>
                                </button>
                                <button
                                    class="btn-link"
                                    type="button"
                                    v-if="items.length > 1"
                                    @click="removeItemInteriorTrim(j)"
                                >
                                    <svg width="24px" height="24px" class="icon fill-red stroke-red">
                                        <use xlink:href="#remove"></use>
                                    </svg>
                                </button>
                            </div>
                        </article>
                    </section>
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_table_item_sub">Interior trim type</article>
                        <article class="quick_estimate_row_table_item">
                            <field-input-dropdown
                                :name="`windows[${i}].children.interiorTrim[${j}].children.interiorTrimType.value`"
                                :custom_class="'input_dropdown_excel'"
                            />
                            <field-error
                                :name="`windows[${i}].children.interiorTrim[${j}].children.interiorTrimType.value`"
                                :custom_class="'input_field_error'"
                            />
                        </article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                    </section>
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_table_item_sub">Interior trim color</article>
                        <article class="quick_estimate_row_table_item">
                            <field-input-dropdown
                                :name="`windows[${i}].children.interiorTrim[${j}].children.interiorTrimColor.value`"
                                :custom_class="'input_dropdown_excel'"
                            />
                            <field-error
                                :name="`windows[${i}].children.interiorTrim[${j}].children.interiorTrimColor.value`"
                                :custom_class="'input_field_error'"
                            />
                        </article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                    </section>
                </template>
                <section class="quick_estimate_row_table" v-if="interiorTrimItems.length === 0">
                    <article class="quick_estimate_row_table_item">Interior trim type section</article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item">
                        <button class="btn-link btn-add-gray" type="button" @click="addItemInteriorTrim">
                            Add interior trim
                        </button>
                    </article>
                </section>
            </field-array>
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_row_table_item">Exterior</article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
            </section>
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_table_item_sub">Exterior color</article>
                <article class="quick_estimate_row_table_item">
                    <field-input-dropdown
                        :name="`windows[${i}].children.exteriorColor.value`"
                        :custom_class="'input_dropdown_excel'"
                    />
                    <field-error
                        :name="`windows[${i}].children.exteriorColor.value`"
                        :custom_class="'input_field_error'"
                    />
                </article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
            </section>
            <field-array
                :name="`windows[${i}].children.exteriorTrim`"
                v-slot="{
                    items: exteriorTrimItems,
                    addItem: addItemExteriorTrim,
                    addItemAfter: addItemAfterExteriorTrim,
                    removeItem: removeItemExteriorTrim,
                }"
            >
                <template v-for="(exteriorTrimItem, j) in exteriorTrimItems" :key="exteriorTrimItem.key">
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_row_table_item">Windows Exterior Trim</article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item">
                            <div style="display: flex; flex-direction: row; gap: 10px">
                                <button class="btn-link" type="button" @click="addItemAfterExteriorTrim(j)">
                                    <svg width="24px" height="24px" class="icon fill-dark stroke-green">
                                        <use xlink:href="#add"></use>
                                    </svg>
                                </button>
                                <button
                                    class="btn-link"
                                    type="button"
                                    v-if="items.length > 1"
                                    @click="removeItemExteriorTrim(j)"
                                >
                                    <svg width="24px" height="24px" class="icon fill-red stroke-red">
                                        <use xlink:href="#remove"></use>
                                    </svg>
                                </button>
                            </div>
                        </article>
                    </section>
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_table_item_sub">Exterior trim type</article>
                        <article class="quick_estimate_row_table_item">
                            <field-input-dropdown
                                :name="`windows[${i}].children.exteriorTrim[${j}].children.exteriorTrimType.value`"
                                :custom_class="'input_dropdown_excel'"
                            />
                            <field-error
                                :name="`windows[${i}].children.exteriorTrim[${j}].children.exteriorTrimType.value`"
                                :custom_class="'input_field_error'"
                            />
                        </article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                    </section>
                    <section class="quick_estimate_row_table">
                        <article class="quick_estimate_table_item_sub">Exterior trim color</article>
                        <article class="quick_estimate_row_table_item">
                            <field-input-dropdown
                                :name="`windows[${i}].children.exteriorTrim[${j}].children.exteriorTrimColor.value`"
                                :custom_class="'input_dropdown_excel'"
                            />
                            <field-error
                                :name="`windows[${i}].children.exteriorTrim[${j}].children.exteriorTrimColor.value`"
                                :custom_class="'input_field_error'"
                            />
                        </article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                        <article class="quick_estimate_row_table_item"></article>
                    </section>
                </template>
                <section class="quick_estimate_row_table" v-if="exteriorTrimItems.length === 0">
                    <article class="quick_estimate_row_table_item">Windows Exterior Trim</article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item">
                        <button class="btn-link btn-add-gray" type="button" @click="addItemExteriorTrim">
                            Add exterior trim
                        </button>
                    </article>
                </section>
            </field-array>
        </template>
    </field-array>
    <section class="quick_estimate_row_table">
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
        <article class="quick_estimate_row_table_item">----</article>
    </section>
</template>
