import { DateTime } from 'luxon';
import FileReference from './FileReference';

const optionIdPattern = /^\d+\.\d+\.\d+$/;

type EstimateFieldOptionDTO = {
    id: string | null;
    value: string | null;
    materialCost: number;
    laborCost: number;
    materialPrice: number;
    laborPrice: number;
    recommendedConversion1: number;
    recommendedConversion2: number;
    recommendedConversion3: number;
    description: string;
    image: Partial<FileReference> | null;
};

export default class EstimateFieldOption {
    id: string | null;
    value: string | null;
    materialCost: number;
    laborCost: number;
    materialPrice: number;
    laborPrice: number;
    recommendedConversion1: number;
    recommendedConversion2: number;
    recommendedConversion3: number;
    description: string;
    image: FileReference | null;

    constructor(dto: Partial<EstimateFieldOptionDTO> = {}) {
        this.id = dto.id ?? null;
        this.value = dto.value ?? null;
        this.materialCost = dto.materialCost ?? 0;
        this.laborCost = dto.laborCost ?? 0;
        this.materialPrice = dto.materialPrice ?? 0;
        this.laborPrice = dto.laborPrice ?? 0;
        this.recommendedConversion1 = dto.recommendedConversion1 ?? 0;
        this.recommendedConversion2 = dto.recommendedConversion2 ?? 0;
        this.recommendedConversion3 = dto.recommendedConversion3 ?? 0;
        this.description = dto.description ?? '';
        if (dto.image) {
            const imageDto = {
                ...dto.image,
                uploadTimestamp:
                    dto.image.uploadTimestamp instanceof DateTime
                        ? dto.image.uploadTimestamp.toISO() ?? ''
                        : dto.image.uploadTimestamp ?? '',
            };
            this.image = new FileReference(imageDto);
        } else {
            this.image = null;
        }
    }

    get fieldId() {
        return EstimateFieldOption.getFieldId(this.id);
    }

    static getFieldId(optionId: string | null) {
        return optionIdPattern.test(optionId || '') ? optionId!.substring(0, optionId!.lastIndexOf('.')) : '';
    }

    static getWorkflowId(optionId: string | null) {
        return optionIdPattern.test(optionId || '') ? parseInt(optionId!.substring(0, optionId!.indexOf('.'))) : 0;
    }

    static valuesToHide = new Set(['n/a', 'no', 'none']);
}
