import api from '@/api/index';
import companyId from '@/api/modules/companyId';
import { getAppInsights } from '@/applicationInsights';
import User from '@/models/User';
import { ActionTree, Commit, GetterTree, MutationTree } from 'vuex';
import { useAccountStore } from '@/store/modules/token';

export interface UserState {
    user: User;
}

export interface RootState {}

const state = (): UserState => ({
    user: new User(),
});

// Getters
const getters: GetterTree<UserState, RootState> = {
    isLoggedIn: (state) =>
        state.user !== null && state.user.id > 0 && (state.user.companyId === null || state.user.active),

    isMasterAdmin: (state, getters) => getters.isLoggedIn && state.user.isMasterAdmin,

    isSuperAdmin: (state, getters) => getters.isLoggedIn && (state.user?.isSuperAdmin ?? false),

    isAdmin: (state, getters) => getters.isUser && (state.user.role === 'Admin' || getters.isSuperAdmin),

    isUser: (state, getters) => getters.isLoggedIn && state.user.companyId !== null && state.user.companyId > 0,

    isCompanyUser: (state, getters) =>
        getters.isLoggedIn &&
        state.user.companyId !== null &&
        state.user.companyId > 0 &&
        (state.user.role === 'User' || state.user.role === 'Admin'),
};

// Mutations
const mutations: MutationTree<UserState> = {
    setUser(state, user: User | null) {
        if (user !== null && user instanceof User) {
            state.user = user;
        } else {
            state.user = new User();
        }
        const appInsights = getAppInsights();
        if (appInsights) {
            const userId: string = state.user.id > 0 ? String(state.user.id) : '';
            const companyId: string =
                state.user.companyId !== null && state.user.companyId > 0 ? String(state.user.companyId) : '';
            appInsights.setAuthenticatedUserContext(userId, companyId, false);
        }
    },
};

// Actions
const actions: ActionTree<UserState, RootState> = {
    async clear({ commit }: { commit: Commit }) {
        commit('setUser', null);
        const accountStore = useAccountStore();
        accountStore.reset();
    },
    async refresh({ commit }: { commit: Commit }) {
        await companyId.setFromIdb();
        const user = await api.users.getSelf();
        commit('setUser', user);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
