import { parseIntProp } from '../_helpers';

export default {
    path: '/admin',
    component: () => import('@/views/admin/_AdminApp.vue'),
    meta: { requiresAuth: true, requiresSuperAdmin: true, blockOffline: true, appName: 'admin' },
    children: [
        {
            path: '',
            redirect: '/admin/companies',
        },
        {
            path: 'companies',
            component: () => import('@/views/admin/CompaniesPage.vue'),
            meta: { title: 'Companies', linkText: 'Companies' },
        },
        {
            path: 'users',
            component: () => import('@/views/admin/UsersPage.vue'),
            meta: { title: 'Users', linkText: 'Users' },
        },
        {
            path: 'users/:id(-?\\d+)',
            component: () => import('@/views/admin/UserEditPage.vue'),
            props: (route: { params: { id: string } }) => ({ id: parseIntProp(route.params.id) }),
            meta: { title: 'User Edit' },
        },
    ],
};
