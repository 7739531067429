type EstimateFieldMaterialTypeDTO = {
    fieldId: string | null;
    optionId: string | null;
    materialFieldId: string | null;
};

export default class EstimateFieldMaterialType {
    fieldId: string | null;
    optionId: string | null;
    materialFieldId: string | null;

    constructor(dto: Partial<EstimateFieldMaterialTypeDTO>) {
        this.fieldId = dto.fieldId ?? null;
        this.optionId = dto.optionId ?? null;
        this.materialFieldId = dto.materialFieldId ?? null;
    }
}
