/* eslint-disable no-unused-vars */
type Item = {
    children: {
        fasciaType: {
            value: string;
        };
        fasciaLength: {
            quantity: number;
        };
    };
};

type Props = {
    items: Item[];
    GET: (arg1: number, arg2: string, arg3: string) => number;
    SET: (modelFieldId: string, recommended: number, index: number) => void;
    modelField: string;
    modelFieldId: string;
};

export const calculateFasciaRecommend = (props: Props) => {
    const { items, GET, SET, modelField, modelFieldId } = props;

    if (!Array.isArray(items)) {
        return [];
    }

    return items.map((x, i) => {
        const feetPerUnit = GET(1, modelField, x.children.fasciaType.value);
        if (feetPerUnit === 0) return 0;

        const length = x.children.fasciaLength.quantity;
        const recommended = Math.ceil(length / feetPerUnit);
        SET(modelFieldId, recommended, i);

        return recommended;
    });
};
