<script setup>
    import { getFieldValuesFromEstimate } from '@/composables/estimate';
    import { safeComputed } from '@/composables/safeComputed';
    import { computed, defineProps } from 'vue';
    import { useStore } from 'vuex';

    import { round } from '@/helpers';
    import { watch } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const $store = useStore();
    const fieldsRef = safeComputed(() => $store.state.estimator.fields);

    const caulkRecommended = computed(() => {
        const altModelValues = getFieldValuesFromEstimate(
            props?.estimate.itemTreeByField,
            fieldsRef.value,
            ['3.20'],
            true
        );
        if (!Array.isArray(altModelValues.windows)) {
            altModelValues.windows = [];
        }
        const totalWindowLengthInches = altModelValues.windows.reduce(
            (sum, x) => sum + x.quantity * (x.children.width.value * 2 + x.children.height.value * 2),
            0
        );
        if (totalWindowLengthInches === 0) return 0;
        if (!Array.isArray(props?.modelValues.caulk)) return [];
        return props?.modelValues.caulk.map((x, i) => {
            const feetPerTube = props?.getRecommendedConversion(2, props?.model.caulk.field, x.value);
            if (feetPerTube === 0) return 0;
            const recommended = Math.ceil((2 * totalWindowLengthInches) / 12 / feetPerTube);
            props?.setRecommendedItem(props?.model.caulk.field.id, recommended, i);
            return recommended;
        });
    });

    function updatePrices() {
        if (Array.isArray(props.modelValues.custom)) {
            for (let i = 0; i < props.modelValues.custom.length; i++) {
                const x = props.modelValues.custom[i];
                const materialMultiplier = props.getRecommendedConversion(
                    1,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                const laborMultiplier = props.getRecommendedConversion(
                    2,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                props.setModelValue(`custom[${i}].materialPriceTemp`, round(x.materialCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].materialPrice`, round(x.materialCost * materialMultiplier, 2));
                props.setModelValue(`custom[${i}].laborPriceTemp`, round(x.laborCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].laborPrice`, round(x.laborCost * laborMultiplier, 2));
            }
        }
    }

    watch(() => props.modelValues.custom, updatePrices, { deep: true });
    const listSections = computed(() => {
        return [
            {
                id: 'f3e592b1-762e-45a6-a961-b8265641305a',
                quantity: () => 0,
                setModelValue: () => '',
                name: '',
                title: 'Lead safe removal',
                isFieldArray: false,
                rows: [
                    {
                        id: 'da21287a-34d2-41e4-9995-49175063898f',
                        columns: [
                            {
                                id: '59cb985a-543b-4c6c-98be-2a419b588c09',
                                label: 'Lead safe removal',
                            },
                            {
                                id: '69a2a248-d486-4ce8-9793-cd8bcb6830e0',
                                type: 'Quantity',
                                name() {
                                    return 'leadSafeRemoval.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '1278fa1e-1800-4586-8413-4913317dcdbb',
                        columns: [
                            {
                                id: 'b37e9ecf-ba34-4c49-96f1-8e224942be74',
                                label: 'Second story windows',
                            },
                            {
                                id: '9c501239-d382-41e9-8354-78b5b9d9190e',
                                type: 'Quantity',
                                name(i) {
                                    return 'secondStoryWindows.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '5eb14fdb-bb26-4d81-9cba-e6ae338c6ac3',
                        columns: [
                            {
                                id: '3d90fb40-a834-4f50-970c-f40ff659f8c5',
                                label: 'Trim nails',
                            },
                            {
                                id: '7df36d36-8f6b-45d4-9f66-e1f3d46f5872',
                                type: 'Quantity',
                                name() {
                                    return 'trimNails.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '9360f997-6890-4800-9ff9-37e52f50b4fe',
                        columns: [
                            {
                                id: '12bed208-76ac-4b67-a3ab-305663f98491',
                                label: 'Spray foam',
                            },
                            {
                                id: '482406cd-65d0-4367-b110-b3405dad0cad',
                                type: 'Quantity',
                                name() {
                                    return 'sprayFoam.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: '6160d577-0441-4d72-9f8d-3753af0398cf',
                quantity: (i) => props?.modelValues.caulk[i].quantity,
                setModelValue: (i) => `caulk[${i}].quantity`,
                name: 'caulk',
                title: 'Caulk',
                isFieldArray: true,
                rows: [
                    {
                        id: '75fbd6b6-b24c-4f97-bc34-05750ff0786d',
                        columns: [
                            {
                                id: '49ac0920-8b8b-4dc8-b6f8-28d11e7ab194',
                                label: 'Caulk',
                            },
                            {
                                id: 'e4c07403-4a25-4007-9605-0e56cc5bb11e',
                                type: 'Quantity',
                                name(i) {
                                    return `caulk[${i}].quantity`;
                                },
                                recommended(i) {
                                    return caulkRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '003162ec-93c0-4e65-8ef6-c5b110b0e891',
                        columns: [
                            {
                                id: '489531a3-68e6-4129-989b-286e4caf3cbd',
                                label: 'Type',
                            },
                            {
                                id: 'fabd146d-c8c0-4544-8f62-9cf270f211c0',
                                type: 'Dropdown',
                                name(i) {
                                    return `caulk[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 'ab022c5e-f970-469d-839e-e494e037bdb7',
                quantity: (i) => props?.modelValues.deliveryDistance[i].quantity,
                setModelValue: (i) => `deliveryDistance[${i}].quantity`,
                name: 'deliveryDistance',
                title: 'Delivery Distance',
                isFieldArray: true,
                rows: [
                    {
                        id: 'bdb7c33d-71e0-4a8e-9494-9b3ba7c32543',
                        columns: [
                            {
                                id: 'ff4cffdf-9765-4d0a-b48f-9fb582ad837f',
                                label: 'Delivery / Distance',
                            },
                            {
                                id: '3a3371e3-23b2-463e-a6d2-7bf622e994f0',
                                type: 'Dropdown',
                                name(i) {
                                    return `deliveryDistance[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '91521155-7e22-4186-bd89-eca2d39597b6',
                        columns: [
                            {
                                id: '58e70f00-0ed7-4103-a284-f08c34c4c1d0',
                                label: 'Quantity',
                            },
                            {
                                id: '145a33ab-d4f8-4aa6-942b-2e7e9177ef56',
                                type: 'Quantity',
                                name(i) {
                                    return `deliveryDistance[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 'c5093c8e-df0c-44e6-a32a-1a0ff5e08a1c',
                quantity: () => 0,
                setModelValue: () => '',
                name: '',
                title: 'Project Specs Options',
                isFieldArray: false,
                rows: [
                    {
                        id: 'a3fb4c2c-a22d-4955-a5a6-0f7a05cca659',
                        columns: [
                            {
                                id: 'ba230ac6-6151-4548-955b-ee884e753bc2',
                                label: 'Windows & Doors permit',
                            },
                            {
                                id: 'e122f476-3447-4d62-93b7-e1916d222577',
                                type: 'Checkbox',
                                name() {
                                    return 'permit.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '9a28d826-8118-4e24-93ed-7e6997f612f6',
                        columns: [
                            {
                                id: '85b9b346-4f01-4e8a-b297-9c8624e2c38d',
                                label: 'Stain / paint set up fee',
                            },
                            {
                                id: '88c267d1-3909-4eb6-ac85-5be9caf89a5c',
                                type: 'Checkbox',
                                name() {
                                    return 'setupFeeStainPaint.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '44b864b7-fc05-4ecd-ada1-ee56d677aa9a',
                        columns: [
                            {
                                id: '00dc47d8-cd46-444c-a234-1e24411d47e0',
                                label: 'Custom stain / paint set up fee',
                            },
                            {
                                id: '4651b3b9-f90d-4fcc-9520-2bd3bef18a22',
                                type: 'Checkbox',
                                name() {
                                    return 'setupFeeStainPaintCustom.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '47bcdb92-44c1-4a0b-ac43-7523500e0e86',
                        columns: [
                            {
                                id: 'd52f71e3-7e5c-4752-aa52-800a1845ea30',
                                label: 'Small job fee',
                            },
                            {
                                id: '65b8f961-9d4f-494a-8d55-7a95b060c0a2',
                                type: 'Checkbox',
                                name() {
                                    return 'smallJobFee.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: '571069e4-f28c-4ce9-9a0e-1d416d28591d',
                quantity: () => 0,
                setModelValue: () => '',
                name: 'custom',
                title: 'ADD-ON SERVICES',
                isFieldArray: true,
                addItem: {
                    show: true,
                    title: 'ADD-ON SERVICES',
                },
                rows: [
                    {
                        id: '1dc266df-324b-42d1-87ba-dafacb7a8f8e',
                        columns: [
                            {
                                id: '6ed024fb-981f-4a09-a0a1-58fc3867e6f6',
                                label: 'Label / name',
                            },
                            {
                                id: '4fce9a27-4f56-48a3-9b5e-21d1649be1a2',
                                type: 'Text',
                                name(i) {
                                    return `custom[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'e51fd3b3-8a89-44fa-8124-de0ec8e2da51',
                        columns: [
                            {
                                id: '137d7325-f376-4ac7-b507-e84e1bf70f86',
                                label: 'Quantity',
                            },
                            {
                                id: '85af06fc-6cb7-4526-a720-fa5c5babc9f0',
                                type: 'Quantity',
                                name(i) {
                                    return `custom[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '47b45739-4e34-4a37-bbab-2a00a345bb52',
                        columns: [
                            {
                                id: 'fbeb6eb5-8451-4a46-a213-71959ac22d7f',
                                label: '$ material cost',
                            },
                            {
                                id: 'f2c12014-7fc2-4345-ab75-7358ba061853',
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].materialCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '5d66e1fd-bb91-4907-8b98-d9385a529aba',
                        columns: [
                            {
                                id: '44d51370-da1d-47aa-a109-66b87286e13b',
                                label: '$ labor cost',
                            },
                            {
                                id: '87934fe2-c340-4b2f-9253-45c2cac14747',
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].laborCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '005fd4b5-305b-405b-be6d-86c50e321418',
                        columns: [
                            {
                                id: '1fab5df8-6bde-416a-87ef-2930431aa663',
                                label: '$ material price',
                            },
                            {
                                id: '11f5bf4b-0894-4398-9bbd-a04b69ea039c',
                                type: 'Price',
                                readonly: true,
                                name(i) {
                                    return `custom[${i}].materialPriceTemp`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '6a22631a-beae-44da-b293-7db130347944',
                        columns: [
                            {
                                id: 'f3262db2-1df0-4923-a0ab-ada2e0d9fce1',
                                label: '$ labor price',
                            },
                            {
                                id: '4252047d-9557-41d6-8bf3-c9481f1934a2',
                                type: 'Price',
                                readonly: true,
                                name(i) {
                                    return `custom[${i}].laborPriceTemp`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
