export function getRecommendedInteriorAndExteriorFeet(
  typeField,
  colorField,
  organized,
  modelValues,
  setRecommendedItem,
  fieldSetId,
  keyTypeId,
  keyColorId
) {
  return modelValues.map((x, i) => {
    const typeId = x.children[keyTypeId]?.value;
    const type = (
      typeId ? typeField.options.find((y) => y.id === typeId)?.value ?? "" : ""
    ).toLowerCase();

    const colorId = x.children[keyColorId]?.value;
    const color = (
      colorId
        ? colorField.options.find((y) => y.id === colorId)?.value ?? ""
        : ""
    ).toLowerCase();

    let recommended = 0;

    if (type && color && organized?.[type]?.[color]) {
      recommended = organized[type][color];
    }

    setRecommendedItem(fieldSetId, recommended, i);
    return recommended;
  });
}

export function generateTrimFeetRecommendedText(organized) {
  let lines = [];
  for (const type of Object.keys(organized)) {
    const organizedType = organized[type];
    for (const color of Object.keys(organizedType)) {
      lines.push(`${organizedType[color]} ft of ${color} ${type}`);
    }
  }
  return lines.join(", ");
}

export function calculateTrimLength(
  quantity,
  width,
  height,
  wastePercentage = 1.15
) {
  return (wastePercentage * quantity * (width * 2 + height * 2)) / 12;
}

export function organizeTrimData(trimData) {
  return trimData.reduce((organized, { type, color, length }) => {
    if (length > 0) {
      if (!organized[type]) {
        organized[type] = {};
      }
      organized[type][color] = (organized[type][color] || 0) + length;
    }
    return organized;
  }, {});
}

export function roundUpOrganizedData(organizedData) {
  for (const type in organizedData) {
    for (const color in organizedData[type]) {
      organizedData[type][color] = Math.ceil(organizedData[type][color]);
    }
  }
  return organizedData;
}
