/* eslint-disable no-unused-vars */
import {
    addMethod as yupAddMethod,
    number as yupNumber,
    object as yupObject,
    array as yupArray,
    string as yupString,
} from 'yup';
import * as yup from 'yup';
import { round, validation as validationHelper } from './helpers';

export const fileInfo = [
    { extension: '.jpg', mimeType: 'image/jpeg' },
    { extension: '.jpeg', mimeType: 'image/jpeg' },
    { extension: '.png', mimeType: 'image/png' },
    { extension: '.gif', mimeType: 'image/gif' },
    { extension: '.pdf', mimeType: 'application/pdf' },
    { extension: '.doc', mimeType: 'application/msword' },
    { extension: '.xls', mimeType: 'application/vnd.ms-excel' },
    { extension: '.docx', mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
    { extension: '.xlsx', mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    { extension: '.csv', mimeType: 'text/csv' },
];

export const fileExtensions = fileInfo.map((x) => x.extension);
export const fileMimeTypes = fileInfo.map((x) => x.mimeType);
export const imageFileExtensions = fileInfo.filter((x) => x.mimeType.startsWith('image/')).map((x) => x.extension);
export const imageFileMimeTypes = Array.from(new Set(fileInfo.filter((x) => x.mimeType.startsWith('image/')))).map(
    (x) => x.extension
);
export const fileSizeLimit = 10000000;
declare module 'yup' {
    interface NumberSchema {
        step(min: number, step: number, msg?: string): this;
    }
    interface StringSchema {
        minFileSize: (bytes: number) => this;
        maxFileSize: (bytes: number) => this;
        fileExtension: (extensions: string[]) => this;
        file: () => this;
        imageFile: () => this;
        fileExists: (objectRef: any) => this;
        fileCanDownload: (objectRef: any) => this;
        zipCode: (msg?: string) => this;
        strongPassword: (msg?: string) => this;
        equalTo: (ref: any, msg?: string) => this;
    }

    interface MixedSchema {
        minFileSize: (bytes?: number) => this;
        maxFileSize: (bytes?: number) => this;
        fileExtension: (extensions?: string[]) => this;
        file: () => this;
        imageFile: () => this;
    }
}

export function useYupExtensions() {
    yupAddMethod(yupArray, 'unique', function (message: string) {
        return this.test('unique', message, function (list: any[] | undefined) {
            return !Array.isArray(list) || list.length === new Set(list).size;
        });
    });

    yupAddMethod(yupString, 'zipCode', function (msg?: string) {
        return this.matches(/(^\d{5}$)|(^\d{5}-\d{4}$)|(^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$)/, {
            message: msg || '${path} must be a valid Zip Code.',
            excludeEmptyString: true,
        });
    });

    yupAddMethod(yupString, 'strongPassword', function (msg?: string) {
        return this.test({
            name: 'strongPassword',
            exclusive: true,
            message: msg || '${path} is not complex enough.',
            test: function (value: string | undefined) {
                return typeof value === 'string' && validationHelper.password.all(value);
            },
        });
    });

    yupAddMethod(yupString, 'equalTo', function (ref: any, msg?: string) {
        return this.test({
            name: 'equalTo',
            exclusive: false,
            params: { reference: ref.label || ref.path },
            message: msg || '${path} must be the same as ${reference}',
            test: function (value: any) {
                return value === this.resolve(ref);
            },
        });
    });

    yupAddMethod(yupNumber, 'step', function (min: number, step: number, msg?: string) {
        return this.test({
            name: 'step',
            exclusive: true,
            params: { step },
            message: msg || '${path} is not a multiple of ${step}',
            test: function (value: number | undefined) {
                if (typeof value !== 'number') return false;

                value = value - min;
                if (value < 0) return false;

                if (step === 0.333) {
                    value = round(value - Math.floor(value), 3);
                    return value === 0 || value === 0.333 || value === 0.666 || value === 0.667;
                } else {
                    const mod = round((value / step) % 1, 3);
                    return mod < 0.0001 || mod > 0.9999;
                }
            },
        });
    });

    yupAddMethod(yupObject, 'minFileSize', function (bytes = 0) {
        bytes = typeof bytes === 'number' && !isNaN(bytes) ? Math.max(0, bytes) : 0;
        const megabytes = bytes / (1 << 20);
        return this.test({
            name: 'minFileSize',
            message: bytes === 0 ? 'File is empty.' : `File must be at least ${megabytes.toFixed(1)} MB.`,
            test: (value: { size?: number } | null) => {
                return value === null || (typeof value.size === 'number' && value.size >= bytes);
            },
            exclusive: true,
        });
    });

    yupAddMethod(yupObject, 'maxFileSize', function (bytes = 0) {
        bytes = typeof bytes === 'number' && !isNaN(bytes) ? Math.min(fileSizeLimit, bytes) : fileSizeLimit;
        const megabytes = bytes / (1 << 20);
        return this.test({
            name: 'maxFileSize',
            message: `File exceeds limit of ${megabytes.toFixed(1)} MB.`,
            test: (value: { size?: number } | null) => {
                return value === null || (typeof value.size === 'number' && value.size <= bytes);
            },
            exclusive: true,
        });
    });

    yupAddMethod(yupObject, 'fileExtension', function (extensions: string[]) {
        if (Array.isArray(extensions) && extensions.length > 0) {
            extensions = fileExtensions.filter((x) => extensions.includes(x));
        } else {
            extensions = fileExtensions;
        }
        return this.test({
            name: 'fileExtension',
            message: `Invalid file type. Only ${extensions.join(', ')} files allowed.`,
            test: (value: { extension?: string } | null) => {
                return value === null || (typeof value.extension === 'string' && extensions.includes(value.extension));
            },
            exclusive: true,
        });
    });

    yupAddMethod(yup.mixed, 'file', function () {
        return this.minFileSize().maxFileSize().fileExtension([]);
    });

    yupAddMethod(yup.mixed, 'imageFile', function () {
        return this.file().fileExtension(imageFileExtensions);
    });

    yupAddMethod(yupString, 'fileExists', function (objectRef: any) {
        return this.test({
            name: 'fileExists',
            message: 'The selected file is inaccessible. Please select a new one or check the permissions.',
            test: function (value: string | undefined) {
                return !value || !!objectRef.value[value];
            },
            exclusive: true,
        });
    });

    yupAddMethod(yupString, 'fileCanDownload', function (objectRef: any) {
        return this.fileExists(objectRef).test({
            name: 'fileCanDownload',
            message:
                'The connected file storage account does not have the necessary permissions on the selected file. Please select a new one or check the permissions.',
            test: function (value: string | undefined) {
                return !value || (!!objectRef.value[value] && objectRef.value[value].canDownload);
            },
            exclusive: true,
        });
    });
}
