import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "refresh-sw-prompt"
}
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "is-loading dots"
}

import { computed, ref } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'RefreshServiceWorker',
  setup(__props) {

const $store = useStore();
const loading = ref(false);
const open = computed(() => $store.state.serviceWorkerUpdate);

function refresh() {
	loading.value = true;
	location.reload();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "refresh-sw",
      appear: ""
    }, {
      default: _withCtx(() => [
        (open.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _cache[2] || (_cache[2] = _createElementVNode("div", null, "App update available!", -1)),
              _createElementVNode("button", {
                class: "btn btn-fill-app",
                disabled: loading.value,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (refresh()))
              }, [
                (!loading.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Update "))
                  : (_openBlock(), _createElementBlock("span", _hoisted_4, _cache[1] || (_cache[1] = [
                      _createElementVNode("span", { class: "dot-1" }, null, -1),
                      _createElementVNode("span", { class: "dot-2" }, null, -1),
                      _createElementVNode("span", { class: "dot-3" }, null, -1)
                    ])))
              ], 8, _hoisted_2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})