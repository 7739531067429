<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKey, estimteCostValues } from '../mocks.ts';
    // import EstimateStepHeader from '../EstimateStepHeader.vue';
    // import EstimateQuickLinks from '../EstimateQuickLinks.vue';
    // import EstimateWorkflowNotes from '../EstimateWorkflowNotes.vue';
    // import NextStepButton from '../NextStepButton.vue';
    // import EstimateSections from '@/models/EstimateSections';
    // import { useEstimateSection } from '@/composables/estimate';

    // const { modelValues, setModelValue, save, meta, errors, validate, loading } = useEstimateSection(
    //     EstimateSections.repairSkylight
    // );
    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: 'curbMountedReplace',
                title: 'CURB MOUNTED',
                setModelValue: (i) => `curbMountedReplace[${i}].quantity`,
                quantity: (i) => props?.modelValues.curbMountedReplace[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Model / type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `curbMountedReplace[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `curbMountedReplace[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: '',
                title: 'REFLASH',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Re-flash',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'curbMountedReFlash.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Re-flash curb mounted skylight'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Cut in',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'curbMountedCutIn.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Cut in curb mounted skylight'),
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Solar blinds',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'curbMountedSolarBlinds.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(
                                props?.fieldsEstimateItems,
                                'Solar blinds for curb mounted skylight'
                            ),
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Build curbs',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'buildCurb.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: 'deckMountedReplace',
                title: 'DECK MOUNTED',
                setModelValue: (i) => `deckMountedReplace[${i}].quantity`,
                quantity: (i) => props?.modelValues.deckMountedReplace[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Model / type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `deckMountedReplace[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),

                            //
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `deckMountedReplace[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: '',
                title: '--',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Re-flash',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'deckMountedReFlash.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Re-flash deck mounted skylight'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Cut in',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'deckMountedCutIn.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Cut in deck mounted skylight'),
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Solar blinds',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'deckMountedSolarBlinds.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(
                                props?.fieldsEstimateItems,
                                'Solar blinds for deck mounted skylight'
                            ),
                        ],
                    },
                ],
            },
            {
                id: 5,
                name: 'sunTunnelReplace',
                title: 'Sun Tunnel',
                setModelValue: (i) => `sunTunnelReplace[${i}].quantity`,
                quantity: (i) => props?.modelValues.sunTunnelReplace[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Model / type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `sunTunnelReplace[${i}].value`;
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Sun tunnel skylight'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `sunTunnelReplace[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
