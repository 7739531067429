import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["type", "disabled"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "is-loading dots"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([$props.altBtnClass, "btn"]),
    type: $props.type,
    disabled: $props.loading
  }, [
    (!$props.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _cache[0] || (_cache[0] = _createTextVNode("Submit"))
          ])
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, _cache[1] || (_cache[1] = [
          _createElementVNode("span", { class: "dot-1" }, null, -1),
          _createElementVNode("span", { class: "dot-2" }, null, -1),
          _createElementVNode("span", { class: "dot-3" }, null, -1)
        ])))
  ], 10, _hoisted_1))
}