import { INT_MAX_VALUE } from '@/helpers';
import { useStore } from 'vuex';

export default class EstimateWorkflows {
    static general = 0;
    static roofing = 1;
    static siding = 2;
    static windows = 3;
    static gutters = 4;
    static trim = 7;
    static repairs = 5;
    static insulation = 6;

    static map = {
        [EstimateWorkflows.general]: {
            id: EstimateWorkflows.general,
            order: 0,
            key: 'general',
            name: 'General',
            quick: '',
        },
        [EstimateWorkflows.roofing]: {
            id: EstimateWorkflows.roofing,
            order: 1,
            key: 'roofing',
            name: 'Roofing',
        },
        [EstimateWorkflows.siding]: {
            id: EstimateWorkflows.siding,
            order: 2,
            key: 'siding',
            name: 'Siding',
        },
        [EstimateWorkflows.windows]: {
            id: EstimateWorkflows.windows,
            order: 3,
            key: 'windows',
            name: 'Windows & Doors',
        },
        [EstimateWorkflows.gutters]: {
            id: EstimateWorkflows.gutters,
            order: 4,
            key: 'gutters',
            name: 'Gutters',
        },
        [EstimateWorkflows.trim]: {
            id: EstimateWorkflows.trim,
            order: 5,
            key: 'trim',
            name: 'Trim',
        },
        [EstimateWorkflows.insulation]: {
            id: EstimateWorkflows.insulation,
            order: 6,
            key: 'insulation',
            name: 'Insulation',
        },
        [EstimateWorkflows.repairs]: {
            id: EstimateWorkflows.repairs,
            order: 7,
            key: 'repairs',
            name: 'Repairs',
        },
    };

    static list = Array.from(Object.values(EstimateWorkflows.map)).sort((a, b) => a.order - b.order);

    static getKey(id: number): string {
        if (Object.hasOwnProperty.call(EstimateWorkflows.map, id)) {
            return EstimateWorkflows.map[id].key;
        } else {
            return '';
        }
    }

    static getName(id: number) {
        const $store = useStore();

        if ($store) {
            const workflow = $store.getters['estimator/getWorkflowById'](id);

            if (Object.hasOwnProperty.call(EstimateWorkflows.map, id)) {
                return workflow.alias ? workflow.alias : EstimateWorkflows.map[id].name;
            }
        } else if (Object.hasOwnProperty.call(EstimateWorkflows.map, id)) {
            return EstimateWorkflows.map[id].name;
        } else {
            return '';
        }
    }

    static getOriginalName(id: number) {
        const $store = useStore();
        const workflow = $store.getters['estimator/getWorkflowById'](id);

        if (Object.hasOwnProperty.call(EstimateWorkflows.map, id)) {
            return EstimateWorkflows.map[id].name;
        } else {
            return '';
        }
    }

    static getOrder(id: number) {
        if (Object.hasOwnProperty.call(EstimateWorkflows.map, id)) {
            return EstimateWorkflows.map[id].order;
        } else {
            return INT_MAX_VALUE;
        }
    }
}
