<template>
	<input-number v-model="modelValue" :defaultValue="defaultValue" />
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	defaultValue: {
		type: Number,
		required: true,
	},
});
const { value: modelValue } = useField(computed(() => props.name));
</script>
