export const calculateSheathingRecommend = (props) => {
  const { GET, ID_GET, SET, VALUE_GET, AREA_GET, SET_ID, index } = props;

  const squaresPerUnit = GET(1, ID_GET, VALUE_GET);
  if (squaresPerUnit === 0) return 0;
  const area = AREA_GET;
  const recommended = Math.ceil(area / squaresPerUnit);
  SET(SET_ID, recommended, index);
  return recommended;
};
