<template>
	<div class="gray-underline-heading mt-1-half">
		<span>{{title}}&nbsp;</span>
		<template v-if="showTooltib">
			<button type="button" class="btn-reset" @click="showDescription">
				<svg class="icon fill-gray mr-half" height="20px" width="20px">
					<use xlink:href="#msg-info"></use>
				</svg>
			</button>
		</template>
	</div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex';
import { computed, inject, defineProps } from 'vue';
import { safeComputed } from '@/composables/safeComputed';

interface ConfirmDialog {
	[x: string]: any;
	openDialog: (message: string, options?: { type?: string; confirmText?: string }) => Promise<boolean>;
	closeDialog: () => void;
}

const $store = useStore();
const settings = safeComputed(() => $store.state.settings, true);
const alertDialog = inject<ConfirmDialog>('alertDialog');

const props = defineProps({
	title: {
		type: String,
		required: false,
	},
	showTooltib: {
		type: Boolean,
		required: true,
	},
	name: {
		type: String,
		required: true,
	},
});

const info = computed(() => {
	const value = settings.value.sectionsInfo.find((x: { key: string; }) => x.key === props.name)?.value;

	return value || `No description available. Please contact your administrator to configure "${props.name}" field.`;
});

const showDescription = () => {
	alertDialog?.value.openDialog(info.value, { confirmText: 'Ok' });
};
</script>
