export const calculateTrimRecommended = (props) => {
    const { items, GET, SET, modelField, modelFieldId } = props;
    if (!Array.isArray(items)) {
        return [];
    }
    return items.map((x, i) => {
        const feetPerUnit = GET(1, modelField, items[i].children.trimSize.value);
        if (feetPerUnit === 0) return 0;
        const length = x.children.trimLength.quantity;
        const recommended = Math.ceil(length / feetPerUnit);
        SET(modelFieldId, recommended, i);
        return recommended;
    });
};
export const calculateTrimCoilFeetRecommended = (a, prop) => (props) => {
    const { altModel, altModelValues, GET } = props;
    const colorField = altModel[prop].children[prop + 'Color'].field;

    if (Array.isArray(altModelValues[prop])) {
        const values = altModelValues[prop]
            .filter((x, i) => {
                if (prop === 'wallFlashing') {
                    const type = altModelValues[prop][i].children[prop + 'Type'].value || '';

                    return type === '2.96.1';
                } else {
                    return true;
                }
            })
            .map((x, i) => {
                let length = x.quantity;

                if (prop === 'wallFlashing') {
                    const quantity = altModelValues[prop][i].children[prop + 'Quantity'].quantity || '';
                    length = quantity;
                }

                let rowsPerFoot = 0;

                if (prop !== 'fasciaWrap') {
                    rowsPerFoot =
                        GET(
                            1,
                            altModel[prop].children[prop + 'Type'].field,
                            altModelValues[prop][i].children[prop + 'Type'].value
                        ) || 1;
                } else {
                    rowsPerFoot =
                        GET(
                            1,
                            altModel[prop].children[prop + 'Width'],
                            altModelValues[prop][i].children[prop + 'Width'].value
                        ) || 1;
                }

                const colorOptionId = x.children[prop + 'Color'].value;

                const color = colorField.options.find((x) => x.id === colorOptionId)?.value ?? '';
                return { color: color, feet: length * rowsPerFoot };
            });
        a.push(...values);
    } else {
        const x = altModelValues[prop];
        const length = x.quantity;
        const rowsPerFoot =
            GET(1, altModel[prop].children[prop + 'Type'].field, altModelValues[prop].children[prop + 'Type'].value) ||
            1;
        const colorOptionId = x.children[prop + 'Color'].value;
        const color = colorField.options.find((x) => x.id === colorOptionId)?.value ?? '';
        a.push({ color: color, feet: length * rowsPerFoot });
    }
    return a;
};

export const calculateOrganized = (items) => {
    return items.reduce((obj, x) => {
        if (x.feet > 0) {
            const key = x.color.toLowerCase();
            const item = key in obj ? obj[key] : (obj[key] = { color: x.color, feet: 0 });
            item.feet += x.feet;
        }
        return obj;
    }, {});
};
