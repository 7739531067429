<script setup>
    import { computed, defineProps } from 'vue';

    import { calculateVentilation } from '@/helpers/estimator/roofing/07_ventilation';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "./types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const requiredVentilation = computed(() =>
        Math.ceil((props?.modelValues.atticSpaceVented.quantity * 144) / 300 / 2)
    );
    const fasciaVentCutInLengthRecommended = computed(() => {
        return calculateVentilation(
            props?.model.fasciaIntakeVent.field,
            props?.modelValues.fasciaIntakeVent.value,
            requiredVentilation.value,
            props?.model.fasciaVentCutInLength.field.id,
            props?.getRecommendedConversion,
            props?.setRecommendedItem
        );
    });
    const fasciaIntakeVentRecommended = computed(() => {
        return calculateVentilation(
            props?.model.fasciaIntakeVent.field,
            props?.modelValues.fasciaIntakeVent.value,
            props?.modelValues.fasciaVentLength.quantity,
            props?.model.fasciaIntakeVent.field.id,
            props?.getRecommendedConversion,
            props?.setRecommendedItem
        );
    });
    const louveredSoffitVentAluminumRecommended = computed(() => {
        return calculateVentilation(
            props?.model.louveredSoffitVentAluminum.field,
            props?.modelValues.louveredSoffitVentAluminum.value,
            requiredVentilation.value,
            props?.model.louveredSoffitVentAluminum.field.id,
            props?.getRecommendedConversion,
            props?.setRecommendedItem
        );
    });
    const louveredSoffitVentWoodRecommended = computed(() => {
        return calculateVentilation(
            props?.model.louveredSoffitVentWood.field,
            props?.modelValues.louveredSoffitVentWood.value,
            requiredVentilation.value,
            props?.model.louveredSoffitVentWood.field.id,
            props?.getRecommendedConversion,
            props?.setRecommendedItem
        );
    });
    const edgeVentCutInLengthRecommended = computed(() => {
        return Array.isArray(props?.modelValues.edgeVent)
            ? props?.modelValues.edgeVent.map((x, i) => {
                  return calculateVentilation(
                      props?.model.edgeVent.children.edgeVentType.field,
                      props?.modelValues.edgeVent[i].children.edgeVentType.value,
                      requiredVentilation.value,
                      props?.model.edgeVent.children.edgeVentCutInLength.field.id,
                      props?.getRecommendedConversion,
                      props?.setRecommendedItem
                  );
              })
            : [];
    });
    const edgeVentRecommended = computed(() => {
        return Array.isArray(props?.modelValues.edgeVent)
            ? props?.modelValues.edgeVent.map((x, i) => {
                  return calculateVentilation(
                      props?.model.edgeVent.children.edgeVentType.field,
                      props?.modelValues.edgeVent[i].children.edgeVentType.value,
                      props?.modelValues.edgeVent[i].children.edgeVentLength.quantity,
                      props?.model.edgeVent.children.edgeVentType.field.id,
                      props?.getRecommendedConversion,
                      props?.setRecommendedItem,
                      2
                  );
              })
            : [];
    });
    const ridgeVentCutInLengthRecommended = computed(() => {
        return Array.isArray(props?.modelValues.ridgeExhaustVent)
            ? props?.modelValues.ridgeExhaustVent.map((x, i) => {
                  return calculateVentilation(
                      props?.model.ridgeExhaustVent.children.ridgeExhaustVentType.field,
                      props?.modelValues.ridgeExhaustVent[i].children.ridgeExhaustVentType.value,
                      requiredVentilation.value,
                      props?.model.ridgeExhaustVent.children.ridgeVentCutInLength.field.id,
                      props?.getRecommendedConversion,
                      props?.setRecommendedItem
                  );
              })
            : [];
    });
    const ridgeExhaustVentRecommended = computed(() => {
        return Array.isArray(props?.modelValues.ridgeExhaustVent)
            ? props?.modelValues.ridgeExhaustVent.map((x, i) => {
                  return calculateVentilation(
                      props?.model.ridgeExhaustVent.children.ridgeExhaustVentType.field,
                      props?.modelValues.ridgeExhaustVent[i].children.ridgeExhaustVentType.value,
                      props?.modelValues.ridgeExhaustVent[i].children.ridgeVentLength.quantity,
                      props?.model.ridgeExhaustVent.children.ridgeExhaustVentType.field.id,
                      props?.getRecommendedConversion,
                      props?.setRecommendedItem,
                      2
                  );
              })
            : [];
    });
    const boxExhaustVentRecommended = computed(() => {
        return calculateVentilation(
            props?.model.boxExhaustVent.field,
            props?.modelValues.boxExhaustVent.value,
            requiredVentilation.value,
            props?.model.boxExhaustVent.field.id,
            props?.getRecommendedConversion,
            props?.setRecommendedItem
        );
    });

    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: 'RecommendCalculations',
                title: 'Recommended calculations',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Ridge length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'ventRidgeLength.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Eave length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'ventEaveLength.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Vent attic space',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'atticSpaceVented.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: '--',
                            },
                            {
                                id: 2,
                                label: `${requiredVentilation?.value} sq. in. of total intake needed`,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: '--',
                            },
                            {
                                id: 2,
                                label: `${requiredVentilation?.value} sq. in. of total exhaust needed`,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'Intaketype',
                title: 'Intake Type',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Fascia vent',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => {
                                    return 'fasciaIntakeVent.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Fascia vent length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'fasciaVentLength.quantity';
                                },
                                recommended: () => {
                                    return props?.modelValues.ventEaveLength.quantity;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Cut in length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'fasciaVentCutInLength.quantity';
                                },
                                recommended: () => {
                                    return fasciaVentCutInLengthRecommended?.value;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'fasciaIntakeVent.quantity';
                                },
                                recommended: () => {
                                    return fasciaIntakeVentRecommended?.value;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: 'edgeVent',
                title: 'Edge Vent',
                isFieldArray: true,
                quantity: (i) => {
                    return props?.modelValues.edgeVent[i].children.edgeVentType.quantity;
                },
                setModelValue: (i) => {
                    return `edgeVent[${i}].children.edgeVentType.quantity`;
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'EdgeVent',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `edgeVent[${i}].children.edgeVentType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'EdgeVent length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `edgeVent[${i}].children.edgeVentLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Cut in length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `edgeVent[${i}].children.edgeVentCutInLength.quantity`;
                                },
                                recommended: (i) => {
                                    return edgeVentCutInLengthRecommended?.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `edgeVent[${i}].children.edgeVentType.quantity`;
                                },
                                recommended: (i) => {
                                    return edgeVentRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: 'Louvered',
                title: 'Louvered',
                isFieldArray: false,
                quantity: () => {
                    return 0;
                },
                setModelValue: () => {
                    return '';
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Louvered 4x16',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => {
                                    return 'louveredSoffitVentAluminum.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'louveredSoffitVentAluminum.quantity';
                                },
                                recommended: () => {
                                    return louveredSoffitVentAluminumRecommended?.value;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Louvered 8x16',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => {
                                    return 'louveredSoffitVentWood.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'louveredSoffitVentWood.quantity';
                                },
                                recommended: () => {
                                    return louveredSoffitVentWoodRecommended.value;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                name: 'ridgeExhaustVent',
                title: 'Ridge Exhaust Vent',
                isFieldArray: true,
                quantity: (i) => {
                    return props?.modelValues.ridgeExhaustVent[i].children.ridgeExhaustVentType.quantity;
                },
                setModelValue: (i) => {
                    return `ridgeExhaustVent[${i}].children.ridgeExhaustVentType.quantity`;
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Ridge Vent',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `ridgeExhaustVent[${i}].children.ridgeExhaustVentType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Ridge  Vent Length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `ridgeExhaustVent[${i}].children.ridgeVentLength.quantity`;
                                },
                                recommended: () => {
                                    return props?.modelValues.ventRidgeLength.quantity;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Cut in length',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `ridgeExhaustVent[${i}].children.ridgeVentCutInLength.quantity`;
                                },
                                recommended: (i) => {
                                    return ridgeVentCutInLengthRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `ridgeExhaustVent[${i}].children.ridgeExhaustVentType.quantity`;
                                },
                                recommended: (i) => {
                                    return ridgeExhaustVentRecommended?.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                name: 'BoxExhaustVent',
                title: 'Box vent',
                isFieldArray: false,
                quantity: () => {
                    return 0;
                },
                setModelValue: () => {
                    return '';
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Box Vent',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => 'boxExhaustVent.value',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'boxExhaustVent.quantity',
                                recommended: () => boxExhaustVentRecommended.value,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 7,
                name: 'Powervent',
                title: 'Power vent',
                isFieldArray: false,
                quantity: () => {
                    return 0;
                },
                setModelValue: () => {
                    return '';
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Power Vent',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => {
                                    return 'powerExhaustVent.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'powerExhaustVent.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 8,
                name: 'Kitchenexhaust',
                title: 'Kitchen exhaust',
                isFieldArray: false,
                quantity: () => {
                    return 0;
                },
                setModelValue: () => {
                    return '';
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Kitchen exhaust',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => 'kitchenExhaustVent.value',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'kitchenExhaustVent.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 9,
                name: 'bathExhaustVent',
                title: 'Bathroom exhaust',
                isFieldArray: true,
                quantity: (i) => {
                    return props?.modelValues.bathExhaustVent[i].quantity;
                },
                setModelValue: (i) => {
                    return `bathExhaustVent[${i}].quantity`;
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Bathroom exhaust',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `bathExhaustVent[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `bathExhaustVent[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 10,
                name: 'patch',
                title: 'Patch',
                isFieldArray: true,
                quantity: (i) => {
                    return props?.modelValues.patch[i].quantity;
                },
                setModelValue: (i) => {
                    return `patch[${i}].quantity`;
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Patch',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `patch[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `patch[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
