<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKeyChild, estimteCostValues } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const compositeTrimRecommended = computed(() => {
        return Array.isArray(props?.modelValues.compositeTrim)
            ? props?.modelValues.compositeTrim.map((x, i) => {
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.compositeTrim.children.compositeTrimSize.field,
                      props?.modelValues.compositeTrim[i].children.compositeTrimSize.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const length = x.children.compositeTrimLength.quantity;
                  const recommended = Math.ceil(length / feetPerUnit);
                  props?.setRecommendedItem(
                      props?.model.compositeTrim.children.compositeTrimSize.field.id,
                      recommended,
                      i
                  );
                  return recommended;
              })
            : [];
    });
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: '9e50cd5d-b80b-4d9d-b036-16e5be237287',
                name: 'compositeTrim',
                quantity: (i) => props?.modelValues.compositeTrim[i].children.compositeTrimSize.quantity,
                setModelValue: (i) => `compositeTrim[${i}].children.compositeTrimSize.quantity`,
                title: 'Composite Trim',
                isFieldArray: true,
                rows: [
                    {
                        id: 'e8740cb2-fa6a-4190-aa71-934bc224b168',
                        columns: [
                            {
                                id: '646c9725-ff32-4abd-b78c-76d589dd4e61',
                                label: 'TOTAL',
                            },
                            {
                                id: 'cd7c0af9-d14a-43a2-8347-dd60abd74ed4',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 'b4b36160-2d43-4740-ab18-af7e3f0a03cd',
                        columns: [
                            {
                                id: 'af587e87-c2a8-4275-b587-6ed9e77c0a91',
                                label: 'Trim Type',
                            },
                            {
                                id: '53ff6af9-284f-462d-984c-e366e4d72f8c',
                                type: 'Dropdown',
                                name(i) {
                                    return `compositeTrim[${i}].children.compositeTrimType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'b3a31065-bdc5-4084-b971-792f827809ba',
                        columns: [
                            {
                                id: '3274a3db-904a-4585-a7fd-6c57603a3be3',
                                label: 'Size',
                            },
                            {
                                id: 'e0a459e4-9c73-4eda-8507-0a6cdf6a260f',
                                type: 'Dropdown',
                                name(i) {
                                    return `compositeTrim[${i}].children.compositeTrimSize.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Size'),
                        ],
                    },
                    {
                        id: '06303cac-08ea-4b09-a9fb-36dd40422e08',
                        columns: [
                            {
                                id: '61e73295-b09b-4b17-8b52-d8e76cc04eb9',
                                label: 'Color',
                            },
                            {
                                id: 'd641495c-f256-4db3-aad1-016301ba97f8',
                                type: 'Dropdown',
                                name(i) {
                                    return `compositeTrim[${i}].children.compositeTrimColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '2513cfc0-ce6f-4526-9421-cd471861f02d',
                        columns: [
                            {
                                id: 'a5b3be94-b6d7-4f05-b237-d6584a9368b6',
                                label: 'Length Feet',
                            },
                            {
                                id: '20aa9179-fbc7-4853-8672-9a24e14245a5',
                                type: 'Quantity',
                                name(i) {
                                    return `compositeTrim[${i}].children.compositeTrimLength.quantity`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Length'),
                        ],
                    },
                    {
                        id: 'afe66d43-5d90-4caf-b289-6250c03d0f36',
                        columns: [
                            {
                                id: '82417943-fbaa-480b-8b0f-b0363edc5e42',
                                label: 'Quantity sadfasdf',
                            },
                            {
                                id: 'a0028b9d-5996-4c58-9abe-d6d6fd237803',
                                type: 'Quantity',
                                recommended(i) {
                                    return compositeTrimRecommended.value[Number(i)];
                                },
                                name(i) {
                                    return `compositeTrim[${i}].children.compositeTrimSize.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
