import EstimateWorkflows from '@/models/EstimateWorkflows';
import { parseIntProp } from '../_helpers';

export default {
    path: '/master',
    component: () => import('@/views/estimator/_EstimatorApp.vue'),
    meta: { requiresAuth: true, requiresUser: true, appName: 'estimator' },
    children: [
        {
            path: '',
            redirect: '/master/folder-log',
        },
        {
            path: 'folder-log',
            component: () => import('@/views/master/FolderLog.vue'),
            meta: { title: 'Folder Log' },
        },
    ],
};
