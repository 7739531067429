import { url as urlHelper } from '@/helpers';
import Company from '@/models/Company';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
    /**
     * Get all companies
     * @param params Object request parameters.
     * @param params.active Boolean if specified, only return either active or inactive companies.
     */
    async getAll({ active = undefined }: { active?: boolean } = {}) {
        const query: { active?: boolean } = {};
        if (typeof active === 'boolean') {
            query.active = active;
        }
        const url = urlHelper('/api/companies', query);
        let response;
        try {
            response = await fetchWrap(url);
        } catch {
            response = offlineResponse();
        }
        if (response?.status >= 200 && response?.status < 300) {
            return response.data.map((x: any) => new Company(x));
        } else {
            return response;
        }
    },
    /**
     * Get a company
     * @param id Number Company ID
     */
    async getById(id: number) {
        let response;
        try {
            response = await fetchWrap('/api/companies/' + id);
        } catch {
            response = offlineResponse();
        }
        if (response.status >= 200 && response.status < 300) {
            return new Company(response.data);
        } else {
            return response;
        }
    },
    /**
     * Create a company
     * @param model Company company to create.
     */
    async create(model: Company, sourceCompanyId?: number) {
        const query: { sourceCompanyId?: number } = {};
        if (typeof sourceCompanyId === 'number' && sourceCompanyId > 0) {
            query.sourceCompanyId = sourceCompanyId;
        }
        const url = urlHelper('/api/companies', query);
        let response;
        try {
            response = await fetchWrap(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        if (response.status >= 200 && response.status < 300) {
            return new Company(response.data);
        } else {
            return response;
        }
    },
    /**
     * Update a company
     * @param model Company company to update.
     */
    async update(model: Company) {
        let response;
        try {
            response = await fetchWrap('/api/companies/' + model.id, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        if (response.status >= 200 && response.status < 300) {
            return new Company(model);
        } else {
            return response;
        }
    },
    /**
     * Delete a company
     * @param id Number Company ID to delete.
     */
    async deleteById(id: number) {
        let response;
        try {
            response = await fetchWrap('/api/companies/' + id, { method: 'DELETE' });
        } catch {
            response = offlineResponse();
        }
        if ((response?.status < 300 && response?.status >= 200) || response.status === 404) {
            return true;
        } else if (response.status === 409) {
            return false;
        } else {
            return response;
        }
    },
};
