<script setup>
    import { defineProps } from 'vue';

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
        custom_class: {
            type: String,
        },
        errors: {
            type: Object,
        },
        customMessage: {
            type: String,
        },
    });
</script>

<template>
    <label class="label-error" v-if="props?.errors?.[props?.name] ?? false" :class="props?.custom_class">
        {{ props?.customMessage ?? props?.errors[props?.name] }}
    </label>
</template>
