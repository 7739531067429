<script setup>
    // <script setup lang = "ts" >

    import { roundUpToStepAndClamp } from '@/helpers';

    import { computed, nextTick, watch } from 'vue';

    import { calculateSheathingRecommend } from '@/helpers/estimator/siding/02_siding';
    import { useField } from 'vee-validate';
    import { defineProps, ref } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKeyChild, estimteCostValues } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const accentTotalArea = computed(() =>
        props?.estimate.items.filter((x) => x.fieldId === '2.101').reduce((sum, x) => sum + x.quantity, 0)
    );
    const sumOfTearOffs = computed(() => props?.modelValues.tearOff.reduce((sum, x) => sum + x.quantity, 0));
    const dumpsterWeight = computed(
        () =>
            props?.modelValues.tearOff.reduce(
                (sum, x) => sum + x.quantity * props?.getRecommendedConversion(1, props?.model.tearOff.field, x.value),
                0
            ) / 2000
    );

    function updateTotalSquares() {
        const totalSidingArea = props?.modelValues.totalSidingArea.quantity;

        const wasteFactorForMaterial = props?.modelValues.wasteFactorForMaterial.quantity;
        const materialWasteFactor = 1 + wasteFactorForMaterial / 100;
        const totalSquaresForMaterial = roundUpToStepAndClamp(
            (materialWasteFactor * totalSidingArea) / 100,
            props?.model.totalSquaresForMaterial.field
        );
        if (totalSquaresForMaterial !== props?.modelValues.totalSquaresForMaterial.quantity) {
            props?.setModelValue('totalSquaresForMaterial.quantity', totalSquaresForMaterial);
        }

        const wasteFactorForLabor = roundUpToStepAndClamp(
            wasteFactorForMaterial,
            props?.model.wasteFactorForLabor.field
        );
        if (wasteFactorForLabor !== props?.modelValues.wasteFactorForLabor.quantity) {
            props?.setModelValue('wasteFactorForLabor.quantity', wasteFactorForLabor);
        }
        const laborWasteFactor = 1 + wasteFactorForLabor / 100;
        const totalSquaresForLabor = roundUpToStepAndClamp(
            (laborWasteFactor * totalSidingArea) / 100,
            props?.model.totalSquaresForLabor.field
        );
        if (totalSquaresForLabor !== props?.modelValues?.totalSquaresForLabor?.quantity) {
            props?.setModelValue('totalSquaresForLabor.quantity', totalSquaresForLabor);
        }

        const packageSidingArea = totalSidingArea - accentTotalArea.value;

        const totalSquaresForMaterialPackage = roundUpToStepAndClamp(
            (materialWasteFactor * packageSidingArea) / 100,
            props?.model.totalSquaresForMaterialPackage.field
        );
        if (totalSquaresForMaterialPackage !== props?.modelValues.totalSquaresForMaterialPackage.quantity) {
            props?.setModelValue('totalSquaresForMaterialPackage.quantity', totalSquaresForMaterialPackage);
        }

        const totalSquaresForLaborPackage = roundUpToStepAndClamp(
            (laborWasteFactor * packageSidingArea) / 100,
            props?.model.totalSquaresForLaborPackage.field
        );
        if (totalSquaresForLaborPackage !== props?.modelValues.totalSquaresForLaborPackage.quantity) {
            props?.setModelValue('totalSquaresForLaborPackage.quantity', totalSquaresForLaborPackage);
        }
    }
    watch(
        [
            () => props?.modelValues.totalSidingArea.quantity,
            () => props?.modelValues.wasteFactorForMaterial.quantity,
            accentTotalArea,
        ],
        updateTotalSquares,
        { immediate: true }
    );

    const sheathingRecommended = computed(() => {
        if (!Array.isArray(props?.modelValues.sheathing)) {
            return [];
        }
        return props?.modelValues.sheathing.map((x, i) => {
            return calculateSheathingRecommend({
                GET: props?.getRecommendedConversion,
                ID_GET: props?.model.sheathing.children.sheathingType.field,
                VALUE_GET: props?.modelValues.sheathing[i].children.sheathingType.value,
                AREA_GET: x.children.sheathingArea.quantity,
                SET: props?.setRecommendedItem,
                SET_ID: props?.model.sheathing.children.sheathingType.field.id,
                index: i,
            });
        });
    });
    const boardsRecommended = computed(() => {
        return Array.isArray(props?.modelValues.boards)
            ? props?.modelValues.boards.map((x, i) => {
                  return calculateSheathingRecommend({
                      GET: props?.getRecommendedConversion,
                      ID_GET: props?.model.boards.children.boardType.field,
                      VALUE_GET: props?.modelValues.boards[i].children.boardType.value,
                      AREA_GET: x.children.boardLength.quantity,
                      SET: props?.setRecommendedItem,
                      SET_ID: props?.model.boards.children.boardType.field.id,
                      index: i,
                  });
              })
            : [];
    });

    // async function populate() {
    //     populating.value = true;

    //     const lead = $store.state.estimator.lead;
    //     if (lead && lead.hoverJobId) {
    //         const response = await api.integrations.hover.jobDetails(lead.hoverJobId);

    //         if (!(response === null || response instanceof Response)) {
    //             const hoverJob = new HoverJob(response);

    //             const siding = hoverJob.measurements.siding_waste;
    //             const openings = hoverJob.measurements.openings;
    //             const roofline = hoverJob.measurements.roofline;
    //             const trim = hoverJob.measurements.trim;
    //             const corners = hoverJob.measurements.corners;

    //             const jChannelLength =
    //                 (openings.total_perimeter.siding ?? 0) +
    //                 (roofline.level_frieze_board.length ?? 0) +
    //                 (roofline.sloped_frieze_board.length ?? 0);

    //             const utilityChannelLength =
    //                 (openings.sills_length.siding ?? 0) +
    //                 (roofline.level_frieze_board.length ?? 0) +
    //                 (roofline.sloped_frieze_board.length ?? 0);

    //             const startersLength = trim.level_starter.siding ?? 0;

    //             const outsideCornerPost = corners.outside_corners_len.siding ?? 0;
    //             const insideCornerPost = corners.inside_corners_len.siding ?? 0;

    //             props?.setModelValue('totalSidingArea.quantity', Math.ceil(siding.zero));
    //             props?.setModelValue('jChannel[0].children.jChannelLength.quantity', Math.ceil(jChannelLength));
    //             props?.setModelValue(
    //                 'utilityChannel[0].children.utilityChannelLength.quantity',
    //                 Math.ceil(utilityChannelLength)
    //             );
    //             props?.setModelValue('starters[0].children.startersLength.quantity', Math.ceil(startersLength));
    //             props?.setModelValue(
    //                 'outsideCornerPost[0].children.outsideCornerPostLength.quantity',
    //                 Math.ceil(outsideCornerPost)
    //             );
    //             props?.setModelValue('outsideCornerPost[0].quantity', outsideCornerPostRecommended.value[0]);
    //             props?.setModelValue(
    //                 'insideCornerPost[0].children.insideCornerPostLength.quantity',
    //                 Math.ceil(insideCornerPost)
    //             );
    //             props?.setModelValue('insideCornerPost[0].quantity', insideCornerPostRecommended.value[0]);
    //         }
    //     }

    //     populating.value = false;
    // }

    // const outsideCornerPostRecommended = computed(() => {
    //     return Array.isArray(props?.modelValues.outsideCornerPost)
    //         ? props?.modelValues.outsideCornerPost.map((x, i) => {
    //               const outsideCornerPostLength = x.children.outsideCornerPostLength.quantity;
    //               const feetPerUnit = props?.getRecommendedConversion(
    //                   1,
    //                   props?.model.outsideCornerPost.children.outsideCornerPostType.field,
    //                   x.children.outsideCornerPostType.value
    //               );
    //               if (feetPerUnit === 0) return 0;
    //               const recommended = Math.ceil(outsideCornerPostLength / feetPerUnit);
    //               props?.setRecommendedItem(props?.model.outsideCornerPost.field.id, recommended, i);
    //               return recommended;
    //           })
    //         : [];
    // });

    // const insideCornerPostRecommended = computed(() => {
    //     return Array.isArray(props?.modelValues.insideCornerPost)
    //         ? props?.modelValues.insideCornerPost.map((x, i) => {
    //               const insideCornerPostLength = x.children.insideCornerPostLength.quantity;
    //               const feetPerUnit = props?.getRecommendedConversion(
    //                   1,
    //                   props?.model.insideCornerPost.children.insideCornerPostType.field,
    //                   x.children.insideCornerPostType.value
    //               );
    //               if (feetPerUnit === 0) return 0;
    //               const recommended = Math.ceil(insideCornerPostLength / feetPerUnit);
    //               props?.setRecommendedItem(props?.model.insideCornerPost.field.id, recommended, i);
    //               return recommended;
    //           })
    //         : [];
    // });

    const dumpsters = ref(null);
    const { setValue: setDumpsterLaborQuantity } = useField('dumpsterLabor.quantity');
    const { setValue: setDumpsterWeightQuantity } = useField('dumpsterWeight.quantity');

    const autoUpdateDumpsterLabor = computed(
        () => typeof sumOfTearOffs.value === 'number' && !isNaN(sumOfTearOffs.value)
    );

    async function updateDumpster() {
        if (!dumpsters.value) await nextTick();
        if (!dumpsters.value) return;
        if (props.modelValues.dumpsterNeeded.value) {
            if (dumpsters.value.items.length === 0) {
                dumpsters.value.addItem();
            }
        } else {
            // eslint-disable-next-line for-direction
            for (let i = dumpsters.value.items.length - 1; i >= 0; i--) {
                dumpsters.value.removeItem(i);
            }
        }
        updateDumpsterLabor();
        updateDumpsterWeight();
    }

    async function updateDumpsterLabor() {
        if (props.modelValues.dumpsterNeeded.value && props.modelValues.dumpsterLabor.value) {
            if (autoUpdateDumpsterLabor.value) {
                const field = props.model.dumpsterLabor.field;
                let value = sumOfTearOffs.value ?? 0;
                setDumpsterLaborQuantity(roundUpToStepAndClamp(value, field));
            }
        } else {
            setDumpsterLaborQuantity(0);
        }
    }

    async function updateDumpsterWeight() {
        const field = props.model.dumpsterWeight.field;
        let value = props.modelValues.dumpsterNeeded.value ? dumpsterWeight.value : 0;
        setDumpsterWeightQuantity(roundUpToStepAndClamp(value, field));
    }
    watch(() => props.modelValues.dumpsterNeeded.value, updateDumpster, {
        flush: 'post',
        immediate: true,
    });

    watch(() => sumOfTearOffs, updateDumpsterLabor, { immediate: true });

    watch(
        () => dumpsterWeight,
        () => {
            updateDumpsterWeight();
        },
        { immediate: true }
    );

    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: '',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                title: 'Details',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Existing siding style',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => 'existingSidingStyle.value',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Install type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => {
                                    return 'sidingInstallType.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'previousSidingProviders',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.previousSidingProviders[i].value,
                setModelValue: (i) => `previousSidingProviders[${i}].value`,
                title: 'Previous siding providers ',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: '',
                            },
                            {
                                id: 2,
                                type: 'Text',
                                name(i) {
                                    return `previousSidingProviders[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: '',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                title: 'Dumpster',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Dumpster needed?',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name: () => 'dumpsterNeeded.value',
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: 'dumpster',
                isFieldArray: true,
                refString: 'dumpsters',
                showSection: props?.modelValues.dumpsterNeeded.value,
                quantity: () => 0,
                setModelValue: () => '',
                title: 'Dumpster Location',
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Dumpster location',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `dumpster[${i}].children.dumpsterLocation.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `dumpster[${i}].children.dumpsterType.value`;
                                },
                            },
                            // ...emptyFields,
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                            //
                        ],
                    },
                ],
            },
            {
                id: 5,
                name: 'dumpster',
                isFieldArray: false,
                showSection: props?.modelValues.dumpsterNeeded.value,
                quantity: () => 0,
                setModelValue: () => '',
                title: 'Dumpster Labor',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Dumpster labor',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'dumpsterLabor.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'dumpsterLabor.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                name: 'dumpster',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                title: 'Material Type',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Material Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => 'materialType.value',
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                name: '1resx1',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                title: 'Waste calculations',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Total siding area',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'totalSidingArea.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Waste factor',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'wasteFactorForMaterial.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Total material',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'totalSquaresForMaterial.quantity',
                                readonly: true,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Total labor',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'totalSquaresForLabor.quantity',
                                readonly: true,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 7,
                name: '1jsmds',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                showSection: accentTotalArea.value > 0,
                title: 'Accent Total Area',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Accent Total Area',
                            },
                            {
                                id: 2,
                                label: `With ${accentTotalArea.value.toLocaleString()} sq ft of accents subtracted from the total siding area`,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 8,
                name: 'x19mx',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                showSection: accentTotalArea.value > 0,
                title: 'Accent Total Area',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Total package material',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'totalSquaresForMaterialPackage.quantity',
                                readonly: true,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Total package labor',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'totalSquaresForLaborPackage.quantity',
                                readonly: true,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 9,
                name: 'tearOff',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.tearOff[i].quantity,
                setModelValue: (i) => `tearOff[${i}].quantity`,
                title: 'Tear Off',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Tear off existing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `tearOff[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Material',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `tearOff[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 10,
                name: 'sheathing',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.sheathing[i].children.sheathingType.quantity,
                setModelValue: (i) => `sheathing[${i}].children.sheathingType.quantity`,
                title: 'Sheating',
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `sheathing[${i}].children.sheathingType.value`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Area Square',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `sheathing[${i}].children.sheathingArea.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `sheathing[${i}].children.sheathingType.quantity`;
                                },
                                recommended(i) {
                                    return sheathingRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 11,
                name: 'boards',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.boards[i].children.boardType.quantity,
                setModelValue: (i) => `boards[${i}].children.boardType.quantity`,
                title: 'Boards',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Boards',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `boards[${i}].children.boardType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Length Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `boards[${i}].children.boardLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `boards[${i}].children.boardType.quantity`;
                                },
                                recommended(i) {
                                    return boardsRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
