<!-- <script setup lang="ts"> -->
<script setup>
    import { computed } from 'vue';

    import { defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKeyChild, estimteCostValues } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const fasciaRecommended = computed(() => {
        return Array.isArray(props?.modelValues.fascia)
            ? props?.modelValues.fascia.map((x, i) => {
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.fascia.children.fasciaType.field,
                      props?.modelValues.fascia[i].children.fasciaType.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const length = x.children.fasciaLength.quantity;
                  const recommended = Math.ceil(length / feetPerUnit);
                  props?.setRecommendedItem(props?.model.fascia.children.fasciaType.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: '172cae7f-096e-4324-b6fd-2c71d3c01d28',
                name: 'fasciaWrap',
                quantity: (i) => props?.modelValues.fasciaWrap[i].quantity,
                setModelValue: (i) => `fasciaWrap[${i}].quantity`,
                title: 'Aluminum Fascia',
                isFieldArray: true,
                rows: [
                    {
                        id: '63458f7c-2dde-4ea1-91e5-1a6a7cac9207',
                        columns: [
                            {
                                id: '7587b1f9-ccf3-4e11-92e4-de36c59cf0e5',
                                label: 'TOTAL',
                            },
                            {
                                id: 'c634f1f7-41eb-4b77-972a-88717462fc6d',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 'cdae47d2-3eda-409d-9dd4-553aeac765b4',
                        columns: [
                            {
                                id: 'f6d808c9-351b-4799-8279-56c756051db5',
                                label: 'Length Feet',
                            },
                            {
                                id: 'aae8b534-6e38-4ac6-b5e1-82217a660eb9',
                                type: 'Quantity',
                                name(i) {
                                    return `fasciaWrap[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '7cff543d-fe26-42d8-8958-7e4f62176355',
                        columns: [
                            {
                                id: '58d12c84-f5aa-4d49-8fb3-af564eeb5c45',
                                label: 'Width',
                            },
                            {
                                id: '6211f4ab-bab6-414a-bca5-942670ef2aa9',
                                type: 'Dropdown',
                                name(i) {
                                    return `fasciaWrap[${i}].children.fasciaWrapWidth.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '220fb837-c677-4f83-ba3e-d25550d0d243',
                        columns: [
                            {
                                id: '4246631f-c421-40b8-bbe6-59420ecd470b',
                                label: 'Color',
                            },
                            {
                                id: 'b7ae4bb0-c75a-423d-ab11-987b4a45d00e',
                                type: 'Dropdown',
                                name(i) {
                                    return `fasciaWrap[${i}].children.fasciaWrapColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: '01dd4e7e-6334-4bab-9fd7-998748b423ea',
                name: 'fascia',
                quantity: (i) => props?.modelValues.fascia[i].children.fasciaType.quantity,
                setModelValue: (i) => `fascia[${i}].children.fasciaType.quantity`,
                title: 'Fascia Boards',
                isFieldArray: true,
                rows: [
                    {
                        id: 'a21cbe8b-b615-454b-9daf-86de5d293ec9',
                        columns: [
                            {
                                id: 'd01c1924-fe95-4b08-bee3-05f6013c4272',
                                label: 'TOTAL',
                            },
                            {
                                id: 'cbf8466f-bc3f-406c-b5f3-7eed29eaa0a7',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: '08627a56-6cc8-4716-bbeb-dabc2049f359',
                        columns: [
                            {
                                id: 'e93afcf8-9faf-4025-b1e9-bf7ca14641cf',
                                label: 'Quantity',
                            },
                            {
                                id: '3596aa24-8530-4ad5-ae08-0cbdaa29e035',
                                type: 'Quantity',
                                recommended: (i) => {
                                    return fasciaRecommended.value[Number(i)];
                                },
                                name(i) {
                                    return `fascia[${i}].children.fasciaType.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'e3ac4c4b-a310-470c-bfda-c618547a12c9',
                        columns: [
                            {
                                id: '2b7b8072-cfac-4259-be15-80ae55a5493a',
                                label: 'Length Feet',
                            },
                            {
                                id: 'b7485a72-5a60-48ad-a8c0-066e44ccc3a0',
                                type: 'Quantity',
                                name(i) {
                                    return `fascia[${i}].children.fasciaLength.quantity`;
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Length'),
                        ],
                    },
                    {
                        id: '9c0867a7-09ee-4257-8800-23aa4a77be0f',
                        columns: [
                            {
                                id: 'ec64aab7-0ffd-4835-876f-a46ac9188b31',
                                label: 'Type',
                            },
                            {
                                id: 'f77c2512-df3b-401f-804d-9ce8d8e9a0ac',
                                type: 'Dropdown',
                                name(i) {
                                    return `fascia[${i}].children.fasciaType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '1400bea4-b8c6-4276-aa9f-fe9d60a21ef6',
                        columns: [
                            {
                                id: 'fd507d26-6b44-4a08-b8b5-6146ffc3c935',
                                label: 'Color',
                            },
                            {
                                id: 'c4fed893-c8eb-4e7f-b509-6e12d33675ac',
                                type: 'Dropdown',
                                name(i) {
                                    return `fascia[${i}].children.fasciaColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
