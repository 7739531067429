/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { register } from 'register-service-worker';
import $store from './store/index';

interface PeriodicSyncServiceWorkerRegistration extends ServiceWorkerRegistration {
    periodicSync?: {
        register: (tag: string, options: { minInterval: number }) => Promise<void>;
    };
}

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.debug(
                'App is being served from cache by a service worker.\n' +
                    'For more details, visit https://goo.gl/AFskqB'
            );

            (async () => {
                const registration = (await navigator.serviceWorker.ready) as PeriodicSyncServiceWorkerRegistration;

                if (registration.periodicSync) {
                    try {
                        await registration.periodicSync.register('sync-download', {
                            minInterval: 1 * 60 * 60 * 1000,
                        });
                    } catch (error) {
                        console.debug('Error registering periodic sync', error);
                    }
                }
            })();
        },
        registered() {
            console.debug('Service worker has been registered.');
        },
        cached() {
            console.debug('Content has been cached for offline use.');
        },
        updatefound() {
            console.debug('New content is downloading.');
        },
        updated() {
            console.debug('New content is available; please refresh.');
            $store.commit('setServiceWorkerUpdate', true);
        },
        offline() {
            console.debug('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        },
    });
}
