<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKey } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Air Seal',
                name: '',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Air sealing package',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'airSealingPackage.value';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Air sealing package'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Attic access',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'atticAccess.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Recessed light',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'sealRecessedLightIcRated.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Recessed light IC rated'),
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Recessed light no rated',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'sealRecessedLightNotIcRated.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Recessed light not IC rated'),
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: 'Dropped soffits',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'sealDroppedSoffits.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Dropped soffits'),
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: 'Top Plates',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'sealTopPlates.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Top plates'),
                        ],
                    },
                    {
                        id: 7,
                        columns: [
                            {
                                id: 1,
                                label: 'Knee wall floor line',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'sealKneeWallFloorLine.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Knee wall floor line'),
                        ],
                    },
                    {
                        id: 8,
                        columns: [
                            {
                                id: 1,
                                label: 'Basement box still',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'sealBasementBoxStill.quantity';
                                },
                            },
                            // "Basement box still"
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Basement box still'),
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
