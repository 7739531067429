type EstimateFieldVariantPriceDTO = {
    materialCost: number;
    laborCost: number;
    materialPrice: number;
    laborPrice: number;
    min: number;
    max: number;
    minHeight: number;
    maxHeight: number;
};

export default class EstimateFieldVariantPrice {
    materialCost: number;
    laborCost: number;
    materialPrice: number;
    laborPrice: number;
    min: number;
    max: number;
    minHeight: number;
    maxHeight: number;

    constructor(dto: Partial<EstimateFieldVariantPriceDTO>) {
        this.materialCost = dto.materialCost ?? 0;
        this.laborCost = dto.laborCost ?? 0;
        this.materialPrice = dto.materialPrice ?? 0;
        this.laborPrice = dto.laborPrice ?? 0;
        this.min = dto.min ?? 0;
        this.max = dto.max ?? 0;
        this.minHeight = dto.minHeight ?? 0;
        this.maxHeight = dto.maxHeight ?? 0;
    }
}
