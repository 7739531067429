<script setup>
    // <script setup lang = "ts" >

    import { calculateFasciaRecommend } from '@/helpers/estimator/siding/08_fascia';
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const fasciaRecommended = computed(() => {
        return calculateFasciaRecommend({
            items: props?.modelValues.fascia,
            GET: props?.getRecommendedConversion,
            SET: props?.setRecommendedItem,
            modelField: props?.model.fascia.children.fasciaType.field,
            modelFieldId: props?.model.fascia.children.fasciaType.field.id,
        });
    });
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Aluminum Fascia',
                name: 'fasciaWrap',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.fasciaWrap[i].quantity,
                setModelValue: (i) => `fasciaWrap[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Length Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `fasciaWrap[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Width',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `fasciaWrap[${i}].children.fasciaWrapWidth.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `fasciaWrap[${i}].children.fasciaWrapColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Fascia Boards',
                name: 'fascia',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.fascia[i].children.fasciaType.quantity,
                setModelValue: (i) => `fascia[${i}].children.fasciaType.quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `fascia[${i}].children.fasciaType.quantity`;
                                },
                                recommended(i) {
                                    return fasciaRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Length Feet',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `fascia[${i}].children.fasciaType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `fascia[${i}].children.fasciaColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
