<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: '222d958c-32fb-44ed-8eb0-0bffa8931923',
                name: '',
                quantity: () => 0,
                setModelValue: () => '',
                title: 'TrimDetails',
                rows: [
                    {
                        id: '33150e7e-2460-49e2-93d3-be87f32da0fd',
                        columns: [
                            {
                                id: '74d68d8c-cfeb-461e-b7db-5ebf87e95807',
                                label: 'Trim area of work',
                            },
                            {
                                id: '60157d9f-24ba-40cf-b698-f7fc33fd88b0',
                                type: 'Dropdown',
                                name() {
                                    return 'trimAreaOfWork.value';
                                },
                                display: 'dropdown',
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
