<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";
    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Garage doors',
                name: 'garageDoors',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.garageDoors[i].quantity,
                setModelValue: (i) => `garageDoors[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Garage door',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `garageDoors[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `garageDoors[${i}].children.garageDoorType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Size',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `garageDoors[${i}].children.garageDoorSize.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `garageDoors[${i}].children.garageDoorColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Garage doors Opener',
                name: 'garageDoorOpener',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.garageDoorOpener[i].quantity,
                setModelValue: (i) => `garageDoorOpener[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Garage door opener',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `garageDoorOpener[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,

                                type: 'Dropdown',
                                name(i) {
                                    return `garageDoorOpener[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                title: 'Service doors',
                name: 'serviceDoors',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.serviceDoors[i].quantity,
                setModelValue: (i) => `serviceDoors[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Service door',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `serviceDoors[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `serviceDoors[${i}].children.serviceDoorType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `serviceDoors[${i}].children.serviceDoorColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
