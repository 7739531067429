interface PaginatedListDto {
    offset: number;
    limit: number;
    totalCount: number;
    data: any[]; // Puedes ajustar esto según el tipo de datos que esperes en la lista
}

export default class PaginatedList {
    data: any[];
    offset: number;
    limit: number;
    totalCount: number;

    constructor(dto: Partial<PaginatedListDto> = {}) {
        this.data = dto.data ?? [];
        this.offset = dto.offset ?? 0;
        this.limit = dto.limit ?? 0;
        this.totalCount = dto.totalCount ?? 0;
    }

    get hasNextPage() {
        return this.totalCount > this.offset + this.limit;
    }

    get hasPreviousPage() {
        return this.offset > 0;
    }
}
