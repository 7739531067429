import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-quarter" }
const _hoisted_2 = { class: "account-nav" }
const _hoisted_3 = { class: "app-version" }
const _hoisted_4 = { class: "semi-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: ["account-nav-contain", { expanded: $setup.isOpen }]
  }, _ctx.$attrs, { ref: "accountNavWidget" }, _toHandlers($setup.focusListeners, true), { tabindex: "0" }), [
    _createElementVNode("button", {
      type: "button",
      class: "account-nav-toggle heading",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.isOpen = !$setup.isOpen))
    }, [
      _cache[2] || (_cache[2] = _createElementVNode("svg", { class: "mr-quarter icon fill-white stroke-white" }, [
        _createElementVNode("use", { "xlink:href": "#account" })
      ], -1)),
      _createElementVNode("span", _hoisted_1, _toDisplayString($setup.user.initials), 1)
    ]),
    _createElementVNode("nav", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/account/companies?select=true" }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("svg", { class: "icon" }, [
            _createElementVNode("use", { "xlink:href": "#compare" })
          ], -1),
          _createTextVNode(" Select company ")
        ])),
        _: 1
      }),
      _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
      _createVNode(_component_router_link, { to: "/account/manage" }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("svg", { class: "icon" }, [
            _createElementVNode("use", { "xlink:href": "#account-manage" })
          ], -1),
          _createTextVNode(" Manage account ")
        ])),
        _: 1
      }),
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onLogout && $setup.onLogout(...args)))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("svg", { class: "icon" }, [
          _createElementVNode("use", { "xlink:href": "#leave" })
        ], -1),
        _createTextVNode(" Logout ")
      ])),
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createTextVNode(" App Version: ")),
        _createElementVNode("span", _hoisted_4, _toDisplayString($setup.version), 1)
      ])
    ])
  ], 16))
}