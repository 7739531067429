import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "quick_estimate_row_table"
}
const _hoisted_2 = { class: "quick_estimate_row_table_item" }
const _hoisted_3 = { style: {"font-size":"12px","margin":"0"} }
const _hoisted_4 = { class: "quick_estimate_row_table_item" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "quick_estimate_row_table" }
const _hoisted_7 = { class: "quick_estimate_table_item" }
const _hoisted_8 = { style: {"font-size":"12px","margin":"0px"} }
const _hoisted_9 = { class: "quick_estimate_table_item" }
const _hoisted_10 = { style: {"display":"flex","flex-direction":"row","gap":"12px"} }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { style: {"font-size":"14px","margin":"0"} }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { style: {"font-size":"14px"} }
const _hoisted_17 = { key: 2 }
const _hoisted_18 = { key: 3 }
const _hoisted_19 = { key: 4 }
const _hoisted_20 = { key: 5 }
const _hoisted_21 = { key: 6 }
const _hoisted_22 = { class: "check-radio-wrap flex-center-y" }
const _hoisted_23 = { key: 7 }
const _hoisted_24 = { key: 8 }
const _hoisted_25 = { style: {"color":"red","font-weight":"bold","font-size":"12px"} }
const _hoisted_26 = { class: "quick_estimate_row_table" }
const _hoisted_27 = { class: "quick_estimate_row_table_item" }
const _hoisted_28 = { style: {"font-size":"12px","margin":"0px"} }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { style: {"font-size":"14px","margin":"0px"} }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { key: 2 }
const _hoisted_33 = { key: 3 }
const _hoisted_34 = { key: 4 }
const _hoisted_35 = { key: 5 }
const _hoisted_36 = { key: 6 }
const _hoisted_37 = { class: "check-radio-wrap flex-center-y" }
const _hoisted_38 = { key: 7 }
const _hoisted_39 = { key: 8 }
const _hoisted_40 = { style: {"color":"red","font-weight":"bold"} }


    // import { SectionProps } from '../quick_estimate/roofing/types';
    import { SectionProps } from '../quick_estimate/roofing/types';
    import FieldExcelError from './FieldExcelError.vue';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'ExcelFields',
  props: {
    sections: {},
    setModelValue: { type: Function },
    modelValues: {},
    errors: {}
  },
  setup(__props: any) {

    const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_input_number = _resolveComponent("input-number")!
  const _component_field_input_quantity = _resolveComponent("field-input-quantity")!
  const _component_field_input_text = _resolveComponent("field-input-text")!
  const _component_field_input_price = _resolveComponent("field-input-price")!
  const _component_field_input_checkbox = _resolveComponent("field-input-checkbox")!
  const _component_field_input_dropdown = _resolveComponent("field-input-dropdown")!
  const _component_field_array = _resolveComponent("field-array")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props?.sections, (section) => {
    return (_openBlock(), _createElementBlock(_Fragment, {
      key: `section-chimneys-${section.id}`
    }, [
      (section?.showSection ?? true)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (section.isFieldArray)
              ? (_openBlock(), _createBlock(_component_field_array, {
                  key: 0,
                  name: section?.name,
                  ref_for: true,
                  ref: section?.refString ?? ''
                }, {
                  default: _withCtx(({ items, removeItem, addItemAfter, addItem }) => [
                    (section?.addItem?.show)
                      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                          _createElementVNode("article", _hoisted_2, [
                            _createElementVNode("p", _hoisted_3, _toDisplayString(section?.addItem?.title), 1)
                          ]),
                          _cache[0] || (_cache[0] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                          _cache[1] || (_cache[1] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                          _cache[2] || (_cache[2] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                          _cache[3] || (_cache[3] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                          _createElementVNode("article", _hoisted_4, [
                            (items.length < 1)
                              ? (_openBlock(), _createElementBlock("button", {
                                  key: 0,
                                  class: "btn-link btn-add",
                                  type: "button",
                                  onClick: addItem
                                }, null, 8, _hoisted_5))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item, i) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: item.key
                      }, [
                        _createElementVNode("section", _hoisted_6, [
                          _createElementVNode("article", _hoisted_7, [
                            _createElementVNode("p", _hoisted_8, _toDisplayString(section.title), 1)
                          ]),
                          _cache[6] || (_cache[6] = _createElementVNode("article", { class: "quick_estimate_table_item" }, null, -1)),
                          _cache[7] || (_cache[7] = _createElementVNode("article", { class: "quick_estimate_table_item" }, null, -1)),
                          _cache[8] || (_cache[8] = _createElementVNode("article", { class: "quick_estimate_table_item" }, null, -1)),
                          _cache[9] || (_cache[9] = _createElementVNode("article", { class: "quick_estimate_table_item" }, null, -1)),
                          _createElementVNode("article", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("button", {
                                class: "btn-link",
                                type: "button",
                                onClick: ($event: any) => (addItemAfter(i))
                              }, _cache[4] || (_cache[4] = [
                                _createElementVNode("svg", {
                                  width: "24px",
                                  height: "24px",
                                  class: "icon fill-dark stroke-green"
                                }, [
                                  _createElementVNode("use", { "xlink:href": "#add" })
                                ], -1)
                              ]), 8, _hoisted_11),
                              (items.length > 1)
                                ? (_openBlock(), _createElementBlock("button", {
                                    key: 0,
                                    class: "btn-link",
                                    type: "button",
                                    onClick: ($event: any) => (removeItem(i))
                                  }, _cache[5] || (_cache[5] = [
                                    _createElementVNode("svg", {
                                      width: "24px",
                                      height: "24px",
                                      class: "icon fill-red stroke-red"
                                    }, [
                                      _createElementVNode("use", { "xlink:href": "#remove" })
                                    ], -1)
                                  ]), 8, _hoisted_12))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.rows, (row) => {
                          return (_openBlock(), _createElementBlock("section", {
                            key: `section-${section}-${section.id}-${row.id}`,
                            class: "quick_estimate_row_table"
                          }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.columns, (col, index) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: `section-${section}-${section.id}-${row.id}-${col.id}`
                              }, [
                                (row?.show?.(i) ?? true)
                                  ? (_openBlock(), _createElementBlock("article", {
                                      key: 0,
                                      class: _normalizeClass(index === 0 ? 'quick_estimate_table_item_sub' : 'quick_estimate_table_item')
                                    }, [
                                      (col?.label)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                            _createElementVNode("p", _hoisted_14, _toDisplayString(col?.label), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (col?.type === 'Cost')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                            _createElementVNode("p", _hoisted_16, _toDisplayString(col?.estimateField?.(i, item.value) ?? ''), 1)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (col?.type === 'Number')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                            _createVNode(FieldExcelError, {
                                              name: col.name?.(i),
                                              custom_class: 'input_field_error',
                                              "custom-message": '*',
                                              errors: props?.errors
                                            }, null, 8, ["name", "errors"]),
                                            (col?.type === 'Number')
                                              ? (_openBlock(), _createBlock(_component_input_number, {
                                                  key: 0,
                                                  id: col?.id_input,
                                                  value: col?.value,
                                                  readonly: col?.readonly,
                                                  custom_class: 'input_number_excel'
                                                }, null, 8, ["id", "value", "readonly"]))
                                              : _createCommentVNode("", true)
                                          ]))
                                        : _createCommentVNode("", true),
                                      (col?.type === 'Quantity')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                            _createVNode(FieldExcelError, {
                                              name: col.name?.(i),
                                              custom_class: 'input_field_error',
                                              "custom-message": '*',
                                              recommended: col.recommended?.(i),
                                              modelValuesProps: _ctx.modelValues,
                                              errors: props?.errors
                                            }, null, 8, ["name", "recommended", "modelValuesProps", "errors"]),
                                            _createVNode(_component_field_input_quantity, {
                                              name: col.name?.(i),
                                              disabled: col?.disabled,
                                              recommended: col.recommended?.(i),
                                              modelValuesProps: _ctx.modelValues,
                                              readonly: col?.readonly,
                                              forceStyle: col?.inputStyle,
                                              custom_class: 'input_number_excel',
                                              custom_select_class: 'input_quantity_select_excel',
                                              recommendedClass: 'input_quantity_recommended',
                                              autoSetRecommend: true
                                            }, null, 8, ["name", "disabled", "recommended", "modelValuesProps", "readonly", "forceStyle"])
                                          ]))
                                        : _createCommentVNode("", true),
                                      (col?.type === 'Text')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                            _createVNode(FieldExcelError, {
                                              name: col.name?.(i),
                                              custom_class: 'input_field_error',
                                              "custom-message": '*',
                                              errors: props?.errors
                                            }, null, 8, ["name", "errors"]),
                                            _createVNode(_component_field_input_text, {
                                              name: col.name?.(i),
                                              recommended: col.recommended?.(i),
                                              modelValuesProps: _ctx.modelValues,
                                              custom_class: 'input_text_excel'
                                            }, null, 8, ["name", "recommended", "modelValuesProps"])
                                          ]))
                                        : _createCommentVNode("", true),
                                      (col?.type === 'Price')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                            _createVNode(FieldExcelError, {
                                              name: col.name?.(i),
                                              custom_class: 'input_field_error',
                                              "custom-message": '*',
                                              errors: props?.errors
                                            }, null, 8, ["name", "errors"]),
                                            _createVNode(_component_field_input_price, {
                                              name: col.name?.(i),
                                              readonly: col?.readonly,
                                              custom_class: "input_price_excel"
                                            }, null, 8, ["name", "readonly"])
                                          ]))
                                        : _createCommentVNode("", true),
                                      (col?.type === 'Checkbox')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                            _createVNode(FieldExcelError, {
                                              name: col.name?.(i),
                                              custom_class: 'input_field_error',
                                              "custom-message": '*',
                                              errors: props?.errors
                                            }, null, 8, ["name", "errors"]),
                                            _createElementVNode("div", _hoisted_22, [
                                              _createVNode(_component_field_input_checkbox, {
                                                name: col.name?.(i),
                                                readonly: col?.readonly
                                              }, null, 8, ["name", "readonly"])
                                            ])
                                          ]))
                                        : _createCommentVNode("", true),
                                      (col?.type === 'Dropdown')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                            _createVNode(FieldExcelError, {
                                              name: col.name?.(i),
                                              custom_class: 'input_field_error',
                                              "custom-message": '*',
                                              errors: props?.errors
                                            }, null, 8, ["name", "errors"]),
                                            _createVNode(_component_field_input_dropdown, {
                                              name: col.name?.(i),
                                              recommended: col.recommended?.(i),
                                              modelValuesProps: _ctx.modelValues,
                                              readonly: col?.readonly,
                                              display: col?.display,
                                              custom_class: 'input_dropdown_excel'
                                            }, null, 8, ["name", "recommended", "modelValuesProps", "readonly", "display"])
                                          ]))
                                        : _createCommentVNode("", true),
                                      (col?.error?.(i))
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                            _createElementVNode("p", _hoisted_25, _toDisplayString(col?.error?.(i)), 1)
                                          ]))
                                        : _createCommentVNode("", true)
                                    ], 2))
                                  : _createCommentVNode("", true)
                              ], 64))
                            }), 128))
                          ]))
                        }), 128))
                      ], 64))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["name"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("section", _hoisted_26, [
                    _createElementVNode("article", _hoisted_27, [
                      _createElementVNode("p", _hoisted_28, _toDisplayString(section.title), 1)
                    ]),
                    _cache[10] || (_cache[10] = _createStaticVNode("<article class=\"quick_estimate_row_table_item\"></article><article class=\"quick_estimate_row_table_item\"></article><article class=\"quick_estimate_row_table_item\"></article><article class=\"quick_estimate_row_table_item\"></article><article class=\"quick_estimate_row_table_item\"></article>", 5))
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.rows, (row) => {
                    return (_openBlock(), _createElementBlock("section", {
                      key: `section-${section}-${section.id}-${row.id}`,
                      class: "quick_estimate_row_table"
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.columns, (col, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: `section-${section}-${section.id}-${row.id}-${col.id}`
                        }, [
                          (row?.show?.(null) ?? true)
                            ? (_openBlock(), _createElementBlock("article", {
                                key: 0,
                                class: _normalizeClass(index === 0 ? 'quick_estimate_table_item_sub' : 'quick_estimate_table_item')
                              }, [
                                (col?.label)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                      _createElementVNode("p", _hoisted_30, _toDisplayString(col?.label), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                (col?.type === 'Cost')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                      _createElementVNode("p", null, _toDisplayString(col?.estimateField?.(null, null) ?? ''), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                (col?.type === 'Number')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                      _createVNode(FieldExcelError, {
                                        name: col.name?.(),
                                        custom_class: 'input_field_error',
                                        "custom-message": '*',
                                        errors: props?.errors
                                      }, null, 8, ["name", "errors"]),
                                      (col?.type === 'Number')
                                        ? (_openBlock(), _createBlock(_component_input_number, {
                                            key: 0,
                                            id: col?.id_input,
                                            value: col?.value,
                                            readonly: col?.readonly,
                                            custom_class: 'input_number_excel'
                                          }, null, 8, ["id", "value", "readonly"]))
                                        : _createCommentVNode("", true)
                                    ]))
                                  : _createCommentVNode("", true),
                                (col?.type === 'Text')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                      _createVNode(FieldExcelError, {
                                        name: col.name?.(),
                                        custom_class: 'input_field_error',
                                        "custom-message": '*',
                                        errors: props?.errors
                                      }, null, 8, ["name", "errors"]),
                                      _createVNode(_component_field_input_text, {
                                        name: col.name?.(),
                                        recommended: col.recommended?.(),
                                        modelValuesProps: _ctx.modelValues,
                                        custom_class: 'input_text_excel'
                                      }, null, 8, ["name", "recommended", "modelValuesProps"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (col?.type === 'Price')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                      _createVNode(FieldExcelError, {
                                        name: col.name?.(),
                                        custom_class: 'input_field_error',
                                        "custom-message": '*',
                                        errors: props?.errors
                                      }, null, 8, ["name", "errors"]),
                                      _createVNode(_component_field_input_price, {
                                        name: col.name?.(),
                                        readonly: col?.readonly,
                                        custom_class: "input_price_excel"
                                      }, null, 8, ["name", "readonly"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (col?.type === 'Quantity')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                      _createVNode(FieldExcelError, {
                                        name: col.name?.(),
                                        custom_class: 'input_field_error',
                                        "custom-message": '*',
                                        errors: props?.errors
                                      }, null, 8, ["name", "errors"]),
                                      _createVNode(_component_field_input_quantity, {
                                        name: col.name?.(),
                                        disabled: col?.disabled,
                                        recommended: col.recommended?.(),
                                        modelValuesProps: _ctx.modelValues,
                                        readonly: col?.readonly,
                                        forceStyle: col?.inputStyle,
                                        custom_class: 'input_number_excel',
                                        custom_select_class: 'input_quantity_select_excel',
                                        recommendedClass: 'input_quantity_recommended',
                                        autoSetRecommend: true
                                      }, null, 8, ["name", "disabled", "recommended", "modelValuesProps", "readonly", "forceStyle"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (col?.type === 'Checkbox')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                      _createVNode(FieldExcelError, {
                                        name: col.name?.(),
                                        custom_class: 'input_field_error',
                                        "custom-message": '*',
                                        errors: props?.errors
                                      }, null, 8, ["name", "errors"]),
                                      _createElementVNode("div", _hoisted_37, [
                                        _createVNode(_component_field_input_checkbox, {
                                          name: col.name?.(),
                                          readonly: col?.readonly
                                        }, null, 8, ["name", "readonly"])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true),
                                (col?.type === 'Dropdown')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                      _createVNode(FieldExcelError, {
                                        name: col.name?.(),
                                        custom_class: 'input_field_error',
                                        "custom-message": '*',
                                        errors: props?.errors
                                      }, null, 8, ["name", "errors"]),
                                      _createVNode(_component_field_input_dropdown, {
                                        name: col.name?.(),
                                        recommended: col.recommended?.(),
                                        modelValuesProps: _ctx.modelValues,
                                        readonly: col?.readonly,
                                        display: col?.display,
                                        custom_class: 'input_dropdown_excel'
                                      }, null, 8, ["name", "recommended", "modelValuesProps", "readonly", "display"])
                                    ]))
                                  : _createCommentVNode("", true),
                                (col?.error?.())
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                                      _createElementVNode("p", _hoisted_40, _toDisplayString(col?.error?.()), 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 2))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]))
                  }), 128))
                ], 64))
          ], 64))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}
}

})