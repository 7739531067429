const EstimateFieldVariantPriceType = {
    standard: 0,
    uiMultipler: 1,
    uiTiers: 2,
    widthHeightTiers: 3,
} as const;

type EstimateFieldVariantPriceType = (typeof EstimateFieldVariantPriceType)[keyof typeof EstimateFieldVariantPriceType];
export default EstimateFieldVariantPriceType;
export type { EstimateFieldVariantPriceType };
