<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimteCostValues } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const paintMaterialTypeRecommended = computed(() => {
        return Array.isArray(props?.modelValues.paintMaterialType)
            ? props?.modelValues.paintMaterialType.map((x, i) => {
                  const feet = props?.modelValues.laborType.map((x) => x.quantity).reduce((a, b) => a + b, 0);

                  const galPerSqFtWall = props?.getRecommendedConversion(
                      1,
                      props?.model.paintMaterialType.field,
                      props?.modelValues.paintMaterialType[i].value
                  );
                  if (galPerSqFtWall === 0) return 0;
                  const recommended = Math.ceil(feet / galPerSqFtWall);
                  props?.setRecommendedItem(props?.model.paintMaterialType.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });

    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: 'laborType',
                title: 'Paint',
                setModelValue: (i) => `laborType[${i}].quantity`,
                quantity: (i) => props?.modelValues.laborType[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Painting Labor Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `laborType[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Square Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `laborType[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'additionalLaborType',
                title: 'Additional Labor Type',
                setModelValue: (i) => `additionalLaborType[${i}].quantity`,
                quantity: (i) => props?.modelValues.additionalLaborType[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Additional Labor Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `additionalLaborType[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Linear FT',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `additionalLaborType[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: 'paintExtras',
                title: 'Paint Extras',
                setModelValue: (i) => `paintExtras[${i}].quantity`,
                quantity: (i) => props?.modelValues.paintExtras[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Paint Extras',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `paintExtras[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `paintExtras[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: 'paintMaterialType',
                title: 'Paint Material Type',
                setModelValue: (i) => `paintMaterialType[${i}].quantity`,
                quantity: (i) => props?.modelValues.paintMaterialType[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Material Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `paintMaterialType[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `paintMaterialType[${i}].quantity`;
                                },
                                recommended(i) {
                                    return paintMaterialTypeRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
