import { getValueFromDto as getValue } from './_helper';
import EstimateSections from './EstimateSections';

interface EstimateSectionDto {
    id?: number;
    workflowId?: number;
    enabled?: boolean;
    description?: string;
    optional?: boolean;
    component?: () => any;
}

export default class EstimateSection {
    id!: number;
    workflowId!: number;
    enabled!: boolean;
    description!: string;
    optional!: boolean;

    constructor(dto: EstimateSectionDto) {
        this.setDynamicData(dto);
    }

    setDynamicData(dto: EstimateSectionDto) {
        this.id = getValue(dto, 'id', 'number', 0);
        this.workflowId = getValue(dto, 'workflowId', 'number', 0);
        this.enabled = getValue(dto, 'enabled', 'boolean', false);
        this.description = getValue(dto, 'description', 'string', '');
        this.optional = getValue(dto, 'optional', 'boolean', false);
    }
    get component() {
        return EstimateSections.getComponent(this.id);
    }
    get name() {
        return this.id === -1 ? this.description : EstimateSections.getName(this.id);
    }

    get order() {
        return EstimateSections.getOrder(this.id);
    }

    get secondaryWorkflowId() {
        return EstimateSections.getSecondaryWorkflowId(this.id);
    }
}
