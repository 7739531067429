import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "name"]

import { computed } from 'vue';
import { useField } from 'vee-validate';
import { createListeners } from '@/composables/validation';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputTextField',
  props: {
	name: {
		type: String,
		required: true,
	},
	customListeners: {
		type: Boolean,
		required: false,
		default: false,
	},
	valueTransform: {
		type: Function,
		required: false,
	},
},
  setup(__props) {

const props = __props;
const {
	value,
	errorMessage,
	handleChange
} = useField(computed(() => props.name), undefined, { validateOnValueUpdate: !props.customListeners });
const listeners = props.customListeners ? createListeners(value, errorMessage, handleChange, props.valueTransform) : {
	input: (e: { target: { value: string; }; }) => value.value = (typeof props.valueTransform === 'function' ? props.valueTransform : ((x: any) => x))(e.target.value)
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("input", _mergeProps({
    type: "text",
    value: _unref(value)
  }, _toHandlers(_unref(listeners), true), { name: __props.name }), null, 16, _hoisted_1))
}
}

})