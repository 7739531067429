<script setup>
    // <script setup lang = "ts" >

    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const jChannelRecommended = computed(() => {
        return Array.isArray(props?.modelValues.jChannel)
            ? props?.modelValues.jChannel.map((x, i) => {
                  const jChannelLength = x.children.jChannelLength.quantity;
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.jChannel.children.jChannelType.field,
                      x.children.jChannelType.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const recommended = Math.ceil(jChannelLength / feetPerUnit);
                  props?.setRecommendedItem(props?.model.jChannel.children.jChannelType.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });

    const fChannelRecommended = computed(() => {
        return Array.isArray(props?.modelValues.fChannel)
            ? props?.modelValues.fChannel.map((x, i) => {
                  const fChannelLength = x.children.fChannelLength.quantity;
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.fChannel.children.fChannelType.field,
                      x.children.fChannelType.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const recommended = Math.ceil(fChannelLength / feetPerUnit);
                  props?.setRecommendedItem(props?.model.fChannel.children.fChannelType.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });

    const utilityChannelRecommended = computed(() => {
        return Array.isArray(props?.modelValues.utilityChannel)
            ? props?.modelValues.utilityChannel.map((x, i) => {
                  const utilityChannelLength = x.children.utilityChannelLength.quantity;
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.utilityChannel.children.utilityChannelType.field,
                      x.children.utilityChannelType.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const recommended = Math.ceil(utilityChannelLength / feetPerUnit);
                  props?.setRecommendedItem(
                      props?.model.utilityChannel.children.utilityChannelType.field.id,
                      recommended,
                      i
                  );
                  return recommended;
              })
            : [];
    });

    const startersRecommended = computed(() => {
        return Array.isArray(props?.modelValues.starters)
            ? props?.modelValues.starters.map((x, i) => {
                  const startersLength = x.children.startersLength.quantity;
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.starters.children.startersType.field,
                      x.children.startersType.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const recommended = Math.ceil(startersLength / feetPerUnit);
                  props?.setRecommendedItem(props?.model.starters.children.startersType.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });

    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Siding Trim',
                name: 'jChannel',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.jChannel[i].quantity,
                setModelValue: (i) => `jChannel[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'J Channel Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `jChannel[${i}].children.jChannelLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `jChannel[${i}].children.jChannelType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `jChannel[${i}].children.jChannelType.quantity`;
                                },
                                recommended(i) {
                                    return jChannelRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'F channel (ft)',
                name: 'fChannel',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.fChannel[i].quantity,
                setModelValue: (i) => `fChannel[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'F channel',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `fChannel[${i}].children.fChannelLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `fChannel[${i}].children.fChannelType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `fChannel[${i}].children.fChannelType.quantity`;
                                },
                                recommended(i) {
                                    return fChannelRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                title: 'Utility Channel',
                name: 'utilityChannel',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.utilityChannel[i].quantity,
                setModelValue: (i) => `utilityChannel[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Utility channel Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `utilityChannel[${i}].children.utilityChannelLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `utilityChannel[${i}].children.utilityChannelType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `utilityChannel[${i}].children.utilityChannelType.quantity`;
                                },
                                recommended(i) {
                                    return utilityChannelRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                title: 'Starters Feet',
                name: 'starters',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.starters[i].quantity,
                setModelValue: (i) => `starters[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Starters',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `starters[${i}].children.startersLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `starters[${i}].children.startersType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `starters[${i}].children.startersType.quantity`;
                                },
                                recommended(i) {
                                    return startersRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                title: 'Mounting blocks',
                name: 'masterBlocks',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.masterBlocks[i].quantity,
                setModelValue: (i) => `masterBlocks[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Master blocks',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `masterBlocks[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `masterBlocks[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                title: 'Split Block',
                name: 'splitBlocks',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.splitBlocks[i].quantity,
                setModelValue: (i) => `splitBlocks[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Split blocks',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `splitBlocks[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `splitBlocks[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 7,
                title: 'Dryer Exhaust Block',
                name: 'dryerExhaustBlock',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.dryerExhaustBlock[i].quantity,
                setModelValue: (i) => `dryerExhaustBlock[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Dryer exhaust block',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `dryerExhaustBlock[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `dryerExhaustBlock[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 8,
                title: 'Mounting block color',
                name: 'on10xn',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Mounting block color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'mountingBlockColor.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>
<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
