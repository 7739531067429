<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKey } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: '',
                title: 'Roof Repairs',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Replace individual shingles',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'replaceShingles.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Replace shingles'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Replace Shingles underlayment',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'replaceShinglesUnderlayment.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Replace shingles + underlayment'),
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Replace Shingles Decking',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'replaceShinglesUnderlaymentDecking.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(
                                props?.fieldsEstimateItems,
                                'Replace shingles + underlayment + decking'
                            ),
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Replace Ridge Shingles',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'replaceRidge.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Replace ridge cap shingles'),
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: 'Replace Valley',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'replaceValley.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Replace valley'),
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: 'Replace Butterfly valley',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'replaceButterflyValley.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(
                                props?.fieldsEstimateItems,
                                'Replace butterfly valley (shingles + membrane)'
                            ),
                        ],
                    },
                    {
                        id: 7,
                        columns: [
                            {
                                id: 1,
                                label: 'Replace Dormer Intersection',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'replaceDormerIntersection.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(
                                props?.fieldsEstimateItems,
                                'Replace dormer intersection (shingles + ice barrier + flashing)'
                            ),
                        ],
                    },
                    {
                        id: 8,
                        columns: [
                            {
                                id: 1,
                                label: 'Replace roof to wall intersection',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'replaceRoofToWallIntersection.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(
                                props?.fieldsEstimateItems,
                                'Replace roof to wall intersection (shingles + underlayment + flashing)'
                            ),
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: '',
                title: 'Shingles',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Shingle type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'package.value';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Shingle type'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'shingleColor.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Squares',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                inputStyle: 'input',
                                name() {
                                    return 'package.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: '',
                title: 'Decking',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Decking',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'replaceDeckBoards.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Material',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'replaceDeckBoards.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Decking',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'decking.value';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Decking'),
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Sheets',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'decking.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: '',
                title: 'Underlayment',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Ice barrier',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'iceBarrier.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'iceBarrier.value';
                                },
                            },
                            // ...emptyFields,
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Ice barrier'),
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Underlayment',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'underlayment.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'underlayment.value';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Underlayment'),
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: 'Starters',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'starters.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'starters.value';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Starters'),
                        ],
                    },
                    {
                        id: 7,
                        columns: [
                            {
                                id: 1,
                                label: 'Hip ridge',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'hipRidge.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 8,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'hipRidge.value';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Hip & ridge'),
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
