/* eslint-disable no-unused-vars */
import { compare as jsonPatchCompare } from 'fast-json-patch';
import { DateTime } from 'luxon';

export function hasDtoProp(dto: any, property: string) {
    return (
        typeof dto === 'object' &&
        dto !== null &&
        Object.hasOwnProperty.call(dto, property) &&
        dto[property] !== undefined &&
        dto[property] !== null
    );
}

export function getArrayFromDto<T>(dto: any, property: string, defaultValue: T[] = []) {
    if (typeof dto === 'object' && dto !== null && Array.isArray(dto[property])) {
        return Array.from(dto[property]) as T[];
    } else {
        return defaultValue;
    }
}

export function getArrayOfObjectsFromDto<T>(
    dto: any,
    property: string,
    classRef: { new (arg: any): T },
    defaultValue: T[] = []
) {
    if (typeof dto === 'object' && dto !== null && Array.isArray(dto[property])) {
        const values = Array.from(dto[property]) as any[];
        for (let i = 0; i < values.length; i++) {
            values[i] = new classRef(values[i]);
        }
        return values;
    } else {
        return defaultValue;
    }
}

export function getObjectFromDto<T>(
    dto: any,
    property: string,
    classRef: { new (arg: any): T },
    defaultValue: T | null = null
) {
    if (typeof dto === 'object' && dto !== null && typeof dto[property] === 'object' && dto[property] != null) {
        return new classRef(dto[property]);
    } else {
        return defaultValue;
    }
}

export function getValueFromDto<T>(dto: any, property: string, type: string, defaultValue: T) {
    if (typeof dto === 'object' && dto !== null && typeof dto[property] === type) {
        return dto[property] as T;
    } else {
        return defaultValue;
    }
}

export function getDateFromDto(dto: any, property: string, defaultValue: string | null) {
    if (typeof dto === 'object' && dto !== null && property in dto) {
        const value = dto[property];
        if (typeof value === 'string' && value.indexOf('0001-01-01') < 0) {
            return value.substring(0, 10);
        } else {
            return defaultValue;
        }
    } else {
        return defaultValue;
    }
}

export function getDateTimeFromDto(dto: any, property: string, defaultValue: DateTime | null) {
    if (typeof dto === 'object' && dto !== null && property in dto) {
        const value = dto[property];
        if (value instanceof DateTime && value.isValid) {
            return value;
        }
        if (typeof value === 'string' && value.indexOf('0001-01-01') < 0) {
            return DateTime.fromISO(dto[property]);
        } else {
            return defaultValue;
        }
    } else {
        return defaultValue;
    }
}

export function isChanged(newModel: any, oldModel: any) {
    if (newModel === null || oldModel === null) return true;
    const oldData = JSON.parse(JSON.stringify(oldModel));
    const newData = JSON.parse(JSON.stringify(newModel));
    const patch = jsonPatchCompare(oldData, newData);
    return patch.length > 0;
}

export function round(numero: number, decimales: number) {
    const factor = Math.pow(10, decimales);
    return Math.round(numero * factor) / factor;
}
