import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pagination-contain"
}
const _hoisted_2 = {
  key: 0,
  class: "mr-third"
}
const _hoisted_3 = {
  key: 1,
  class: "btn-prev disabled"
}
const _hoisted_4 = ["value", "max"]
const _hoisted_5 = { class: "semi-bold" }
const _hoisted_6 = {
  key: 1,
  class: "ml-third"
}
const _hoisted_7 = {
  key: 1,
  class: "btn-next disabled"
}



export default /*@__PURE__*/_defineComponent({
  __name: 'PaginationControls',
  props: {
	model: {
		type: Object,
		required: true,
	},
	slidingRange: {
		type: Number,
		required: false,
		default: 4,
	},
	hideOnSinglePage: {
		type: Boolean,
		required: false,
		default: true,
	},
	hidePreviousNext: {
		type: Boolean,
		required: false,
		default: false,
	},
},
  emits: ['update:pageNumber'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

function onBeforeInput(event: InputEvent) {
	if (event && event.data && /[^\d]/g.test(event.data)) {
		event.preventDefault();
		event.stopPropagation();
	}
}
function onInputChange(event: Event) {
	const input = event.target as HTMLInputElement;
	if (!input) {
		return;
	}
	let value = input.value;
	if (value.length > 0) {
		const pattern = /[^\d]/g;
		if (pattern.test(value)) {
			const index = value.search(pattern);
			value = value.replace(pattern, '');
			input.value = value;
			input.setSelectionRange(index, index);
		}
	}
	if (value.length > 0) {
		updatePageNumber(parseInt(value, 10));
	}
}

function onInputBlur(event: Event) {
	const input = event.target as HTMLInputElement;
	if (!input) {
		return;
	}
	if (input.value.length === 0) {
		input.value = props.model.pageNumber.toString();
	}
}

function updatePageNumber(pageNumber: number) {
	emit('update:pageNumber', pageNumber);
}

return (_ctx: any,_cache: any) => {
  return (!__props.hideOnSinglePage || __props.model.pageCount > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!__props.hidePreviousNext)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (__props.model.hasPreviousPage)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn-prev",
                    type: "button",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (updatePageNumber(__props.model.pageNumber - 1)))
                  }))
                : (_openBlock(), _createElementBlock("span", _hoisted_3))
            ]))
          : _createCommentVNode("", true),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "semi-bold" }, "Page", -1)),
        _createElementVNode("input", {
          class: "mx-third text-center bold",
          ref: "input",
          type: "number",
          value: __props.model.pageNumber,
          required: "",
          min: "1",
          max: __props.model.pageCount,
          step: "1",
          style: _normalizeStyle({ width: __props.model.pageNumber.toString().length + 5 + 'ch' }),
          onBeforeinput: onBeforeInput,
          onInput: onInputChange,
          onBlur: onInputBlur
        }, null, 44, _hoisted_4),
        _createElementVNode("span", _hoisted_5, "of " + _toDisplayString(__props.model.pageCount.toLocaleString()), 1),
        (!__props.hidePreviousNext)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (__props.model.hasNextPage)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn-next",
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (updatePageNumber(__props.model.pageNumber + 1)))
                  }))
                : (_openBlock(), _createElementBlock("span", _hoisted_7))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})