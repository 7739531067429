import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "label-error"
}

import { toRef } from 'vue';
import { useField } from 'vee-validate';


export default /*@__PURE__*/_defineComponent({
  __name: 'FieldError',
  props: {
	name: {
		type: String,
		required: true,
	},
},
  setup(__props) {

const props = __props;
const { errorMessage } = useField(toRef(props, 'name'));

return (_ctx: any,_cache: any) => {
  return (_unref(errorMessage))
    ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_unref(errorMessage)), 1))
    : _createCommentVNode("", true)
}
}

})