import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { getFieldModel } from '@/composables/estimate';
    import { calculateOrganized, calculateTrimRecommended } from '@/helpers/estimator/siding/09_trim';
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKeyChild } from '../mocks';
    import { SectionProps } from '../roofing/types';

    type Color = {
        color: string;
        feet: number;
    };
    
export default /*@__PURE__*/_defineComponent({
  __name: 'Siding09Trim',
  props: {
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    },
  setup(__props) {

    const props = __props;

    const trimRecommended = computed(() => {
        return calculateTrimRecommended({
            items: props?.modelValues.trim,
            GET: props?.getRecommendedConversion,
            SET: props?.setRecommendedItem,
            modelField: props?.model.trim.children.trimSize.field,
            modelFieldId: props?.model.trim.children.trimSize.field.id,
        });
    });

    const $store = useStore();

    const fieldsRef = computed(() => $store.state.estimator.fields);

    const altFieldIds = ['2.49', '2.52', '2.55', '2.58', '2.651', '2.126'];
    const altModel = getFieldModel(fieldsRef.value, altFieldIds);

    const trimCoilFeetRecommended = computed((): { color: string; feet: number }[] => {
        const organized = Object.keys(altModel).reduce((acc, current) => {
            const colorField = props?.model?.[current].children[current + 'Color'].field;
            if (Array.isArray(props?.modelValues[current])) {
                const values = props?.modelValues[current]
                    .filter((x, i) => {
                        if (current === 'wallFlashing') {
                            const type = props?.modelValues[current][i].children[current + 'Type'].value || '';

                            if (type === '2.96.1') {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    })
                    .map((x, i) => {
                        let length = x.quantity;

                        if (current === 'wallFlashing') {
                            const quantity =
                                props?.modelValues[current][i].children[current + 'Quantity'].quantity || '';
                            length = quantity;
                        }

                        let rowsPerFoot = 0;

                        if (current !== 'fasciaWrap') {
                            rowsPerFoot =
                                props?.getRecommendedConversion(
                                    1,
                                    props?.model?.[current].children[current + 'Type'].field,
                                    props?.modelValues[current][i].children[current + 'Type'].value
                                ) || 1;
                        } else {
                            rowsPerFoot =
                                props?.getRecommendedConversion(
                                    1,
                                    props?.model?.[current].children[current + 'Width'],
                                    props?.modelValues?.[current][i].children[current + 'Width'].value
                                ) || 1;
                        }

                        const colorOptionId = x.children[current + 'Color'].value;
                        const color = (
                            colorField.options.find((x: { id: string }) => x.id === colorOptionId) ?? { value: '' }
                        ).value;
                        return { color: color, feet: length * rowsPerFoot };
                    });
                return [...acc, ...values];
            } else {
                const x = props?.modelValues?.[current];
                const length = x.quantity;
                const rowsPerFoot =
                    props?.getRecommendedConversion(
                        1,
                        props?.model?.[current].children[current + 'Type'].field,
                        props?.modelValues?.[current].children[current + 'Type'].value
                    ) || 1;
                const colorOptionId = x.children[current + 'Color'].value;
                const color = (colorField.options.find((x: { id: string }) => x.id === colorOptionId) ?? { value: '' })
                    .value;
                return [...acc, { color: color, feet: length * rowsPerFoot }];
            }
        }, [] as Color[]);

        return calculateOrganized(organized);
    });

    const formatTrimCoilFeetRecommended = computed(() => {
        const trimCoilFeetRecommendedValue = trimCoilFeetRecommended.value;
        if (Object.keys(trimCoilFeetRecommendedValue).length === 0) {
            return '';
        }
        return Object.values(trimCoilFeetRecommendedValue)
            .map((x) => `${x.feet} ft of ${x.color}`)
            .join(', ');
    });

    const trimCoilRecommended = computed(() => {
        const combined = trimCoilFeetRecommended.value;
        const colorField = props.model.trimCoil.children.trimCoilColor.field;
        return Array.isArray(props.modelValues.trimCoil)
            ? props.modelValues.trimCoil.map((x: { children: { trimCoilColor: { value: string } } }, i: number) => {
                  let recommended;
                  const feetPerUnit = props.getRecommendedConversion(
                      1,
                      props.model.trimCoil.children.trimCoilType.field,
                      props.modelValues.trimCoil[i].children.trimCoilType.value
                  );
                  if (feetPerUnit === 0) {
                      recommended = 0;
                      props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                      return recommended;
                  }

                  const optionId = x.children.trimCoilColor.value;
                  const option = colorField.options.find((x: { id: string }) => x.id === optionId);

                  if (option) {
                      const color = option.value.toLowerCase();
                      if (color in combined) {
                          recommended = Math.ceil(combined[color].feet / feetPerUnit);
                          props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                          return recommended;
                      }
                  }

                  recommended = 0;
                  props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });
    const listSections = computed((): SectionProps[] => {
        return [
            {
                id: 1,
                title: 'Siding Trim',
                name: 'trim',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.trim[i].children.trimSize.quantity,
                setModelValue: (i) => `trim[${i}].children.trimSize.quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Trim',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `trim[${i}].children.trimType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Size',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `trim[${i}].children.trimSize.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `trim[${i}].children.trimColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Length Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `trim[${i}].children.trimLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `trim[${i}].children.trimSize.quantity`;
                                },
                                recommended(i) {
                                    return trimRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 40,
                isFieldArray: false,
                name: '',
                quantity: () => 0,
                setModelValue: () => '',
                title: `Trim Coil Need: `,
                rows: [
                    {
                        id: 'test',
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended',
                            },
                            {
                                id: 2,
                                label: `${formatTrimCoilFeetRecommended.value}`,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Trim Coil',
                name: 'trimCoil',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.trimCoil[i].quantity,
                setModelValue: (i) => `trimCoil[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Trim coil',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `trimCoil[${i}].quantity`;
                                },
                                recommended(i) {
                                    return trimCoilRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `trimCoil[${i}].children.trimCoilColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `trimCoil[${i}].children.trimCoilType.value`;
                                },
                            },
                            // ...emptyFields,
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'TYPE'),
                        ],
                    },
                ],
            },
        ];
    });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ExcelFields, {
    modelValues: props?.modelValues,
    setModelValue: props?.setModelValue,
    sections: listSections.value,
    errors: props?.errors
  }, null, 8, ["modelValues", "setModelValue", "sections", "errors"]))
}
}

})