/* eslint-disable no-unused-vars */
type GetterFunction = (getN: number, fieldGet: string, fieldGetValue: string) => number;
type SetterFunction = (idSet: string, value: number) => void;

export const calculateVentilation = (
    FIELD_GET: string,
    FIELD_GET_VALUE: string,
    CEIL: number,
    ID_SET: string,
    GET: GetterFunction,
    SET: SetterFunction,
    GET_N: number = 1
) => {
    const conversion = GET(GET_N, FIELD_GET, FIELD_GET_VALUE);
    if (conversion === 0) {
        return 0;
    }
    const recommended = Math.ceil(CEIL / conversion);
    SET(ID_SET, recommended);
    return recommended;
};
