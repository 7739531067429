<!-- eslint-disable no-unused-vars -->
<script setup>
    import { roundUpToStepAndClamp } from '@/helpers';
    import {
        calculateDumpsterWeight,
        calculateMaxSquares,
        calculateNumbOfStories,
        calculateTearOffLayerField,
        calculateTotalSquares,
    } from '@/helpers/estimator/roofing/02_details';
    import { useField, useFieldArray } from 'vee-validate';
    import { computed, defineProps, inject, nextTick, onMounted, ref, watch } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import FieldExcelError from '../../atoms/FieldExcelError.vue';
    import { emptyFields, estimteCostValues } from '../mocks.ts';
    // import {
    //   SectionProps,
    //   SectionProps as SectionPropsExcel,
    //   TypeEstimate,
    // } from "./types";

    const totalSquaresForMaterialRef = ref(0);
    const totalSquaresForLaborRef = ref(0);

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    const totalSquares = computed(() => {
        const total = calculateTotalSquares(props?.modelValues.installPitch, props?.model.installPitch.field);

        return total;
    });

    const maxSquares = computed(() => {
        const totalMaxSquares = calculateMaxSquares(
            props?.modelValues.membrane[0].children.membraneArea.quantity,
            props?.modelValues.totalSquaresForMaterial.quantity
        );

        return totalMaxSquares;
    });

    const numberOfStories = computed(() => {
        return calculateNumbOfStories(props?.estimate.items);
    });
    const sumOfTearOffs = computed(() => {
        const tearOffLayerField = props?.model.tearOff.children.tearOffLayer.field;

        const totalTearfOff = calculateTearOffLayerField(
            props?.modelValues.tearOff,
            tearOffLayerField,
            props?.getRecommendedConversion
        );

        return totalTearfOff;
    });
    const dumpsterWeight = computed(() => {
        const totalDumpsterWeight = calculateDumpsterWeight(
            props?.modelValues.tearOff,
            props?.model.tearOff.children.tearOffLayer.field,
            props?.model.tearOff.children.tearOffMaterial.field,
            props?.getRecommendedConversion
        );

        return totalDumpsterWeight;
    });

    watch(
        props?.loading,
        (x) => {
            if (!x) {
                for (let i = numberOfStories.value + 1; i <= 4; i++) {
                    props?.setModelValue('squaresOnStory' + i + '.quantity', 0);
                }
            }
        },
        { immediate: true }
    );

    function updateTotalSquares() {
        const totalRoofArea = props?.modelValues.totalRoofArea.quantity;
        const membraneArea = props?.modelValues.membrane[0].children.membraneArea.quantity || 0;

        const wasteFactorForMaterial = props?.modelValues.wasteFactorForMaterial.quantity;
        const materialWasteFactor = 1 + wasteFactorForMaterial / 100;
        let totalSquaresForMaterial = roundUpToStepAndClamp(
            (materialWasteFactor * totalRoofArea) / 100,
            props?.model.totalSquaresForMaterial.field
        );

        if (membraneArea > 0) {
            totalSquaresForMaterial = totalSquaresForMaterial - membraneArea;
        }

        totalSquaresForMaterialRef.value = totalSquaresForMaterial + membraneArea;
        props?.setModelValue('totalSquaresForMaterial.membraneArea', membraneArea);

        if (totalSquaresForMaterial !== props?.modelValues.totalSquaresForMaterial.quantity) {
            props?.setModelValue('totalSquaresForMaterial.quantity', totalSquaresForMaterial);
        }

        const wasteFactorForLabor = roundUpToStepAndClamp(
            wasteFactorForMaterial,
            props?.model.wasteFactorForLabor.field
        );
        if (wasteFactorForLabor !== props?.modelValues.wasteFactorForLabor.quantity) {
            props?.setModelValue('wasteFactorForLabor.quantity', wasteFactorForLabor);
        }
        const laborWasteFactor = 1 + wasteFactorForLabor / 100;
        let totalSquaresForLabor = roundUpToStepAndClamp(
            (laborWasteFactor * totalRoofArea) / 100,
            props?.model.totalSquaresForLabor.field
        );

        if (membraneArea > 0) {
            totalSquaresForLabor = totalSquaresForLabor - membraneArea;
        }

        if (totalSquaresForLabor < membraneArea) {
            totalSquaresForLabor = totalSquaresForLabor + membraneArea;
        }

        totalSquaresForLaborRef.value = totalSquaresForLabor;

        if (totalSquaresForLabor !== props?.modelValues?.totalSquaresForLabor?.quantity) {
            props?.setModelValue('totalSquaresForLabor.quantity', totalSquaresForLabor);
        }
    }
    watch(
        [() => props?.modelValues.totalRoofArea.quantity, () => props?.modelValues.wasteFactorForMaterial.quantity],
        updateTotalSquares,
        { immediate: true }
    );

    onMounted(() => {
        updateTotalSquares();
    });

    const dumpsters = ref(null);
    const { setValue: setDumpsterLaborQuantity } = useField('dumpsterLabor.quantity');
    const { setValue: setDumpsterWeightQuantity } = useField('dumpsterWeight.quantity');

    const autoUpdateDumpsterLabor = computed(
        () => typeof sumOfTearOffs?.value === 'number' && !isNaN(sumOfTearOffs?.value)
    );

    async function updateDumpster() {
        if (!dumpsters.value) await nextTick();
        if (!dumpsters.value) return;
        if (props.modelValues.dumpsterNeeded.value) {
            if (dumpsters.value.items.length === 0) {
                dumpsters.value.addItem();
            }
        } else {
            // eslint-disable-next-line for-direction
            for (let i = dumpsters.value.items.length - 1; i >= 0; i--) {
                dumpsters.value.removeItem(i);
            }
        }
        updateDumpsterLabor();
        updateDumpsterWeight();
    }

    async function updateDumpsterLabor() {
        if (props.modelValues.dumpsterNeeded.value && props.modelValues.dumpsterLabor.value) {
            if (autoUpdateDumpsterLabor.value) {
                const field = props.model.dumpsterLabor.field;
                let value = sumOfTearOffs?.value ?? 0;
                setDumpsterLaborQuantity(roundUpToStepAndClamp(value, field));
            }
        } else {
            setDumpsterLaborQuantity(0);
        }
    }

    async function updateDumpsterWeight() {
        const field = props.model.dumpsterWeight.field;
        let value = props.modelValues.dumpsterNeeded.value ? dumpsterWeight?.value : 0;
        setDumpsterWeightQuantity(roundUpToStepAndClamp(value, field));
    }

    watch(() => props.modelValues.dumpsterNeeded.value, updateDumpster, {
        flush: 'post',
        immediate: true,
    });
    watch(() => sumOfTearOffs?.value, updateDumpsterLabor, { immediate: true });
    watch(() => dumpsterWeight?.value, updateDumpsterWeight, { immediate: true });

    const sections = computed(() => {
        return [
            {
                id: 5,
                name: '',
                title: `Structure height`,
                hideAction: true,
                NotfieldArray: true,
                setModelValue: (i) => '',
                quantity: (i) => 0,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: '1st Story',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'squaresOnStory1.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: '2nd story',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                disabled: numberOfStories.value < 2,
                                name: () => {
                                    return 'squaresOnStory2.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: '3rd story',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                disabled: numberOfStories.value < 3,
                                name: () => {
                                    return 'squaresOnStory3.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: '3rd story',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                disabled: numberOfStories.value < 4,
                                name: () => {
                                    return 'squaresOnStory3.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },

            {
                id: 1,
                name: 'tearOff',
                title: 'Roofing detail - Tear off',
                setModelValue: (i) => `tearOff[${i}].quantity`,
                quantity: (i) => props?.modelValues.tearOff[i].quantity,

                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Tear Off Squares',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `tearOff[${i}].quantity`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Layer',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `tearOff[${i}].children.tearOffLayer.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Pitch',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `tearOff[${i}].children.tearOffPitch.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Material',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `tearOff[${i}].children.tearOffMaterial.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: 'mdtype',
                hideAction: true,
                NotfieldArray: true,
                title: 'Material Type',
                setModelValue: (i) => `tearOff[${i}].quantity`,
                quantity: (i) => props?.modelValues.tearOff[i].quantity,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => {
                                    return `materialType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'tearOff',
                hideAction: true,
                NotfieldArray: true,
                title: 'Install details & Waste calculations',
                setModelValue: (i) => `tearOff[${i}].quantity`,
                quantity: (i) => props?.modelValues.tearOff[i].quantity,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Total roof area',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'totalRoofArea.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Waste factor',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'wasteFactorForMaterial.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Material total',
                            },
                            {
                                id: 2,
                                type: 'VModel_Material',
                                id_input: 'field_1_totalSquaresForMaterial_quantity',
                                name: () => {
                                    return 'totalSquaresForMaterial.quantity';
                                },
                                value: totalSquaresForMaterialRef.value,
                                inputStyle: 'input',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Labor Total',
                            },
                            {
                                id: 2,
                                type: 'VModel_Labor',
                                id_input: 'field_1_totalSquaresForLabor_quantity',
                                name: () => {
                                    return 'totalSquaresForLabor.quantity';
                                },
                                value: totalSquaresForLaborRef.value,
                                inputStyle: 'input',
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });

    const listExcelFields = computed(() => {
        return [
            {
                id: 1,
                isFieldArray: false,
                title: 'Dumpster',
                name: 'Dumpster',
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Dumpster ',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name() {
                                    return 'dumpsterNeeded.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                isFieldArray: true,
                title: 'Dumpster Location',
                name: 'dumpster',
                refString: 'dumpsters',
                quantity: () => 0,
                setModelValue: () => '',
                showSection: props?.modelValues.dumpsterNeeded.value,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Location ',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `dumpster[${i}].children.dumpsterLocation.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `dumpster[${i}].children.dumpsterType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                isFieldArray: false,
                title: 'Dumpster labor',
                name: 'dumpster',
                refString: 'dumpsters',
                quantity: () => 0,
                setModelValue: () => '',
                showSection: props?.modelValues.dumpsterNeeded.value,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Labor',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'dumpsterLabor.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return 'dumpsterLabor.quantity';
                                },
                                disabled: autoUpdateDumpsterLabor.value,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Dumpster weight',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'dumpsterWeight.quantity';
                                },
                                disabled: true,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
    const getNewFieldArrayItem = inject('getNewFieldArrayItem');

    const NameFieldArray = ref('installPitch');

    const { remove: removeItem, push: pushItem, insert: insertItem, fields: items } = useFieldArray(NameFieldArray);

    const handleFillPitchDetails = () => {
        const arr = props?.modelValues?.installPitch;
        const model = props?.model?.installPitch?.field?.options;

        if (arr.length === model.length) {
            return;
        }
        const needed = model?.length - arr?.length;

        for (let index = 0; index < needed; index++) {
            const payload = getNewFieldArrayItem('installPitch');

            pushItem(payload);

            setTimeout(() => {
                const findIndex = props?.modelValues?.installPitch.findIndex((e) => e?.id === payload?.id);

                props?.setModelValue(`installPitch[${findIndex}].value`, model[findIndex]?.id);
            }, index * 100);
        }

        return arr;
    };

    watch(
        () => props?.model?.installPitch?.field?.options,
        () => {
            handleFillPitchDetails();

            // for (const iterator of props?.model?.installPitch?.field?.options ?? []) {

            //     pushItem(getNewFieldArrayItem('installPitch'));
            // }
        },
        {
            deep: true,
            immediate: true,
        }
    );
</script>

<template>
    <template v-for="section in sections" :key="`section-${section}-${section.id}`">
        <field-array
            v-if="!section.NotfieldArray"
            :name="section.name"
            v-slot="{ items, removeItem, addItemAfter, addItem }"
            :ref="section?.refString"
        >
            <template v-for="(item, i) in items" :key="item.key">
                <section class="quick_estimate_row_table">
                    <article class="quick_estimate_row_table_item">
                        <p style="font-size: 14px">{{ section.title }}</p>
                    </article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>
                    <article class="quick_estimate_row_table_item"></article>

                    <article class="quick_estimate_row_table_item">
                        <div v-if="!section.hideAction" style="display: flex; flex-direction: row; gap: 12px">
                            <button
                                class="btn-link btn-add"
                                v-if="items.length < 1"
                                type="button"
                                @click="addItem"
                            ></button>
                            <button class="btn-link" type="button" @click="addItemAfter(i)">
                                <svg width="24px" height="24px" class="icon fill-dark stroke-green">
                                    <use xlink:href="#add"></use>
                                </svg>
                            </button>
                            <button class="btn-link" type="button" v-if="items.length > 1" @click="removeItem(i)">
                                <svg width="24px" height="24px" class="icon fill-red stroke-red">
                                    <use xlink:href="#remove"></use>
                                </svg>
                            </button>
                        </div>
                    </article>
                </section>
                <template v-for="row in section.rows" :key="`section-${section}-${section.id}-${row.id}`">
                    <section class="quick_estimate_row_table">
                        <template
                            v-for="(col, index) in row.columns"
                            :key="`section-${section}-${section.id}-${row.id}-${col.id}`"
                        >
                            <article
                                :class="index === 0 ? 'quick_estimate_table_item_sub' : 'quick_estimate_table_item'"
                            >
                                <div v-if="col?.label">
                                    <p style="font-size: 14px">{{ col?.label }}</p>
                                </div>
                                <div v-if="col?.type === 'Cost'">
                                    <p style="font-size: 14px">
                                        {{ col?.estimateField?.(i, item.value) ?? '' }}
                                    </p>
                                </div>
                                <div v-if="col?.type === 'Quantity'">
                                    <FieldExcelError
                                        :name="col.name?.(i)"
                                        :custom_class="'input_field_error'"
                                        :custom-message="'*'"
                                        :errors="props?.errors"
                                    />
                                    <field-input-quantity
                                        :name="col.name?.(i)"
                                        :forceStyle="col?.inputStyle"
                                        :disabled="col?.disabled"
                                        :recommended="col.recommended?.(i)"
                                        :custom_class="'input_number_excel'"
                                        :recommendedClass="'input_quantity_recommended'"
                                        :autoSetRecommend="true"
                                    />

                                    <!-- <field-error
                                        :name="col?.error?.(i) ?? col.name?.(i)"
                                        :recommended="col.recommended?.(i)"
                                        :modelValuesProps="modelValues"
                                        :custom_class="'input_field_error'"
                                    /> -->
                                </div>
                                <div v-if="col?.type === 'Checkbox'">
                                    <FieldExcelError
                                        :name="col.name?.(i)"
                                        :custom_class="'input_field_error'"
                                        :custom-message="'*'"
                                        :errors="props?.errors"
                                    />
                                    <div class="check-radio-wrap flex-center-y">
                                        <field-input-checkbox :name="col.name?.(i)" />
                                    </div>
                                    <!-- <field-error :name="col.name?.(i)" :custom_class="'input_field_error'" /> -->
                                </div>
                                <div v-if="col?.type === 'Dropdown'">
                                    <FieldExcelError
                                        :name="col.name?.(i)"
                                        :custom_class="'input_field_error'"
                                        :custom-message="'*'"
                                        :errors="props?.errors"
                                    />
                                    <field-input-dropdown
                                        :name="col.name?.(i)"
                                        :recommended="col.recommended?.(i)"
                                        :modelValuesProps="modelValues"
                                        :custom_class="'input_dropdown_excel'"
                                    />
                                    <!-- <field-error
                                        :name="col.name?.(i)"
                                        :recommended="col.recommended?.(i)"
                                        :modelValuesProps="modelValues"
                                        :custom_class="'input_field_error'"
                                    /> -->
                                </div>
                            </article>
                        </template>
                    </section>
                </template>
            </template>
        </field-array>
        <template v-else>
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_row_table_item">
                    <p style="font-size: 12px">{{ section.title }}</p>
                </article>

                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
            </section>

            <template v-for="row in section.rows" :key="`section-${section}-${section.id}-${row.id}`">
                <section class="quick_estimate_row_table">
                    <template
                        v-for="(col, index) in row.columns"
                        :key="`section-${section}-${section.id}-${row.id}-${col.id}-details-roofing`"
                    >
                        <article :class="index === 0 ? 'quick_estimate_table_item_sub' : 'quick_estimate_table_item'">
                            <div v-if="col?.label">
                                <p style="font-size: 12px">{{ col?.label }}</p>
                            </div>
                            <div v-if="col?.type === 'Cost'">
                                <p style="font-size: 12px">
                                    {{ col?.estimateField?.(null, null) ?? '' }}
                                </p>
                            </div>
                            <div v-if="col?.type === 'Quantity'">
                                <FieldExcelError
                                    :name="col.name?.()"
                                    :custom_class="'input_field_error'"
                                    :custom-message="'*'"
                                    :errors="props?.errors"
                                />
                                <field-input-quantity
                                    :name="col.name?.()"
                                    :forceStyle="'input'"
                                    :disabled="col?.disabled"
                                    :recommended="col.recommended?.()"
                                    :custom_class="'input_number_excel'"
                                    :recommendedClass="'input_quantity_recommended'"
                                    :autoSetRecommend="true"
                                />
                                <!-- <field-error
                                    :name="col?.error?.() ?? col.name?.()"
                                    :recommended="col.recommended?.()"
                                    :modelValuesProps="modelValues"
                                    :custom_class="'input_field_error'"
                                /> -->
                            </div>
                            <div v-if="col?.type === 'VModel_Material'">
                                <FieldExcelError
                                    :name="col.name?.()"
                                    :custom_class="'input_field_error'"
                                    :custom-message="'*'"
                                    :errors="props?.errors"
                                />
                                <input-number
                                    :id="col?.id_input"
                                    v-model="totalSquaresForMaterialRef"
                                    readonly
                                    custom_class="input_number_excel"
                                />

                                <!-- <field-error
                                    :name="col.name?.()"
                                    :recommended="col.recommended?.()"
                                    :modelValuesProps="modelValues"
                                    :custom_class="'input_field_error'"
                                /> -->
                            </div>
                            <div v-if="col?.type === 'VModel_Labor'">
                                <FieldExcelError
                                    :name="col.name?.()"
                                    :custom_class="'input_field_error'"
                                    :custom-message="'*'"
                                    :errors="props?.errors"
                                />
                                <input-number
                                    :id="col?.id_input"
                                    v-model="totalSquaresForLaborRef"
                                    readonly
                                    custom_class="input_number_excel"
                                />

                                <!-- <field-error
                                    :name="col.name?.()"
                                    :recommended="col.recommended?.()"
                                    :modelValuesProps="modelValues"
                                    :custom_class="'input_field_error'"
                                /> -->
                            </div>

                            <div v-if="col?.type === 'Dropdown'">
                                <FieldExcelError
                                    :name="col.name?.()"
                                    :custom_class="'input_field_error'"
                                    :custom-message="'*'"
                                    :errors="props?.errors"
                                />
                                <field-input-dropdown
                                    :name="col.name?.()"
                                    :recommended="col.recommended?.()"
                                    :modelValuesProps="modelValues"
                                    :custom_class="'input_dropdown_excel'"
                                />
                                <!-- <field-error
                                    :name="col.name?.()"
                                    :recommended="col.recommended?.()"
                                    :modelValuesProps="modelValues"
                                    :custom_class="'input_field_error'"
                                /> -->
                            </div>
                        </article>
                    </template>
                </section>
            </template>
        </template>
    </template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listExcelFields"
        :errors="props?.errors"
    />

    <field-array name="installPitch" v-slot="{ items, removeItem, addItem }" ref="installPitchRef">
        <section class="quick_estimate_row_table">
            <article class="quick_estimate_row_table_item">
                <p style="font-size: 12px; margin: 0">Pitch Details</p>
            </article>
            <article class="quick_estimate_row_table_item">
                <FieldExcelError
                    :name="`installPitch`"
                    :custom_class="'input_field_error'"
                    :custom-message="'*'"
                    :errors="props?.errors"
                />
                <p style="font-size: 12px; margin: 0">
                    {{ `Total squares: ${totalSquares} of ${maxSquares}` }}
                </p>
            </article>
            <article class="quick_estimate_row_table_item"></article>
            <article class="quick_estimate_row_table_item"></article>
            <article class="quick_estimate_row_table_item"></article>

            <article class="quick_estimate_row_table_item">
                <button class="btn-link btn-add" type="button" @click="addItem"></button>
            </article>
        </section>
        <template v-for="(item, i) in items" :key="item.key">
            <!-- <section class="quick_estimate_row_table">
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>

                <article class="quick_estimate_row_table_item" style="gap: 8px; display: flex; flex-direction: row">
                    <button class="btn-link" type="button" @click="addItemAfter(i)">
                        <svg width="24px" height="24px" class="icon fill-dark stroke-green">
                            <use xlink:href="#add"></use>
                        </svg>
                    </button>
                    <button class="btn-link" type="button" v-if="items.length > 1" @click="removeItem(i)">
                        <svg width="24px" height="24px" class="icon fill-red stroke-red">
                            <use xlink:href="#remove"></use>
                        </svg>
                    </button>
                </article>
            </section> -->
            <!-- <section class="quick_estimate_row_table">
                <article class="quick_estimate_table_item_sub">
                    <field-label description="squares" :name="`installPitch[${i}].quantity`" />
                </article>
                <article class="quick_estimate_row_table_item">
                    <FieldExcelError
                        :name="`installPitch[${i}].quantity`"
                        :custom_class="'input_field_error'"
                        :custom-message="'*'"
                        :errors="props?.errors"
                    />
                    <field-input-quantity
                        :name="`installPitch[${i}].quantity`"
                        :custom_class="'input_number_excel'"
                        :custom_select_class="'input_quantity_select_excel'"
                        :recommendedClass="'input_quantity_recommended'"
                        :autoSetRecommend="true"
                    />
                </article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>

                <article class="quick_estimate_row_table_item"></article>
            </section> -->
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_table_item_sub">
                    <!-- <field-label description="pitch" :name="`installPitch[${i}].value`" /> -->
                    <!-- kek -->
                    <field-input-dropdown
                        :name="`installPitch[${i}].value`"
                        :custom_class="'input_dropdown_excel'"
                        display="dropdown"
                    />
                </article>
                <article class="quick_estimate_row_table_item">
                    <!-- <FieldExcelError
                        :name="`installPitch[${i}].value`"
                        :custom_class="'input_field_error'"
                        :custom-message="'*'"
                    />
                    <field-input-dropdown
                        :name="`installPitch[${i}].value`"
                        :custom_class="'input_dropdown_excel'"
                        display="dropdown"
                    /> -->

                    <field-input-quantity
                        :name="`installPitch[${i}].quantity`"
                        :custom_class="'input_number_excel'"
                        :custom_select_class="'input_quantity_select_excel'"
                        :recommendedClass="'input_quantity_recommended'"
                        :autoSetRecommend="true"
                    />
                </article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>

                <article class="quick_estimate_row_table_item">
                    <button class="btn-link" type="button" v-if="items.length > 1" @click="removeItem(i)">
                        <svg width="24px" height="24px" class="icon fill-red stroke-red">
                            <use xlink:href="#remove"></use>
                        </svg>
                    </button>
                </article>
            </section>
        </template>
    </field-array>
</template>
