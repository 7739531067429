<template>
    <div class="thumbnail-group">
        <div
            class="package-thumbnail has-img has-img-multiple"
            :class="{ 'constrain-img': constrain, highlight: dragging }"
        >
            <img v-if="value.url" :src="isLocalUrl(value?.data) ? value.url : `${APP_URL_ORIGIN}${value.url}`" />
            <img v-else src="@/img/unknown-image.png" />
        </div>
        <div class="mt-half">
            <label class="label-error mt-third mb-1" v-show="error">{{ error }}</label>
            <button class="btn-link btn-link-delete" type="button" @click="removeItem(index)">Remove</button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { toRef, defineProps } from 'vue';
import { useField } from 'vee-validate';
import { APP_URL_ORIGIN } from '@/api/_helpers';

interface ValueType {
    data?: File
    url?: string;
}

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
    removeItem: {
        type: Function,
        required: true,
    },
    constrain: {
        type: Boolean,
        required: false,
        default: false,
    },
    dragging: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const isLocalUrl = (data?: File): boolean => !!data && data instanceof File;
const { value, errorMessage: error } = useField<ValueType>(toRef(props, 'name'), undefined, {
    validateOnMount: true,
});
</script>