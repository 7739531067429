interface UserDto {
    id?: number;
    externalId?: string;
    folderId?: string;
    email?: string;
    phoneNumber?: string;
    emailConfirmed?: boolean;
    firstName?: string;
    lastName?: string;
    active?: boolean;
    isSuperAdmin?: boolean;
    isMasterAdmin?: boolean;
    role?: string;
    companyId?: number;
}

export default class User {
    id: number;
    externalId: string | null;
    folderId: string | null;
    email: string;
    phoneNumber: string;
    emailConfirmed: boolean;
    firstName: string;
    lastName: string;
    active: boolean;
    isSuperAdmin: boolean;
    isMasterAdmin: boolean;
    role: string;
    companyId: number | null;

    constructor(dto: Partial<UserDto> = {}) {
        this.id = dto.id ?? 0;
        this.externalId = dto.externalId ?? null;
        this.folderId = dto.folderId ?? null;
        this.email = dto.email ?? '';
        this.phoneNumber = dto.phoneNumber ?? '';
        this.emailConfirmed = dto.emailConfirmed ?? false;
        this.firstName = dto.firstName ?? '';
        this.lastName = dto.lastName ?? '';
        this.active = dto.active ?? false;
        this.isSuperAdmin = dto.isSuperAdmin ?? false;
        this.isMasterAdmin = dto.isMasterAdmin ?? false;
        this.role = dto.role ?? '';
        this.companyId = dto.companyId ?? null;
    }

    get name() {
        return (this.firstName + ' ' + this.lastName).trim();
    }

    get initials() {
        const fi = this.firstName ? this.firstName.substr(0, 1) : '?';
        const li = this.lastName ? this.lastName.substr(0, 1) : '?';
        return (fi + li).toUpperCase();
    }

    get firstNameLastInitial() {
        const li = this.lastName ? this.lastName.substr(0, 1) : '?';
        return this.firstName + ' ' + li.toUpperCase() + '.';
    }
}
