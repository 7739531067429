<template>
	<input type="text" :value="value" v-on="listeners" :name="name" />
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { useField } from 'vee-validate';
import { createListeners } from '@/composables/validation';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	customListeners: {
		type: Boolean,
		required: false,
		default: false,
	},
	valueTransform: {
		type: Function,
		required: false,
	},
});
const {
	value,
	errorMessage,
	handleChange
} = useField(computed(() => props.name), undefined, { validateOnValueUpdate: !props.customListeners });
const listeners = props.customListeners ? createListeners(value, errorMessage, handleChange, props.valueTransform) : {
	input: (e: { target: { value: string; }; }) => value.value = (typeof props.valueTransform === 'function' ? props.valueTransform : ((x: any) => x))(e.target.value)
};
</script>
