import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"
import _imports_0 from '@/img/icons/app-icon.png'


const _hoisted_1 = {
  key: 0,
  class: "pwa-install-banner"
}
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "answer-buttons" }

import { computed } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallPrompt',
  setup(__props) {

const $store = useStore();
const open = computed(() => $store.state.showInstallBanner && !$store.state.serviceWorkerUpdate);

function closeBanner() {
	$store.commit('setCustomPwaBanner', false);
	localStorage.setItem('pwaUserChoice', 'no');
}
function showPwaInstall() {
	$store.commit('setCustomPwaBanner', false);
	$store.state.installPrompt.prompt();

	$store.state.installPrompt.userChoice.then((choiceResult: { outcome: string; }) => {
		if (choiceResult.outcome === 'accepted') {
			$store.commit('setPwaInstallButton', false);
		} else {
			localStorage.setItem('pwaUserChoice', 'no');
		}
	});
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "pwa-toggle",
      appear: ""
    }, {
      default: _withCtx(() => [
        (open.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "icon-prompt-text" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  }),
                  _createElementVNode("div", { class: "ml-1" }, "Install IHS Home Builder to your home screen?")
                ], -1)),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (showPwaInstall())),
                    class: "btn btn-fill-app mb-quarter no-min"
                  }, "Yes"),
                  _createElementVNode("button", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (closeBanner())),
                    class: "btn-link ml-1-half no-min"
                  }, "No")
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})