<script setup>
    // <script setup lang = "ts" >

    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";
    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Shutters',
                name: 'shutters',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.shutters[i].quantity,
                setModelValue: (i) => `shutters[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Shutter',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `shutters[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `shutters[${i}].children.shutterType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `shutters[${i}].children.shutterColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Install type',
                name: 'djn1mz',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Install type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'shutterInstallType.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>
<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
