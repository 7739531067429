export const calculateCaulkRmSiding = (props) => {
  const { items, GET, SET, field, trimItems, quantity, fieldId } = props;
  if (!Array.isArray(items)) {
    return [];
  }
  return items.map((x, i) => {
    const value = items[i].value;
    const feetPerUnit = GET(1, field, value);
    const sqPerUnit = GET(2, field, value);
    if (feetPerUnit === 0) return 0;
    if (sqPerUnit === 0) return 0;

    const trimLength = trimItems
      .map((x) => x.children.trimLength.quantity)
      .reduce((a, b) => a + b, 0);

    const recommended =
      Math.ceil(trimLength / feetPerUnit) +
      Math.ceil(quantity / sqPerUnit) * 12;
    SET(fieldId, recommended, i);
    return recommended;
  });
};
