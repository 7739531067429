import { clearDatabase, getDb } from '@/idbInit';

let _companyId: number | null = null;
const broadcast = new BroadcastChannel('sync');

broadcast.addEventListener('message', (event: MessageEvent) => {
    if (typeof event.data === 'object' && event.data !== null) {
        if (event.data.key === 'companyId') {
            companyId.setFromIdb();
        }
    }
});

const companyId = {
    /**
     * Get the companyId
     */
    get() {
        return _companyId;
    },
    /**
     * Set the companyId
     * @param value the new company ID
     */
    set(value: number | null) {
        if (typeof value === 'number' && value > 0) {
            _companyId = value;
        } else {
            _companyId = null;
        }
    },
    async setFromIdb() {
        const idb = await getDb();
        const id: number | null = (await idb.get('info', 'companyId')) ?? null;
        this.set(id);
    },
    async writeToIdbAndBroadcast() {
        await clearDatabase(true);
        const idb = await getDb();
        if (_companyId && _companyId > 0) {
            await idb.put('info', _companyId, 'companyId');
        } else {
            await idb.delete('info', 'companyId');
        }
        broadcast.postMessage({ key: 'companyId' });
    },
    broadcastChannel: broadcast,
};

export default companyId;
