import { DateTime } from 'luxon';

interface FolderLogDTO {
    folderType?: string;
    folderId?: string;
    projectType?: string;
    projectTypes?: string;
    isMappingCorrect?: boolean;
    details?: string;
    isFixed?: boolean;
    createdAt?: string | null;
}

export default class FolderLog {
    folderType: string;
    folderId: string;
    projectType: string;
    projectTypes: string;
    isMappingCorrect: boolean;
    details: string;
    isFixed: boolean;
    createdAt: DateTime | null;

    constructor(dto: Partial<FolderLogDTO> = {}) {
        this.folderType = dto.folderType ?? '';
        this.folderId = dto.folderId ?? '';
        this.projectType = dto.projectType ?? '';
        this.projectTypes = dto.projectTypes ?? '';
        this.isMappingCorrect = dto.isMappingCorrect ?? false;
        this.details = dto.details ?? '';
        this.isFixed = dto.isFixed ?? false;
        this.createdAt = dto.createdAt ? DateTime.fromISO(dto.createdAt) : null;
    }
}
