import { parseBoolProp, parseIntProp } from '../_helpers';

export default {
    path: '/account',
    component: () => import('@/views/account/_AccountApp.vue'),
    meta: { blockOffline: true, appName: 'account' },
    children: [
        {
            path: 'forgot-password',
            component: () => import('@/views/account/ForgotPasswordPage.vue'),
            meta: { title: 'Forgot Password', showAppVer: true },
        },
        {
            path: 'login',
            component: () => import('@/views/account/LoginPage.vue'),
            props: (route: { query: { referrer: string } }) => ({ referrer: route.query.referrer }),
            meta: { title: 'Login', requiresAnonymous: true, showAppVer: true },
        },
        {
            path: 'manage',
            component: () => import('@/views/account/ManagePage.vue'),
            meta: { title: 'Manage Account', requiresAuth: true },
        },
        {
            path: 'companies',
            component: () => import('@/views/account/CompaniesPage.vue'),
            props: (route: { query: { select: string } }) => ({ select: parseBoolProp(route.query.select) }),
            meta: { title: 'Companies', requiresAuth: true },
        },
        {
            path: 'reset-password',
            component: () => import('@/views/account/ResetPasswordPage.vue'),
            props: (route: { query: { code: string } }) => ({ code: route.query.code }),
            meta: { title: 'Reset Password', showAppVer: true },
        },
        {
            path: 'setup',
            component: () => import('@/views/account/SetupPage.vue'),
            props: (route: { query: { code: string; userId: string } }) => ({
                code: route.query.code,
                userId: parseIntProp(route.query.userId),
            }),
            meta: { title: 'Setup Account', showAppVer: true },
        },
    ],
};
