import { useAccountStore } from '@/store/modules/token';
import axios from 'axios';

import { storeToRefs } from 'pinia';
const baseURL = process.env.VUE_APP_API_BASE_URL;

const axiosInstance = axios.create({ baseURL: baseURL });

axiosInstance.interceptors.request.use(
    async (config) => {
        const accountStore = useAccountStore();
        const { accessToken } = storeToRefs(accountStore);

        if (config && config.headers) config.headers.Authorization = `Bearer ${accessToken.value}`;

        return config;
    },
    (err) => Promise.reject(err)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            const accountStore = useAccountStore();
            accountStore.$reset();
            // window.location.href = '/account/logout';
            return;
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
