import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "no-select"
}
const _hoisted_4 = { class: "admin-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: ["admin-nav-contain", { expanded: $setup.isOpen }],
    ref: "adminNavWidget"
  }, _toHandlers($setup.focusListeners, true)), [
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.isOpen = !$setup.isOpen))
    }, [
      (_ctx.$route.matched[0].path === '/config')
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Config"))
        : (_ctx.$route.matched[0].path === '/estimator')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Sales"))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, "Select"))
    ]),
    _createElementVNode("nav", _hoisted_4, [
      ($setup.isUser)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "/estimator"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Sales")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($setup.isAdmin)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: "/config"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Config")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($setup.isMasterAdmin)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 2,
            class: "flex-center-y",
            to: "/master/folder-log"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Folder Log")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($setup.isSuperAdmin)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 3,
            class: "flex-center-y",
            to: "/admin"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Admin "),
              _createElementVNode("svg", {
                class: "icon stroke-white ml-half",
                width: "14px",
                height: "14px"
              }, [
                _createElementVNode("use", { "xlink:href": "#leave" })
              ], -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ], 16))
}