import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { round } from '@/helpers';
    import {
        calculalteHeadFlashing,
        calculateChimneyCaulk,
        calculateCounterCaulk,
        calculateCounterFlashing,
    } from '@/helpers/estimator/roofing/11_extras';
    import { computed, onMounted, watch } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimteCostValues } from '../mocks';
    import { SectionProps } from './types';

    type FlashingColor = { value: string; quantity: number };

    
export default /*@__PURE__*/_defineComponent({
  __name: 'ExtrasComponent',
  props: {
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    },
  setup(__props) {

    const props = __props;

    const headFlashing = computed(() => {
        const colors = props?.model?.headFlashing.children.headFlashingColor.field.options;
        return calculalteHeadFlashing(colors, props.modelValues.headFlashing);
    });
    const counterFlashing = computed(() => {
        const colors = props?.model?.counterFlashing.children.counterFlashingColor.field.options;

        return calculateCounterFlashing(colors, props.modelValues.counterFlashing);
    });

    const chimneyFlashing = computed(() => {
        const colors = props?.model.chimneyFlashingColor.field.options;
        return (
            props?.modelValues?.chimneyFlashingColor?.map?.((item: FlashingColor) => {
                const color = (colors.find((x: { id: string }) => x.id === item.value) ?? {}).value || '';
                return { color, feet: item.quantity };
            }) ?? []
        );
    });

    const trimCoilFeetRecommended = computed((): { color: string; feet: number }[] => {
        return [
            ...chimneyFlashing.value,
            ...headFlashing.value,
            ...counterFlashing.value,
            // ...fasciaWrap.value
        ].reduce((obj, x) => {
            if (x.feet > 0) {
                const key = x.color.toLowerCase();
                const item = key in obj ? obj[key] : (obj[key] = { color: x.color, feet: 0 });
                item.feet += x.feet;
            }
            return obj;
        }, {});
    });

    const trimCoilRecommended = computed(() => {
        const combined = trimCoilFeetRecommended.value;
        const colorField = props.model.trimCoil.children.trimCoilColor.field;
        return Array.isArray(props.modelValues.trimCoil)
            ? props.modelValues.trimCoil.map((x: { children: { trimCoilColor: { value: string } } }, i: number) => {
                  let recommended;
                  const feetPerUnit = props.getRecommendedConversion(
                      1,
                      props.model.trimCoil.children.trimCoilType.field,
                      props.modelValues.trimCoil[i].children.trimCoilType.value
                  );
                  if (feetPerUnit === 0) {
                      recommended = 0;
                      props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                      return recommended;
                  }

                  const optionId = x.children.trimCoilColor.value;
                  const option = colorField.options.find((x: { id: string }) => x.id === optionId);
                  if (option) {
                      const color = option.value.toLowerCase();
                      if (color in combined) {
                          recommended = Math.ceil(combined[color].feet / feetPerUnit);
                          props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                          return recommended;
                      }
                  }

                  recommended = 0;
                  props.setRecommendedItem(props.model.trimCoil.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });
    const chimneyCaulk = computed(() =>
        calculateChimneyCaulk(
            props.modelValues.chimneyFlashing,
            props?.model.chimneyFlashing.field,
            props?.getRecommendedConversion
        )
    );
    const headCaulk = computed(() =>
        calculateChimneyCaulk(
            props.modelValues.headFlashing,
            props?.model.headFlashing.field,
            props?.getRecommendedConversion
        )
    );
    const counterCaulk = computed(() =>
        calculateCounterCaulk(
            props.modelValues.counterFlashing,
            props?.model.counterFlashing.children.counterFlashingType.field,
            props?.getRecommendedConversion
        )
    );
    const caulkRecommended = computed(() => {
        if (!Array.isArray(props?.modelValues.caulk)) return [];
        return props?.modelValues.caulk.map((x: { value: string }, i: number) => {
            const feetPerTube = props?.getRecommendedConversion(2, props?.model.caulk.field, x.value);
            if (feetPerTube === 0) return 0;
            const recommended = Math.ceil((chimneyCaulk.value + headCaulk.value + counterCaulk.value) / feetPerTube);
            props?.setRecommendedItem(props?.model.caulk.field.id, recommended, i);
            return recommended;
        });
    });

    const fastenerRecommended = computed(() => {
        if (!Array.isArray(props?.modelValues.fasteners)) return [];
        return props?.modelValues.fasteners.map((x: { value: string }, i: number) => {
            const feetPerTube = props?.getRecommendedConversion(1, props?.model.fasteners.field, x.value);
            const recommended = Math.ceil(props.modelValues.totalSquaresForMaterial.quantity * feetPerTube);
            props?.setRecommendedItem(props?.model.fasteners.field.id, recommended, i);
            return recommended;
        });
    });

    const roofingCementRecommended = computed(() => {
        if (!Array.isArray(props?.modelValues.roofingCement)) return [];
        return props?.modelValues.roofingCement.map((x: { value: string }, i: number) => {
            const gallonPerValley = props?.getRecommendedConversion(1, props?.model.roofingCement.field, x.value);
            const recommended = Math.ceil(props.modelValues.valleyLength.quantity * (gallonPerValley / 20));
            props?.setRecommendedItem(props?.model.roofingCement.field.id, recommended, i);
            return recommended;
        });
    });

    function updatePrices() {
        if (Array.isArray(props.modelValues.custom)) {
            for (let i = 0; i < props.modelValues.custom.length; i++) {
                const x = props.modelValues.custom[i];
                const materialMultiplier = props.getRecommendedConversion(
                    1,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                const laborMultiplier = props.getRecommendedConversion(
                    2,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                props.setModelValue(`custom[${i}].materialPriceTemp`, round(x.materialCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].materialPrice`, round(x.materialCost * materialMultiplier, 2));
                props.setModelValue(`custom[${i}].laborPriceTemp`, round(x.laborCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].laborPrice`, round(x.laborCost * laborMultiplier, 2));
            }
        }
    }
    watch(() => props.modelValues.custom, updatePrices, { deep: true });

    onMounted(() => {
        updatePrices();
    });

    const formatTrimCoilFeetRecommended = computed(() => {
        const trimCoilFeetRecommendedValue = trimCoilFeetRecommended.value;
        if (Object.keys(trimCoilFeetRecommendedValue).length === 0) {
            return '';
        }
        return Object.values(trimCoilFeetRecommendedValue)
            .map((x) => `${x.feet} ft of ${x.color}`)
            .join(', ');
    });

    const listSections = computed((): SectionProps[] => {
        return [
            {
                id: 1,
                isFieldArray: false,
                name: 'none',
                quantity: () => 0,
                setModelValue: () => '',
                title: 'Extras',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Ice & water install behind gutters',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'gutterIceBarrier.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Install attic baffles',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'atticBaffles.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Roofing permit',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name: () => {
                                    return 'permit.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Tarp attic',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name: () => {
                                    return 'tarpAttic.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                isFieldArray: true,
                name: 'caulk',
                quantity: (i) => props?.modelValues.caulk[i].quantity,
                setModelValue: (i) => `caulk[${i}].quantity`,
                title: 'Extras - Caulk',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Caulk',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `caulk[${i}].quantity`;
                                },
                                recommended: (i) => {
                                    return caulkRecommended?.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `caulk[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                isFieldArray: true,
                name: 'roofingCement',
                quantity: (i) => props?.modelValues.roofingCement[i].quantity,
                setModelValue: (i) => `roofingCement[${i}].quantity`,
                title: 'Roofing cement',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Roofing Cement',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `roofingCement[${i}].quantity`;
                                },
                                recommended: (i) => {
                                    return roofingCementRecommended?.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `roofingCement[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                isFieldArray: true,
                name: 'fasteners',
                quantity: (i) => props?.modelValues.fasteners[i].quantity,
                setModelValue: (i) => `fasteners[${i}].quantity`,
                title: 'Fasteners',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Fasteners',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `fasteners[${i}].quantity`;
                                },
                                recommended: (i) => {
                                    return fastenerRecommended?.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `fasteners[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                isFieldArray: true,
                name: 'corniceReturns',
                quantity: (i) => props?.modelValues.corniceReturns[i].quantity,
                setModelValue: (i) => `corniceReturns[${i}].quantity`,
                title: 'Cornice Returns',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Eyebrow / cornice return',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => `corniceReturns[${i}].quantity`,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => `corniceReturns[${i}].value`,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                isFieldArray: true,
                name: 'deliveryDistance',
                quantity: (i) => props?.modelValues.deliveryDistance[i].quantity,
                setModelValue: (i) => `deliveryDistance[${i}].quantity`,
                title: 'Delivery Distance',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Delivery / Distance',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `deliveryDistance[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `deliveryDistance[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 7,
                isFieldArray: true,
                name: 'handLoading',
                quantity: (i) => props?.modelValues.handLoading[i].quantity,
                setModelValue: (i) => `handLoading[${i}].quantity`,
                title: 'Hand Loading',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Hand Loading',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `handLoading[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Squares',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `handLoading[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 40,
                isFieldArray: false,
                name: '',
                quantity: () => 0,
                setModelValue: () => '',
                title: `Trim Coil Need: `,
                rows: [
                    {
                        id: 'test',
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended',
                            },
                            {
                                id: 2,
                                label: `${formatTrimCoilFeetRecommended.value}`,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 77,
                isFieldArray: true,
                name: 'trimCoil',
                quantity: (i) => props?.modelValues.trimCoil[i].quantity,
                setModelValue: (i) => `trimCoil[${i}].quantity`,
                title: 'Trim Coil',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Trim coil',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `trimCoil[${i}].quantity`;
                                },
                                recommended(i) {
                                    return trimCoilRecommended.value[Number(i)];
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `trimCoil[${i}].children.trimCoilColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `trimCoil[${i}].children.trimCoilType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 8,
                isFieldArray: true,
                name: 'custom',
                quantity: () => 0,
                setModelValue: () => '',
                title: 'Add On Services',
                hideClearAction: true,
                addItem: {
                    show: true,
                    title: 'Add On Services',
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Label / name',
                            },
                            {
                                id: 2,
                                type: 'Text',
                                name: (i) => {
                                    return `custom[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `custom[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: '$ material cost',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name: (i) => {
                                    return `custom[${i}].materialCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: '$ labor cost',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name: (i) => {
                                    return `custom[${i}].laborCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    // {
                    //     id: 5,
                    //     columns: [
                    //         {
                    //             id: 1,
                    //             label: '$ material price',
                    //         },
                    //         {
                    //             id: 2,
                    //             type: 'Price',
                    //             name: (i) => {
                    //                 return `custom[${i}].materialPrice`;
                    //             },
                    //             readonly: true,
                    //         },
                    //         {
                    //             id: 3,
                    //             label: '--',
                    //         },
                    //     ],
                    // },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: '$ material price',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name: (i) => {
                                    return `custom[${i}].materialPriceTemp`;
                                },
                                readonly: true,
                            },
                            ...emptyFields,
                        ],
                    },
                    // {
                    //     id: 7,
                    //     columns: [
                    //         {
                    //             id: 1,
                    //             label: '$ labor price',
                    //         },
                    //         {
                    //             id: 2,
                    //             type: 'Price',
                    //             name: (i) => {
                    //                 return `custom[${i}].laborPrice`;
                    //             },
                    //             readonly: true,
                    //         },
                    //         {
                    //             id: 3,
                    //             label: '--',
                    //         },
                    //     ],
                    // },
                    {
                        id: 8,
                        columns: [
                            {
                                id: 1,
                                label: '$ labor price',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name: (i) => {
                                    return `custom[${i}].laborPriceTemp`;
                                },
                                readonly: true,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ExcelFields, {
    modelValues: props?.modelValues,
    setModelValue: props?.setModelValue,
    sections: listSections.value,
    errors: __props.errors
  }, null, 8, ["modelValues", "setModelValue", "sections", "errors"]))
}
}

})