import EstimateFieldOption from './EstimateFieldOption';

type EstimateComparisonDTO = {
    fieldOptionId: string | null;
    items: string;
};

export default class EstimateComparison {
    fieldOptionId: string | null;
    items: string | null;
    constructor(dto: Partial<EstimateComparisonDTO>) {
        this.fieldOptionId = dto.fieldOptionId ?? null;
        this.items = dto.items ?? null;
    }

    get workflowId() {
        return EstimateFieldOption.getWorkflowId(this.fieldOptionId);
    }
}
