<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKey, estimteCostValues } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: '',
                title: 'Intake Type',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Fascia vent',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'fasciaIntakeVent.quantity';
                                },
                            },

                            ...emptyFields,
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'EdgeVent',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'edgeVent.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'EdgeVent'),
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Louvered 4x16',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'louveredSoffitVentAluminum.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Louvered 4″x16″ intake vent'),
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Louvered 8x16',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'louveredSoffitVentWood.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Louvered 8″x16″ intake vent'),
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: '',
                title: 'Exhaust Type',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Ridge vent',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'ridgeExhaustVent.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Ridge vent'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Box vent',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'boxExhaustVent.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Box vent'),
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Power vent',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'powerExhaustVent.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Kitchen exhaust',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'kitchenExhaustVent.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: 'bathExhaustVent',
                title: 'Bath Exhaust Vent',
                setModelValue: (i) => `bathExhaustVent[${i}].quantity`,
                quantity: (i) => props?.modelValues.bathExhaustVent[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Bathroom exhaust',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `bathExhaustVent[${i}].quantity`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `bathExhaustVent[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
