type CompanyDTO = {
    id: number;
    name: string;
    active: boolean;
};

export default class Company {
    id: number;
    name: string;
    active: boolean;
    sourceCompanyId!: number;

    constructor(dto: Partial<CompanyDTO>) {
        this.id = dto.id ?? 0;
        this.name = dto.name ?? '';
        this.active = dto.active ?? true;
    }
}
