<script setup>
    import { nextTick, provide, ref } from 'vue';
    import DialogConfirm from './views/partials/DialogConfirm.vue';
    import DialogAlert from './views/partials/DialogAlert.vue';
    import { useAccountStore } from './store/modules/token';

    const confirmDialog = ref(null);
    provide('confirmDialog', confirmDialog);

    const alertDialog = ref(null);
    provide('alertDialog', alertDialog);

    useAccountStore();

    async function loaded(isLoaded) {
        if (isLoaded) {
            await nextTick();
            const appLoader = document.getElementById('app_loader');
            if (appLoader) {
                appLoader.classList.add('hide');
                setTimeout(() => {
                    appLoader.remove();
                    document.body.classList.add('hide-bg');
                }, 500);
            }
        }
    }
</script>

<template>
    <router-view @onLoaded="loaded" />
    <app-icons />
    <app-messages />
    <refresh-service-worker />
    <dialog-confirm ref="confirmDialog" />
    <dialog-alert ref="alertDialog" />
</template>
