<script setup>
    import { getFieldValuesFromEstimate } from '@/composables/estimate';
    import { safeComputed } from '@/composables/safeComputed';

    import { computed, defineProps } from 'vue';
    import { useStore } from 'vuex';

    import {
        calculateTrimLength,
        // generateTrimFeetRecommendedText,
        getRecommendedInteriorAndExteriorFeet,
        organizeTrimData,
        roundUpOrganizedData,
    } from '@/helpers/estimator/windows/03_trim';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const $store = useStore();
    const fieldsRef = safeComputed(() => $store.state.estimator.fields);

    const interiorTrimQtyRecommended = computed(() => {
        return Array.isArray(props?.modelValues.interiorTrim)
            ? props?.modelValues.interiorTrim.map((x, i) => {
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.interiorTrim.children.interiorTrimType.field,
                      props?.modelValues.interiorTrim[i].children.interiorTrimType.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const length = x.children.interiorTrimLength.quantity;
                  const recommended = Math.ceil(length / feetPerUnit);
                  props?.setRecommendedItem(
                      props?.model.interiorTrim.children.interiorTrimType.field.id,
                      recommended,
                      i
                  );
                  return recommended;
              })
            : [];
    });

    const exteriorTrimQtyRecommended = computed(() => {
        return Array.isArray(props?.modelValues.exteriorTrim)
            ? props?.modelValues.exteriorTrim.map((x, i) => {
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.exteriorTrim.children.exteriorTrimType.field,
                      props?.modelValues.exteriorTrim[i].children.exteriorTrimType.value
                  );
                  if (feetPerUnit === 0) return 0;
                  const length = x.children.exteriorTrimLength.quantity;
                  const recommended = Math.ceil(length / feetPerUnit);
                  props?.setRecommendedItem(
                      props?.model.exteriorTrim.children.exteriorTrimType.field.id,
                      recommended,
                      i
                  );
                  return recommended;
              })
            : [];
    });

    const windowTrimFeetOrganized = computed(() => {
        let altModelValues = getFieldValuesFromEstimate(
            props?.estimate.itemTreeByField,
            fieldsRef.value,
            ['3.20'],
            true
        );
        if (!Array.isArray(altModelValues.windows)) {
            altModelValues.windows = [];
        }
        const summary = { interior: [], exterior: [] };

        for (const window of altModelValues.windows) {
            const length = calculateTrimLength(
                window.quantity,
                window.children.width.value,
                window.children.height.value
            );

            summary.interior.push(
                ...window.children.interiorTrim.map((trim) => ({
                    type: (trim.children.interiorTrimType?.value ?? '').toLowerCase(),
                    color: (trim.children.interiorTrimColor?.value ?? '').toLowerCase(),
                    length,
                }))
            );

            summary.exterior.push(
                ...window.children.exteriorTrim.map((trim) => ({
                    type: (trim.children.exteriorTrimType?.value ?? '').toLowerCase(),
                    color: (trim.children.exteriorTrimColor?.value ?? '').toLowerCase(),
                    length,
                }))
            );
        }

        const organized = {};

        for (const key in summary) {
            const organizedData = organizeTrimData(summary[key]);
            organized[key] = roundUpOrganizedData(organizedData);
        }

        return organized;
    });
    // const interiorTrimFeetRecommendedText = computed(() => {
    //     const organized = windowTrimFeetOrganized.value.interior;
    //     return generateTrimFeetRecommendedText(organized);
    // });
    const interiorTrimFeetRecommended = computed(() => {
        const typeField = props?.model.interiorTrim.children.interiorTrimType.field;
        const colorField = props?.model.interiorTrim.children.interiorTrimColor.field;
        const organized = windowTrimFeetOrganized.value.interior;

        return getRecommendedInteriorAndExteriorFeet(
            typeField,
            colorField,
            organized,
            props?.modelValues.interiorTrim,
            props?.setRecommendedItem,
            props?.model.interiorTrim.children.interiorTrimLength.field.id,
            'interiorTrimType',
            'interiorTrimColor'
        );
    });
    // const exteriorTrimFeetRecommendedText = computed(() => {
    //     const organized = windowTrimFeetOrganized.value.exterior;
    //     return generateTrimFeetRecommendedText(organized);
    // });
    const exteriorTrimFeetRecommended = computed(() => {
        const typeField = props?.model.exteriorTrim.children.exteriorTrimType.field;
        const colorField = props?.model.exteriorTrim.children.exteriorTrimColor.field;
        const organized = windowTrimFeetOrganized.value.exterior;
        return getRecommendedInteriorAndExteriorFeet(
            typeField,
            colorField,
            organized,
            props?.modelValues.exteriorTrim,
            props?.setRecommendedItem,
            props?.model.exteriorTrim.children.exteriorTrimLength.field.id,
            'exteriorTrimType',
            'exteriorTrimColor'
        );
    });

    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: '103229ba-225d-41f4-babb-74b9e571851c',
                quantity: (i) => props?.modelValues.interiorTrim[i].quantity,
                setModelValue: (i) => `interiorTrim[${i}].quantity`,
                name: 'interiorTrim',
                title: 'Interior Trim',
                isFieldArray: true,
                rows: [
                    {
                        id: 'df4255f3-6991-4459-8c72-e2bad8eed790',
                        columns: [
                            {
                                id: 'cc042aa7-1866-465f-a064-9661fe3d2076',
                                label: 'Interior trim type',
                            },
                            {
                                id: 'b9907a93-b7e0-45bc-a06b-bfa522832785',
                                type: 'Dropdown',
                                name(i) {
                                    return `interiorTrim[${i}].children.interiorTrimType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '62128855-1ebf-4371-8324-9aa44ef23858',
                        columns: [
                            {
                                id: '3f90cf9f-0fa6-4852-b671-2606d6305b08',
                                label: 'Color',
                            },
                            {
                                id: 'f98891e4-94e6-4f26-9287-7fc9e02fbf7d',
                                type: 'Dropdown',
                                name(i) {
                                    return `interiorTrim[${i}].children.interiorTrimColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '35443ec7-14b4-4918-a883-963a69cdfdc8',
                        columns: [
                            {
                                id: '17c34edf-3280-4d49-81c9-c2cfd740d743',
                                label: 'Length Feet',
                            },
                            {
                                id: '08edcc1c-89f8-42fc-bfed-c5d148cd0152',
                                type: 'Quantity',
                                recommended(i) {
                                    return interiorTrimFeetRecommended.value[Number(i)];
                                },
                                name(i) {
                                    return `interiorTrim[${i}].children.interiorTrimLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '5e13a0b3-f8ab-40fe-b841-3f3c5ee2c74c',
                        columns: [
                            {
                                id: 'c5b5db34-90c7-405c-9d7f-d0fa92b3e7f6',
                                label: 'Quantity',
                            },
                            {
                                id: '0f66ef11-99cd-4229-832e-2702747ac06b',
                                type: 'Quantity',
                                name(i) {
                                    return `interiorTrim[${i}].children.interiorTrimType.quantity`;
                                },
                                recommended(i) {
                                    return interiorTrimQtyRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 'f6ce4ae4-945c-40ea-81bb-a38347773c4a',
                quantity: (i) => props?.modelValues.exteriorTrim[i].quantity,
                setModelValue: (i) => `exteriorTrim[${i}].quantity`,
                name: 'exteriorTrim',
                title: 'Exterior Trim',
                isFieldArray: true,
                rows: [
                    {
                        id: '8d22a225-bdf8-40d9-8a97-5d654d3cfbe9',
                        columns: [
                            {
                                id: '3d78d909-09a3-4433-9bdb-2b84d77a0b0e',
                                label: 'Exterior trim type',
                            },
                            {
                                id: 'f11478be-2142-4872-8a3b-e433d9557cd2',
                                type: 'Dropdown',
                                name(i) {
                                    return `exteriorTrim[${i}].children.exteriorTrimType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'c1b9983c-ba0d-4179-a76d-972bc18679ac',
                        columns: [
                            {
                                id: 'e2fdf296-64de-41dd-8ebd-6d516c73596d',
                                label: 'Color',
                            },
                            {
                                id: '72f6528e-0455-49e9-b827-9715b042e5d6',
                                type: 'Dropdown',
                                name(i) {
                                    return `exteriorTrim[${i}].children.exteriorTrimColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '65571982-fe70-4c48-8bde-ec905ead96a7',
                        columns: [
                            {
                                id: '9f1ff620-60d2-4237-b9e3-195ebeaebe70',
                                label: 'Length Feet',
                            },
                            {
                                id: '591a6000-e049-4da0-8061-071bc76253b4',
                                type: 'Quantity',
                                recommended(i) {
                                    return exteriorTrimFeetRecommended.value[Number(i)];
                                },
                                name(i) {
                                    return `exteriorTrim[${i}].children.exteriorTrimLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'cfb00fac-767f-4920-9f70-7710e8537417',
                        columns: [
                            {
                                id: '9524b29a-6455-47a6-bc19-66dd77716fe9',
                                label: 'Quantity',
                            },
                            {
                                id: '16a2f7cc-1f56-4c0c-9481-9613b4c3f382',
                                type: 'Quantity',
                                recommended(i) {
                                    return exteriorTrimQtyRecommended.value[Number(i)];
                                },
                                name(i) {
                                    return `exteriorTrim[${i}].children.exteriorTrimType.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
