export const calculateInsulationRecommended = (
  membraneItems,
  insulationItems,
  ID_GET,
  ID_SET,
  GET,
  SET
) => {
  const membrane = (Array.isArray(membraneItems) ? membraneItems : []).reduce(
    (sum, x) => sum + x.children.membraneType.quantity,
    0
  );
  return insulationItems.map((v, i) => {
    const recommended = Math.ceil(membrane * GET(1, ID_GET, v.value));
    SET(ID_SET, recommended, i);
    return recommended;
  });
};

export const calculateEdgeDetailRecommend = (
  items,
  ID_GET_EDGE,
  ID_SET_EDGE,
  GET,
  SET
) => {
  return items.map((v, i) => {
    const perFeet = GET(1, ID_GET_EDGE, v.children.edgeDetailType.value);
    const recommended = Math.ceil(
      v.children.edgeDetailLength.quantity / (perFeet == 0 ? 1 : perFeet)
    );
    SET(ID_SET_EDGE, recommended, i);
    return recommended;
  });
};
export const calculatePlateRecommended = (
  insulationItems,
  plateItems,
  ID_GET,
  ID_SET,
  GET,
  SET
) => {
  const insulation = (
    Array.isArray(insulationItems) ? insulationItems : []
  ).reduce((sum, x) => sum + x.quantity, 0);
  return (Array.isArray(plateItems) ? plateItems : []).map((v, i) => {
    const recommended = Math.ceil(insulation * GET(1, ID_GET, v.value));
    SET(ID_SET, recommended, i);
    return recommended;
  });
};

export const calculateScrewsRecommended = (
  insulationItems,
  screwsItems,
  ID_GET,
  ID_SET,
  GET,
  SET
) => {
  const insulation = (
    Array.isArray(insulationItems) ? insulationItems : []
  ).reduce((sum, x) => sum + x.quantity, 0);
  return screwsItems.map((v, i) => {
    const recommended = Math.ceil(insulation * GET(1, ID_GET, v.value));
    SET(ID_SET, recommended, i);
    return recommended;
  });
};
