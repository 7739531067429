import FileReference from './FileReference';
import FileStorageService from './FileStorageService';
import MicrosoftOneDriveInfo from './MicrosoftOneDriveInfo';

export interface SettingsDto {
    timeZone?: string;
    companyName?: string;
    companyWebsiteUrl?: string;
    addressStreet?: string;
    addressCountry?: string;
    addressCity?: string;
    addressState?: string;
    addressPostalCode?: string;
    phoneNumber?: string;
    faxNumber?: string;
    salespersonTitle?: string;
    license?: string;
    themeColor?: string;
    fromEmailAddress?: string;
    replyToEmailAddress?: string;
    smtpHost?: string;
    smtpPort?: number;
    smtpUserName?: string;
    smtpPassword?: string;
    fileStorageService?: number;
    estimateFieldsSpreadsheet?: string;
    pdfFallbackFolder?: string;
    projectTemplateFolder?: string;
    projectContractsTemplateFolder?: string;
    projectWorkflowTemplateFolder?: string;
    salespersonFallbackFolder?: string;
    microsoftOneDriveInfo?: MicrosoftOneDriveInfo;
    hoverIntegrationEnabled?: boolean;
    zohoIntegrationEnabled?: boolean;
    zohoOrganizationDomainName?: string;
    zohoMarketLocation?: string;
    leadProjectTypesToFolderNameMapping?: object;
    projectProjectTypeToFolderNameMapping?: object;
    workflowToLeadProjectTypesMapping?: object;
    logoImage?: FileReference;
    contractLogos?: FileReference[];
    logoDarkImage?: FileReference;
    contractAttachments?: any[];
    contractRoofingScopeOfWork?: string;
    contractSidingScopeOfWork?: string;
    contractWindowsScopeOfWork?: string;
    contractWindowDetailsScopeOfWork?: string;
    contractGuttersScopeOfWork?: string;
    contractTrimScopeOfWork?: string;
    contractRepairScopeOfWork?: string;
    contractInsulationScopeOfWork?: string;
    changeOrderScopeOfWork?: string;
    contractWarrantyText?: string;
    contractWarrantyImage?: FileReference;
    contractCashDiscountPercent?: number;
    contractFinancingApr?: number;
    contractPaymentDisclaimer?: string;
    changeOrderPaymentDisclaimer?: string;
    contractProjectStartDateDisclaimer?: string;
    contractLegalText?: string;
    contractDisclosures?: string;
    contractAcknowledgements?: string;
    contractPackageLabel1?: string;
    contractPackageLabel2?: string;
    contractPackageLabel3?: string;
    contractEmailMessage?: string;
    showDiscountsOnContract?: boolean;
    showPaymentOptionsOnContract?: boolean;
    usageReportRecipients?: object;
    usageReportFrequency?: number;
    usageReportStartDate?: string;
    sectionsInfo?: object;
    showMeasurmentPopulate?: boolean;
    paymentOptions?: object;
    lifetimeWorkmanship?: boolean;
    cashCheckDiscount?: number;
    zeroInterest?: object;
    reducedRate?: object;
    showPromotionsOnContract?: boolean;
    appCompanyName?: string;
    showPhotosSection?: boolean;
    showPaymentOptions?: boolean;
}

export default class Settings {
    timeZone: string;
    companyName: string;
    companyWebsiteUrl: string;
    addressStreet: string;
    addressCountry: string;
    addressCity: string;
    addressState: string;
    addressPostalCode: string;
    phoneNumber: string;
    faxNumber: string;
    salespersonTitle: string;
    license: string;
    themeColor: string;
    fromEmailAddress: string;
    replyToEmailAddress: string;
    smtpHost: string;
    smtpPort: number;
    smtpUserName: string;
    smtpPassword: string;
    fileStorageService: number;
    estimateFieldsSpreadsheet: string;
    pdfFallbackFolder: string;
    projectTemplateFolder: string;
    projectContractsTemplateFolder: string;
    projectWorkflowTemplateFolder: string;
    salespersonFallbackFolder: string;
    microsoftOneDriveInfo: MicrosoftOneDriveInfo | null;
    hoverIntegrationEnabled: boolean;
    zohoIntegrationEnabled: boolean;
    zohoOrganizationDomainName: string;
    zohoMarketLocation: string;
    leadProjectTypesToFolderNameMapping: object;
    projectProjectTypeToFolderNameMapping: object;
    workflowToLeadProjectTypesMapping: object;
    logoImage: FileReference | null;
    contractLogos: FileReference[];
    logoDarkImage: FileReference | null;
    contractAttachments: any[];
    contractRoofingScopeOfWork: string;
    contractSidingScopeOfWork: string;
    contractWindowsScopeOfWork: string;
    contractWindowDetailsScopeOfWork: string;
    contractGuttersScopeOfWork: string;
    contractTrimScopeOfWork: string;
    contractRepairScopeOfWork: string;
    contractInsulationScopeOfWork: string;
    changeOrderScopeOfWork: string;
    contractWarrantyText: string;
    contractWarrantyImage: FileReference | null;
    contractCashDiscountPercent: number;
    contractFinancingApr: number;
    contractPaymentDisclaimer: string;
    changeOrderPaymentDisclaimer: string;
    contractProjectStartDateDisclaimer: string;
    contractLegalText: string;
    contractDisclosures: string;
    contractAcknowledgements: string;
    contractPackageLabel1: string;
    contractPackageLabel2: string;
    contractPackageLabel3: string;
    contractEmailMessage: string;
    showDiscountsOnContract: boolean;
    showPaymentOptionsOnContract: boolean;
    usageReportRecipients: object;
    usageReportFrequency: number;
    usageReportStartDate: string | null;
    sectionsInfo: object;
    showMeasurmentPopulate: boolean;
    paymentOptions: object;
    lifetimeWorkmanship: boolean;
    cashCheckDiscount: number;
    zeroInterest: object;
    reducedRate: object;
    showPromotionsOnContract: boolean;
    appCompanyName: string;
    showPhotosSection: boolean;
    showPaymentOptions: boolean;

    constructor(dto: Partial<SettingsDto> = {}) {
        this.timeZone = dto.timeZone ?? '';
        this.companyName = dto.companyName ?? '';
        this.companyWebsiteUrl = dto.companyWebsiteUrl ?? '';
        this.addressStreet = dto.addressStreet ?? '';
        this.addressCountry = dto.addressCountry ?? '';
        this.addressCity = dto.addressCity ?? '';
        this.addressState = dto.addressState ?? '';
        this.addressPostalCode = dto.addressPostalCode ?? '';
        this.phoneNumber = dto.phoneNumber ?? '';
        this.faxNumber = dto.faxNumber ?? '';
        this.salespersonTitle = dto.salespersonTitle ?? '';
        this.license = dto.license ?? '';
        this.themeColor = dto.themeColor ?? '#2ab0da';
        this.fromEmailAddress = dto.fromEmailAddress ?? '';
        this.replyToEmailAddress = dto.replyToEmailAddress ?? '';
        this.smtpHost = dto.smtpHost ?? '';
        this.smtpPort = dto.smtpPort ?? 0;
        this.smtpUserName = dto.smtpUserName ?? '';
        this.smtpPassword = dto.smtpPassword ?? '';
        this.fileStorageService = dto.fileStorageService ?? FileStorageService.none;
        this.estimateFieldsSpreadsheet = dto.estimateFieldsSpreadsheet ?? '';
        this.pdfFallbackFolder = dto.pdfFallbackFolder ?? '';
        this.projectTemplateFolder = dto.projectTemplateFolder ?? '';
        this.projectContractsTemplateFolder = dto.projectContractsTemplateFolder ?? '';
        this.projectWorkflowTemplateFolder = dto.projectWorkflowTemplateFolder ?? '';
        this.salespersonFallbackFolder = dto.salespersonFallbackFolder ?? '';
        this.microsoftOneDriveInfo = dto.microsoftOneDriveInfo ?? null;
        this.hoverIntegrationEnabled = dto.hoverIntegrationEnabled ?? false;
        this.zohoIntegrationEnabled = dto.zohoIntegrationEnabled ?? false;
        this.zohoOrganizationDomainName = dto.zohoOrganizationDomainName ?? '';
        this.zohoMarketLocation = dto.zohoMarketLocation ?? '';
        this.leadProjectTypesToFolderNameMapping = dto.leadProjectTypesToFolderNameMapping ?? {};
        this.projectProjectTypeToFolderNameMapping = dto.projectProjectTypeToFolderNameMapping ?? {};
        this.workflowToLeadProjectTypesMapping = dto.workflowToLeadProjectTypesMapping ?? {};
        this.logoImage = dto.logoImage ?? null;
        this.contractLogos =
            dto.contractLogos?.map((x) => {
                if (x instanceof FileReference) {
                    return x;
                } else {
                    return new FileReference(x);
                }
            }) ?? [];
        this.logoDarkImage = dto.logoDarkImage ?? null;
        this.contractAttachments = dto.contractAttachments ?? [];
        this.contractRoofingScopeOfWork = dto.contractRoofingScopeOfWork ?? '';
        this.contractSidingScopeOfWork = dto.contractSidingScopeOfWork ?? '';
        this.contractWindowsScopeOfWork = dto.contractWindowsScopeOfWork ?? '';
        this.contractWindowDetailsScopeOfWork = dto.contractWindowDetailsScopeOfWork ?? '';
        this.contractGuttersScopeOfWork = dto.contractGuttersScopeOfWork ?? '';
        this.contractTrimScopeOfWork = dto.contractTrimScopeOfWork ?? '';
        this.contractRepairScopeOfWork = dto.contractRepairScopeOfWork ?? '';
        this.contractInsulationScopeOfWork = dto.contractInsulationScopeOfWork ?? '';
        this.changeOrderScopeOfWork = dto.changeOrderScopeOfWork ?? '';
        this.contractWarrantyText = dto.contractWarrantyText ?? '';
        this.contractWarrantyImage = dto.contractWarrantyImage ?? null;
        this.contractCashDiscountPercent = dto.contractCashDiscountPercent ?? 0;
        this.contractFinancingApr = dto.contractFinancingApr ?? 0;
        this.contractPaymentDisclaimer = dto.contractPaymentDisclaimer ?? '';
        this.changeOrderPaymentDisclaimer = dto.changeOrderPaymentDisclaimer ?? '';
        this.contractProjectStartDateDisclaimer = dto.contractProjectStartDateDisclaimer ?? '';
        this.contractLegalText = dto.contractLegalText ?? '';
        this.contractDisclosures = dto.contractDisclosures ?? '';
        this.contractAcknowledgements = dto.contractAcknowledgements ?? '';
        this.contractPackageLabel1 = dto.contractPackageLabel1 ?? '';
        this.contractPackageLabel2 = dto.contractPackageLabel2 ?? '';
        this.contractPackageLabel3 = dto.contractPackageLabel3 ?? '';
        this.contractEmailMessage = dto.contractEmailMessage ?? '';
        this.showDiscountsOnContract = dto.showDiscountsOnContract ?? true;
        this.showPaymentOptionsOnContract = dto.showPaymentOptionsOnContract ?? true;
        this.usageReportRecipients = dto.usageReportRecipients ?? {};
        this.usageReportFrequency = dto.usageReportFrequency ?? 0;
        this.usageReportStartDate = dto.usageReportStartDate ?? null;
        this.sectionsInfo = dto.sectionsInfo ?? {};
        this.showMeasurmentPopulate = dto.showMeasurmentPopulate ?? false;
        this.paymentOptions = dto.paymentOptions ?? {};
        this.lifetimeWorkmanship = dto.lifetimeWorkmanship ?? true;
        this.cashCheckDiscount = dto.cashCheckDiscount ?? 0;
        this.zeroInterest = dto.zeroInterest ?? {};
        this.reducedRate = dto.reducedRate ?? {};
        this.showPromotionsOnContract = dto.showPromotionsOnContract ?? false;
        this.showPhotosSection = dto.showPhotosSection ?? false;
        this.showPaymentOptions = dto.showPaymentOptions ?? false;
        // readonly
        this.appCompanyName = dto.appCompanyName ?? '';
    }
}
