<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";
    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: '995d1791-b690-43cc-a758-5181a73097f3',
                quantity: () => 0,
                setModelValue: () => '',
                name: '',
                title: 'Questionnaire',
                isFieldArray: false,
                rows: [
                    {
                        id: 'fa7cddd3-3da9-40f1-bc56-e6847602ecc8',
                        columns: [
                            {
                                id: 'cdce61a7-71db-433f-b402-ea8969f173f6',
                                label: 'Years in home',
                            },
                            {
                                id: 'b9967367-c928-4fba-811a-46b8647d69de',
                                type: 'Quantity',
                                name() {
                                    return 'yearsInHome.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '133a4481-a990-4e07-95ee-488d3d775b6f',
                        columns: [
                            {
                                id: 'a4a7aee3-ef95-4fca-86d0-7de3a5b0a51f',
                                label: 'Age of windows',
                            },
                            {
                                id: 'b60b2ce4-b5b4-4caa-ac83-44f64f0f2204',
                                type: 'Quantity',
                                name() {
                                    return 'ageOfWindows.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'd8756bff-3094-42a1-b917-62471c38c08f',
                        columns: [
                            {
                                id: '87cb9ff6-abdd-4aae-9533-7e6986cc48ce',
                                label: 'Storm windows',
                            },
                            {
                                id: '0431e412-3737-4099-bf49-7b28af4d45c5',
                                type: 'Dropdown',
                                display: 'dropdown',
                                name() {
                                    return 'stormWindows.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '097cc30b-6843-4b0e-bb61-9e18ec9cc0a2',
                        columns: [
                            {
                                id: 'dc438f87-86d2-4de3-bce7-2b31cbf283e4',
                                label: 'Product Interest',
                            },
                            {
                                id: '86c33f91-ff8c-4e6b-aabb-046283809af8',
                                type: 'Dropdown',
                                display: 'dropdown',
                                name() {
                                    return 'productInterest.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'ab353721-1001-4e17-b23f-5b5e8c4f9b2e',
                        columns: [
                            {
                                id: 'd10768a5-82e2-4c89-85b4-5c8111340fd2',
                                label: 'Energy Efficiency',
                            },
                            {
                                id: '831fd7c6-29af-4093-8254-85cc3560f490',
                                type: 'Dropdown',
                                display: 'dropdown',
                                name() {
                                    return 'energyEfficiency.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '91bac999-5d01-4327-bf8d-9c05dc0c73eb',
                        columns: [
                            {
                                id: '151db796-0293-43c8-918d-ad3411c7bfa6',
                                label: 'Preferred timeline',
                            },
                            {
                                id: '368b8195-7a84-4234-99c3-545b9d92ea75',
                                type: 'Dropdown',
                                display: 'dropdown',
                                name() {
                                    return 'preferredTimeline.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '5ed68244-3cfa-4191-b99f-d2351d72f303',
                        columns: [
                            {
                                id: '084c6cfe-b710-4d3c-a4ca-aa2ff4b2303f',
                                label: 'Deciding factor',
                            },
                            {
                                id: 'e255facb-3b35-4826-825a-eafa839a58b4',
                                type: 'Dropdown',
                                display: 'dropdown',
                                name() {
                                    return 'decidingFactor.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'e9ac1299-3277-4bfd-ac63-9782ce32e93e',
                        columns: [
                            {
                                id: '16806958-5fed-485a-abcb-b24ddbc2f86e',
                                label: 'Warranty',
                            },
                            {
                                id: 'ea8f06b6-63be-4e78-b8bc-f9e2f8bcfa2c',
                                type: 'Dropdown',
                                display: 'dropdown',
                                name() {
                                    return 'warranty.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '414266f5-9cd9-4cd3-80da-16f05e340f94',
                        columns: [
                            {
                                id: 'f3a85670-e38c-49c6-b925-2e45793999a9',
                                label: 'Window Style',
                            },
                            {
                                id: '79098481-02c9-4872-b265-8022f6394d09',
                                type: 'Dropdown',
                                display: 'dropdown',
                                name() {
                                    return 'windowStyle.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 'c90bb8b3-ab60-42a3-82e6-3660b2e31406',
                name: '',
                title: 'Window Problems',
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: '8afc427a-ea78-432e-8867-0861b6d23476',
                        columns: [
                            {
                                id: '532bca90-becf-4163-939d-2bc4243f7efa',
                                label: 'Maintenance',
                            },
                            {
                                id: '4fb5fc7a-3c60-4253-a138-df9ca2369afa',
                                type: 'Checkbox',
                                name() {
                                    return 'windowProblemMaintenance.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '5b0dcbf1-b007-432a-af9e-e3b66871ad72',
                        columns: [
                            {
                                id: '70171806-11ab-43a7-9708-cdebdf7c8260',
                                label: 'Rotten',
                            },
                            {
                                id: '832d766c-fb43-4469-9776-a8fa1fb3b91b',
                                type: 'Checkbox',
                                name() {
                                    return 'windowProblemRotten.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '96927b17-8abd-4e64-97d6-bccad8113901',
                        columns: [
                            {
                                id: 'b9c8345d-72d1-4dc6-ac0d-202eb92202b8',
                                label: 'Don’t function',
                            },
                            {
                                id: 'f3059b03-a05e-4f7d-af70-7005a467cb2b',
                                type: 'Checkbox',
                                name() {
                                    return 'windowProblemNonFunctional.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '0fda61aa-6e3f-496d-b655-aff8f0aa5b30',
                        columns: [
                            {
                                id: '61ca2222-0b77-4e91-a4b8-961ea77ec602',
                                label: 'Damaged sills',
                            },
                            {
                                id: '04132efc-3047-4df1-9061-198b96010036',
                                type: 'Checkbox',
                                name() {
                                    return 'windowProblemDamagedSills.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'd39ebe78-9186-4421-80bb-43427a325b0c',
                        columns: [
                            {
                                id: '94102767-9cad-4cef-bc24-d3f2eeeb3cf4',
                                label: 'Don’t want to paint',
                            },
                            {
                                id: '3c809870-b8b3-410d-b9bd-769a767f4cf3',
                                type: 'Checkbox',
                                name() {
                                    return 'windowProblemNeedsPainting.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
