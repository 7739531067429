import companyId from '@/api/modules/companyId';
import { DateTime } from 'luxon';

type FileReferenceDTO = {
    id: string;
    data?: any;
    name?: string;
    size?: number;
    uploadTimestamp?: string;
    url?: string;
};

export default class FileReference {
    id: string;
    data: any;
    name: string;
    size: number;
    uploadTimestamp: DateTime;
    url: string;

    constructor(dto: Partial<FileReferenceDTO> = {}) {
        this.id = dto.id ?? FileReference.DEFAULT_ID;
        this.data = dto.data ?? null;
        this.name = dto.name ?? '';
        this.size = dto.size ?? 0;
        this.uploadTimestamp = dto.uploadTimestamp ? DateTime.fromISO(dto.uploadTimestamp) : DateTime.now();
        this.url = dto.url ?? '';
    }

    get extension() {
        if (this.name) {
            const index = this.name.lastIndexOf('.');
            if (index >= 0 && index < this.name.length - 1) {
                return this.name.substr(index).toLowerCase();
            }
        }
        return null;
    }

    static DEFAULT_ID = '0000000000000000000000000000000000000000000000000000000000000000';

    static getUrl(id: string) {
        if (id && id !== this.DEFAULT_ID) {
            return '/api/Files/Download/' + id + '?companyId=' + companyId.get();
        } else {
            return '';
        }
    }
}
