import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { App } from 'vue';
import { Router } from 'vue-router';

let appInsights: ApplicationInsights;
interface InitApplicationInsightsOptions {
    app: App<Element>;
    connectionString: string;
    router: Router;
    version: string;
}

export function initApplicationInsights({ app, connectionString, router, version }: InitApplicationInsightsOptions) {
    const ai = new ApplicationInsights({
        config: {
            connectionString: connectionString,
            maxAjaxCallsPerView: -1,
            disableDataLossAnalysis: false,
            enableAutoRouteTracking: false,
            disableFetchTracking: false,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            correlationHeaderExcludedDomains: ['*.queue.core.windows.net', 'apis.google.com', 'www.googleapis.com'],
        },
    });
    appInsights = ai;
    ai.loadAppInsights();

    const errorHandler = app.config.errorHandler;
    app.config.errorHandler = (error, instance, info) => {
        ai.trackException({ exception: error as Error });
        if (typeof errorHandler === 'function') {
            errorHandler.call(app, error, instance, info);
        }
    };

    router.isReady().then(() => {
        router.onError((error) => {
            ai.trackException({ exception: error as Error });
        });
        router.afterEach((to, from, failure) => {
            if (failure) {
                return;
            }
            ai.trackPageView({
                name: typeof to.meta.title === 'string' ? to.meta.title : '',
                uri: location.origin + to.fullPath,
            });
        });
    });
}

export const getAppInsights = () => appInsights;
