import { fetchWrap } from '../_helpers';

interface cacheFileparams {
    url: string;
}

export async function cacheFiles(files: cacheFileparams[]) {
    while (files.length > 0) {
        const file = files.shift();
        if (!file) {
            break;
        }
        try {
            await fetchWrap(file.url);
        } catch {
            break;
        }
    }
}
