import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "quick_estimate_row_table" }
const _hoisted_2 = { class: "quick_estimate_row_table_item" }
const _hoisted_3 = { style: {"font-size":"12px","font-weight":"bold"} }
const _hoisted_4 = {
  class: "quick_estimate_row_table_item",
  style: {"align-items":"center","justify-content":"start","display":"flex"}
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "quick_estimate_row_table_item" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "quick_estimate_row_table_item" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "quick_estimate_row_table" }
const _hoisted_11 = { class: "quick_estimate_row_table_item" }
const _hoisted_12 = { style: {"display":"flex","flex-direction":"row","gap":"10px"} }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "quick_estimate_row_table" }
const _hoisted_16 = { class: "quick_estimate_row_table_item" }
const _hoisted_17 = { class: "quick_estimate_row_table" }
const _hoisted_18 = { class: "quick_estimate_row_table_item" }
const _hoisted_19 = { class: "quick_estimate_row_table" }
const _hoisted_20 = { class: "quick_estimate_row_table_item" }
const _hoisted_21 = {
  key: 0,
  class: "quick_estimate_row_table"
}
const _hoisted_22 = { class: "quick_estimate_row_table_item" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "quick_estimate_row_table" }
const _hoisted_25 = { class: "quick_estimate_row_table_item" }
const _hoisted_26 = { class: "quick_estimate_row_table" }
const _hoisted_27 = { class: "quick_estimate_row_table_item" }
const _hoisted_28 = { class: "quick_estimate_row_table" }
const _hoisted_29 = { class: "quick_estimate_row_table_item" }
const _hoisted_30 = { style: {"display":"flex","flex-direction":"row","gap":"10px"} }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "quick_estimate_row_table" }
const _hoisted_34 = { class: "quick_estimate_row_table_item" }
const _hoisted_35 = { class: "quick_estimate_row_table" }
const _hoisted_36 = { class: "quick_estimate_row_table_item" }
const _hoisted_37 = {
  key: 0,
  class: "quick_estimate_row_table"
}
const _hoisted_38 = { class: "quick_estimate_row_table_item" }
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { class: "quick_estimate_row_table" }
const _hoisted_41 = { class: "quick_estimate_row_table_item" }
const _hoisted_42 = { class: "quick_estimate_row_table" }
const _hoisted_43 = { class: "quick_estimate_row_table_item" }
const _hoisted_44 = { style: {"display":"flex","flex-direction":"row","gap":"10px"} }
const _hoisted_45 = ["onClick"]
const _hoisted_46 = ["onClick"]
const _hoisted_47 = { class: "quick_estimate_row_table" }
const _hoisted_48 = { class: "quick_estimate_row_table_item" }
const _hoisted_49 = { class: "quick_estimate_row_table" }
const _hoisted_50 = { class: "quick_estimate_row_table_item" }
const _hoisted_51 = {
  key: 0,
  class: "quick_estimate_row_table"
}
const _hoisted_52 = { class: "quick_estimate_row_table_item" }
const _hoisted_53 = ["onClick"]

import { getFieldValuesFromEstimate } from '@/composables/estimate';
    import { safeComputed } from '@/composables/safeComputed';
    import { computed, inject, ref, watch } from 'vue';

    import { useFieldArray } from 'vee-validate';
    
    import { useStore } from 'vuex';

    type WindowType = {
        id: number;
        children: {
            height: {
                value: number;
            };
            width: {
                value: number;
            };
            roomName: {
                value: string;
            };
        };
    };

    // function fieldHasValidOptions(i: number, propName: string) {
    type RowsProps = {
        id: string;
        title: string;
        main?: boolean;
        type?: string;
        readonly?: boolean;
        showFieldOpts?: (i: number) => string;
        value: (i: number) => string;
        text?: (item: { value: { id: number } }) => string;
    };
    
export default /*@__PURE__*/_defineComponent({
  __name: 'Window02Details',
  props: {
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
    },
  setup(__props) {

    const props = __props;

    function fieldHasValidOptions(i: number, propName: string) {
        if (!(i <= props?.modelValues.windows.length)) {
            return false;
        }
        const parentId = props?.modelValues.windows[i]?.children?.[propName]?.parentId;
        if (!parentId) {
            return false;
        }
        const validOptions = props?.model.windows.children[propName].validOptions[parentId] ?? [];
        return validOptions.length > 0;
    }

    // function showFootLock(i: number) {
    function showFootLock(i: number) {
        const style = getOptionText(i, 'windowStyle');
        return style && style.toLowerCase() === 'patio door';
    }

    // function getOptionText(i: number, prop: any) {
    function getOptionText(i: number, prop: string) {
        if (!(i <= props?.modelValues.windows.length)) {
            return false;
        }
        const value = props?.modelValues.windows[i]?.children?.[prop]?.value;
        if (!value) {
            return null;
        }
        const field = props?.model.windows.children[prop].field;
        // const selectedOption = value ? field.options.find((x: { id: string }) => x.id === value) : null;
        const selectedOption = value ? field.options.find((x: { id: string }) => x.id === value) : null;

        return selectedOption ? selectedOption.value : null;
    }
    const rows: RowsProps[] = [
        {
            id: '96488244-3f55-4069-9879-efaf2c44172c',
            title: 'Windows',
            main: true,
            // eslint-disable-next-line no-unused-vars

            value: (i) => {
                return ``;
            },
        },
        {
            id: '6cbeb3d2-e531-428d-89cd-64b3905457ab',
            title: 'Room name',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.roomName.value`;
            },
        },

        {
            id: '416723af-aa34-42f6-befb-647c333e9cdd',
            title: 'Room details',
            type: 'text',
            value: (i) => {
                return `windows[${i}].children.roomDetails.value`;
            },
        },
        {
            id: '419c1eed-afad-4650-9004-3636e1ad8ad3',
            title: 'Width',
            type: 'quantity',
            value: (i) => {
                return `windows[${i}].children.width.quantity`;
            },
        },
        {
            id: '20de22ba-8ec4-4300-87f8-cf3848521363',
            title: 'Height',
            type: 'quantity',
            value: (i) => {
                return `windows[${i}].children.height.quantity`;
            },
        },
        {
            id: 'cabac435-19ab-4c03-8e78-9b3b5ccf3aea',
            title: 'UI',
            type: 'quantity',
            readonly: true,
            value: (i) => {
                return `windows[${i}].children.ui.quantity`;
            },
        },
        {
            id: 'a1a9e6d1-cb6b-4fd1-bdf1-f51642af193c',
            title: 'Window type',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.windowStyle.value`;
            },
        },
        {
            id: '17eeef99-d5e2-4bc7-83c3-ad95f8a1a169',
            title: 'Type details',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.windowStyleDetails.value`;
            },
        },
        {
            id: '699e8224-a0ba-457c-a4f1-786bd6dcda7c',
            title: 'Quantity',
            type: 'quantity',
            value: (i) => {
                return `windows[${i}].quantity`;
            },
        },
        {
            id: 'f0de00e4-5fde-4470-abc3-4a9f0bc2c512',
            title: 'Model',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.windowModel.value`;
            },
        },
        {
            id: 'a6aecfdb-a3df-4f64-84cf-c618f28c12cf',
            title: 'Install Type',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.installType.value`;
            },
        },
        {
            id: 'd7e21631-7bd9-4b40-bd5d-6a7b60fd9854',
            title: 'Hardware',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.hardware.value`;
            },
        },
        {
            id: 'e3090bbd-b711-498b-957b-1b09f5a29732',
            title: 'screen ',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.screen.value`;
            },
        },
        {
            id: '87b8e6b4-4e29-4905-9cf9-82516220e5fc',
            title: 'Swing',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'swing'),
            value: (i) => {
                return `windows[${i}].children.swing.value`;
            },
        },
        {
            id: '4678069b-48c1-479c-bd8b-9151b8f60fd3',
            title: 'Operating sash',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'operatingSash'),
            value: (i) => {
                return `windows[${i}].children.operatingSash.value`;
            },
        },
        {
            id: '6c07e34b-d7e7-447d-ac11-315573e4e416',
            title: 'Obscure sash',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'obscureSash'),
            value: (i) => {
                return `windows[${i}].children.obscureSash.value`;
            },
        },
        {
            id: '75eb5a6e-c07a-46d0-9ab1-5aefd3226955',
            title: 'Jamb depth',
            type: 'quantity',
            value: (i) => {
                return `windows[${i}].children.jambDepth.quantity`;
            },
        },
        {
            id: '158be9de-521b-42f9-85a0-796518b860fe',
            title: 'Blinds',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'blinds'),
            value: (i) => {
                return `windows[${i}].children.blinds.value`;
            },
        },
        {
            id: '7474584d-a615-4f7c-85a5-5ce5e0067f41',
            title: 'Weld',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'weld'),
            value: (i) => {
                return `windows[${i}].children.weld.value`;
            },
        },
        {
            id: 'b2db964e-a1ea-4903-9009-ac0bc82be018',
            title: 'Roof option',
            type: 'dropdown',
            showFieldOpts: (i) => fieldHasValidOptions(i, 'roofOption'),
            value: (i) => {
                return `windows[${i}].children.roofOption.value`;
            },
        },
        {
            id: '12ac44c8-a609-499b-b169-0c991c11a71e',
            title: 'Foot lock',
            type: 'checkbox',
            showFieldOpts: (i) => showFootLock(i),
            value: (i) => {
                return `windows[${i}].children.footLock.value`;
            },
        },
        {
            id: '09eed311-0b02-4d95-a4e9-2c8eca407f80',
            title: 'Glass Section ',
            // eslint-disable-next-line no-unused-vars
            value: (i) => {
                return '';
            },
        },
        {
            id: '4ab27faa-7aa4-4cae-9677-cd39ce0b61da',
            title: 'Glass',
            type: 'dropdown',
            value: (i) => {
                return `windows[${i}].children.glassType.value`;
            },
        },
    ];

    const $store = useStore();

    const fields = safeComputed(() => $store.state.estimator.fields);

    const windows = computed(
        () => getFieldValuesFromEstimate?.(props?.estimate.itemTreeByField, fields.value, ['3.20'], true)?.windows
    );
    const windowsCount = computed(() =>
        windows.value
            ? windows.value.reduce((i: number, window: { quantity: number }) => {
                  return i + window.quantity;
              }, 0)
            : 0
    );

    const getNewFieldArrayItem = inject('getNewFieldArrayItem') as any;

    const NameFieldArray = ref('windows');

    const { remove: removeItem, push: pushItem, insert: insertItem, fields: items } = useFieldArray(NameFieldArray);

    const handleCreateNewItem = () => {
        const payload = getNewFieldArrayItem('windows');
        pushItem(payload);
    };
    watch(
        props?.modelValues?.windows,
        () => {
            const wndows = props?.modelValues?.windows;
            wndows?.forEach((_: any, i: number) => {
                const widthQuantity = props?.modelValues?.windows[i]?.children?.width?.quantity;
                const heightQuantity = props?.modelValues.windows[i]?.children?.height?.quantity;
                props?.setModelValue(
                    `windows[${i}].children.ui.quantity`,
                    Number(widthQuantity) + Number(heightQuantity)
                );
            });
        },
        {
            deep: true,
            immediate: true,
        }
    );

return (_ctx: any,_cache: any) => {
  const _component_field_input_dropdown = _resolveComponent("field-input-dropdown")!
  const _component_field_input_text = _resolveComponent("field-input-text")!
  const _component_field_input_quantity = _resolveComponent("field-input-quantity")!
  const _component_field_input_checkbox = _resolveComponent("field-input-checkbox")!
  const _component_field_error = _resolveComponent("field-error")!
  const _component_field_array = _resolveComponent("field-array")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[95] || (_cache[95] = _createStaticVNode("<section class=\"quick_estimate_row_table\"><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article></section>", 1)),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("article", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(windowsCount.value) + " Total windows", 1)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
      _cache[1] || (_cache[1] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
      _cache[3] || (_cache[3] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
      _createElementVNode("article", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn-link btn-add-gray",
          type: "button",
          onClick: handleCreateNewItem,
          disabled: __props.loading
        }, " Create window ", 8, _hoisted_5)
      ])
    ]),
    _cache[96] || (_cache[96] = _createStaticVNode("<section class=\"quick_estimate_row_table\"><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article></section>", 1)),
    _createVNode(_component_field_array, { name: "windows" }, {
      default: _withCtx(({ items }) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (item, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.key
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(rows, (row) => {
              return _createElementVNode("section", {
                key: row?.id,
                class: "quick_estimate_row_table"
              }, [
                _createElementVNode("article", {
                  class: _normalizeClass(row?.main ? 'quick_estimate_row_table_item' : 'quick_estimate_table_item_sub')
                }, _toDisplayString(row?.main ? `${Number(i) + 1}. ${row?.title}` : row?.title), 3),
                _createElementVNode("article", _hoisted_6, [
                  (row?.main)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(windows.value?.find((e) => e?.id === item?.value?.id)?.children?.width?.value) + " x " + _toDisplayString(windows.value?.find((e) => e?.id === item?.value?.id)?.children?.height?.value) + " -- " + _toDisplayString(windows.value?.find((e) => e?.id === item?.value?.id)?.children?.roomName?.value), 1))
                    : _createCommentVNode("", true),
                  (row?.showFieldOpts?.(i))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (row?.value(i))
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              (row?.type === 'dropdown')
                                ? (_openBlock(), _createBlock(_component_field_input_dropdown, {
                                    key: 0,
                                    name: row?.value(i),
                                    readonly: row?.readonly ?? false,
                                    custom_class: 'input_dropdown_excel'
                                  }, null, 8, ["name", "readonly"]))
                                : _createCommentVNode("", true),
                              (row?.type === 'text')
                                ? (_openBlock(), _createBlock(_component_field_input_text, {
                                    key: 1,
                                    name: row?.value(i),
                                    readonly: row?.readonly ?? false,
                                    custom_class: 'input_text_excel'
                                  }, null, 8, ["name", "readonly"]))
                                : _createCommentVNode("", true),
                              (row?.type === 'quantity')
                                ? (_openBlock(), _createBlock(_component_field_input_quantity, {
                                    key: 2,
                                    name: row?.value(i),
                                    readonly: row?.readonly ?? false,
                                    custom_class: 'input_number_excel',
                                    custom_select_class: 'input_quantity_select_excel',
                                    recommendedClass: 'input_quantity_recommended'
                                  }, null, 8, ["name", "readonly"]))
                                : _createCommentVNode("", true),
                              (row?.type === 'checkbox')
                                ? (_openBlock(), _createBlock(_component_field_input_checkbox, {
                                    key: 3,
                                    name: row?.value(i),
                                    readonly: row?.readonly ?? false
                                  }, null, 8, ["name", "readonly"]))
                                : _createCommentVNode("", true),
                              _createVNode(_component_field_error, {
                                name: row?.value(i)
                              }, null, 8, ["name"])
                            ], 64))
                          : _createCommentVNode("", true)
                      ], 64))
                    : (row?.value?.(i))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          (row?.type === 'dropdown')
                            ? (_openBlock(), _createBlock(_component_field_input_dropdown, {
                                key: 0,
                                name: row?.value(i),
                                readonly: row?.readonly ?? false,
                                custom_class: 'input_dropdown_excel'
                              }, null, 8, ["name", "readonly"]))
                            : _createCommentVNode("", true),
                          (row?.type === 'text')
                            ? (_openBlock(), _createBlock(_component_field_input_text, {
                                key: 1,
                                name: row?.value(i),
                                readonly: row?.readonly ?? false,
                                custom_class: 'input_text_excel'
                              }, null, 8, ["name", "readonly"]))
                            : _createCommentVNode("", true),
                          (row?.type === 'quantity')
                            ? (_openBlock(), _createBlock(_component_field_input_quantity, {
                                key: 2,
                                name: row?.value(i),
                                readonly: row?.readonly ?? false,
                                custom_class: 'input_number_excel',
                                custom_select_class: 'input_quantity_select_excel',
                                recommendedClass: 'input_quantity_recommended'
                              }, null, 8, ["name", "readonly"]))
                            : _createCommentVNode("", true),
                          (row?.type === 'checkbox')
                            ? (_openBlock(), _createBlock(_component_field_input_checkbox, {
                                key: 3,
                                name: row?.value(i),
                                readonly: row?.readonly ?? false
                              }, null, 8, ["name", "readonly"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_field_error, {
                            name: row?.value(i)
                          }, null, 8, ["name"])
                        ], 64))
                      : _createCommentVNode("", true)
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                _cache[5] || (_cache[5] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                _cache[6] || (_cache[6] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                _createElementVNode("article", _hoisted_8, [
                  (row?.main)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn-link btn-remove-red",
                        type: "button",
                        onClick: ($event: any) => (_unref(removeItem)(i))
                      }, " Remove window ", 8, _hoisted_9))
                    : _createCommentVNode("", true)
                ])
              ])
            }), 64)),
            _createVNode(_component_field_array, {
              name: `windows[${i}].children.grid`
            }, {
              default: _withCtx(({
                    items: gridItems,
                    addItem: addItemGrid,
                    addItemAfter: addItemAfterGrid,
                    removeItem: removeItemGrid,
                }) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(gridItems, (gridItem, j) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: gridItem.key
                  }, [
                    _createElementVNode("section", _hoisted_10, [
                      _cache[9] || (_cache[9] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, "Window grid", -1)),
                      _cache[10] || (_cache[10] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[11] || (_cache[11] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[12] || (_cache[12] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[13] || (_cache[13] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _createElementVNode("article", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("button", {
                            class: "btn-link",
                            type: "button",
                            onClick: ($event: any) => (addItemAfterGrid(j))
                          }, _cache[7] || (_cache[7] = [
                            _createElementVNode("svg", {
                              width: "24px",
                              height: "24px",
                              class: "icon fill-dark stroke-green"
                            }, [
                              _createElementVNode("use", { "xlink:href": "#add" })
                            ], -1)
                          ]), 8, _hoisted_13),
                          (items.length > 1)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "btn-link",
                                type: "button",
                                onClick: ($event: any) => (removeItemGrid(j))
                              }, _cache[8] || (_cache[8] = [
                                _createElementVNode("svg", {
                                  width: "24px",
                                  height: "24px",
                                  class: "icon fill-red stroke-red"
                                }, [
                                  _createElementVNode("use", { "xlink:href": "#remove" })
                                ], -1)
                              ]), 8, _hoisted_14))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _createElementVNode("section", _hoisted_15, [
                      _cache[14] || (_cache[14] = _createElementVNode("article", { class: "quick_estimate_table_item_sub" }, "Grid size", -1)),
                      _createElementVNode("article", _hoisted_16, [
                        _createVNode(_component_field_input_dropdown, {
                          name: `windows[${i}].children.grid[${j}].children.gridSize.value`,
                          custom_class: 'input_dropdown_excel'
                        }, null, 8, ["name"]),
                        _createVNode(_component_field_error, {
                          name: `windows[${i}].children.grid[${j}].children.gridSize.value`,
                          custom_class: 'input_field_error'
                        }, null, 8, ["name"])
                      ]),
                      _cache[15] || (_cache[15] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[16] || (_cache[16] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[17] || (_cache[17] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[18] || (_cache[18] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1))
                    ]),
                    _createElementVNode("section", _hoisted_17, [
                      _cache[19] || (_cache[19] = _createElementVNode("article", { class: "quick_estimate_table_item_sub" }, "Grid pattern", -1)),
                      _createElementVNode("article", _hoisted_18, [
                        _createVNode(_component_field_input_dropdown, {
                          name: `windows[${i}].children.grid[${j}].children.gridPattern.value`,
                          custom_class: 'input_dropdown_excel'
                        }, null, 8, ["name"]),
                        _createVNode(_component_field_error, {
                          name: `windows[${i}].children.grid[${j}].children.gridPattern.value`,
                          custom_class: 'input_field_error'
                        }, null, 8, ["name"])
                      ]),
                      _cache[20] || (_cache[20] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[21] || (_cache[21] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[22] || (_cache[22] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[23] || (_cache[23] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1))
                    ]),
                    _createElementVNode("section", _hoisted_19, [
                      _cache[24] || (_cache[24] = _createElementVNode("article", { class: "quick_estimate_table_item_sub" }, "Grid color", -1)),
                      _createElementVNode("article", _hoisted_20, [
                        _createVNode(_component_field_input_dropdown, {
                          name: `windows[${i}].children.grid[${j}].children.gridColor.value`,
                          custom_class: 'input_dropdown_excel'
                        }, null, 8, ["name"]),
                        _createVNode(_component_field_error, {
                          name: `windows[${i}].children.grid[${j}].children.gridColor.value`,
                          custom_class: 'input_field_error'
                        }, null, 8, ["name"])
                      ]),
                      _cache[25] || (_cache[25] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[26] || (_cache[26] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[27] || (_cache[27] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[28] || (_cache[28] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1))
                    ])
                  ], 64))
                }), 128)),
                (gridItems.length === 0)
                  ? (_openBlock(), _createElementBlock("section", _hoisted_21, [
                      _cache[29] || (_cache[29] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, "Window grid", -1)),
                      _cache[30] || (_cache[30] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[31] || (_cache[31] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[32] || (_cache[32] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[33] || (_cache[33] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _createElementVNode("article", _hoisted_22, [
                        _createElementVNode("button", {
                          class: "btn-link btn-add-gray",
                          type: "button",
                          onClick: addItemGrid
                        }, "Add grid", 8, _hoisted_23)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["name"]),
            _cache[93] || (_cache[93] = _createElementVNode("section", { class: "quick_estimate_row_table" }, [
              _createElementVNode("article", { class: "quick_estimate_row_table_item" }, "Interior"),
              _createElementVNode("article", { class: "quick_estimate_row_table_item" }),
              _createElementVNode("article", { class: "quick_estimate_row_table_item" }),
              _createElementVNode("article", { class: "quick_estimate_row_table_item" }),
              _createElementVNode("article", { class: "quick_estimate_row_table_item" }),
              _createElementVNode("article", { class: "quick_estimate_row_table_item" })
            ], -1)),
            _createElementVNode("section", _hoisted_24, [
              _cache[34] || (_cache[34] = _createElementVNode("article", { class: "quick_estimate_table_item_sub" }, "Interior color", -1)),
              _createElementVNode("article", _hoisted_25, [
                _createVNode(_component_field_input_dropdown, {
                  name: `windows[${i}].children.interiorColor.value`,
                  custom_class: 'input_dropdown_excel'
                }, null, 8, ["name"]),
                _createVNode(_component_field_error, {
                  name: `windows[${i}].children.interiorColor.value`,
                  custom_class: 'input_field_error'
                }, null, 8, ["name"])
              ]),
              _cache[35] || (_cache[35] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
              _cache[36] || (_cache[36] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
              _cache[37] || (_cache[37] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
              _cache[38] || (_cache[38] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1))
            ]),
            _createElementVNode("section", _hoisted_26, [
              _cache[39] || (_cache[39] = _createElementVNode("article", { class: "quick_estimate_table_item_sub" }, "Finished box interior", -1)),
              _createElementVNode("article", _hoisted_27, [
                _createVNode(_component_field_input_dropdown, {
                  name: `windows[${i}].children.interiorBoxFinished.value`,
                  custom_class: 'input_dropdown_excel'
                }, null, 8, ["name"]),
                _createVNode(_component_field_error, {
                  name: `windows[${i}].children.interiorBoxFinished.value`,
                  custom_class: 'input_field_error'
                }, null, 8, ["name"])
              ]),
              _cache[40] || (_cache[40] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
              _cache[41] || (_cache[41] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
              _cache[42] || (_cache[42] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
              _cache[43] || (_cache[43] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1))
            ]),
            _createVNode(_component_field_array, {
              name: `windows[${i}].children.interiorTrim`
            }, {
              default: _withCtx(({
                    items: interiorTrimItems,
                    addItem: addItemInteriorTrim,
                    addItemAfter: addItemAfterInteriorTrim,
                    removeItem: removeItemInteriorTrim,
                }) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(interiorTrimItems, (interiorTrimItem, j) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: interiorTrimItem.key
                  }, [
                    _createElementVNode("section", _hoisted_28, [
                      _cache[46] || (_cache[46] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, "Interior trim type section", -1)),
                      _cache[47] || (_cache[47] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[48] || (_cache[48] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[49] || (_cache[49] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[50] || (_cache[50] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _createElementVNode("article", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("button", {
                            class: "btn-link",
                            type: "button",
                            onClick: ($event: any) => (addItemAfterInteriorTrim(j))
                          }, _cache[44] || (_cache[44] = [
                            _createElementVNode("svg", {
                              width: "24px",
                              height: "24px",
                              class: "icon fill-dark stroke-green"
                            }, [
                              _createElementVNode("use", { "xlink:href": "#add" })
                            ], -1)
                          ]), 8, _hoisted_31),
                          (items.length > 1)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "btn-link",
                                type: "button",
                                onClick: ($event: any) => (removeItemInteriorTrim(j))
                              }, _cache[45] || (_cache[45] = [
                                _createElementVNode("svg", {
                                  width: "24px",
                                  height: "24px",
                                  class: "icon fill-red stroke-red"
                                }, [
                                  _createElementVNode("use", { "xlink:href": "#remove" })
                                ], -1)
                              ]), 8, _hoisted_32))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _createElementVNode("section", _hoisted_33, [
                      _cache[51] || (_cache[51] = _createElementVNode("article", { class: "quick_estimate_table_item_sub" }, "Interior trim type", -1)),
                      _createElementVNode("article", _hoisted_34, [
                        _createVNode(_component_field_input_dropdown, {
                          name: `windows[${i}].children.interiorTrim[${j}].children.interiorTrimType.value`,
                          custom_class: 'input_dropdown_excel'
                        }, null, 8, ["name"]),
                        _createVNode(_component_field_error, {
                          name: `windows[${i}].children.interiorTrim[${j}].children.interiorTrimType.value`,
                          custom_class: 'input_field_error'
                        }, null, 8, ["name"])
                      ]),
                      _cache[52] || (_cache[52] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[53] || (_cache[53] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[54] || (_cache[54] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[55] || (_cache[55] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1))
                    ]),
                    _createElementVNode("section", _hoisted_35, [
                      _cache[56] || (_cache[56] = _createElementVNode("article", { class: "quick_estimate_table_item_sub" }, "Interior trim color", -1)),
                      _createElementVNode("article", _hoisted_36, [
                        _createVNode(_component_field_input_dropdown, {
                          name: `windows[${i}].children.interiorTrim[${j}].children.interiorTrimColor.value`,
                          custom_class: 'input_dropdown_excel'
                        }, null, 8, ["name"]),
                        _createVNode(_component_field_error, {
                          name: `windows[${i}].children.interiorTrim[${j}].children.interiorTrimColor.value`,
                          custom_class: 'input_field_error'
                        }, null, 8, ["name"])
                      ]),
                      _cache[57] || (_cache[57] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[58] || (_cache[58] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[59] || (_cache[59] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[60] || (_cache[60] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1))
                    ])
                  ], 64))
                }), 128)),
                (interiorTrimItems.length === 0)
                  ? (_openBlock(), _createElementBlock("section", _hoisted_37, [
                      _cache[61] || (_cache[61] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, "Interior trim type section", -1)),
                      _cache[62] || (_cache[62] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[63] || (_cache[63] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[64] || (_cache[64] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[65] || (_cache[65] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _createElementVNode("article", _hoisted_38, [
                        _createElementVNode("button", {
                          class: "btn-link btn-add-gray",
                          type: "button",
                          onClick: addItemInteriorTrim
                        }, " Add interior trim ", 8, _hoisted_39)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["name"]),
            _cache[94] || (_cache[94] = _createElementVNode("section", { class: "quick_estimate_row_table" }, [
              _createElementVNode("article", { class: "quick_estimate_row_table_item" }, "Exterior"),
              _createElementVNode("article", { class: "quick_estimate_row_table_item" }),
              _createElementVNode("article", { class: "quick_estimate_row_table_item" }),
              _createElementVNode("article", { class: "quick_estimate_row_table_item" }),
              _createElementVNode("article", { class: "quick_estimate_row_table_item" }),
              _createElementVNode("article", { class: "quick_estimate_row_table_item" })
            ], -1)),
            _createElementVNode("section", _hoisted_40, [
              _cache[66] || (_cache[66] = _createElementVNode("article", { class: "quick_estimate_table_item_sub" }, "Exterior color", -1)),
              _createElementVNode("article", _hoisted_41, [
                _createVNode(_component_field_input_dropdown, {
                  name: `windows[${i}].children.exteriorColor.value`,
                  custom_class: 'input_dropdown_excel'
                }, null, 8, ["name"]),
                _createVNode(_component_field_error, {
                  name: `windows[${i}].children.exteriorColor.value`,
                  custom_class: 'input_field_error'
                }, null, 8, ["name"])
              ]),
              _cache[67] || (_cache[67] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
              _cache[68] || (_cache[68] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
              _cache[69] || (_cache[69] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
              _cache[70] || (_cache[70] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1))
            ]),
            _createVNode(_component_field_array, {
              name: `windows[${i}].children.exteriorTrim`
            }, {
              default: _withCtx(({
                    items: exteriorTrimItems,
                    addItem: addItemExteriorTrim,
                    addItemAfter: addItemAfterExteriorTrim,
                    removeItem: removeItemExteriorTrim,
                }) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(exteriorTrimItems, (exteriorTrimItem, j) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: exteriorTrimItem.key
                  }, [
                    _createElementVNode("section", _hoisted_42, [
                      _cache[73] || (_cache[73] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, "Windows Exterior Trim", -1)),
                      _cache[74] || (_cache[74] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[75] || (_cache[75] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[76] || (_cache[76] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[77] || (_cache[77] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _createElementVNode("article", _hoisted_43, [
                        _createElementVNode("div", _hoisted_44, [
                          _createElementVNode("button", {
                            class: "btn-link",
                            type: "button",
                            onClick: ($event: any) => (addItemAfterExteriorTrim(j))
                          }, _cache[71] || (_cache[71] = [
                            _createElementVNode("svg", {
                              width: "24px",
                              height: "24px",
                              class: "icon fill-dark stroke-green"
                            }, [
                              _createElementVNode("use", { "xlink:href": "#add" })
                            ], -1)
                          ]), 8, _hoisted_45),
                          (items.length > 1)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "btn-link",
                                type: "button",
                                onClick: ($event: any) => (removeItemExteriorTrim(j))
                              }, _cache[72] || (_cache[72] = [
                                _createElementVNode("svg", {
                                  width: "24px",
                                  height: "24px",
                                  class: "icon fill-red stroke-red"
                                }, [
                                  _createElementVNode("use", { "xlink:href": "#remove" })
                                ], -1)
                              ]), 8, _hoisted_46))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _createElementVNode("section", _hoisted_47, [
                      _cache[78] || (_cache[78] = _createElementVNode("article", { class: "quick_estimate_table_item_sub" }, "Exterior trim type", -1)),
                      _createElementVNode("article", _hoisted_48, [
                        _createVNode(_component_field_input_dropdown, {
                          name: `windows[${i}].children.exteriorTrim[${j}].children.exteriorTrimType.value`,
                          custom_class: 'input_dropdown_excel'
                        }, null, 8, ["name"]),
                        _createVNode(_component_field_error, {
                          name: `windows[${i}].children.exteriorTrim[${j}].children.exteriorTrimType.value`,
                          custom_class: 'input_field_error'
                        }, null, 8, ["name"])
                      ]),
                      _cache[79] || (_cache[79] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[80] || (_cache[80] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[81] || (_cache[81] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[82] || (_cache[82] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1))
                    ]),
                    _createElementVNode("section", _hoisted_49, [
                      _cache[83] || (_cache[83] = _createElementVNode("article", { class: "quick_estimate_table_item_sub" }, "Exterior trim color", -1)),
                      _createElementVNode("article", _hoisted_50, [
                        _createVNode(_component_field_input_dropdown, {
                          name: `windows[${i}].children.exteriorTrim[${j}].children.exteriorTrimColor.value`,
                          custom_class: 'input_dropdown_excel'
                        }, null, 8, ["name"]),
                        _createVNode(_component_field_error, {
                          name: `windows[${i}].children.exteriorTrim[${j}].children.exteriorTrimColor.value`,
                          custom_class: 'input_field_error'
                        }, null, 8, ["name"])
                      ]),
                      _cache[84] || (_cache[84] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[85] || (_cache[85] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[86] || (_cache[86] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[87] || (_cache[87] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1))
                    ])
                  ], 64))
                }), 128)),
                (exteriorTrimItems.length === 0)
                  ? (_openBlock(), _createElementBlock("section", _hoisted_51, [
                      _cache[88] || (_cache[88] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, "Windows Exterior Trim", -1)),
                      _cache[89] || (_cache[89] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[90] || (_cache[90] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[91] || (_cache[91] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _cache[92] || (_cache[92] = _createElementVNode("article", { class: "quick_estimate_row_table_item" }, null, -1)),
                      _createElementVNode("article", _hoisted_52, [
                        _createElementVNode("button", {
                          class: "btn-link btn-add-gray",
                          type: "button",
                          onClick: addItemExteriorTrim
                        }, " Add exterior trim ", 8, _hoisted_53)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["name"])
          ], 64))
        }), 128))
      ]),
      _: 1
    }),
    _cache[97] || (_cache[97] = _createStaticVNode("<section class=\"quick_estimate_row_table\"><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article><article class=\"quick_estimate_row_table_item\">----</article></section>", 1))
  ], 64))
}
}

})