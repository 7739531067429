export const calculateChimneyFlashing = (colors, items) => {
  return items.map((item) => {
    const color = (colors.find((x) => x.id === item.value) ?? {}).value || "";
    return { color, feet: item.quantity };
  });
};

export const calculalteHeadFlashing = (colors, items) => {
  return items.map((item) => {
    const color =
      (colors.find((x) => x.id === item.children.headFlashingColor.value) ?? {})
        .value || "";
    return { color, feet: item.quantity };
  });
};

export const calculateCounterFlashing = (colors, items) => {
  return items.map((item) => {
    const color =
      (
        colors.find((x) => x.id === item.children.counterFlashingColor.value) ??
        {}
      ).value || "";
    return { color, feet: item.quantity };
  });
};

export const calculateFasciaWrap = (colors, items) => {
  return items.map((item) => {
    const color =
      (colors.find((x) => x.id === item.children.fasciaWrapColor.value) ?? {})
        .value || "";
    return { color, feet: item.quantity };
  });
};

export const calculateTrimCoilFeet = (items) => {
  if (!items?.length) {
    return {};
  }
  return items.reduce((obj, x) => {
    if (x.feet > 0) {
      const key = x.color.toLowerCase();
      const item =
        key in obj ? obj[key] : (obj[key] = { color: x.color, feet: 0 });
      item.feet += x.feet;
    }
    return obj;
  }, {});
};

export const calculateChimneyCaulk = (items, field, GET) => {
  return items.reduce((sum, x) => sum + x.quantity * GET(2, field, x.value), 0);
};

export const calculateCounterCaulk = (items, field, GET) => {
  return items.reduce(
    (sum, x) =>
      sum + x.quantity * GET(2, field, x.children.counterFlashingType.value),
    0
  );
};
