<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKey, estimteCostValues } from '../mocks.ts';
    // import EstimateStepHeader from '../EstimateStepHeader.vue';
    // import EstimateQuickLinks from '../EstimateQuickLinks.vue';
    // import EstimateWorkflowNotes from '../EstimateWorkflowNotes.vue';
    // import NextStepButton from '../NextStepButton.vue';
    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    // const { save, modelValues, meta, errors, validate, loading } = useEstimateSection(
    //     EstimateSections.insulationDetails
    // );

    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Open Joist Insulation',
                name: 'openJoistInsulation',
                setModelValue: (i) => `openJoistInsulation[${i}].quantity`,
                quantity: (i) => props?.modelValues.openJoistInsulation[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Open joist',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `openJoistInsulation[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Square Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `openJoistInsulation[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Knee Wall Insulation',
                name: 'kneeWallInsulation',
                setModelValue: (i) => `kneeWallInsulation[${i}].quantity`,
                quantity: (i) => props?.modelValues.kneeWallInsulation[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Knee wall',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `kneeWallInsulation[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Square Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `kneeWallInsulation[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                title: 'House Wrap',
                name: 'houseWrap',
                setModelValue: (i) => `houseWrap[${i}].quantity`,
                quantity: (i) => props?.modelValues.houseWrap[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'House wrap',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `houseWrap[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Square Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `houseWrap[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                title: 'Dense pack wall (exterior)',
                name: 'densePackWallExteriorInsulation',
                setModelValue: (i) => `densePackWallExteriorInsulation[${i}].quantity`,
                quantity: (i) => props?.modelValues.densePackWallExteriorInsulation[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Dense pack wall',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `densePackWallExteriorInsulation[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Square Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `densePackWallExteriorInsulation[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                title: 'Dense pack wall (interior)',
                name: '',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Dense pack wall',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'densePackWallInteriorInsulation.value';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Dense pack wall - interior access'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Square Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'densePackWallInteriorInsulation.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                title: 'Closed Floor Insulation',
                name: 'closedFloorInsulation',
                setModelValue: (i) => `closedFloorInsulation[${i}].quantity`,
                quantity: (i) => props?.modelValues.closedFloorInsulation[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Closed floor',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `closedFloorInsulation[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Square Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `closedFloorInsulation[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
