<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";
    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    // const listSections = computed((): SectionProps[] => {

    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: '',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                title: 'Questionnarie',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Area of work',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => 'areaOfWork.value',
                                display: 'dropdown',
                            },
                            ...emptyFields,
                        ],
                    },
                    // {
                    //     id: 2,
                    //     columns: [
                    //         {
                    //             id: 1,
                    //             label: 'Years in home',
                    //         },
                    //         {
                    //             id: 2,
                    //             type: 'Quantity',
                    //             name() {
                    //                 return 'yearsInHome.quantity';
                    //             },
                    //             inputStyle: 'dropdown',
                    //         },
                    //         ...emptyFields,
                    //     ],
                    // },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: '# of stories',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'numberOfStories.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    // {
                    //     id: 4,
                    //     columns: [
                    //         {
                    //             id: 1,
                    //             label: 'Age of siding',
                    //         },
                    //         {
                    //             id: 2,
                    //             type: 'Quantity',
                    //             inputStyle: 'dropdown',
                    //             name() {
                    //                 return 'ageOfSiding.quantity';
                    //             },
                    //         },
                    //         ...emptyFields,
                    //     ],
                    // },
                    // {
                    //     id: 5,
                    //     columns: [
                    //         {
                    //             id: 1,
                    //             label: 'Siding access',
                    //         },
                    //         {
                    //             id: 2,
                    //             type: 'Dropdown',
                    //             name() {
                    //                 return 'sidingAccess.value';
                    //             },
                    //             display: 'dropdown',
                    //         },
                    //         ...emptyFields,
                    //     ],
                    // },
                    // {
                    //     id: 6,
                    //     columns: [
                    //         {
                    //             id: 1,
                    //             label: 'Energy efficiency',
                    //         },
                    //         {
                    //             id: 2,
                    //             type: 'Dropdown',
                    //             display: 'dropdown',
                    //             name() {
                    //                 return 'energyEfficiency.value';
                    //             },
                    //         },
                    //         ...emptyFields,
                    //     ],
                    // },
                    // {
                    //     id: 7,
                    //     columns: [
                    //         {
                    //             id: 1,
                    //             label: 'Preferred timeline',
                    //         },
                    //         {
                    //             id: 2,
                    //             type: 'Dropdown',
                    //             display: 'dropdown',
                    //             name() {
                    //                 return 'preferredTimeline.value';
                    //             },
                    //         },
                    //         ...emptyFields,
                    //     ],
                    // },
                    // {
                    //     id: 8,
                    //     columns: [
                    //         {
                    //             id: 1,
                    //             label: 'Deciding factor',
                    //         },
                    //         {
                    //             id: 2,
                    //             type: 'Dropdown',
                    //             display: 'dropdown',
                    //             name() {
                    //                 return 'decidingFactor.value';
                    //             },
                    //         },
                    //         ...emptyFields,
                    //     ],
                    // },
                    // {
                    //     id: 9,
                    //     columns: [
                    //         {
                    //             id: 1,
                    //             label: 'Warranty',
                    //         },
                    //         {
                    //             id: 2,
                    //             type: 'Dropdown',
                    //             display: 'dropdown',
                    //             name() {
                    //                 return 'warranty.value';
                    //             },
                    //         },
                    //         ...emptyFields,
                    //     ],
                    // },
                    // {
                    //     id: 10,
                    //     columns: [
                    //         {
                    //             id: 1,
                    //             label: 'Budget',
                    //         },
                    //         {
                    //             id: 2,
                    //             type: 'Dropdown',
                    //             name() {
                    //                 return 'budget.value';
                    //             },
                    //         },
                    //         ...emptyFields,
                    //     ],
                    // },
                ],
            },
            // {
            //     id: 2,
            //     name: 'two-secunb',
            //     isFieldArray: false,
            //     quantity: () => 0,
            //     setModelValue: () => '',
            //     title: 'Questionnarie - Siding Problems',
            //     rows: [
            //         {
            //             id: 11,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Poor curb appeal',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'sidingProblemPoorCurbAppeal.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 12,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Maintenance',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'sidingProblemMaintenance.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 13,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Storm damage',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'sidingProblemStormDamage.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 14,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Poorly insulated',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'sidingProblemPoorlyInsulated.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 15,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'No Paint',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'sidingProblemNoPaint.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
            // {
            //     id: 3,
            //     name: 'three-secunb',
            //     isFieldArray: false,
            //     quantity: () => 0,
            //     setModelValue: () => '',
            //     title: 'Questionnarie - Gutter problems',

            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Leaking corners',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'gutterProblemLeakingCorners.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 2,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Not properly pitched',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'gutterProblemNotProperlyPitched.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 3,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Clogging',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'gutterProblemClogging.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
            // {
            //     id: 4,
            //     name: 'four-secunb',
            //     isFieldArray: false,
            //     quantity: () => 0,
            //     setModelValue: () => '',
            //     title: 'Questionnarie - Siding interest',
            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Basic vinyl',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'sidingInterestBasicVinyl.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 2,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Insulated vinyl',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'sidingInterestInsulatedVinyl.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 3,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Hard board',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'sidingInterestHardBoard.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 4,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Cedar',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Checkbox',
            //                     name: () => 'sidingInterestCedar.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
