import { DateTime } from 'luxon';

export const parseIntProp = (rawValue?: string) => {
    const value = parseInt(rawValue!);
    return isNaN(value) ? null : value;
};

export const parseBoolProp = (rawValue: string) => {
    if (rawValue === 'true') {
        return true;
    } else if (rawValue === 'false') {
        return false;
    }
    return null;
};

export const parseDateTimeProp = (rawValue: string) => {
    let value = rawValue ?? '';
    if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) {
        value = '';
    }
    const dateTime = DateTime.fromISO(value);
    return dateTime.isValid ? dateTime : null;
};
