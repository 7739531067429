import Gutter01Details from '@/components/general/quick_estimate/gutters/Gutter01Details.vue';
import Gutter02Gutter from '@/components/general/quick_estimate/gutters/Gutter02Gutter.vue';
import Gutter03ExteriorLighting from '@/components/general/quick_estimate/gutters/Gutter03ExteriorLighting.vue';
import Gutter04Extras from '@/components/general/quick_estimate/gutters/Gutter04Extras.vue';
import Insulation01Questionnaire from '@/components/general/quick_estimate/insulation/Insulation01Questionnaire.vue';
import Insulation02PreTest from '@/components/general/quick_estimate/insulation/Insulation02PreTest.vue';
import Insulation03AirSeal from '@/components/general/quick_estimate/insulation/Insulation03AirSeal.vue';
import Insulation04Details from '@/components/general/quick_estimate/insulation/Insulation04Details.vue';
import Insulation05Extras from '@/components/general/quick_estimate/insulation/Insulation05Extras.vue';
import Repair01Roof from '@/components/general/quick_estimate/repairs/Repair01Roof.vue';
import Repair02Flashing from '@/components/general/quick_estimate/repairs/Repair02Flashing.vue';
import Repair03Chimney from '@/components/general/quick_estimate/repairs/Repair03Chimney.vue';
import Repair04FlatRoof from '@/components/general/quick_estimate/repairs/Repair04FlatRoof.vue';
import Repair05Skylight from '@/components/general/quick_estimate/repairs/Repair05Skylight.vue';
import Repair06Ventilation from '@/components/general/quick_estimate/repairs/Repair06Ventilation.vue';
import Repair07Paint from '@/components/general/quick_estimate/repairs/Repair07Paint.vue';
import Repair08Extras from '@/components/general/quick_estimate/repairs/Repair08Extras.vue';
import ChimneysComponent from '@/components/general/quick_estimate/roofing/ChimneysComponent.vue';
import DeckingUnderlayment from '@/components/general/quick_estimate/roofing/DeckingUnderlayment.vue';
import DetailsComponent from '@/components/general/quick_estimate/roofing/DetailsComponent.vue';
import ExtrasComponent from '@/components/general/quick_estimate/roofing/ExtrasComponent.vue';
import FlashingComponent from '@/components/general/quick_estimate/roofing/FlashingComponent.vue';
import FlatRoofingComponent from '@/components/general/quick_estimate/roofing/FlatRoofingComponent.vue';
import PackageComponent from '@/components/general/quick_estimate/roofing/PackageComponent.vue';
import QuestionnarieComponent from '@/components/general/quick_estimate/roofing/QuestionnarieComponent.vue';
import SkylightComponent from '@/components/general/quick_estimate/roofing/SkylightComponent.vue';
import VentilationComponent from '@/components/general/quick_estimate/roofing/VentilationComponent.vue';
import Siding01QuestionnarieSection from '@/components/general/quick_estimate/siding/Siding01QuestionnarieSection.vue';
import Siding02DetailsSection from '@/components/general/quick_estimate/siding/Siding02DetailsSection.vue';
import Siding03HouseWrap from '@/components/general/quick_estimate/siding/Siding03HouseWrap.vue';
import Siding04Accessories from '@/components/general/quick_estimate/siding/Siding04Accessories.vue';
import Siding05Corners from '@/components/general/quick_estimate/siding/Siding05Corners.vue';
import Siding06Wraps from '@/components/general/quick_estimate/siding/Siding06Wraps.vue';
import Siding07Soffit from '@/components/general/quick_estimate/siding/Siding07Soffit.vue';
import Siding08Fascia from '@/components/general/quick_estimate/siding/Siding08Fascia.vue';
import Siding09Trim from '@/components/general/quick_estimate/siding/Siding09Trim.vue';
import Siding10Shutters from '@/components/general/quick_estimate/siding/Siding10Shutters.vue';
import Siding11Accents from '@/components/general/quick_estimate/siding/Siding11Accents.vue';
import Siding12Doors from '@/components/general/quick_estimate/siding/Siding12Doors.vue';
import Siding13Paint from '@/components/general/quick_estimate/siding/Siding13Paint.vue';
import Siding14Extras from '@/components/general/quick_estimate/siding/Siding14Extras.vue';
import Siding15Package from '@/components/general/quick_estimate/siding/Siding15Package.vue';
import SoffitFascia01Details from '@/components/general/quick_estimate/soffitFascia/SoffitFascia01Details.vue';
import SoffitFascia02Soffit from '@/components/general/quick_estimate/soffitFascia/SoffitFascia02Soffit.vue';
import SoffitFascia03Fascia from '@/components/general/quick_estimate/soffitFascia/SoffitFascia03Fascia.vue';
import SoffitFascia04Composite from '@/components/general/quick_estimate/soffitFascia/SoffitFascia04Composite.vue';
import SoffitFascia05Wraps from '@/components/general/quick_estimate/soffitFascia/SoffitFascia05Wraps.vue';
import SoffitFascia06Paint from '@/components/general/quick_estimate/soffitFascia/SoffitFascia06Paint.vue';
import SoffitFascia07ExteriorLighting from '@/components/general/quick_estimate/soffitFascia/SoffitFascia07ExteriorLighting.vue';
import Window01Questionnaire from '@/components/general/quick_estimate/windows/Window01Questionnaire.vue';
import Window02Details from '@/components/general/quick_estimate/windows/Window02Details.vue';
import Window03Trim from '@/components/general/quick_estimate/windows/Window03Trim.vue';
import Window04ProjectSepecs from '@/components/general/quick_estimate/windows/Window04ProjectSepecs.vue';
import { INT_MAX_VALUE } from '@/helpers';
import EstimateWorkflows from './EstimateWorkflows';

//Interfaces
type WorkflowInfo = {
    workflowId: number;
};

type WorkflowItem = {
    id: number;
    workflowId: number;
    order: number;
    name: string;
    isNotes: boolean;
    isDiscount: boolean;
    secondaryWorkflowId?: string;
    component?: any | null;
};

export default class EstimateSections {
    static roofingQuestionnaire = 1;
    static roofingDetails = 2;
    static roofingDeckUnderlayment = 3;
    static roofingFlashing = 4;
    static roofingChimneys = 5;
    static roofingSkylight = 6;
    static roofingVentilation = 7;
    static roofingFlatRoof = 8;
    static roofingExtras = 9;
    static roofingPackage = 10;
    static roofingNotes = 18;
    static roofingDiscount = 19;
    static roofingFascia = 146;
    static roofingSoffit = 147;
    static roofingExteriorLighting = 148;
    static roofingGutters = 152;

    static sidingQuestionnaire = 21;
    static sidingDetails = 22;
    static sidingHouseWrap = 23;
    static sidingAccessories = 24;
    static sidingCorners = 25;
    static sidingWraps = 26;
    static sidingTrim = 27;
    static sidingShutters = 28;
    static sidingAccents = 29;
    static sidingDoors = 30;
    static sidingExtras = 31;
    static sidingPackage = 32;
    static sidingNotes = 38;
    static sidingDiscount = 39;
    static sidingPainting = 141;
    static sidingFascia = 144;
    static sidingSoffit = 145;
    static sidingExteriorLighting = 149;
    static sidingGutters = 153;

    static windowQuestionnaire = 41;
    static windowList = 42;
    static windowTrim = 45;
    static windowProjectSpecs = 43;
    static windowPackage = 44;
    static windowNotes = 58;
    static windowDiscount = 59;

    static gutterDetails = 61;
    static gutterGutters = 66;
    // static gutterSoffit = 62;
    // static gutterFascia = 64;
    // static gutterFriezeBoard = 65;
    static gutterExtras = 63;
    static gutterNotes = 78;
    static gutterDiscount = 79;
    static gutterExteriorLighting = 150;

    static trimDetails = 121;
    static trimSoffit = 122;
    static trimFascia = 123;
    static trimComposite = 124;
    static trimWraps = 126;
    static trimExtras = 125;
    static trimNotes = 138;
    static trimDiscount = 139;
    static trimPainting = 142;
    static trimExteriorLighting = 151;
    static trimGutters = 154;

    static repairRoof = 81;
    static repairFlashing = 82;
    static repairChimney = 83;
    static repairFlatRoof = 84;
    static repairSkylight = 85;
    static repairVentilation = 86;
    static repairExtras = 87;
    static repairNotes = 98;
    static repairDiscount = 99;
    static repairPainting = 143;

    static insulationQuestionnaire = 101;
    static insulationPreTest = 102;
    static insulationAirSeal = 103;
    static insulationDetails = 104;
    static insulationExtras = 105;
    static insulationNotes = 118;
    static insulationDiscount = 119;

    static map: { [key: number]: WorkflowItem } = {
        // general
        // roofing
        [EstimateSections.roofingNotes]: {
            id: EstimateSections.roofingNotes,
            workflowId: EstimateWorkflows.roofing,
            order: 0,
            name: 'Notes',
            isNotes: true,
            isDiscount: false,
        },
        [EstimateSections.roofingDiscount]: {
            id: EstimateSections.roofingDiscount,
            workflowId: EstimateWorkflows.roofing,
            order: 0,
            name: 'Discount',
            isNotes: false,
            isDiscount: true,
        },
        [EstimateSections.roofingQuestionnaire]: {
            id: EstimateSections.roofingQuestionnaire,
            workflowId: EstimateWorkflows.roofing,
            order: 1,
            name: 'Questionnaire',
            isNotes: false,
            isDiscount: false,
            component: QuestionnarieComponent,
        },
        [EstimateSections.roofingDetails]: {
            id: EstimateSections.roofingDetails,
            workflowId: EstimateWorkflows.roofing,
            order: 2,
            name: 'Roof details',
            isNotes: false,
            isDiscount: false,
            component: DetailsComponent,
        },
        [EstimateSections.roofingDeckUnderlayment]: {
            id: EstimateSections.roofingDeckUnderlayment,
            workflowId: EstimateWorkflows.roofing,
            order: 3,
            name: 'Decking & Underlayment',
            isNotes: false,
            isDiscount: false,
            component: DeckingUnderlayment,
        },
        [EstimateSections.roofingFlashing]: {
            id: EstimateSections.roofingFlashing,
            workflowId: EstimateWorkflows.roofing,
            order: 4,
            name: 'Flashing',
            isNotes: false,
            isDiscount: false,
            component: FlashingComponent,
        },
        [EstimateSections.roofingChimneys]: {
            id: EstimateSections.roofingChimneys,
            workflowId: EstimateWorkflows.roofing,
            order: 5,
            name: 'Chimneys',
            isNotes: false,
            isDiscount: false,
            component: ChimneysComponent,
        },
        [EstimateSections.roofingSkylight]: {
            id: EstimateSections.roofingSkylight,
            workflowId: EstimateWorkflows.roofing,
            order: 6,
            name: 'Skylight',
            isNotes: false,
            isDiscount: false,
            component: SkylightComponent,
        },
        [EstimateSections.roofingVentilation]: {
            id: EstimateSections.roofingVentilation,
            workflowId: EstimateWorkflows.roofing,
            order: 7,
            name: 'Ventilation',
            isNotes: false,
            isDiscount: false,
            component: VentilationComponent,
        },
        [EstimateSections.roofingFlatRoof]: {
            id: EstimateSections.roofingFlatRoof,
            workflowId: EstimateWorkflows.roofing,
            order: 8,
            name: 'Flat roofing',
            isNotes: false,
            isDiscount: false,
            component: FlatRoofingComponent,
        },
        [EstimateSections.roofingSoffit]: {
            id: EstimateSections.roofingSoffit,
            workflowId: EstimateWorkflows.roofing,
            order: 9,
            name: 'Soffit',
            isNotes: false,
            isDiscount: false,
            component: null,
        },
        [EstimateSections.roofingFascia]: {
            id: EstimateSections.roofingFascia,
            workflowId: EstimateWorkflows.roofing,
            order: 10,
            name: 'Fascia',
            isNotes: false,
            isDiscount: false,
            component: null,
        },
        [EstimateSections.roofingExteriorLighting]: {
            id: EstimateSections.roofingExteriorLighting,
            workflowId: EstimateWorkflows.roofing,
            order: 11,
            name: 'Exterior Lighting',
            isNotes: false,
            isDiscount: false,
            component: null,
        },
        [EstimateSections.roofingGutters]: {
            id: EstimateSections.roofingGutters,
            workflowId: EstimateWorkflows.roofing,
            order: 12,
            name: 'Gutters',
            isNotes: false,
            isDiscount: false,
            component: null,
        },
        [EstimateSections.roofingExtras]: {
            id: EstimateSections.roofingExtras,
            workflowId: EstimateWorkflows.roofing,
            order: 13,
            name: 'Extras',
            isNotes: false,
            isDiscount: false,
            component: ExtrasComponent,
        },
        [EstimateSections.roofingPackage]: {
            id: EstimateSections.roofingPackage,
            workflowId: EstimateWorkflows.roofing,
            order: 14,
            name: 'Packages',
            isNotes: false,
            isDiscount: false,
            component: PackageComponent,
        },
        // siding
        [EstimateSections.sidingNotes]: {
            id: EstimateSections.sidingNotes,
            workflowId: EstimateWorkflows.siding,
            order: 0,
            name: 'Notes',
            isNotes: true,
            isDiscount: false,
        },
        [EstimateSections.sidingDiscount]: {
            id: EstimateSections.sidingDiscount,
            workflowId: EstimateWorkflows.siding,
            order: 0,
            name: 'Discount',
            isNotes: false,
            isDiscount: true,
        },
        [EstimateSections.sidingQuestionnaire]: {
            id: EstimateSections.sidingQuestionnaire,
            workflowId: EstimateWorkflows.siding,
            order: 1,
            name: 'Questionnaire',
            isNotes: false,
            isDiscount: false,
            component: Siding01QuestionnarieSection,
        },
        [EstimateSections.sidingDetails]: {
            id: EstimateSections.sidingDetails,
            workflowId: EstimateWorkflows.siding,
            order: 2,
            name: 'Details',
            isNotes: false,
            isDiscount: false,
            component: Siding02DetailsSection,
        },
        [EstimateSections.sidingHouseWrap]: {
            id: EstimateSections.sidingHouseWrap,
            workflowId: EstimateWorkflows.siding,
            order: 3,
            name: 'House Wrap',
            isNotes: false,
            isDiscount: false,
            component: Siding03HouseWrap,
        },
        [EstimateSections.sidingAccessories]: {
            id: EstimateSections.sidingAccessories,
            workflowId: EstimateWorkflows.siding,
            order: 4,
            name: 'Accessories',
            isNotes: false,
            isDiscount: false,
            component: Siding04Accessories,
        },
        [EstimateSections.sidingCorners]: {
            id: EstimateSections.sidingCorners,
            workflowId: EstimateWorkflows.siding,
            order: 5,
            name: 'Corners',
            isNotes: false,
            isDiscount: false,
            component: Siding05Corners,
        },
        [EstimateSections.sidingWraps]: {
            id: EstimateSections.sidingWraps,
            workflowId: EstimateWorkflows.siding,
            order: 6,
            name: 'Wraps',
            isNotes: false,
            isDiscount: false,
            component: Siding06Wraps,
        },
        [EstimateSections.sidingSoffit]: {
            id: EstimateSections.sidingSoffit,
            workflowId: EstimateWorkflows.siding,
            order: 7,
            name: 'Soffit',
            isNotes: false,
            isDiscount: false,
            component: Siding07Soffit,
        },
        [EstimateSections.sidingFascia]: {
            id: EstimateSections.sidingFascia,
            workflowId: EstimateWorkflows.siding,
            order: 8,
            name: 'Fascia',
            isNotes: false,
            isDiscount: false,
            component: Siding08Fascia,
        },
        [EstimateSections.sidingTrim]: {
            id: EstimateSections.sidingTrim,
            workflowId: EstimateWorkflows.siding,
            order: 9,
            name: 'Trim',
            isNotes: false,
            isDiscount: false,
            component: Siding09Trim,
        },
        [EstimateSections.sidingShutters]: {
            id: EstimateSections.sidingShutters,
            workflowId: EstimateWorkflows.siding,
            order: 10,
            name: 'Shutters',
            isNotes: false,
            isDiscount: false,
            component: Siding10Shutters,
        },
        [EstimateSections.sidingAccents]: {
            id: EstimateSections.sidingAccents,
            workflowId: EstimateWorkflows.siding,
            order: 11,
            name: 'Accents',
            isNotes: false,
            isDiscount: false,
            component: Siding11Accents,
        },
        [EstimateSections.sidingDoors]: {
            id: EstimateSections.sidingDoors,
            workflowId: EstimateWorkflows.siding,
            order: 12,
            name: 'Doors',
            isNotes: false,
            isDiscount: false,
            component: Siding12Doors,
        },
        [EstimateSections.sidingPainting]: {
            id: EstimateSections.sidingPainting,
            workflowId: EstimateWorkflows.siding,
            order: 13,
            name: 'Paint',
            isNotes: false,
            isDiscount: false,
            component: Siding13Paint,
        },
        [EstimateSections.sidingExteriorLighting]: {
            id: EstimateSections.sidingExteriorLighting,
            workflowId: EstimateWorkflows.siding,
            order: 14,
            name: 'Exterior Lighting',
            isNotes: false,
            isDiscount: false,
            component: null,
        },
        [EstimateSections.sidingGutters]: {
            id: EstimateSections.sidingGutters,
            workflowId: EstimateWorkflows.siding,
            order: 15,
            name: 'Gutters',
            isNotes: false,
            isDiscount: false,
        },
        [EstimateSections.sidingExtras]: {
            id: EstimateSections.sidingExtras,
            workflowId: EstimateWorkflows.siding,
            order: 16,
            name: 'Extras',
            isNotes: false,
            isDiscount: false,
            component: Siding14Extras,
        },
        [EstimateSections.sidingPackage]: {
            id: EstimateSections.sidingPackage,
            workflowId: EstimateWorkflows.siding,
            order: 17,
            name: 'Package',
            isNotes: false,
            isDiscount: false,
            component: Siding15Package,
        },
        // windows
        [EstimateSections.windowNotes]: {
            id: EstimateSections.windowNotes,
            workflowId: EstimateWorkflows.windows,
            order: 0,
            name: 'Notes',
            isNotes: true,
            isDiscount: false,
        },
        [EstimateSections.windowDiscount]: {
            id: EstimateSections.windowDiscount,
            workflowId: EstimateWorkflows.windows,
            order: 0,
            name: 'Discount',
            isNotes: false,
            isDiscount: true,
        },
        [EstimateSections.windowQuestionnaire]: {
            id: EstimateSections.windowQuestionnaire,
            workflowId: EstimateWorkflows.windows,
            order: 1,
            name: 'Questionnaire',
            isNotes: false,
            isDiscount: false,
            component: Window01Questionnaire,
        },
        [EstimateSections.windowList]: {
            id: EstimateSections.windowList,
            workflowId: EstimateWorkflows.windows,
            order: 2,
            name: 'Add windows',
            isNotes: false,
            isDiscount: false,
            component: Window02Details,
        },
        [EstimateSections.windowTrim]: {
            id: EstimateSections.windowTrim,
            workflowId: EstimateWorkflows.windows,
            order: 3,
            name: 'Trim',
            isNotes: false,
            isDiscount: false,
            component: Window03Trim,
        },
        [EstimateSections.windowProjectSpecs]: {
            id: EstimateSections.windowProjectSpecs,
            workflowId: EstimateWorkflows.windows,
            order: 4,
            name: 'Project specs',
            isNotes: false,
            isDiscount: false,
            component: Window04ProjectSepecs,
        },
        [EstimateSections.windowPackage]: {
            id: EstimateSections.windowPackage,
            workflowId: EstimateWorkflows.windows,
            order: 5,
            name: 'Packages',
            isNotes: false,
            isDiscount: false,
        },
        // gutters
        [EstimateSections.gutterNotes]: {
            id: EstimateSections.gutterNotes,
            workflowId: EstimateWorkflows.gutters,
            order: 0,
            name: 'Notes',
            isNotes: true,
            isDiscount: false,
        },
        [EstimateSections.gutterDiscount]: {
            id: EstimateSections.gutterDiscount,
            workflowId: EstimateWorkflows.gutters,
            order: 0,
            name: 'Discount',
            isNotes: false,
            isDiscount: true,
        },
        [EstimateSections.gutterDetails]: {
            id: EstimateSections.gutterDetails,
            workflowId: EstimateWorkflows.gutters,
            order: 1,
            name: 'Details',
            isNotes: false,
            isDiscount: false,
            component: Gutter01Details,
        },
        [EstimateSections.gutterGutters]: {
            id: EstimateSections.gutterGutters,
            workflowId: EstimateWorkflows.gutters,
            order: 2,
            name: 'Gutters',
            isNotes: false,
            isDiscount: false,
            component: Gutter02Gutter,
        },
        [EstimateSections.gutterExteriorLighting]: {
            id: EstimateSections.gutterExteriorLighting,
            workflowId: EstimateWorkflows.gutters,
            order: 3,
            name: 'Exterior Lighting',
            isNotes: false,
            isDiscount: false,
            component: Gutter03ExteriorLighting,
        },
        [EstimateSections.gutterExtras]: {
            id: EstimateSections.gutterExtras,
            workflowId: EstimateWorkflows.gutters,
            order: 4,
            name: 'Extras',
            isNotes: false,
            isDiscount: false,
            component: Gutter04Extras,
        },
        // trim
        [EstimateSections.trimNotes]: {
            id: EstimateSections.trimNotes,
            workflowId: EstimateWorkflows.trim,
            order: 0,
            name: 'Notes',
            isNotes: true,
            isDiscount: false,
        },
        [EstimateSections.trimDiscount]: {
            id: EstimateSections.trimDiscount,
            workflowId: EstimateWorkflows.trim,
            order: 0,
            name: 'Discount',
            isNotes: false,
            isDiscount: true,
        },
        [EstimateSections.trimDetails]: {
            id: EstimateSections.trimDetails,
            workflowId: EstimateWorkflows.trim,
            order: 1,
            name: 'Details',
            isNotes: false,
            isDiscount: false,
            component: SoffitFascia01Details,
        },
        [EstimateSections.trimSoffit]: {
            id: EstimateSections.trimSoffit,
            workflowId: EstimateWorkflows.trim,
            order: 2,
            name: 'Soffit',
            isNotes: false,
            isDiscount: false,
            component: SoffitFascia02Soffit,
        },
        [EstimateSections.trimFascia]: {
            id: EstimateSections.trimFascia,
            workflowId: EstimateWorkflows.trim,
            order: 3,
            name: 'Fascia',
            isNotes: false,
            isDiscount: false,
            component: SoffitFascia03Fascia,
        },
        [EstimateSections.trimComposite]: {
            id: EstimateSections.trimComposite,
            workflowId: EstimateWorkflows.trim,
            order: 4,
            name: 'Composite',
            isNotes: false,
            isDiscount: false,
            component: SoffitFascia04Composite,
        },
        [EstimateSections.trimWraps]: {
            id: EstimateSections.trimWraps,
            workflowId: EstimateWorkflows.trim,
            order: 5,
            name: 'Wraps',
            isNotes: false,
            isDiscount: false,
            component: SoffitFascia05Wraps,
        },
        [EstimateSections.trimPainting]: {
            id: EstimateSections.trimPainting,
            workflowId: EstimateWorkflows.trim,
            order: 6,
            name: 'Paint',
            isNotes: false,
            isDiscount: false,
            component: SoffitFascia06Paint,
        },
        [EstimateSections.trimExteriorLighting]: {
            id: EstimateSections.trimExteriorLighting,
            workflowId: EstimateWorkflows.trim,
            order: 7,
            name: 'Exterior Lighting',
            isNotes: false,
            isDiscount: false,
            component: SoffitFascia07ExteriorLighting,
        },
        [EstimateSections.trimGutters]: {
            id: EstimateSections.trimGutters,
            workflowId: EstimateWorkflows.trim,
            order: 8,
            name: 'Gutters',
            isNotes: false,
            isDiscount: false,
        },
        [EstimateSections.trimExtras]: {
            id: EstimateSections.trimExtras,
            workflowId: EstimateWorkflows.trim,
            order: 9,
            name: 'Extras',
            isNotes: false,
            isDiscount: false,
        },
        // repairs
        [EstimateSections.repairRoof]: {
            id: EstimateSections.repairRoof,
            workflowId: EstimateWorkflows.repairs,
            order: 1,
            name: 'Roof',
            isNotes: false,
            isDiscount: false,
            component: Repair01Roof,
        },
        [EstimateSections.repairFlashing]: {
            id: EstimateSections.repairFlashing,
            workflowId: EstimateWorkflows.repairs,
            order: 2,
            name: 'Flashing',
            isNotes: false,
            isDiscount: false,
            component: Repair02Flashing,
        },
        [EstimateSections.repairChimney]: {
            id: EstimateSections.repairChimney,
            workflowId: EstimateWorkflows.repairs,
            order: 3,
            name: 'Chimney',
            isNotes: false,
            isDiscount: false,
            component: Repair03Chimney,
        },
        [EstimateSections.repairFlatRoof]: {
            id: EstimateSections.repairFlatRoof,
            workflowId: EstimateWorkflows.repairs,
            order: 4,
            name: 'Flat Roof',
            isNotes: false,
            isDiscount: false,
            component: Repair04FlatRoof,
        },
        [EstimateSections.repairSkylight]: {
            id: EstimateSections.repairSkylight,
            workflowId: EstimateWorkflows.repairs,
            order: 5,
            name: 'Skylight',
            isNotes: false,
            isDiscount: false,
            component: Repair05Skylight,
        },
        [EstimateSections.repairVentilation]: {
            id: EstimateSections.repairVentilation,
            workflowId: EstimateWorkflows.repairs,
            order: 6,
            name: 'Ventilation',
            isNotes: false,
            isDiscount: false,
            component: Repair06Ventilation,
        },
        [EstimateSections.repairPainting]: {
            id: EstimateSections.repairPainting,
            workflowId: EstimateWorkflows.repairs,
            order: 7,
            name: 'Paint',
            isNotes: false,
            isDiscount: false,
            component: Repair07Paint,
        },
        [EstimateSections.repairExtras]: {
            id: EstimateSections.repairExtras,
            workflowId: EstimateWorkflows.repairs,
            order: 8,
            name: 'Extras',
            isNotes: false,
            isDiscount: false,
            component: Repair08Extras,
        },
        [EstimateSections.repairNotes]: {
            id: EstimateSections.repairNotes,
            workflowId: EstimateWorkflows.repairs,
            order: 0,
            name: 'Notes',
            isNotes: true,
            isDiscount: false,
        },
        [EstimateSections.repairDiscount]: {
            id: EstimateSections.repairDiscount,
            workflowId: EstimateWorkflows.repairs,
            order: 0,
            name: 'Discount',
            isNotes: false,
            isDiscount: true,
        },

        // insulation
        [EstimateSections.insulationNotes]: {
            id: EstimateSections.insulationNotes,
            workflowId: EstimateWorkflows.insulation,
            order: 0,
            name: 'Notes',
            isNotes: true,
            isDiscount: false,
        },
        [EstimateSections.insulationDiscount]: {
            id: EstimateSections.insulationDiscount,
            workflowId: EstimateWorkflows.insulation,
            order: 0,
            name: 'Discount',
            isNotes: false,
            isDiscount: true,
        },
        [EstimateSections.insulationQuestionnaire]: {
            id: EstimateSections.insulationQuestionnaire,
            workflowId: EstimateWorkflows.insulation,
            order: 1,
            name: 'Questionnaire',
            isNotes: false,
            isDiscount: false,
            component: Insulation01Questionnaire,
        },
        [EstimateSections.insulationPreTest]: {
            id: EstimateSections.insulationPreTest,
            workflowId: EstimateWorkflows.insulation,
            order: 2,
            name: 'Pre-Test',
            isNotes: false,
            isDiscount: false,
            component: Insulation02PreTest,
        },
        [EstimateSections.insulationAirSeal]: {
            id: EstimateSections.insulationAirSeal,
            workflowId: EstimateWorkflows.insulation,
            order: 3,
            name: 'Air Seal',
            isNotes: false,
            isDiscount: false,
            component: Insulation03AirSeal,
        },
        [EstimateSections.insulationDetails]: {
            id: EstimateSections.insulationDetails,
            workflowId: EstimateWorkflows.insulation,
            order: 4,
            name: 'Details',
            isNotes: false,
            isDiscount: false,
            component: Insulation04Details,
        },
        [EstimateSections.insulationExtras]: {
            id: EstimateSections.insulationExtras,
            workflowId: EstimateWorkflows.insulation,
            order: 5,
            name: 'Extras',
            isNotes: false,
            isDiscount: false,
            component: Insulation05Extras,
        },
    };

    static list = Array.from(Object.values(this.map));

    static getWorkflowId(id: number) {
        if (Object.hasOwnProperty.call(this.map, id)) {
            return this.map[id].workflowId;
        } else {
            return INT_MAX_VALUE;
        }
    }

    static getName(id: number) {
        if (Object.hasOwnProperty.call(this.map, id)) {
            return this.map[id].name;
        } else {
            return '';
        }
    }

    static getOrder(id: number) {
        if (Object.hasOwnProperty.call(this.map, id)) {
            return this.map[id].order;
        } else {
            return INT_MAX_VALUE;
        }
    }

    static getSectionsInWorkflow(workflowId: number) {
        const sections: { [key: string]: WorkflowInfo } = {};
        for (const key in this.map) {
            if (Object.hasOwnProperty.call(this.map, key)) {
                const info = this.map[key];
                if (info.workflowId === workflowId) {
                    sections[key] = info;
                }
            }
        }
        return sections;
    }

    static getNotesSectionId(workflowId: number) {
        return (this.list.find((x) => x.workflowId === workflowId && x.isNotes) || { id: null }).id;
    }

    static getDiscountSectionId(workflowId: number) {
        return (this.list.find((x) => x.workflowId === workflowId && x.isDiscount) || { id: null }).id;
    }

    static getSecondaryWorkflowId(id: number) {
        if (Object.hasOwnProperty.call(this.map, id)) {
            return this.map[id].secondaryWorkflowId;
        } else {
            return '';
        }
    }
    static getComponent(id: number) {
        if (Object.hasOwnProperty.call(this.map, id)) {
            return this.map[id].component;
        } else {
            return null;
        }
    }
}
