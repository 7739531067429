import { Router } from 'vue-router';

interface InitGoogleAnalyticsOptions {
    router: Router;
}

/**
 *
 * @param {Object} options
 * @param {import('vue-router').Router} options.router
 */

export function initGoogleAnalytics({ router }: InitGoogleAnalyticsOptions) {
    router.isReady().then(() => {
        router.afterEach((to, from, failure) => {
            if (failure) return;

            const gtag = (window as any).gtag;
            if (typeof gtag === 'function') {
                gtag('event', 'page_view', {
                    page_title: to.meta.title ?? '',
                    page_location: location.origin + to.fullPath,
                });
            }
        });
    });
}
