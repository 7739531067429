/* eslint-disable no-unused-vars */
import { ref, watch, reactive, Ref } from 'vue';

export function getFocus(focusRef: Ref<HTMLElement | null>) {
    const isOpen = ref<boolean>(false);
    const focusListeners = reactive<{
        focusout: ((event: FocusEvent) => void) | null;
    }>({
        focusout: null,
    });

    watch(isOpen, (newValue, oldValue) => {
        if (newValue && !oldValue) {
            window.addEventListener('keydown', escapeListener, true);
            focusListeners.focusout = focusOutListener;
        } else if (!newValue && oldValue) {
            window.removeEventListener('keydown', escapeListener);
            focusListeners.focusout = null;
        }
    });

    function escapeListener(event: KeyboardEvent) {
        if (event.key === 'Esc' || event.key === 'Escape') {
            isOpen.value = false;
        }
    }

    function focusOutListener(event: FocusEvent) {
        const relatedTarget = event.relatedTarget as Node | null;
        if (!focusRef.value || !focusRef.value.contains(relatedTarget)) {
            isOpen.value = false;
        }
    }

    return {
        isOpen,
        focusListeners,
    };
}
