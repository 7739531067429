<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKey } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const chimneyFlashingColorRecommended = computed(() =>
        props?.modelValues.chimneyFlashing.reduce((sum, x) => {
            const recommended =
                sum + x.quantity * props?.getRecommendedConversion(1, props?.model.chimneyFlashing.field, x.value);
            props?.setRecommendedItem(props?.model.chimneyFlashingColor.field.id, recommended);
            return recommended;
        }, 0)
    );
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: 'chimneyFlashing',
                title: 'CHIMNEY FLASHING',
                setModelValue: (i) => `chimneyFlashing[${i}].quantity`,
                quantity: (i) => props?.modelValues.chimneyFlashing[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Chimney flashing',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `chimneyFlashing[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `chimneyFlashing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'chimneyFlashingColor',
                title: 'CHIMNEY FLASHING COLOR',
                setModelValue: (i) => `chimneyFlashingColor[${i}].quantity`,
                quantity: (i) => props?.modelValues.chimneyFlashingColor[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Chimney flashing color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `chimneyFlashingColor[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `chimneyFlashingColor[${i}].quantity`;
                                },
                                recommended() {
                                    return chimneyFlashingColorRecommended?.value;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: 'chimneyFlashingColor',
                title: 'CHIMNEY EXTRAS',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Chimney chase cap',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => 'chimneyChaseCap.value',
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Chimney chase cap'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'chimneyChaseCap.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: 'tuckPointChimney',
                title: 'Tuck Point Chimney',
                setModelValue: (i) => `tuckPointChimney[${i}].quantity`,
                quantity: (i) => props?.modelValues.tuckPointChimney[i].quantity,
                isFieldArray: true,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Tuck point',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `tuckPointChimney[${i}].value`;
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Tuck point chimney'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `tuckPointChimney[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                name: '',
                title: '',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Masonry sealer',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'chimneyMasonrySealer.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
