import { url as urlHelper } from '@/helpers';
import { getDb } from '@/idbInit';
import * as localChanges from '@/idbLocalChanges';
import * as localIds from '@/idbLocalIds';
import Lead from '@/models/Lead';
import LocalChange from '@/models/LocalChange';
import LocalChangeState from '@/models/LocalChangeState';
import PaginatedList from '@/models/PaginatedList';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { abortedResponse, fetchWrap, offlineResponse } from '../_helpers';

function compareLeadsByName(a: Lead, b: Lead): number {
    if (a.lastName !== b.lastName) {
        return a.lastName < b.lastName ? -1 : 1;
    } else if (a.firstName !== b.firstName) {
        return a.firstName < b.firstName ? -1 : 1;
    } else {
        return 0;
    }
}

function compareLeadsByCompany(a: Lead, b: Lead): number {
    if (a.company !== b.company) {
        return a.company < b.company ? -1 : 1;
    } else {
        return 0;
    }
}

function compareLeadsByMeetingDate(a: Lead, b: Lead): number {
    const fallback = DateTime.fromObject({ year: 9999 });
    const aDate = a.meetingDate ? a.meetingDate : fallback;
    const bDate = b.meetingDate ? b.meetingDate : fallback;
    return aDate.toMillis() - bDate.toMillis();
}

export default {
    getComparator(sort: string): (a: Lead, b: Lead) => number {
        switch (sort) {
            case 'name-asc':
                return compareLeadsByName;
            case 'name-desc':
                return (a, b) => compareLeadsByName(b, a);
            case 'company-asc':
                return compareLeadsByCompany;
            case 'company-desc':
                return (a, b) => compareLeadsByCompany(b, a);
            case 'meeting-asc':
                return compareLeadsByMeetingDate;
            case 'meeting-desc':
                return (a, b) => compareLeadsByMeetingDate(b, a);
            default:
                return () => 0;
        }
    },
    /**
     * Get a paginated list of leads, filtered by the following parameters.
     */
    async getPaged(
        {
            searchQuery = undefined,
            userId = undefined,
            startDate = undefined,
            endDate = undefined,
            limit = undefined,
            offset = undefined,
            sort = undefined,
        }: {
            searchQuery?: string;
            userId?: number;
            startDate?: DateTime;
            endDate?: DateTime;
            limit?: number;
            offset?: number;
            sort?: string;
        } = {},
        abortSignal?: AbortSignal
    ) {
        const allowedSorts = ['name-asc', 'name-desc', 'company-asc', 'company-desc', 'meeting-asc', 'meeting-desc'];
        let path = '/api/leads';
        const query: any = {
            limit: 100,
            offset: 0,
        };
        const isSearch = typeof searchQuery === 'string' && searchQuery;
        if (isSearch) {
            query.searchQuery = searchQuery;
            path = '/api/leads/search';
            allowedSorts.unshift('relevance');
        }
        query.userId = 0; // any userId
        if (userId === null || typeof userId === 'number') {
            query.userId = userId;
        }
        if (startDate instanceof DateTime) {
            query.startDate = startDate.toISO();
        }
        if (endDate instanceof DateTime) {
            query.endDate = endDate.toISO();
        }
        if (typeof limit === 'number' && limit >= 1 && limit <= 100) {
            query.limit = limit;
        }
        if (typeof offset === 'number' && offset >= 0) {
            query.offset = offset;
        }
        if (typeof sort === 'string' && allowedSorts.includes(sort)) {
            query.sort = sort;
        } else {
            query.sort = allowedSorts[0];
        }
        const url = urlHelper(path, query);
        let response: AxiosResponse;
        try {
            const init: RequestInit = {};
            if (abortSignal instanceof AbortSignal) {
                init.signal = abortSignal;
            }
            response = await fetchWrap(url, init);
        } catch (e) {
            if (e instanceof DOMException && e.name === 'AbortError') {
                return abortedResponse();
            } else if (isSearch) {
                return offlineResponse();
            }
            const idb = await getDb();
            const data = await idb.getAll('leads');
            const model = new PaginatedList();
            model.data = data
                .map((x) => new Lead(x))
                .filter((x) => {
                    let include = true;
                    include = include && (query.userId === 0 || x.userId === query.userId);
                    include =
                        include &&
                        (!(startDate instanceof DateTime) || (x.meetingDate ? x.meetingDate >= startDate : false));
                    include =
                        include &&
                        (!(endDate instanceof DateTime) || (x.meetingDate ? x.meetingDate < endDate : false));
                    return include;
                })
                .sort(this.getComparator(query.sort))
                .slice(query.offset, query.offset + query.limit);

            return model;
        }
        if (response.status >= 200 && response.status < 300) {
            const data = response.data;
            const idb = await getDb();
            for (let i = 0; i < data.data.length; i++) {
                await idb.put('leads', data.data[i], data.data[i].id);
            }
            const model = new PaginatedList(data);
            model.data = data.data.map((x: any) => new Lead(x));
            return model;
        } else {
            return response;
        }
    },

    async getById(id: number): Promise<Lead | AxiosResponse> {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/leads/' + id);
        } catch {
            const idb = await getDb();
            const data = await idb.get('leads', id);
            return data ? new Lead(data) : offlineResponse();
        }
        if (response.status >= 200 && response.status < 300) {
            const data = response.data;
            const idb = await getDb();
            await idb.put('leads', data, data.id);
            return new Lead(data);
        } else {
            return response;
        }
    },

    async getPONumbers(id: number): Promise<any | Response> {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/leads/' + id + '/projects');
        } catch {
            return offlineResponse();
        }
        if (response.status >= 200 && response.status < 300) {
            const data = response.data;
            return data;
        } else {
            return response;
        }
    },

    async create(model: Lead): Promise<Lead | AxiosResponse> {
        const requestBody = JSON.stringify(model);
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/leads/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: requestBody,
            });
        } catch {
            const data = JSON.parse(requestBody);
            const idb = await getDb();
            if (data.id === 0) {
                data.id = await localIds.getNewId(idb, 'leads');
            }
            await idb.put('leads', data, data.id);
            await localChanges.add(
                idb,
                new LocalChange({ storeName: 'leads', id: data.id, state: LocalChangeState.added })
            );
            return new Lead(data);
        }
        if (response.status >= 200 && response.status < 300) {
            const data = response.data;
            const idb = await getDb();
            await localIds.addLocalIdMap(idb, 'leads', model.id, data.id);
            await idb.delete('leads', model.id);
            await idb.put('leads', data, data.id);
            await localChanges.deleteChange(idb, LocalChange.getKey('leads', model.id));
            return new Lead(data);
        } else {
            return response;
        }
    },

    async update(model: Lead): Promise<Lead | AxiosResponse> {
        const idb = await getDb();
        model.id = await localIds.mapLocalId(idb, 'leads', model.id);
        const requestBody = JSON.stringify(model);
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/leads/' + model.id, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: requestBody,
            });
        } catch {
            const data = JSON.parse(requestBody);
            await idb.put('leads', data, data.id);
            await localChanges.add(
                idb,
                new LocalChange({ storeName: 'leads', id: data.id, state: LocalChangeState.modified })
            );
            return new Lead(data);
        }
        if (response.status >= 200 && response.status < 300) {
            const data = JSON.parse(requestBody);
            await idb.put('leads', data, data.id);
            await localChanges.deleteChange(idb, LocalChange.getKey('leads', model.id));
            return new Lead(data);
        } else {
            return response;
        }
    },

    async setHoverJobId(leadId: number, jobId: string): Promise<Lead | AxiosResponse> {
        const idb = await getDb();
        leadId = await localIds.mapLocalId(idb, 'leads', leadId);
        const url = urlHelper('/api/leads/' + leadId + '/SetHoverJobId', { jobId });
        let response: AxiosResponse;
        try {
            response = await fetchWrap(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            });
        } catch {
            return offlineResponse();
        }
        if (response.status >= 200 && response.status < 300) {
            const data = response.data;
            await idb.put('leads', data, data.id);
            await localChanges.deleteChange(idb, LocalChange.getKey('leads', leadId));
            return new Lead(data);
        } else {
            return response;
        }
    },

    async deleteById(id: number): Promise<boolean | AxiosResponse> {
        const idb = await getDb();
        id = await localIds.mapLocalId(idb, 'leads', id);
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/leads/' + id, { method: 'DELETE' });
        } catch {
            const model = await idb.get('leads', id);
            if (!model) {
                return offlineResponse();
            }
            let cannotDelete = !!model.externalId;
            cannotDelete = cannotDelete || (await idb.countFromIndex('estimates', 'leadId', id)) > 0;
            if (cannotDelete) {
                return false;
            } else {
                await idb.delete('leads', id);
                await localChanges.add(
                    idb,
                    new LocalChange({ storeName: 'leads', id: id, state: LocalChangeState.deleted })
                );
                return true;
            }
        }
        if ((response.status >= 200 && response.status < 300) || response.status === 404) {
            await idb.delete('leads', id);
            await localChanges.deleteChange(idb, LocalChange.getKey('leads', id));
            return true;
        } else if (response.status === 409) {
            await localChanges.deleteChange(idb, LocalChange.getKey('leads', id));
            return false;
        } else {
            return response;
        }
    },

    /**
     * Get lead photos
     * @param {id} Number Lead ID to get photos.
     * @returns (async) Returns a list of photos.
     */
    async getPhotos(id: number) {
        const query = {
            id,
        };
        const path = '/api/leads/photos';
        const url = urlHelper(path, query);
        let response;
        try {
            response = await fetchWrap(url);
        } catch {
            if (response) {
                response.status = 400;
            }
        }
        if (response?.status && response?.status >= 200 && response?.status < 300) {
            const data = await response.data;
            return data;
        } else {
            return response;
        }
    },
};
