<!-- eslint-disable no-unused-vars -->
<script setup>
    import { computed, defineProps } from 'vue';

    import { calculateEstimateValues, calculateRecommended } from '@/helpers/estimator/roofing/04_flashing';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimteCostValues } from '../mocks.ts';
    // import { SectionProps } from "./types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const keyStates = {
        eaven: '1.58',
        rakeEdge: '1.60',
        valley: '1.37',
        roofToWall: '1.146',
        flashing: '1.189',
    };

    const eaveLength = computed(
        () => {
            return props?.modelValues.eaveLength.quantity;
        }
        // calculateEstimateValues(estimate.items, keyStates.eaven)
    );
    const rakeEdgeLength = computed(
        () => {
            return props?.modelValues.rakeEdgeLength.quantity;
        }
        // calculateEstimateValues(estimate.items, keyStates.rakeEdge)
    );
    const valleyLength = computed(
        () => {
            return props?.modelValues.valleyLength.quantity;
        }
        // calculateEstimateValues(estimate.items, keyStates.valley)
    );
    const roofToWallFlashingLength = computed(
        () => {
            return props?.modelValues.roofToWallFlashingLength.quantity;
        }
        // (
        //   estimate.items.find((x) => x.fieldId === keyStates.roofToWall) ?? {
        //     quantity: 0,
        //   }
        // ).quantity
    );
    const flashingLength = computed(() => calculateEstimateValues(props?.estimate.items, keyStates.flashing));
    const gutterFlashingRecommended = computed(() => {
        return calculateRecommended({
            items: props?.modelValues.gutterFlashing,
            GET_CEIL: () => eaveLength.value,
            GET_ID: props?.model.gutterFlashing.children.gutterFlashingType.field,
            SET_ID: props?.model.gutterFlashing.field.id,
            GETFN: props?.getRecommendedConversion,
            SETFN: props?.setRecommendedItem,
            GETUNITCALLBACK: (index) => props?.modelValues.gutterFlashing[index].children.gutterFlashingType.value,
        });
    });
    const valleyFlashingRecommended = computed(() => {
        return calculateRecommended({
            items: props?.modelValues.valleyFlashing,
            GET_CEIL: () => valleyLength.value,
            GET_ID: props?.model.valleyFlashing.children.valleyFlashingType.field,
            SET_ID: props?.model.valleyFlashing.field.id,
            GETFN: props?.getRecommendedConversion,
            SETFN: props?.setRecommendedItem,
            GETUNITCALLBACK: (i) => props?.modelValues.valleyFlashing[i].children.valleyFlashingType.value,
        });
    });
    const stepFlashingRecommended = computed(() => {
        return calculateRecommended({
            items: props?.modelValues.stepFlashing,
            GET_CEIL: () => roofToWallFlashingLength.value,
            GET_ID: props?.model.stepFlashing.children.stepFlashingType.field,
            SET_ID: props?.model.stepFlashing.field.id,
            GETFN: props?.getRecommendedConversion,
            SETFN: props?.setRecommendedItem,
            GETUNITCALLBACK: (i) => props?.modelValues.stepFlashing[i].children.stepFlashingType.value,
        });
    });
    const headFlashingRecommended = computed(() => {
        return calculateRecommended({
            items: props?.modelValues.headFlashing,
            GET_CEIL: () => flashingLength.value,
            GET_ID: props?.model.headFlashing.field,
            SET_ID: props?.model.headFlashing.field.id,
            GETFN: props?.getRecommendedConversion,
            SETFN: props?.setRecommendedItem,
            GETUNITCALLBACK: (i) => props?.modelValues.headFlashing[i].value,
        });
    });
    const dripEdgeRecommended = computed(() => {
        return calculateRecommended({
            items: props?.modelValues.dripEdge,
            GET_CEIL: () => rakeEdgeLength.value,
            GET_ID: props?.model.dripEdge.children.dripEdgeType.field,
            GETUNITCALLBACK: (i) => props?.modelValues.dripEdge[i].children.dripEdgeType.value,
            SET_ID: props?.model.dripEdge.field.id,
            GETFN: props?.getRecommendedConversion,
            SETFN: props?.setRecommendedItem,
        });
    });
    const roofToWallTrimTypeRecommended = computed(() => {
        if (Array.isArray(props?.modelValues.roofToWallTrim)) {
            const roofOfWall = [...(props?.modelValues.roofToWallTrim ?? [])];

            return calculateRecommended({
                items: props?.modelValues.dripEdge,
                GET_CEIL: (i) => roofOfWall[i].children.roofToWallTrimArea.quantity,
                GET_ID: props?.model.roofToWallTrim.children.roofToWallTrimType.field,
                GETUNITCALLBACK: (i) => roofOfWall[i]?.children.roofToWallTrimType.value,
                SET_ID: props?.model.roofToWallTrim.children.roofToWallTrimType.field.id,
                GETFN: props?.getRecommendedConversion,
                SETFN: props?.setRecommendedItem,
            });
        }
        return [];
    });

    const sections = computed(() => {
        return [
            {
                id: 1,
                title: 'Flashing type - Drip edge',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.dripEdge[i].quantity,
                name: 'dripEdge',
                setModelValue: (i) => `dripEdge[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                label: '',
                                type: 'Quantity',
                                name: (i) => `dripEdge[${i}].quantity`,
                                recommended: (i) => dripEdgeRecommended.value[Number(i)],
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: '(color)',
                            },
                            {
                                id: 2,
                                label: '',
                                type: 'Dropdown',
                                name: (i) => {
                                    return `dripEdge[${i}].children.dripEdgeColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: '(type)',
                            },
                            {
                                id: 2,
                                label: '',
                                type: 'Dropdown',
                                name: (i) => {
                                    return `dripEdge[${i}].children.dripEdgeType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Flashing type - Gutter apron',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.gutterFlashing[i].quantity,
                name: 'gutterFlashing',
                setModelValue: (i) => `gutterFlashing[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => `gutterFlashing[${i}].quantity`,
                                recommended: (i) => gutterFlashingRecommended.value[Number(i)],
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `gutterFlashing[${i}].children.gutterFlashingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `gutterFlashing[${i}].children.gutterFlashingType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                title: 'Flashing type - Valleys',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.valleyFlashing[i].quantity,
                name: 'valleyFlashing',
                setModelValue: (i) => {
                    return `valleyFlashing[${i}].quantity`;
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `valleyFlashing[${i}].quantity`;
                                },
                                recommended: (i) => {
                                    return valleyFlashingRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `valleyFlashing[${i}].children.valleyFlashingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `valleyFlashing[${i}].children.valleyFlashingType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                title: 'Flashing type - Step flashing ',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.stepFlashing[i].quantity,
                name: 'stepFlashing',
                setModelValue: (i) => `stepFlashing[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Step Flashing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `stepFlashing[${i}].quantity`;
                                },
                                recommended: (i) => {
                                    return stepFlashingRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `stepFlashing[${i}].children.stepFlashingColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `stepFlashing[${i}].children.stepFlashingType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                title: 'Flashing type - Head flashing',
                isFieldArray: true,
                name: 'headFlashing',
                setModelValue: (i) => `headFlashing[${i}].quantity`,
                quantity: (i) => props?.modelValues.headFlashing[i].quantity,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => `headFlashing[${i}].quantity`,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => `headFlashing[${i}].children.headFlashingColor.value`,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => `headFlashing[${i}].children.headFlashingType.value`,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 7,
                title: 'Flashing type - Roof to wall trim',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.roofToWallTrim[i].children.roofToWallTrimType.quantity,
                name: 'roofToWallTrim',
                setModelValue: (i) => `roofToWallTrim[${i}].children.roofToWallTrimType.quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Roof to wall trim',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `roofToWallTrim[${i}].children.roofToWallTrimArea.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Trim Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `roofToWallTrim[${i}].children.roofToWallTrimType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Piece',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `roofToWallTrim[${i}].children.roofToWallTrimType.quantity`;
                                },
                                recommended: (i) => {
                                    return roofToWallTrimTypeRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                title: 'Flashing type - Counter flashing',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.counterFlashing[i].quantity,
                name: 'counterFlashing',
                setModelValue: (i) => `counterFlashing[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `counterFlashing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => `counterFlashing[${i}].children.counterFlashingColor.value`,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Style',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `counterFlashing[${i}].children.counterFlashingType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },

            {
                id: 8,
                title: 'Pipe Flashing - PVC',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.pvcBoot[i].quantity,
                name: 'pvcBoot',
                setModelValue: (i) => `pvcBoot[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'PVC',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `pvcBoot[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `pvcBoot[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 9,
                title: 'Pipe Flashing - Lead',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.leadBoot[i].quantity,
                name: 'leadBoot',
                setModelValue: (i) => `leadBoot[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Lead',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `leadBoot[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `leadBoot[${i}].quantity`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                ],
            },
            {
                id: 10,
                name: 'zipperBoot',
                title: 'Pipe Flashing - Zipper',
                isFieldArray: false,
                setModelValue: (i) => '',
                quantity: (i) => 0,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'zipperBoot.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>
<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="sections"
        :errors="props?.errors"
    />
</template>
