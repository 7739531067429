/* eslint-disable no-unused-vars */
import { onMounted, onUnmounted, ref } from 'vue';

export function useDragAndDrop() {
    const dragging = ref<boolean>(false);
    const dropHandlers: Array<(files: FileList) => void> = [];

    const dropAreaListeners: {
        [key: string]: Array<(e: DragEvent) => void>;
    } = {
        dragenter: [preventDefaults, highlight],
        dragover: [preventDefaults, highlight],
        dragleave: [preventDefaults, unhighlight],
        drop: [preventDefaults, unhighlight, handleDrop],
    };

    function preventDefaults(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    function highlight() {
        dragging.value = true;
    }

    function unhighlight() {
        dragging.value = false;
    }

    function handleDrop(event: DragEvent) {
        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
            for (const handler of dropHandlers) {
                handler.call(undefined, files);
            }
        }
    }

    function addDropHandler(handler: (files: FileList) => void) {
        if (typeof handler === 'function') {
            dropHandlers.push(handler);
        }
    }

    onMounted(() => {
        (['dragenter', 'dragover', 'dragleave', 'drop'] as Array<keyof HTMLElementEventMap>).forEach((eventName) => {
            document.body.addEventListener(eventName, preventDefaults as EventListener, false);
        });
    });

    onUnmounted(() => {
        (['dragenter', 'dragover', 'dragleave', 'drop'] as Array<keyof HTMLElementEventMap>).forEach((eventName) => {
            document.body.removeEventListener(eventName, preventDefaults as EventListener, false);
        });
    });

    return {
        dragging,
        dropAreaListeners,
        addDropHandler,
    };
}
