/* eslint-disable no-unused-vars */
import EstimateFieldOption from './EstimateFieldOption';
import { fieldInfo } from './EstimateFields';
import EstimateFieldVariant from './EstimateFieldVariant';
import EstimateFieldMaterialType from './EstimateFieldMaterialType';
import { getValueFromDto as getValue } from './_helper';

const fieldIdPattern = /^\d+\.\d+$/;

export type FieldInfoType = {
    [key: string]: {
        defaultValue?: string | boolean;
        name?: string;
        description?: string;
        units?: string;
        dataType?: 'boolean' | 'number' | 'string' | 'dropdown';
        property?: any;
        allowQuantityChange?: boolean;
        allowPriceChange?: boolean;
        allowMultiple?: boolean;
        allowNull?: boolean;
        defaultOne?: boolean;
        isComparison?: boolean;
        childIds?: number[] | null;
        parentId?: number | null;
        ancestorIds?: number[] | null;
        descendantIds?: number[] | null;
        excludeFromDiscount?: boolean;
    };
};

export default class EstimateField {
    static DEFAULT_MIN = 0;
    static DEFAULT_MAX = 0;
    static DEFAULT_STEP = 1;

    id!: string;
    defaultValue1!: string;
    min!: number | null;
    max!: number | null;
    step!: number | null;
    materialCost!: number;
    laborCost!: number;
    materialPrice!: number;
    laborPrice!: number;
    recommendedConversion1!: number;
    recommendedConversion2!: number;
    recommendedConversion3!: number;
    options!: EstimateFieldOption[];
    variants!: EstimateFieldVariant[];
    materialTypes!: EstimateFieldMaterialType[];
    secondaryWorkflowId!: number | null;
    defaultValue!: {
        id: number;
        parentId: number | null;
        fieldId: string;
        value?: string | boolean | number | null;
        quantity?: number;
        materialCost?: number;
        laborCost?: number;
        materialPrice?: number;
        laborPrice?: number;
    };
    showProductTypes!: boolean;

    constructor(dto: any) {
        this.setDynamicData(dto);
    }

    setDynamicData(dto: any) {
        this.id = getValue(dto, 'id', 'string', '');
        this.defaultValue1 = getValue(dto, 'defaultValue', 'string', '');
        this.min = getValue(dto, 'min', 'number', null);
        this.max = getValue(dto, 'max', 'number', null);
        this.step = getValue(dto, 'step', 'number', null);
        this.materialCost = getValue(dto, 'materialCost', 'number', 0);
        this.laborCost = getValue(dto, 'laborCost', 'number', 0);
        this.materialPrice = getValue(dto, 'materialPrice', 'number', 0);
        this.laborPrice = getValue(dto, 'laborPrice', 'number', 0);
        this.recommendedConversion1 = getValue(dto, 'recommendedConversion1', 'number', 0);
        this.recommendedConversion2 = getValue(dto, 'recommendedConversion2', 'number', 0);
        this.recommendedConversion3 = getValue(dto, 'recommendedConversion3', 'number', 0);
        this.options = [];
        this.variants = [];
        this.materialTypes = [];
        this.secondaryWorkflowId = null;
        this.showProductTypes = dto?.showProductTypes ?? false;

        if (typeof dto === 'object' && dto !== null) {
            if (Array.isArray(dto.options)) {
                for (let i = 0; i < dto.options.length; i++) {
                    this.options.push(new EstimateFieldOption(dto.options[i]));
                }
            }
            if (Array.isArray(dto.variants)) {
                for (let i = 0; i < dto.variants.length; i++) {
                    this.variants.push(new EstimateFieldVariant(dto.variants[i]));
                }
            }
            if (Array.isArray(dto.materialTypes)) {
                for (let i = 0; i < dto.materialTypes.length; i++) {
                    this.materialTypes.push(new EstimateFieldMaterialType(dto.materialTypes[i]));
                }
            }
        }

        if (
            this.min === null ||
            this.max === null ||
            this.step === null ||
            (this.dataType === 'dropdown' && this.options.length === 0)
        ) {
            this.min = EstimateField.DEFAULT_MIN;
            this.max = EstimateField.DEFAULT_MAX;
            this.step = EstimateField.DEFAULT_STEP;
        }
        this.setDefaultValue();
    }

    setDefaultValue() {
        const staticDefaultValue = (fieldInfo[this.id] || {}).defaultValue;
        this.defaultValue = { id: 0, parentId: null, fieldId: this.id };
        if (this.dataType === 'boolean') {
            this.defaultValue.value = typeof staticDefaultValue === 'boolean' ? staticDefaultValue : false;
        } else if (this.dataType === 'number') {
            this.defaultValue.quantity = staticDefaultValue === 'max' ? this.max ?? 0 : this.min ?? 0;
        } else if (this.dataType === 'string') {
            this.defaultValue.value = '';
        } else if (this.dataType === 'dropdown') {
            if (staticDefaultValue !== null && staticDefaultValue !== undefined && this.options.length > 0) {
                this.defaultValue.value = this.options[0].id;
            } else {
                this.defaultValue.value = this.defaultValue1 !== '' ? this.defaultValue1 : null;
            }
        }

        if (this.dataType !== 'number' && this.allowQuantityChange) {
            this.defaultValue.quantity = this.min ?? 0;
        }
        if (this.allowPriceChange) {
            this.defaultValue.materialCost = 0;
            this.defaultValue.laborCost = 0;
            this.defaultValue.materialPrice = 0;
            this.defaultValue.laborPrice = 0;
        }
    }

    static getWorkflowId(fieldId: string) {
        return fieldIdPattern.test(fieldId) ? parseInt(fieldId.split('.')[0]) : 0;
    }

    optionsByMaterialType(materialType: string | null) {
        if (materialType) {
            const validMaterialTypes = this.materialTypes.filter((x) => x.materialFieldId === materialType);
            if (validMaterialTypes.length > 0) {
                return this.options.filter((option) => validMaterialTypes.some((mt) => mt.optionId === option.id));
            }
        }
        return this.options;
    }

    get workflowId() {
        return EstimateField.getWorkflowId(this.id);
    }

    get name() {
        return (fieldInfo[this.id] || {}).name;
    }

    get description() {
        return (fieldInfo[this.id] || {}).description;
    }

    get units() {
        return (fieldInfo[this.id] || {}).units || '';
    }

    get dataType() {
        return (fieldInfo[this.id] || {}).dataType;
    }

    get property() {
        return (fieldInfo[this.id] || {}).property;
    }

    get allowQuantityChange() {
        return (fieldInfo[this.id] || {}).allowQuantityChange || false;
    }

    get allowPriceChange() {
        return (fieldInfo[this.id] || {}).allowPriceChange || false;
    }

    get allowMultiple() {
        return (fieldInfo[this.id] || {}).allowMultiple || false;
    }

    get allowNull() {
        return (fieldInfo[this.id] || {}).allowNull || false;
    }

    get defaultOne() {
        return (fieldInfo[this.id] || {}).defaultOne || false;
    }

    get isComparison() {
        return (fieldInfo[this.id] || {}).isComparison || false;
    }

    get childIds() {
        return (fieldInfo[this.id] || {}).childIds || null;
    }

    get parentId() {
        return (fieldInfo[this.id] || {}).parentId || null;
    }

    get ancestorIds() {
        return (fieldInfo[this.id] || {}).ancestorIds || null;
    }

    get descendantIds() {
        return (fieldInfo[this.id] || {}).descendantIds || null;
    }

    get excludeFromDiscount() {
        return (fieldInfo[this.id] || {}).excludeFromDiscount || false;
    }
}
