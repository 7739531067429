<script setup>
    import { defineProps, inject } from 'vue';
    import { computed, onMounted, ref, watch } from 'vue';
    import { calculateEstimateField } from '@/helpers/estimator/siding/15_package';
    import PackageSwatchBanner from '@/views/estimator/estimate/PackageSwatchBanner.vue';
    import EstimateSections from '@/models/EstimateSections';
    import { getSidingPackagesQuickEstimate } from '@/composables/sidingPackages';
    import { useStore } from 'vuex';
    import { useField } from 'vee-validate';
    import { mapping as fieldMapping } from '@/models/EstimateFields';
    import { safeComputed } from '@/composables/safeComputed';
    // import { cloneDeep } from 'lodash';
    import { formatCurrency } from '@/helpers';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        validate: null,
        getItems: null,
        fieldsEstimateItems: null,
    });

    // const packageInput = ref(null);
    // const colorsShown = ref(false);
    const selectedPackageId = ref(null);
    const totalSquaresForMaterialPackage = computed(() => {
        return props?.modelValues?.totalSquaresForMaterialPackage?.quantity;
    });
    const totalSquaresForLaborPackage = computed(() => calculateEstimateField(props?.estimate.items, '2.100'));

    function updatePackageQuantity() {
        props?.setModelValue('sidingPackage.quantity', totalSquaresForMaterialPackage.value);
        props?.setModelValue('sidingColor.quantity', totalSquaresForMaterialPackage.value);
        props?.setModelValue('sidingReveal.quantity', totalSquaresForMaterialPackage.value);
        props?.setModelValue('sidingPackageInstallation.quantity', totalSquaresForLaborPackage.value);
        if (totalSquaresForMaterialPackage.value <= 0) {
            props?.setModelValue('sidingPackage.value', null);
            props?.setModelValue('sidingColor.value', null);
            props?.setModelValue('sidingReveal.value', null);
        }
    }

    // const initialModelValue = ref(null);
    const $store = useStore();
    // const filterOpen = ref(false);
    const field = computed(() => props.model.sidingPackage.field);
    // const { value: selectedPackageId } = useField(computed(() => field.value.property + '.value'));
    const { value: quantity } = useField(computed(() => field.value.property + '.quantity'));
    const fieldIds = fieldMapping[EstimateSections.sidingPackage];

    const { validate: validateFilter, value: filter } = useField('_comparison');

    const estimate = safeComputed(() => $store.state.estimator.estimate);
    const fields = safeComputed(() => $store.state.estimator.fields);
    const fieldOrder = safeComputed(() => $store.state.estimator.fieldOrder[field.value.workflowId]);
    const installationValue = computed(() => props.modelValues.sidingPackageInstallation.value);
    const installationQuantity = computed(() => props.modelValues.sidingPackageInstallation.quantity);

    const loading = inject('loading');
    const totalPrice = ref(0);
    function updateTotalPrice() {
        const workflowId = field.value.workflowId;
        const items = [];
        props.getItems(items);
        const e = estimate.value.clone();
        e.updateItems(items, fieldIds, fields.value, true);
        totalPrice.value = e.getWorkflowSummary(workflowId).total;
    }

    const options = ref([]);

    function updateOptions() {
        options.value = getSidingPackagesQuickEstimate({
            estimate: estimate.value,
            fields: fields.value,
            fieldOrder: fieldOrder.value,
            materialQty: quantity.value,
            laborOptionId: installationValue.value,
            laborQty: installationQuantity.value,
            refreshSelectedPackage: true,
            modelValues: props?.modelValues,
        });
    }

    // function onLoaded() {
    //     if (selectedPackageId.value && !field.value.options.find((x) => x.id === selectedPackageId.value)) {
    //         selectedPackageId.value = null;
    //     }
    //     for (let i = 0; i <script filter.value.length; i++) {
    //         const optionId = filter.value[i];
    //         if (!field.value.options.find((x) => x.id === optionId)) {
    //             filter.value.splice(i, 1);
    //             i--;
    //         }
    //     }
    //     const id = selectedPackageId.value;
    //     if (!id || filter.value.length === 0) {
    //         filterOpen.value = true;
    //     } else if (!filter.value.includes(id)) {
    //         filter.value = [id];
    //         filterOpen.value = true;
    //     } else {
    //         filterOpen.value = false;
    //     }
    // }
    // const handleReset = () => {
    //     const materialTypes = props?.model.sidingPackage.field.materialTypes;

    //     if (materialTypes && props?.modelValues.sidingPackage.value != null) {
    //         if (
    //             materialTypes.filter(
    //                 (x) =>
    //                     // x.materialFieldId == materialType.value.fieldOptionId &&
    //                     x.materialFieldId === props?.modelValues.materialType.value &&
    //                     x.optionId === props?.modelValues.sidingPackage.value
    //             ).length === 0
    //         ) {
    //             // colorsShown.value = false;
    //             // packageInput.value.filterOpen = true;
    //             props?.setModelValue('sidingPackage.value', null);
    //             // props?.setModelValue('sidingPackage.quantity', 0);
    //             props?.setModelValue('sidingPackageInstallation.value', null);
    //             // props?.setModelValue('sidingPackageInstallation.quantity', 0);
    //             props?.setModelValue('_comparison', []);

    //             // packageInput.value.updateOptions?.();
    //         }
    //     }
    // };
    function toggleOption(optionId) {
        if (!optionId) return;
        filter.value = [optionId];
        props?.setModelValue('sidingPackage.value', optionId);
        selectedPackageId.value = optionId;
        validateFilter();
    }
    const handleChangeSelectPackage = (e) => {
        if (!e.target?.value) return;

        toggleOption(e.target.value);
    };

    onMounted(() => {
        if (props?.modelValues?.sidingPackage?.value) {
            selectedPackageId.value = props?.modelValues?.sidingPackage?.value;
            toggleOption(selectedPackageId.value);
        }
    });

    // watch(selectedPackageId, (newValue) => toggleOption(newValue));

    onMounted(() => {
        // handleReset();
        updateOptions();
        // onLoaded();
        // initialModelValue.value = cloneDeep(props.modelValues);
    });
    watch([totalSquaresForMaterialPackage, totalSquaresForLaborPackage], updatePackageQuantity, { immediate: true });
    watch(
        () => props?.modelValues._comparison,
        () => {
            props?.validate();
        },
        { deep: true }
    );
    watch(
        [
            quantity,
            installationValue,
            installationQuantity,
            estimate,
            props?.modelValues,
            EstimateSections.sidingPackage,
        ],
        updateOptions,
        {
            deep: true,
            immediate: true,
        }
    );
    watch(
        [loading, () => props.modelValues, props?.modelValues.package, estimate],
        () => {
            updateTotalPrice();
        },
        { deep: true, immediate: true }
    );

    const costSidingInstallation = computed(() => {
        return props?.fieldsEstimateItems?.find((e) => e?.label?.toUpperCase() === 'INSTALLATION LABOR');
    });
    const costValueSidingColorPackage = computed(() => {
        return props?.fieldsEstimateItems?.find((e) => e?.label?.toUpperCase() === 'SIDING COLOR');
    });
    const handleResetPackage = () => {
        selectedPackageId.value = null;
        props?.setModelValue('sidingPackage.value', null);
        // props?.setModelValue('sidingPackage.quantity', 0);
        props?.setModelValue('sidingPackageInstallation.value', null);
        // props?.setModelValue('sidingPackageInstallation.quantity', 0);
        props?.setModelValue('_comparison', []);
    };
    watch(
        props?.modelValues.materialType,
        () => {
            handleResetPackage();
        },
        {
            deep: true,
            // immediate: true,
        }
    );
</script>
<template>
    <template v-if="totalSquaresForMaterialPackage > 0">
        <section class="quick_estimate_row_table">
            <article class="quick_estimate_row_table_item">
                <p style="font-size: 12px">Siding Installation</p>
            </article>
            <article class="quick_estimate_row_table_item">
                <field-input-dropdown
                    name="sidingPackageInstallation.value"
                    :modelValuesProps="props?.modelValues"
                    :custom_class="'input_dropdown_excel'"
                />
                <field-error name="sidingPackageInstallation.value" :custom_class="'input_field_error'" />
            </article>
            <article class="quick_estimate_row_table_item">
                {{ costSidingInstallation?.material ? formatCurrency(costSidingInstallation?.material) : '' }}
            </article>
            <article class="quick_estimate_row_table_item">
                {{ costSidingInstallation?.labor ? formatCurrency(costSidingInstallation?.labor) : '' }}
            </article>
            <article class="quick_estimate_row_table_item">
                {{ costSidingInstallation?.total ? formatCurrency(costSidingInstallation?.total) : '' }}
            </article>
            <article class="quick_estimate_row_table_item"></article>
        </section>
        <section class="quick_estimate_row_table">
            <article class="quick_estimate_row_table_item">
                <p style="font-size: 12px">Package</p>
            </article>
            <article class="quick_estimate_row_table_item input_dropdown_excel">
                <select style="width: 100%" :value="selectedPackageId" @change="handleChangeSelectPackage">
                    <option disabled value="">Select an item</option>
                    <option v-for="pckg in options" :key="`package-${pckg?.id}`" :value="pckg?.id">
                        {{ pckg?.name }}
                    </option>
                </select>
            </article>
            <article class="quick_estimate_row_table_item"></article>
            <article class="quick_estimate_row_table_item"></article>
            <article class="quick_estimate_row_table_item"></article>
            <article class="quick_estimate_row_table_item"></article>
        </section>
        <!-- <p>
            {{ options }}
        </p>
        <p>
            {{ `${selectedPackageId}` }}
        </p> -->
        <template v-if="selectedPackageId">
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item">
                    <p>
                        {{ formatCurrency(options?.find?.((e) => e?.id === selectedPackageId)?.price ?? 0) }}
                    </p>
                    <div
                        class="package-details"
                        v-html="options?.find?.((e) => e?.id === selectedPackageId)?.description"
                    ></div>
                </article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
                <article class="quick_estimate_row_table_item"></article>
            </section>
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_table_item"></article>
                <article class="quick_estimate_table_item">
                    <package-swatch-banner name="sidingColor.value" />
                    <field-label-selection name="sidingColor.value" removePrefixFieldName="sidingPackage.value" />
                    <field-input-dropdown
                        class="constrain-img"
                        name="sidingColor.value"
                        display="swatch"
                        :modelValuesProps="props?.modelValues"
                        :custom_class="'input_dropdown_excel'"
                    />
                    <field-error name="sidingColor.value" :custom_class="'input_field_error'" />
                </article>
                <article class="quick_estimate_table_item">
                    {{
                        costValueSidingColorPackage?.material
                            ? formatCurrency(costValueSidingColorPackage?.material)
                            : ''
                    }}
                </article>
                <article class="quick_estimate_table_item">
                    {{ costValueSidingColorPackage?.labor ? formatCurrency(costValueSidingColorPackage?.labor) : '' }}
                </article>
                <article class="quick_estimate_table_item">
                    {{ costValueSidingColorPackage?.total ? formatCurrency(costValueSidingColorPackage?.total) : '' }}
                </article>
                <article class="quick_estimate_table_item"></article>
            </section>
            <section class="quick_estimate_row_table">
                <article class="quick_estimate_table_item"></article>
                <article class="quick_estimate_table_item">
                    <div class="form-row mt-1-half">
                        <div class="form-group">
                            <field-input-dropdown
                                name="sidingReveal.value"
                                class="siding-reveal"
                                :modelValuesProps="props?.modelValues"
                                :custom_class="'input_dropdown_excel'"
                            />
                            <field-error name="sidingReveal.value" :custom_class="'input_field_error'" />
                        </div>
                    </div>
                </article>
                <article class="quick_estimate_table_item"></article>
                <article class="quick_estimate_table_item"></article>
                <article class="quick_estimate_table_item"></article>
                <article class="quick_estimate_table_item"></article>
            </section>
        </template>
    </template>
    <section v-else class="quick_estimate_row_table">
        <article class="quick_estimate_table_item"></article>
        <article class="quick_estimate_table_item">
            <p class="bold mb-half" style="font-size: 12px">Package is not applicable to this estimate.</p>
            <p style="font-weight: normal; font-size: 11px">
                Check the total squares entered on the
                <q>details</q>
                step, and the squares of each accent on the
                <q>accents</q>
                step if this is unexpected.
            </p>
        </article>
        <article class="quick_estimate_table_item"></article>
        <article class="quick_estimate_table_item"></article>
        <article class="quick_estimate_table_item"></article>
        <article class="quick_estimate_table_item"></article>
    </section>
</template>
<style scoped>
    .small_color_preview {
        width: 100px;
        height: 50px;
        object-fit: cover;
    }
    .container_selector_color {
        display: grid;
        grid-template-columns: 280px 1fr;
        gap: 10px;
    }
    .two_columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .row_four_columns {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 10px;
        justify-content: center;
        align-items: flex-start;
        min-height: 50px;
    }
    .selector {
        outline: none;
        border: #848484 solid 2px;
        border-radius: 5px;
        font-size: 16px;
        height: 45px;
        padding: 10px;
        transition: all 0.3s ease;
        width: 100%;
        background: white;
        position: relative;
        z-index: 1;
        min-width: 125px;
        -webkit-appearance: none;
        appearance: none;
    }
</style>
