<template>
	<input type="text" :id="id" :value="modelValue" @input="handleInput" :placeholder="placeholder" :class="class" :style="{
			width: modelValue ? `calc(${modelValue.length}ch + 25px)` : `160px`,
		}" autocomplete="off" />
	<ul v-show="searchResults.length && isOpen" class="dropdown">
		<li v-for="result in searchResults" :key="result.poNumber" @click="setSelected(result)">
			{{ result.poNumber }} - {{ result.projectType }}
		</li>
	</ul>
</template>

<script setup>
import { computed, ref, defineEmits, defineProps } from 'vue';

const props = defineProps({
	id: {
		type: String,
		required: true,
	},
	placeholder: {
		type: String,
		default: '',
	},
	class: {
		type: String,
		default: '',
	},
	items: {
		type: Array,
		default: () => [],
	},
	modelValue: {
		type: String,
		default: '',
	},
});

const emit = defineEmits(['update:modelValue']);

const searchText = ref('');
const isOpen = ref(false);

const searchResults = computed(() => {
	if (searchText.value === '') 
		return [];
	
	return props.items.filter((item) => {
		if (item.poNumber.toLowerCase().includes(searchText.value.toLowerCase()))
			return item;
	});
})

const setSelected = result => {
	isOpen.value = false;
	searchText.value = result.poNumber;
	emit('update:modelValue', result.poNumber);
}

const handleInput = (e) => {
	isOpen.value = true;
	searchText.value = e.target.value;
	emit('update:modelValue', e.target.value);
}
</script>

<style scoped>
.dropdown {
	padding-left: 4px !important;
	padding-right: 4px;
	position: absolute;
	width: auto;
	margin-top: 4px; /* Ajusta el margen superior según tus necesidades */
	background-color: white;
	border: 1px solid #ccc;
	border-top: none;
	border-radius: 0 0 5px 5px; /* Añade bordes redondeados en la parte inferior */
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	list-style: none;
	z-index: 999;
	font-size: 15px;
}

.dropdown li {
	padding: 8px;
	cursor: pointer;
}

.dropdown li:hover {
	background-color: #f0f0f0;
}
</style>
