/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import api from '@/api/index';
import { copyValues, createCallbacks } from '@/helpers';
import MessageType from '@/models/MessageType';
import Settings from '@/models/Settings';
import { AxiosResponse } from 'axios';
import { compare as jsonPatchCompare } from 'fast-json-patch';
import { useField, useFieldArray, useForm } from 'vee-validate';
import { computed, inject, onMounted, reactive, Ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useStore } from 'vuex';
import { cloneDeep } from 'lodash';
import {
    ArraySchema,
    BooleanSchema,
    DateSchema,
    NumberSchema,
    ObjectSchema,
    StringSchema,
    object as yupObject,
} from 'yup';
import { onBeforeUnload } from './beforeUnload';

export function createListeners(
    valueRef: Ref<unknown, unknown>,
    errorMessageRef: Ref<string | undefined, string | undefined>,
    handleChange: {
        (e: Event | unknown, shouldValidate?: boolean): void;
        (e: Event | unknown, shouldValidate?: boolean): void;
        (arg0: any, arg1: boolean | undefined): any;
    },
    valueTransform: Function = (x: string) => x
) {
    return computed(() => {
        const shouldValidate = errorMessageRef.value ? true : false;
        return {
            blur: (e: { target: { value: string } }) =>
                valueRef.value ? handleChange(valueTransform(e.target.value.trim())) : undefined,
            change: (e: { target: { value: string } }) => handleChange(valueTransform(e.target.value.trim())),
            input: (e: { target: { value: string } }) =>
                handleChange(valueTransform(e.target.value.trim()), shouldValidate),
        };
    });
}

type ModelInfo = {
    [key: string]: any;
    timeZone?: { value: string; schema: StringSchema<string, any, undefined, ''> };
    companyName?: { value: string; schema: StringSchema<string, any, undefined, ''> };
    companyWebsiteUrl?: {
        value: string;
        schema: StringSchema<string, any, undefined, ''>;
        customListeners: boolean;
    };
    addressStreet?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    addressCountry?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    addressCity?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    addressState?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    addressPostalCode?: { value: string; schema: any; customListeners: boolean };
    phoneNumber?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    faxNumber?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    salespersonTitle?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    license?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    themeColor?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    fromEmailAddress?: {
        value: string;
        schema: StringSchema<string | undefined, any, undefined, ''>;
        customListeners: boolean;
    };
    replyToEmailAddress?: {
        value: string;
        schema: StringSchema<string | undefined, any, undefined, ''>;
        customListeners: boolean;
    };
    smtpHost?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    smtpPort?: { value: number; schema: NumberSchema<number | undefined, any, undefined, ''> };
    smtpUserName?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    smtpPassword?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> };
    microsoftOneDriveInfo?: { value: null; schema: ObjectSchema<{}, any, {}, ''> };
    showMeasurmentPopulate?: {
        value: boolean;
        schema: BooleanSchema<boolean | undefined, any, undefined, ''>;
    };
    email?: { value: string; schema: StringSchema<string, any, undefined, ''>; customListeners: boolean } | undefined;
    password?:
        | { value: string; schema: StringSchema<string, any, undefined, ''>; customListeners: boolean }
        | undefined;
    rememberMe?: { value: boolean; schema: BooleanSchema<boolean | undefined, any, undefined, ''> } | undefined;
    firstName?: { value: any; schema: StringSchema<string, any, undefined, ''>; customListeners: boolean } | undefined;
    lastName?: { value: any; schema: StringSchema<string, any, undefined, ''>; customListeners: boolean } | undefined;
    id?: { value: number; schema: NumberSchema<number | undefined, any, undefined, ''> } | undefined;
    name?: { value: string; schema: StringSchema<string, any, undefined, ''>; customListeners: boolean } | undefined;
    active?: { value: boolean; schema: BooleanSchema<boolean | undefined, any, undefined, ''> } | undefined;
    sourceCompanyId?: { value: number; schema: NumberSchema<number | undefined, any, undefined, ''> } | undefined;
    role?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> } | undefined;
    formFile?: { value: null; schema: any } | undefined;
    provider?: { value: string; schema: StringSchema<string, any, undefined, ''> } | undefined;
    body?:
        | {
              value: { clientId: string; clientSecret: string };
              schema: ObjectSchema<
                  { clientId: string; clientSecret: string },
                  any,
                  { clientId: undefined; clientSecret: undefined },
                  ''
              >;
          }
        | undefined;
    authorizeUrl?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> } | undefined;
    externalId?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> } | undefined;
    userId?: { value: null; schema: NumberSchema<number | null | undefined, any, undefined, ''> } | undefined;
    userExternalId?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> } | undefined;
    company?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    phone?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    mobilePhone?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    street?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    city?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    state?: { value: string; schema: StringSchema<string | undefined, any, undefined, ''> } | undefined;
    zipCode?: { value: string; schema: any; customListeners: boolean } | undefined;
    description?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    projectType?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    leadStatus?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    customerNumber?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    meetingDate?: { value: null; schema: DateSchema<Date | null | undefined, any, undefined, ''> } | undefined;
    projectFolderLink?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    contractLink?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    estimationLink?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    productionLink?:
        | {
              value: string;
              schema: StringSchema<string | undefined, any, undefined, ''>;
              customListeners: boolean;
          }
        | undefined;
    paymentMethod?: { value: number; schema: NumberSchema<number, any, undefined, ''> } | undefined;
    includedWorkflowIds?:
        | { value: never[]; schema: ArraySchema<any[] | undefined, any, any[] | undefined, ''> }
        | undefined;
    promotions?:
        | { value: number; schema: any }
        | {
              value: { key: number; value: number }[];
              schema: ArraySchema<{ value?: number | null | undefined; key: number }[] | undefined, any, '', ''>;
          }
        | undefined;
    downPayment?: { value: number; schema: any } | undefined;
    zeroInterest?:
        | {
              value: any;
              schema: ObjectSchema<
                  {
                      fee: number | undefined;
                      principal: number | undefined;
                      monthlyPayment: number | undefined;
                      termInMonths: number | undefined;
                  },
                  any,
                  {},
                  ''
              >;
          }
        | undefined;
};

export function useValidation(modelInfo: ModelInfo | any, confirmOnLeave?: boolean | undefined) {
    const confirmDialog = confirmOnLeave
        ? inject<Ref<{ openDialog: (message: string, options: { closeOnConfirm: boolean }) => Promise<boolean> }>>(
              'confirmDialog'
          )
        : null;
    const $store = useStore();

    const keys = Object.keys(modelInfo);
    const initialValues: { [key: string]: any } = {};
    const validationSchema: { [key: string]: any } = {};
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const element = modelInfo[key];
        if (element.schema) {
            initialValues[key] = element.value;
            validationSchema[key] = element.schema;
        }
    }

    const {
        values,
        setErrors,
        setFieldValue: setModelValue,
        validate,
        resetForm,
        handleSubmit,
        meta,
        errors,
    } = useForm({ validationSchema: yupObject(validationSchema), initialValues });
    let model: { [key: string]: any } = {};
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const element = modelInfo[key];
        if (element.schema) {
            const customListeners = element.customListeners;
            if (element.isArray) {
                const {
                    remove: removeArrayItem,
                    push: pushArrayItem,
                    insert: insertArrayItem,
                    fields: arrayItems,
                } = useFieldArray(key);
                model[key] = {
                    items: arrayItems,
                    addItem: pushArrayItem,
                    addItemAfter: insertArrayItem,
                    removeItem: removeArrayItem,
                };
            } else {
                const { value, errorMessage, handleChange } = useField(key, undefined, {
                    validateOnValueUpdate: !customListeners,
                });
                model[key] = {
                    value,
                    error: errorMessage,
                    listeners: customListeners ? createListeners(value, errorMessage, handleChange) : null,
                };
            }
        } else {
            model[key] = element.value;
        }
    }
    model = reactive(model);

    async function handleNonOkResponse(response: AxiosResponse) {
        if (response.status === 400 || response.status === 500) {
            const serverErrors = await api.helpers.processValidationErrors(response, keys);
            setErrors(serverErrors);
        } else {
            await api.helpers.handleHttpError(response, '');
        }
    }

    onBeforeRouteLeave(async (to, from) => {
        if (meta.value.dirty && to.path !== from.path && confirmDialog && confirmDialog.value) {
            const navigationConfirmed = await confirmDialog.value.openDialog(
                'Do you really want to leave? You have unsaved changes!',
                { closeOnConfirm: true }
            );
            // return false to cancel the navigation and stay on the same page
            if (!navigationConfirmed) return false;
        }
    });

    if (confirmOnLeave) {
        onBeforeUnload(() => meta.value.dirty);
    }

    return {
        $store,
        setErrors,
        resetForm,
        validate,
        handleSubmit,
        meta,
        errors,
        model,
        modelValues: values,
        setModelValue,
        initialValues,
        handleNonOkResponse,
    };
}

export function useSettingsValidation(
    modelInfo: ModelInfo,
    confirmDialog: boolean | undefined,
    saveLoading: Ref<boolean, boolean>
) {
    const { resetForm, handleSubmit, model, handleNonOkResponse, $store, setErrors, setModelValue, modelValues } =
        useValidation(modelInfo, confirmDialog);

    const { addCallback: onBeforeSaveData, callCallbacks: callOnBeforeSaveData } = createCallbacks();
    const { addCallback: onBeforeShowData, callCallbacks: callOnBeforeShowData } = createCallbacks();

    function settingsToValues(settings: Settings) {
        const values: { [key: string]: any } = {};
        for (const key in modelInfo) {
            if (Object.hasOwnProperty.call(modelInfo, key)) {
                if (Object.hasOwnProperty.call(settings, key)) {
                    values[key] = settings[key];
                } else {
                    values[key] = modelInfo[key].value;
                }
            }
        }
        callOnBeforeShowData(values);
        return values;
    }

    interface Settings {
        [key: string]: any;
    }

    const save = handleSubmit(async (values: any, actions) => {
        saveLoading.value = true;
        const defaultSettings: Settings = new Settings({});
        for (const key of Object.keys(modelInfo)) {
            if (!(key in values)) {
                values[key] = defaultSettings[key];
            }
        }

        const settings = copyValues(new Settings($store.state.settings), values);
        callOnBeforeSaveData(settings);
        const currentData = JSON.parse(JSON.stringify($store.state.settings));
        const newData = JSON.parse(JSON.stringify(settings));
        const patch = jsonPatchCompare(currentData, newData);
        if (patch.length > 0) {
            const response = await api.settings.update(patch);
            if (response instanceof Settings) {
                values = settingsToValues(new Settings(response as Settings));
                actions.resetForm({ values });
                $store.dispatch('addMessage', { message: 'Saved', type: MessageType.success, autoClose: true });
                $store.commit('setSettings', response);
            } else {
                await handleNonOkResponse(response);
            }
        } else {
            $store.dispatch('addMessage', {
                message: 'No changes to save',
                type: MessageType.success,
                autoClose: true,
            });
        }
        saveLoading.value = false;
    });

    function fetchData() {
        const obj = cloneDeep($store.state.settings);
        const values = settingsToValues(new Settings(obj));
        resetForm({ values });
    }
    onMounted(fetchData);

    return {
        model,
        modelValues,
        save,
        setErrors,
        onBeforeSaveData,
        onBeforeShowData,
        setModelValue,
    };
}

/*
export function useAppSettingsValidation(modelInfo, confirmDialog, saveLoading) {
	const savedData = ref(null);
	const { resetForm, handleSubmit, model, handleNonOkResponse, $store, setErrors } = useValidation(modelInfo, confirmDialog);

	const save = handleSubmit(async (values, actions) => {
		saveLoading.value = true;
		const settings = copyValues(new AppSettings(savedData.value), values);
		const response = await api.appSettings.update(settings, savedData.value);
		if (response instanceof AppSettings) {
			savedData.value = response;
			actions.resetForm({ values });
			$store.dispatch('addMessage', { message: 'Saved', type: MessageType.success, autoClose: true });
		} else {
			await handleNonOkResponse(response);
		}
		saveLoading.value = false;
	});

	async function fetchData() {
		const response = await api.appSettings.getAppSettings();
		if (!(response instanceof AppSettings)) {
			await handleNonOkResponse(response);
			return;
		}
		savedData.value = response;
		const values = {};
		for (const key in modelInfo) {
			if (Object.hasOwnProperty.call(modelInfo, key) && Object.hasOwnProperty.call(savedData.value, key)) {
				values[key] = savedData.value[key];
			}
		}
		resetForm({ values });
	}
	onMounted(fetchData);

	return {
		model,
		save,
		setErrors,
	};
}
*/
