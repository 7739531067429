interface LocalIdMapDTO {
    storeName?: string;
    oldId?: number;
    newId?: number;
}

class LocalIdMap {
    storeName: string;
    oldId: number;
    newId: number;

    constructor(dto: Partial<LocalIdMapDTO> = {}) {
        this.storeName = dto.storeName ?? '';
        this.oldId = dto.oldId ?? 0;
        this.newId = dto.newId ?? 0;
    }

    get key() {
        return LocalIdMap.getKey(this.storeName, this.oldId);
    }

    static getKey(storeName: string, oldId: number): string {
        return `${storeName}:${oldId}`;
    }
}

export default LocalIdMap;
