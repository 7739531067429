import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

import $store from '@/store/index';
import { computed, ref, watch } from 'vue';
import AppMessage from './AppMessage.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppMessages',
  setup(__props) {

const messageContainer = ref(null);
const messages = computed(() => $store.state.messages);
const overflow = ref(false);

function isOverflown(element: Element) {
	return element.scrollHeight > element.clientHeight;
}
watch(messages.value, async () => {
	overflow.value = isOverflown(messageContainer.value!);
}, { flush: 'post' });

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-message-float", { overflow: overflow.value }])
  }, [
    _createElementVNode("div", {
      class: "inner",
      ref_key: "messageContainer",
      ref: messageContainer
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messages.value, (message) => {
        return (_openBlock(), _createBlock(AppMessage, {
          key: message.id,
          message: message
        }, null, 8, ["message"]))
      }), 128))
    ], 512)
  ], 2)), [
    [_vShow, messages.value.length > 0]
  ])
}
}

})