import {
    getEstimateItemsFromValues,
    getFieldValuesFromEstimate,
    getSelectedOptionsForVariant,
    getValidFieldOptions,
    sortFieldIds,
    useRecommended,
} from '@/composables/estimate';
import { escapeRegex } from '@/helpers';
import Estimate from '@/models/Estimate';
import EstimateItem from '@/models/EstimateItem';
import EstimateWorkflows from '@/models/EstimateWorkflows';
import { ref } from 'vue';

const packageFieldId = '1.132';
const colorFieldId = '1.133';
const laborFieldId = '1.134';
const iceBarrierFieldId = '1.57';
const underlaymentFieldId = '1.59';
const startersFieldId = '1.61';
const hipRidgeFieldId = '1.62';
// eaveLength, rakeEdgeLength, hipRidgeLength, valleyLength, roofToWallFlashingLength, iceBarrierRows, totalSquaresForMaterial
const otherFieldIds = ['1.58', '1.60', '1.63', '1.37', '1.146', '1.36', '1.168', '1.189', '1.89', '1.43'];

interface GetRoofingPackagesParams {
    estimate: any;
    fields: Record<string, any>;
    fieldOrder: string[];
    materialQty?: number;
    laborOptionId?: string;
    laborQty?: number;
    refreshSelectedPackage?: boolean;
    getItems?: boolean;
    comparisonOptions?: { [key: string]: boolean } | any;
    comparisonItems?: { [key: string]: any };
    marginUpdated?: boolean;
    modelValues?: any;
}

interface PackageOption {
    label?: string;
    estimate?: any;
    id: string | number;
    name: string;
    description: string;
    imageUrl: string;
    price: number;
    selected: boolean;
    color: any | null;
    colors: number;
    modelValues: null;
}

export function getRoofingPackagesInQuickEstimate({
    estimate,
    fields,
    fieldOrder,
    materialQty = undefined,
    laborOptionId = undefined,
    laborQty = undefined,
    refreshSelectedPackage = false,
    comparisonOptions = {},
    comparisonItems = {},
    marginUpdated = false,
    modelValues,
}: GetRoofingPackagesParams): PackageOption[] {
    if (!estimate || !fields) {
        return [];
    }
    let fieldIds = [
        packageFieldId,
        colorFieldId,
        laborFieldId,
        iceBarrierFieldId,
        underlaymentFieldId,
        startersFieldId,
        hipRidgeFieldId,
        ...otherFieldIds,
    ];
    fieldIds = sortFieldIds(fieldIds, fieldOrder);

    const packageField = fields[packageFieldId];
    const colorField = fields[colorFieldId];
    const laborField = fields[laborFieldId];

    const updateEstimateWorkflowSummary = () => {
        const e = new Estimate({});
        e.items = estimate.items.filter((x: { workflowId: string }) => x.workflowId === workflowId);
        const workflowKey = EstimateWorkflows.map[workflowId].key;
        e.workflowSummaries[workflowKey] = estimate.workflowSummaries[workflowKey];
        return e.clone();
    };

    const workflowId = packageField.workflowId;
    estimate = updateEstimateWorkflowSummary();

    type InstallPitch = {
        quantity: number;
        value?: string;
    };

    interface DefaultModelValues {
        [key: string]: {
            quantity: number;
            value?: string;
            installPitch: InstallPitch[];
        };
    }
    const defaultModelValues: DefaultModelValues =
        modelValues ?? getFieldValuesFromEstimate(estimate.itemTreeByField, fields, fieldIds);

    if (
        !(
            defaultModelValues[packageField.property]?.quantity &&
            defaultModelValues[packageField.property].quantity > 0
        ) &&
        !(materialQty !== undefined && !isNaN(materialQty) && materialQty > 0)
    ) {
        return [];
    }
    // set quantities
    if (materialQty !== undefined && !isNaN(materialQty) && materialQty > 0) {
        defaultModelValues[packageField.property].quantity = materialQty;
        defaultModelValues[colorField.property].quantity = materialQty;
    }
    if (laborOptionId !== undefined && laborField) {
        defaultModelValues[laborField.property].value = laborOptionId;
    }
    if (laborQty !== undefined && !isNaN(laborQty) && laborQty > 0 && laborField) {
        defaultModelValues[laborField.property].quantity = laborQty;
    }

    // <<<<<<< HEAD
    const { getRecommendedConversion } = useRecommended(ref(fields));
    // =======

    // >>>>>>> origin/staging

    // calculate estimate workflow total for each comparison option
    const undecidedPattern = /undecided/i;

    const pkg = packageField
        .optionsByMaterialType(defaultModelValues.materialType.value)
        .map((packageOption: { id: string | number; value: string; description: string; image: { url: string } }) => {
            if (comparisonOptions && comparisonOptions[packageOption.id] && !marginUpdated) {
                return comparisonOptions[packageOption.id];
            }
            const e = estimate.clone();
            const modelValues = JSON.parse(JSON.stringify(defaultModelValues));
            const po: PackageOption = {
                id: packageOption.id,
                name: packageOption.value,
                description: packageOption.description,
                imageUrl: packageOption.image ? packageOption.image.url : '',
                price: 0,
                selected: packageOption.id === modelValues[packageField.property].value,
                color: null,
                colors: 0,
                estimate: null,
                modelValues: null,
            };
            const allValidOptions: Record<string, any> = {};
            if (!po.selected || refreshSelectedPackage) {
                // set package
                modelValues[packageField.property].value = packageOption.id;
                // check other fields, setting them to the first valid option if needed
                for (const fieldId of fieldIds) {
                    if (fieldId === packageFieldId) continue;
                    const field = fields[fieldId];
                    const selectedOptions = getSelectedOptionsForVariant(
                        fields,
                        e.itemTreeByField,
                        modelValues,
                        field.property
                    );
                    const validOptions = getValidFieldOptions(selectedOptions, field);
                    const selectedValue = modelValues[field.property].value;
                    const newValue =
                        validOptions.length > 0
                            ? (validOptions.find((x) => x.id === selectedValue) ?? validOptions[0]).id
                            : null;
                    modelValues[field.property].value = newValue;
                    allValidOptions[fieldId] = validOptions;

                    if (field.allowQuantityChange) {
                        let recommended = -1;
                        // these must match the recommend calcs on the roofing package page
                        if (field.id === iceBarrierFieldId) {
                            const feetPerUnit = getRecommendedConversion(1, field, modelValues.iceBarrier.value);

                            if (feetPerUnit === 0) {
                                recommended = 0;
                            }
                            if (recommended !== 0) {
                                const roofToWallFlashingLength = modelValues.roofToWallFlashingLength.quantity / 2;
                                const valleyLength = modelValues.valleyLength.quantity;
                                const flashingLength = modelValues.flashingLength.quantity;
                                const iceBarrierRows = modelValues.iceBarrierRows.quantity;
                                const eaveLength = modelValues.eaveLength.quantity;
                                const sum =
                                    roofToWallFlashingLength +
                                    valleyLength +
                                    flashingLength +
                                    iceBarrierRows * eaveLength;
                                const result = Math.ceil(sum / feetPerUnit);
                                recommended = result;
                            }
                        } else if (field.id === underlaymentFieldId) {
                            const squarePerUnit = getRecommendedConversion(1, field, modelValues.underlayment.value);
                            let squares = modelValues.totalSquaresForMaterial.quantity;

                            if (
                                Array.isArray(defaultModelValues.installPitch) &&
                                defaultModelValues.installPitch.length === 1 &&
                                defaultModelValues.installPitch.some((x) => x.value === '1.43.1')
                            ) {
                                squares = defaultModelValues.installPitch[0].quantity;
                            }

                            recommended = squarePerUnit === 0 ? 0 : Math.ceil(squares / squarePerUnit);
                        } else if (field.id === startersFieldId) {
                            const feetPerUnit = getRecommendedConversion(1, field, modelValues.starters.value);
                            recommended =
                                feetPerUnit === 0
                                    ? 0
                                    : Math.ceil(
                                          (modelValues.eaveLength.quantity + modelValues.rakeEdgeLength.quantity) /
                                              feetPerUnit
                                      );
                        } else if (field.id === hipRidgeFieldId) {
                            const feetPerUnit = getRecommendedConversion(1, field, modelValues.hipRidge.value);
                            recommended =
                                feetPerUnit === 0 ? 0 : Math.ceil(modelValues.hipRidgeLength.quantity / feetPerUnit);
                        }
                        if (recommended >= 0) {
                            modelValues[field.property].quantity = recommended;
                        }
                    }
                }
                let modelValuesBck = modelValues;
                if (comparisonItems[po.id]) {
                    modelValuesBck = Object.assign({}, modelValues, comparisonItems[po.id]);

                    po.modelValues = modelValuesBck;
                }

                const newItems = getEstimateItemsFromValues(modelValues, fieldIds, e.itemTreeByField, fields);
                e.updateItems(newItems, fieldIds, fields, true);
            } else {
                const fieldId = colorFieldId;
                const field = fields[fieldId];
                const selectedOptions = getSelectedOptionsForVariant(
                    fields,
                    e.itemTreeByField,
                    modelValues,
                    field.property
                );
                const validOptions = getValidFieldOptions(selectedOptions, field);
                allValidOptions[fieldId] = validOptions;
            }
            const packageOptionRegex = new RegExp(escapeRegex(packageOption.value), 'i');

            const colors = allValidOptions[colorFieldId].map(
                (x: { id: string | number; value: string; image: { url: string } }) => ({
                    id: x.id,
                    name: x.value.replace(packageOptionRegex, '').trim(),
                    imageUrl: x.image ? x.image.url : '',
                    selected: false,
                })
            );
            po.colors = colors.filter((x: { name: string }) => !undecidedPattern.test(x.name)).length;
            if (colors.length > 0) {
                po.color = colors.find((x: { id: string | number }) => x.id === modelValues[colorField.property].value);
                if (po.color) {
                    po.color.selected = po.selected && !undecidedPattern.test(po.color.name);
                }
            }
            po.price = e.getWorkflowSummary(workflowId).totalWithMargin;
            po.estimate = e;
            return po;
        })
        .sort((a: { price: number }, b: { price: number }) => a.price - b.price);

    return pkg;
}

export function getRoofingPackages({
    estimate,
    fields,
    fieldOrder,
    materialQty = undefined,
    laborOptionId = undefined,
    laborQty = undefined,
    refreshSelectedPackage = false,
    comparisonOptions = {},
    comparisonItems = {},
    marginUpdated = false,
}: GetRoofingPackagesParams): PackageOption[] {
    if (!estimate || !fields) {
        return [];
    }
    let fieldIds = [
        packageFieldId,
        colorFieldId,
        laborFieldId,
        iceBarrierFieldId,
        underlaymentFieldId,
        startersFieldId,
        hipRidgeFieldId,
        ...otherFieldIds,
    ];
    fieldIds = sortFieldIds(fieldIds, fieldOrder);

    const packageField = fields[packageFieldId];
    const colorField = fields[colorFieldId];
    const laborField = fields[laborFieldId];

    const workflowId = packageField.workflowId;
    estimate = (() => {
        const e = new Estimate({}); //no interface created
        e.items = estimate.items.filter((x: { workflowId: string }) => x.workflowId === workflowId);
        const workflowKey = EstimateWorkflows.map[workflowId].key;
        e.workflowSummaries[workflowKey] = estimate.workflowSummaries[workflowKey];
        return e.clone();
    })();

    type InstallPitch = {
        quantity: number;
        value?: string;
    };

    interface DefaultModelValues {
        [key: string]: {
            quantity: number;
            value?: string;
            installPitch: InstallPitch[];
        };
    }

    const defaultModelValues: DefaultModelValues = getFieldValuesFromEstimate(
        estimate.itemTreeByField,
        fields,
        fieldIds
    ) as DefaultModelValues;
    if (
        !(
            defaultModelValues[packageField.property]?.quantity &&
            defaultModelValues[packageField.property].quantity > 0
        ) &&
        !(materialQty !== undefined && !isNaN(materialQty) && materialQty > 0)
    ) {
        return [];
    }
    // set quantities
    if (materialQty !== undefined && !isNaN(materialQty) && materialQty > 0) {
        defaultModelValues[packageField.property].quantity = materialQty;
        defaultModelValues[colorField.property].quantity = materialQty;
    }
    if (laborOptionId !== undefined && laborField) {
        defaultModelValues[laborField.property].value = laborOptionId;
    }
    if (laborQty !== undefined && !isNaN(laborQty) && laborQty > 0 && laborField) {
        defaultModelValues[laborField.property].quantity = laborQty;
    }

    const comparisons = estimate.comparisons;
    const { getRecommendedConversion } = useRecommended(ref(fields));

    // calculate estimate workflow total for each comparison option
    const undecidedPattern = /undecided/i;

    const pkg = packageField
        .optionsByMaterialType(defaultModelValues.materialType.value)
        .map((packageOption: { id: string | number; value: string; description: string; image: { url: string } }) => {
            if (comparisonOptions && comparisonOptions[packageOption.id] && !marginUpdated) {
                return comparisonOptions[packageOption.id];
            }

            const e = estimate.clone();
            const modelValues = JSON.parse(JSON.stringify(defaultModelValues));
            const po: PackageOption = {
                id: packageOption.id,
                name: packageOption.value,
                description: packageOption.description,
                imageUrl: packageOption.image ? packageOption.image.url : '',
                price: 0,
                selected: packageOption.id === modelValues[packageField.property].value,
                color: null,
                colors: 0,
                estimate: null,
                modelValues: null,
            };
            const allValidOptions: Record<string, any> = {};
            if (!po.selected || refreshSelectedPackage) {
                // set package
                modelValues[packageField.property].value = packageOption.id;
                // check other fields, setting them to the first valid option if needed
                for (const fieldId of fieldIds) {
                    if (fieldId === packageFieldId) continue;
                    const field = fields[fieldId];
                    const itemTree = (e.itemTreeByField as Record<string, EstimateItem[]>) || {};
                    const selectedOptions = getSelectedOptionsForVariant(fields, itemTree, modelValues, field.property);
                    const validOptions = getValidFieldOptions(selectedOptions, field);
                    const selectedValue = modelValues[field.property].value;
                    const newValue =
                        validOptions.length > 0
                            ? (validOptions.find((x) => x.id === selectedValue) ?? validOptions[0]).id
                            : null;
                    modelValues[field.property].value = newValue;
                    allValidOptions[fieldId] = validOptions;

                    if (field.allowQuantityChange) {
                        let recommended = -1;
                        // these must match the recommend calcs on the roofing package page
                        if (field.id === iceBarrierFieldId) {
                            const feetPerUnit = getRecommendedConversion(1, field, modelValues.iceBarrier.value);
                            recommended =
                                feetPerUnit === 0
                                    ? 0
                                    : Math.ceil(
                                          (modelValues.roofToWallFlashingLength.quantity / 2 +
                                              modelValues.valleyLength.quantity +
                                              modelValues.flashingLength.quantity +
                                              modelValues.iceBarrierRows.quantity * modelValues.eaveLength.quantity) /
                                              feetPerUnit
                                      );
                        } else if (field.id === underlaymentFieldId) {
                            const squarePerUnit = getRecommendedConversion(1, field, modelValues.underlayment.value);
                            let squares = modelValues.totalSquaresForMaterial.quantity;

                            if (
                                Array.isArray(defaultModelValues.installPitch) &&
                                defaultModelValues.installPitch.length === 1 &&
                                defaultModelValues.installPitch.some((x) => x.value === '1.43.1')
                            ) {
                                squares = defaultModelValues.installPitch[0].quantity;
                            }

                            recommended = squarePerUnit === 0 ? 0 : Math.ceil(squares / squarePerUnit);
                        } else if (field.id === startersFieldId) {
                            const feetPerUnit = getRecommendedConversion(1, field, modelValues.starters.value);
                            recommended =
                                feetPerUnit === 0
                                    ? 0
                                    : Math.ceil(
                                          (modelValues.eaveLength.quantity + modelValues.rakeEdgeLength.quantity) /
                                              feetPerUnit
                                      );
                        } else if (field.id === hipRidgeFieldId) {
                            const feetPerUnit = getRecommendedConversion(1, field, modelValues.hipRidge.value);
                            recommended =
                                feetPerUnit === 0 ? 0 : Math.ceil(modelValues.hipRidgeLength.quantity / feetPerUnit);
                        }
                        if (recommended >= 0) {
                            modelValues[field.property].quantity = recommended;
                        }
                    }
                }

                let modelValuesBck = modelValues;
                if (comparisonItems[po.id] && !marginUpdated) {
                    modelValuesBck = Object.assign({}, modelValues, comparisonItems[po.id]);

                    po.modelValues = modelValuesBck;
                }

                const newItems = getEstimateItemsFromValues(modelValuesBck, fieldIds, e.itemTreeByField, fields);
                e.updateItems(newItems, fieldIds, fields, true);
            } else {
                const fieldId = colorFieldId;
                const field = fields[fieldId];
                const itemTree = (e.itemTreeByField as Record<string, EstimateItem[]>) || {};
                const selectedOptions = getSelectedOptionsForVariant(fields, itemTree, modelValues, field.property);
                const validOptions = getValidFieldOptions(selectedOptions, field);
                allValidOptions[fieldId] = validOptions;
            }
            const packageOptionRegex = new RegExp(escapeRegex(packageOption.value), 'i');

            const colors = allValidOptions[colorFieldId].map(
                (x: { id: string | number; value: string; image: { url: string } }) => ({
                    id: x.id,
                    name: x.value.replace(packageOptionRegex, '').trim(),
                    imageUrl: x.image ? x.image.url : '',
                    selected: false,
                })
            );
            po.colors = colors.filter((x: { name: string }) => !undecidedPattern.test(x.name)).length;
            if (colors.length > 0) {
                po.color = colors.find((x: { id: string | number }) => x.id === modelValues[colorField.property].value);
                if (po.color) {
                    po.color.selected = po.selected && !undecidedPattern.test(po.color.name);
                }
            }
            po.price = e.getWorkflowSummary(workflowId).totalWithMargin;
            po.estimate = e;
            return po;
        })
        .sort((a: { price: number }, b: { price: number }) => a.price - b.price);

    return pkg;
}
