<script setup>
    // <script setup lang = "ts" >

    import { getFieldValuesFromEstimate } from '@/composables/estimate';
    import { safeComputed } from '@/composables/safeComputed';
    import { roundUpToStepAndClamp } from '@/helpers';
    import { defineProps } from 'vue';

    import { computed, watch } from 'vue';
    import { useStore } from 'vuex';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const $store = useStore();
    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const fieldsRef = safeComputed(() => $store.state.estimator.fields);
    // totalSidingArea, wasteFactorForMaterial, wasteFactorForLabor
    const altFieldIds = ['2.98', '2.99', '2.116'];
    const altModelValues = computed(() =>
        getFieldValuesFromEstimate(props?.estimate.itemTreeByField, fieldsRef.value, altFieldIds)
    );

    function updateTotalSquares() {
        let accentTotalArea = 0;
        for (let i = 0; i < props?.modelValues.gableAccents.length; i++) {
            const item = props?.modelValues.gableAccents[i];
            const accentArea = item.children.gableAccentArea.quantity;
            accentTotalArea += accentArea;

            const wasteFactorForMaterial = item.children.gableAccentWasteFactorForMaterial.quantity;
            const accentMaterialWasteFactor = 1 + wasteFactorForMaterial / 100;
            const accentSquaresForMaterial = roundUpToStepAndClamp(
                (accentMaterialWasteFactor * accentArea) / 100,
                props?.model.gableAccents.children.gableAccentType.field
            );
            if (accentSquaresForMaterial !== item.children.gableAccentType.quantity) {
                props?.setModelValue(`gableAccents[${i}].children.gableAccentType.quantity`, accentSquaresForMaterial);
            }

            const wasteFactorForLabor = roundUpToStepAndClamp(
                wasteFactorForMaterial,
                props?.model.gableAccents.children.gableAccentWasteFactorForLabor.field
            );
            if (wasteFactorForLabor !== item.children.gableAccentWasteFactorForLabor.quantity) {
                props?.setModelValue(
                    `gableAccents[${i}].children.gableAccentWasteFactorForLabor.quantity`,
                    wasteFactorForLabor
                );
            }

            const accentLaborWasteFactor = 1 + wasteFactorForLabor / 100;
            const accentSquaresForLabor = roundUpToStepAndClamp(
                (accentLaborWasteFactor * accentArea) / 100,
                props?.model.gableAccents.children.gableAccentLabor.field
            );
            if (accentSquaresForLabor !== item.children.gableAccentLabor.quantity) {
                props?.setModelValue(`gableAccents[${i}].children.gableAccentLabor.quantity`, accentSquaresForLabor);
            }
        }

        const totalSidingArea = altModelValues.value.totalSidingArea.quantity - accentTotalArea;

        const materialWasteFactor = 1 + altModelValues.value.wasteFactorForMaterial.quantity / 100;
        const totalSquaresForMaterialPackage = roundUpToStepAndClamp(
            (materialWasteFactor * totalSidingArea) / 100,
            props?.model.totalSquaresForMaterialPackage.field
        );
        if (totalSquaresForMaterialPackage !== props?.modelValues.totalSquaresForMaterialPackage.quantity) {
            props?.setModelValue('totalSquaresForMaterialPackage.quantity', totalSquaresForMaterialPackage);
        }

        const laborWasteFactor = 1 + altModelValues.value.wasteFactorForLabor.quantity / 100;
        const totalSquaresForLaborPackage = roundUpToStepAndClamp(
            (laborWasteFactor * totalSidingArea) / 100,
            props?.model.totalSquaresForLaborPackage.field
        );
        if (totalSquaresForLaborPackage !== props?.modelValues.totalSquaresForLaborPackage.quantity) {
            props?.setModelValue('totalSquaresForLaborPackage.quantity', totalSquaresForLaborPackage);
        }
    }
    watch(() => props?.modelValues.gableAccents, updateTotalSquares, {
        immediate: true,
        deep: true,
    });
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Accents',
                name: 'gableAccents',
                isFieldArray: true,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Gable accent',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gableAccents[${i}].children.gableAccentType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gableAccents[${i}].children.gableAccentColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Area Square Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `gableAccents[${i}].children.gableAccentArea.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Waste Factor',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `gableAccents[${i}].children.gableAccentWasteFactorForMaterial.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: 'Material total',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                readonly: true,
                                name(i) {
                                    return `gableAccents[${i}].children.gableAccentType.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: 'Labor total',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                readonly: true,
                                name(i) {
                                    return `gableAccents[${i}].children.gableAccentLabor.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'gableVent',
                name: 'gableVent',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.gableVent[i].children.gableVentType.quantity,
                setModelValue: (i) => `gableVent[${i}].children.gableVentType.quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Gable Vent Type',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `gableVent[${i}].children.gableVentType.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gableVent[${i}].children.gableVentType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `gableVent[${i}].children.gableVentColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                title: 'Posts',
                name: 'posts',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.posts[i].quantity,
                setModelValue: (i) => `posts[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Post',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `posts[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `posts[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
