import { defineStore } from 'pinia';

export const useAccountStore = defineStore({
    id: 'Account',
    state: () => ({
        accessToken: localStorage.getItem('accessToken') || '', // Recupera el token de localStorage si existe
    }),
    getters: {
        getBearerAccessToken: (state): string => `Bearer ${state.accessToken}`,
    },
    actions: {
        setAccessToken(accessToken: string) {
            this.accessToken = accessToken;
            localStorage.setItem('accessToken', accessToken); // Guarda el token en localStorage
        },
        reset() {
            this.accessToken = '';
            localStorage.removeItem('accessToken'); // Elimina el token de localStorage
        },
    },
});
