import { createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/img/sending.gif'
import _imports_1 from '@/img/creating.gif'


const _hoisted_1 = { class: "loading-animate-contain" }
const _hoisted_2 = { class: "loading-animate" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "toggle-overlay",
      appear: ""
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "loading-overlay" }, null, -1)
      ])),
      _: 1
    }),
    _createVNode(_Transition, {
      name: "toggle-animate",
      appear: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          ($props.action === 'send')
            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
            : (_openBlock(), _createElementBlock("img", _hoisted_4))
        ])
      ]),
      _: 1
    })
  ]))
}