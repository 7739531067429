/* eslint-disable no-unused-vars */
interface Item {
    value: string;
    quantity: number;
    children?: {
        counterFlashingColor?: {
            value: string;
        };
    };
}

interface Color {
    id: string;
    value: string;
}

interface Coil {
    color: string;
    feet: number;
}

export const calculateFlashingItems = (items: Item[], colors: Color[]) => {
    return items.map((item) => {
        const color = colors.find((x) => x.id === item.value)?.value ?? '';
        return { color, feet: item.quantity };
    });
};

export const calculateFlashingCounter = (items: Item[], colors: Color[]) => {
    return items.map((item) => {
        const color = colors.find((x) => x.id === item.children?.counterFlashingColor?.value)?.value ?? '';
        return { color, feet: item.quantity };
    });
};

export const calculateFlashingCoil = (items: { color: string; feet: number }[]) => {
    return items.reduce((obj, x) => {
        if (x.feet > 0) {
            const key = x.color.toLowerCase();
            const item = key in obj ? obj[key] : (obj[key] = { color: x.color, feet: 0 });
            item.feet += x.feet;
        }
        return obj;
    }, {} as Record<string, Coil>);
};

export const calculateLaborHours = (
    estimateItems: { workflowId: number; fieldId: string; quantity: number }[],
    EstimateWorkflowId: number,
    fieldIds: string[],
    GET: (index: number, index2: object) => number
) => {
    const items = estimateItems.filter((x) => x.workflowId === EstimateWorkflowId && !fieldIds.includes(x.fieldId));
    const recommended = items.reduce((sum, x) => sum + x.quantity * GET(3, x), 0);
    return recommended;
};
