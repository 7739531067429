<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimteCostValues } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";
    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 'ef1e3221-6d1a-4c14-bf03-6af995d03ff5',
                name: 'soffitLightingType',
                quantity: (i) => props?.modelValues.soffitLightingType[i].quantity,
                setModelValue: (i) => `soffitLightingType[${i}].quantity`,
                title: 'Exterior Lighting',
                isFieldArray: true,
                rows: [
                    {
                        id: '0bf20a5d-b9bb-48aa-8d75-ff48b7244684',
                        columns: [
                            {
                                id: '5ce58136-98c0-460c-9f90-455ceb7f6789',
                                label: 'Soffit Lighting Type',
                            },
                            {
                                id: '0f89f489-3cdf-4a8c-9ce7-129719ec2142',
                                type: 'Dropdown',
                                name(i) {
                                    return `soffitLightingType[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: '61ff7fd7-cd4e-403d-90f8-8791de4da03f',
                        columns: [
                            {
                                id: '2c27c461-1a1a-46a8-af74-321b496b8695',
                                label: 'Quantity',
                            },
                            {
                                id: 'ba8e5e9f-9a24-4add-8162-f8d4ecddae1f',
                                type: 'Quantity',
                                name(i) {
                                    return `soffitLightingType[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: '061ca6f0-4e07-4c1c-9120-96aae6945e6b',
                name: 'trackLightingType',
                quantity: (i) => props?.modelValues.soffitLightingType[i].quantity,
                setModelValue: (i) => `soffitLightingType[${i}].quantity`,
                title: 'Exterior Lighting',
                isFieldArray: true,
                rows: [
                    {
                        id: '3545443b-e375-49d7-a59c-4aaf8966695d',
                        columns: [
                            {
                                id: 'c3398cb8-2ed0-457d-8674-a0c9b1a88fcc',
                                label: 'Track lighting Type',
                            },
                            {
                                id: '1c098358-2797-4ffc-8c67-8967d6a7dcd8',
                                type: 'Dropdown',
                                name(i) {
                                    return `trackLightingType[${i}].value`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: '01b5ed28-98c0-413c-8931-5872aa0ac1e7',
                        columns: [
                            {
                                id: '9c985748-4fe0-422f-9d2e-91244c393526',
                                label: 'Feet',
                            },
                            {
                                id: '0fbc5d9a-3584-4022-bedf-0f7ecb344782',
                                type: 'Quantity',
                                name(i) {
                                    return `trackLightingType[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
