<!-- // <script setup lang = "ts" > -->
<script setup>
    import { getFieldModel, getFieldValuesFromEstimate } from '@/composables/estimate';
    import { safeComputed } from '@/composables/safeComputed';
    import { round } from '@/helpers';
    import { calculateCaulkRmSiding } from '@/helpers/estimator/siding/14_extras';
    import { computed, defineProps, watch } from 'vue';
    import { useStore } from 'vuex';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimteCostValues } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const $store = useStore();
    const fieldsRef = safeComputed(() => $store.state.estimator.fields);

    const altFieldIds = ['2.49', '2.52', '2.58', '2.65', '2.108', '2.111', '2.61'];
    const altModel = getFieldModel(fieldsRef.value, altFieldIds);
    const altModelValues = getFieldValuesFromEstimate(props?.estimate.itemTreeByField, fieldsRef.value, altFieldIds);

    function houseWrapTapeFeetRecommendedHelper(x, prop, i, altModelValues) {
        if (altModel[prop].children && prop != 'trim') {
            let quantity = 0;
            if (prop === 'houseWrap') {
                quantity = x.children['houseWrapMaterial'].quantity;
            } else {
                quantity = x.quantity;
            }
            const field = altModel[prop].children[prop + 'Type'].field;
            const optionId = (Array.isArray(altModelValues[prop]) ? altModelValues[prop][i] : altModelValues[prop])
                .children[prop + 'Type'].value;
            const feetPerUnit = props?.getRecommendedConversion(1, field, optionId) || 1;
            return quantity * feetPerUnit;
        } else {
            return 0;
        }
    }

    const houseWrapTapeFeetRecommended = computed(() => {
        return Object.keys(altModel).reduce((sum, prop) => {
            if (Array.isArray(altModelValues[prop])) {
                altModelValues[prop].forEach((x, i) => {
                    sum += houseWrapTapeFeetRecommendedHelper(x, prop, i, altModelValues);
                });
            } else {
                sum += houseWrapTapeFeetRecommendedHelper(altModelValues[prop], prop, -1, altModelValues);
            }
            return sum;
        }, 0);
    });
    const houseWrapTapeRecommended = computed(() => {
        const feet = houseWrapTapeFeetRecommended.value;
        const feetPerUnit = props?.getRecommendedConversion(
            1,
            props?.model.houseWrapTape.field,
            props?.modelValues.houseWrapTape.value
        );
        if (feetPerUnit === 0) return 0;
        const recommended = Math.ceil(feet / feetPerUnit);
        props?.setRecommendedItem(props?.model.houseWrapTape.field.id, recommended);
        return recommended;
    });

    const nailsRecommended = computed(() => {
        return Array.isArray(props?.modelValues.nails)
            ? props?.modelValues.nails.map((x, i) => {
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.nails.field,
                      props?.modelValues.nails[i].value
                  );
                  if (feetPerUnit === 0) return 0;
                  const recommended = Math.ceil(altModelValues.totalSquaresForMaterial.quantity / feetPerUnit);
                  props?.setRecommendedItem(props?.model.nails.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });

    const caulkRecommended = computed(() => {
        return calculateCaulkRmSiding({
            items: props?.modelValues.caulk,
            GET: props?.getRecommendedConversion,
            SET: props?.setRecommendedItem,
            field: props?.model.caulk.field,
            trimItems: altModelValues?.trim,
            quantity: altModelValues?.totalSquaresForMaterial.quantity,
            fieldId: props?.model.caulk.field.id,
        });
    });

    function updatePrices() {
        if (Array.isArray(props.modelValues.custom)) {
            for (let i = 0; i < props.modelValues.custom.length; i++) {
                const x = props.modelValues.custom[i];
                const materialMultiplier = props.getRecommendedConversion(
                    1,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                const laborMultiplier = props.getRecommendedConversion(
                    2,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                props.setModelValue(`custom[${i}].materialPriceTemp`, round(x.materialCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].materialPrice`, round(x.materialCost * materialMultiplier, 2));
                props.setModelValue(`custom[${i}].laborPriceTemp`, round(x.laborCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].laborPrice`, round(x.laborCost * laborMultiplier, 2));
            }
        }
    }
    watch(() => props.modelValues.custom, updatePrices, { deep: true });

    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Setup Fee',
                name: 'setupFee',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.setupFee[i].quantity,
                setModelValue: (i) => `setupFee[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Setup fee',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `setupFee[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `setupFee[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Extras',
                name: '2inz1',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'House wrap tape',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'houseWrapTape.quantity';
                                },
                                recommended: () => houseWrapTapeRecommended.value,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => 'houseWrapTape.value',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Window flashing tape',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'windowFlashingTape.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: () => 'windowFlashingTape.value',
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                title: 'Nails',
                name: 'nails',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.nails[i].quantity,
                setModelValue: (i) => `nails[${i}].quantity`,
                rows: [
                    {
                        id: 'aba0ae94-cdaf-48b1-830d-92158311bbb7',
                        columns: [
                            {
                                id: '891c094b-b888-43fb-9e04-82d9e534dc97',
                                label: 'TOTAL',
                            },
                            {
                                id: 'e2f07c4d-67a9-409b-a711-c5d139ba7b5e',
                                label: '--',
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Nails',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `nails[${i}].quantity`;
                                },
                                recommended(i) {
                                    return nailsRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `nails[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                title: 'Calk',
                name: 'caulk',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.caulk[i].quantity,
                setModelValue: (i) => `caulk[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Caulk',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `caulk[${i}].quantity`;
                                },
                                recommended(i) {
                                    return caulkRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `caulk[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                title: 'Delivery Distance',
                name: 'deliveryDistance',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.deliveryDistance[i].quantity,
                setModelValue: (i) => `deliveryDistance[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Delivery / Distance',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `deliveryDistance[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `deliveryDistance[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                title: 'Siding permit',
                name: 'deliveryDistance',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Siding permit',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name: () => 'permit.value',
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 7,
                title: 'ADD-ON SERVICES',
                name: 'custom',
                isFieldArray: true,
                addItem: {
                    show: true,
                    title: 'ADD-ON SERVICES',
                },
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Label / name',
                            },
                            {
                                id: 2,
                                type: 'Text',
                                name(i) {
                                    return `custom[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `custom[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: '$ material cost',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].materialCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: '$ labor cost',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].laborCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: '$ material price',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                readonly: true,
                                name(i) {
                                    return `custom[${i}].materialPriceTemp`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: '$ labor price',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                readonly: true,
                                name(i) {
                                    return `custom[${i}].laborPriceTemp`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
