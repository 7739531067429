<template>
    <div class="selected-swatch-banner">
        <img
            v-if="selectedOption && selectedOption.image"
            :src="`${APP_URL_ORIGIN}${selectedOption.image.url}`"
            alt=""
        />
        <div class="no-img" v-else>No Image</div>
    </div>
</template>

<script setup>
    import { useField } from 'vee-validate';
    import { computed, inject, toRef, defineProps } from 'vue';
    import { APP_URL_ORIGIN } from '@/api/_helpers';
    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
    });

    const modelLookup = inject('modelLookup');
    const { value } = useField(toRef(props, 'name'));
    const model = computed(() => modelLookup(props.name));
    const selectedOption = computed(() => model.value.field.options.find((x) => x.id === value.value));
</script>
