import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/img/unknown-image.png'


const _hoisted_1 = { class: "thumbnail-group" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  src: _imports_0
}
const _hoisted_4 = { class: "mt-half" }

import { toRef } from 'vue';
import { useField } from 'vee-validate';
import { APP_URL_ORIGIN } from '@/api/_helpers';

interface ValueType {
    data?: File
    url?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'InputFileImagesItem',
  props: {
    name: {
        type: String,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
    removeItem: {
        type: Function,
        required: true,
    },
    constrain: {
        type: Boolean,
        required: false,
        default: false,
    },
    dragging: {
        type: Boolean,
        required: false,
        default: false,
    },
},
  setup(__props) {

const props = __props;

const isLocalUrl = (data?: File): boolean => !!data && data instanceof File;
const { value, errorMessage: error } = useField<ValueType>(toRef(props, 'name'), undefined, {
    validateOnMount: true,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["package-thumbnail has-img has-img-multiple", { 'constrain-img': __props.constrain, highlight: __props.dragging }])
    }, [
      (_unref(value).url)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: isLocalUrl(_unref(value)?.data) ? _unref(value).url : `${_unref(APP_URL_ORIGIN)}${_unref(value).url}`
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("img", _hoisted_3))
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("label", { class: "label-error mt-third mb-1" }, _toDisplayString(_unref(error)), 513), [
        [_vShow, _unref(error)]
      ]),
      _createElementVNode("button", {
        class: "btn-link btn-link-delete",
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (__props.removeItem(__props.index)))
      }, "Remove")
    ])
  ]))
}
}

})