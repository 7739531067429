type MembraneItem = {
    children: {
        membraneType: {
            quantity: number;
        };
    };
};

export const calculateTotalSquaresForMembrane = (items: MembraneItem[]) => {
    return items.map((x) => x.children.membraneType.quantity).reduce((a, b) => a + b, 0);
};
