import { url as urlHelper } from '@/helpers';
import Credential from '@/models/Credential';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
    /**
     * Get all credentials
     */
    async getAll(decrypt = false) {
        const query = {
            decrypt,
        };
        const url = urlHelper('/api/credential', query);
        let response;
        try {
            response = await fetchWrap(url);
        } catch {
            response = offlineResponse();
        }
        if (response?.status < 300 && response?.status >= 200) {
            return response.data.map((x: any) => new Credential(x));
        } else {
            return response;
        }
    },
    /**
     * Get a credential
     * @param id Number Credential ID
     */
    async getById(id: number) {
        let response;
        try {
            response = await fetchWrap('/api/credential/' + id);
        } catch {
            response = offlineResponse();
        }
        if (response?.status < 300 && response?.status >= 200) {
            return new Credential(response.data);
        } else {
            return response;
        }
    },
    /**
     * Create a credential
     * @param model Credential credential to create.
     */
    async create(model: Credential) {
        const url = '/api/credential';
        let response;
        try {
            response = await fetchWrap(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        if (response?.status < 300 && response?.status >= 200) {
            return new Credential(model);
        } else {
            return response;
        }
    },
    /**
     * Update a credential
     * @param model Credential credential to update.
     */
    async update(model: Credential) {
        let response;
        try {
            response = await fetchWrap('/api/credential', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        if (response?.status < 300 && response?.status >= 200) {
            return new Credential(model);
        } else {
            return response;
        }
    },
    /**
     * Delete a credential
     * @param id Number Credential ID to delete.
     */
    async deleteById(id: number) {
        let response;
        try {
            response = await fetchWrap('/api/credential/' + id, { method: 'DELETE' });
        } catch {
            response = offlineResponse();
        }
        if ((response?.status < 300 && response?.status >= 200) || response.status === 404) {
            return true;
        } else if (response.status === 409) {
            return false;
        } else {
            return response;
        }
    },
};
