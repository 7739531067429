<!-- eslint-disable no-unused-vars -->
<template>
    <slot
        :items="items"
        :removeItem="removeItem"
        :addItem="addItem"
        :addItemAfter="addItemAfter"
    /><!-- eslint-disable-line vue/no-multiple-template-root -->
</template>

<script setup lang="ts">
    import { useFieldArray } from 'vee-validate';
    import { defineExpose, defineProps, inject, onMounted, toRef } from 'vue';

    // Definimos las propiedades del componente
    const props = defineProps<{
        name: string;
    }>();

    // Tipos para los items y funciones inyectadas
    interface FieldArrayItem {
        id?: string;
        name?: string;
        value?: any;
    }

    interface Model {
        field: {
            id: string;
        };
    }

    // Inyección de dependencias con tipado
    const getNewFieldArrayItem = inject<(name: string) => FieldArrayItem>('getNewFieldArrayItem');
    const modelLookup = inject<(name: string) => Model>('modelLookup');
    const modelValues = inject<Record<string, any>>('modelValues');
    const recommendedItems = inject<Record<string, Record<string, number>>>('recommendedItems');

    if (!getNewFieldArrayItem || !modelLookup) {
        throw new Error('Missing required inject dependencies');
    }

    const model = modelLookup(props.name);
    const {
        remove: removeItem,
        push: pushItem,
        insert: insertItem,
        fields: items,
    } = useFieldArray(toRef(props, 'name'));

    const addItem = () => {
        pushItem(getNewFieldArrayItem(props.name));
    };

    const addItemAfter = (i: number) => {
        insertItem(i + 1, getNewFieldArrayItem(props.name));
    };

    onMounted(() => {
        if (recommendedItems && recommendedItems[model.field.id]) {
            const values = recommendedItems[model.field.id];
            const keys = Object.keys(values);

            const sortedKeys = keys.sort((a, b) => values[b] - values[a]);

            if (items.value.length === sortedKeys.length) {
                let count = 0;
                sortedKeys.forEach((key, i) => {
                    if (values[key] === 0 && items.value.length > 1) {
                        removeItem(i - count);
                        count++;
                    }
                });
            }
        }
    });

    defineExpose({
        items,
        removeItem,
        addItem,
        addItemAfter,
    });
</script>
