<script setup>
    import { computed, defineProps } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimateTotalsWithKey, estimateTotalsWithKeyChild } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: '',
                title: 'FLAT ROOF DETAILS',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 'd4b3c2cb-8849-4b3e-b7e5-5ffddaf6e901',
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Flat roofing'),
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Flat roofing type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'flatRoof.children.flatRoofType.value';
                                },
                            },
                            ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Type', 'Flat roofing'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Flat roofing color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'flatRoof.children.flatRoofColor.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Area',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'flatRoof.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Tear-off',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'tearOff.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: '',
                title: 'EDGES',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Termination bars',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'terminationBars.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Flat roof termination bar'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: '< 4″ Metal Edge',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => {
                                    return 'metalEdgeNarrow.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Flat roof < 4″ Metal Edge'),
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: '> 4″ Metal Edge',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'metalEdgeWide.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Flat roof > 4″ Metal Edge'),
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Penetrations',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'penetrations.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Flat roof penetrations'),
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: '',
                title: 'Recovery Board',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Insulation type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'insulation.value';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Flat roof insulation type'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Area Square Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'insulation.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: '',
                title: 'DRAINS',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Re-use existing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'drainsReUse.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Install new 4″',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'drainsNew.quantity';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Install new 4″ flat roof drains'),
                        ],
                    },
                ],
            },
            {
                id: 5,
                name: '',
                title: 'RAILINGS',
                setModelValue: () => '',
                quantity: () => 0,
                isFieldArray: false,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Railing type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'railingSections.value';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Flat roof railing type'),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: '6ft sections',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'railingSections.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Railing posts',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'railingPosts.value';
                                },
                            },
                            ...estimateTotalsWithKey(props?.fieldsEstimateItems, 'Flat roof railing posts'),
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'railingPosts.quantity';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
