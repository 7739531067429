<template>
    <slider
        :id="htmlFieldName"
        :min="min"
        :max="max"
        :step="step === 0.333 ? 1 : step"
        v-bind="$attrs"
        v-model="modelValue"
        :defaultValue="min"
        :disabled="disabled"
        class="slider-color mb-2 mt-3"
    />
</template>

<script setup>
    import Slider from '@vueform/slider';
    import EstimateField from '@/models/EstimateField';
    import { useField } from 'vee-validate';
    import { computed, inject, toRef, defineProps, toRefs, watch } from 'vue';

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    });

    const modelLookup = inject('modelLookup');
    const workflowId = inject('estimateWorkflowId');
    const htmlFieldName = computed(() => 'field_' + workflowId + '_' + props.name.replace('.', '_'));
    const { value: modelValue } = useField(toRef(props, 'name'));
    const model = computed(() => modelLookup(props.name));
    const field = computed(() => (model.value ? model.value.field : null));

    const min = computed(() => (field.value ? field.value.min : null) ?? EstimateField.DEFAULT_MIN);
    const max = computed(() => (field.value ? field.value.max : null) ?? EstimateField.DEFAULT_MAX);
    const step = computed(() => (field.value ? field.value.step : null) ?? EstimateField.DEFAULT_STEP);
</script>

<script>
    export default { inheritAttrs: false };
</script>
