import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "icon type-icon" }
const _hoisted_2 = ["xlink:href"]
const _hoisted_3 = { class: "message" }

import Message from '@/models/Message';
import $store from '@/store/index';
import { computed, onMounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppMessage',
  props: {
	message: {
		type: Object,
		required: true,
	},
},
  setup(__props) {

const props = __props;
const messageClosed = ref(false);
const autoCloseStyle = computed(() => ({ animationDuration: Message.AUTO_CLOSE_DELAY + 'ms' }));

function closeMessage() {
	messageClosed.value = true;
	setTimeout(() => {
		$store.commit('removeMessage', props.message.id);
		// 1s delay for animate out:
	}, 1000);
}

onMounted(() => {
	if (props.message.autoClose) {
		setTimeout(() => {
			messageClosed.value = true;
		}, Message.AUTO_CLOSE_DELAY);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert", [messageClosed.value ? 'animate-out' : 'animate-in', `alert-${__props.message.type}`]])
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("use", {
        "xlink:href": `#msg-${__props.message.type}`
      }, null, 8, _hoisted_2)
    ])),
    _createElementVNode("span", _hoisted_3, _toDisplayString(__props.message.message), 1),
    _createElementVNode("button", {
      class: "close-message",
      type: "button",
      onClick: closeMessage
    }, [
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass({ 'auto-close': __props.message.autoClose })
      }, [
        _createElementVNode("circle", {
          style: _normalizeStyle(__props.message.autoClose ? autoCloseStyle.value : ''),
          r: "10",
          cx: "12",
          cy: "12"
        }, null, 4)
      ], 2))
    ])
  ], 2))
}
}

})