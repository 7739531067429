/* eslint-disable no-unused-vars */
import LocalChangeState from './models/LocalChangeState';
import LocalChange from './models/LocalChange';
import $store from './store/index';
import { IDBPDatabase } from 'idb';

export const add = async (db: IDBPDatabase, change: LocalChange) => {
    let action: 'put' | 'delete' | null = null;

    if (change.state === LocalChangeState.added) {
        action = 'put';
    } else if (change.state === LocalChangeState.modified) {
        const currentChange = await db.get('local-changes', change.key);
        if (currentChange && currentChange.state === LocalChangeState.added) {
            change.state = LocalChangeState.added;
            action = 'put';
        } else {
            action = 'put';
        }
    } else if (change.state === LocalChangeState.deleted) {
        const currentChange = await db.get('local-changes', change.key);
        if (currentChange && currentChange.state === LocalChangeState.added) {
            action = 'delete';
        } else {
            action = 'put';
        }
    }

    if (action === 'put') {
        await db.put('local-changes', change, change.key);
        await updateVuex(db);
    } else if (action === 'delete') {
        await db.delete('local-changes', change.key);
        await updateVuex(db);
    }
};

export const deleteChange = async (db: IDBPDatabase, changeKey: string) => {
    await db.delete('local-changes', changeKey);
    await updateVuex(db);
};

export const getAll = async (db: IDBPDatabase, storeName: string, state: number) => {
    const changes = (await db.getAllFromIndex('local-changes', 'storeName', storeName)) as LocalChange[];
    return changes.filter((x) => x.state === state).map((x: object) => new LocalChange(x));
};

const updateVuex = async (db: IDBPDatabase) => {
    const unsyncedChanges = await db.count('local-changes');
    $store.commit('setUnsyncedChanges', unsyncedChanges);
};
