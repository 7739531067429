<template>
	<div class="admin-nav-contain" :class="{ expanded: isOpen }" ref="adminNavWidget" v-on="focusListeners">
		<button type="button" @click="isOpen = !isOpen">
			<span v-if="$route.matched[0].path === '/config'">Config</span>
			<span v-else-if="$route.matched[0].path === '/estimator'">Sales</span>
			<span class="no-select" v-else>Select</span>
		</button>
		<nav class="admin-nav">
			<router-link to="/estimator" v-if="isUser">Sales</router-link>
			<router-link to="/config" v-if="isAdmin">Config</router-link>
			<router-link class="flex-center-y" to="/master/folder-log" v-if="isMasterAdmin">Folder Log</router-link>
			<router-link class="flex-center-y" to="/admin" v-if="isSuperAdmin">
				Admin
				<svg class="icon stroke-white ml-half" width="14px" height="14px">
					<use xlink:href="#leave"></use>
				</svg>
			</router-link>
		</nav>
	</div>
</template>

<script lang="ts">
	import { computed, ref } from 'vue';
	import { useStore } from 'vuex';
	import { getFocus } from '@/composables/focus';

	export default {
		setup() {
			const $store = useStore();
			const isSuperAdmin = computed(() => $store.getters['auth/isSuperAdmin']);
			const isMasterAdmin = computed(() => $store.getters['auth/isMasterAdmin']);
			const isAdmin = computed(() => $store.getters['auth/isAdmin']);
			const isUser = computed(() => $store.getters['auth/isUser']);
			const adminNavWidget = ref(null);
			const { isOpen, focusListeners } = getFocus(adminNavWidget);

			return {
				isOpen,
				focusListeners,
				adminNavWidget,
				isMasterAdmin,
				isSuperAdmin,
				isAdmin,
				isUser,
			};
		},
	};
</script>
