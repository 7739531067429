type Item = {
    fieldId: string;
    quantity?: number;
    value?: string;
};

export const calculateEstimateField = (items: Item[], fieldId: string = '2.29') => {
    return items.find((x) => x.fieldId === fieldId)?.quantity ?? 0;
};

export const calculateMaterialType = (items: Item[], fieldId: string = '2.187') => {
    return items.find((x) => x.fieldId === fieldId) || { value: '' };
};
