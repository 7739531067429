import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "reset-list" }
const _hoisted_2 = {
  key: 0,
  class: "check-radio-wrap"
}
const _hoisted_3 = ["checked"]
const _hoisted_4 = {
  key: 1,
  class: "check-radio-wrap"
}
const _hoisted_5 = ["checked"]
const _hoisted_6 = {
  key: 2,
  class: "check-radio-wrap"
}
const _hoisted_7 = ["checked"]
const _hoisted_8 = {
  key: 3,
  class: "check-radio-wrap"
}
const _hoisted_9 = ["checked"]
const _hoisted_10 = {
  key: 4,
  class: "check-radio-wrap"
}
const _hoisted_11 = ["checked"]
const _hoisted_12 = {
  key: 5,
  class: "check-radio-wrap"
}
const _hoisted_13 = ["checked"]

import { computed } from 'vue';
import { INT_MAX_VALUE, passwordOptions as requirements, validation } from '@/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'PasswordRequirements',
  props: {
	modelValue: {
		type: String,
		required: true,
	},
},
  setup(__props) {

const props = __props;

const hasUppercase = computed(() => validation.password.requireUppercase(props.modelValue));
const hasLowercase = computed(() => validation.password.requireLowercase(props.modelValue));
const hasDigit = computed(() => validation.password.requireDigit(props.modelValue));
const hasNonAlphanumeric = computed(() => validation.password.requireNonAlphanumeric(props.modelValue));
const meetsMinLength = computed(() => validation.password.minLength(props.modelValue));
const meetsMaxLength = computed(() => validation.password.maxLength(props.modelValue));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[4] || (_cache[4] = _createElementVNode("label", null, "Passwords must contain:", -1)),
    _createElementVNode("ul", _hoisted_1, [
      (_unref(requirements).requireUppercase)
        ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
            _createElementVNode("input", {
              class: "ui-check-pswd",
              type: "checkbox",
              disabled: "",
              checked: hasUppercase.value
            }, null, 8, _hoisted_3),
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "At least 1 uppercase letter", -1))
          ]))
        : _createCommentVNode("", true),
      (_unref(requirements).requireLowercase)
        ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
            _createElementVNode("input", {
              class: "ui-check-pswd",
              type: "checkbox",
              disabled: "",
              checked: hasLowercase.value
            }, null, 8, _hoisted_5),
            _cache[1] || (_cache[1] = _createElementVNode("span", null, "At least 1 lowercase letter", -1))
          ]))
        : _createCommentVNode("", true),
      (_unref(requirements).requireDigit)
        ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
            _createElementVNode("input", {
              class: "ui-check-pswd",
              type: "checkbox",
              disabled: "",
              checked: hasDigit.value
            }, null, 8, _hoisted_7),
            _cache[2] || (_cache[2] = _createElementVNode("span", null, "At least 1 number", -1))
          ]))
        : _createCommentVNode("", true),
      (_unref(requirements).requireNonAlphanumeric)
        ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
            _createElementVNode("input", {
              class: "ui-check-pswd",
              type: "checkbox",
              disabled: "",
              checked: hasNonAlphanumeric.value
            }, null, 8, _hoisted_9),
            _cache[3] || (_cache[3] = _createElementVNode("span", null, "At least 1 symbol", -1))
          ]))
        : _createCommentVNode("", true),
      (_unref(requirements).minLength > 1)
        ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
            _createElementVNode("input", {
              class: "ui-check-pswd",
              type: "checkbox",
              disabled: "",
              checked: meetsMinLength.value
            }, null, 8, _hoisted_11),
            _createElementVNode("span", null, "Minimum " + _toDisplayString(_unref(requirements).minLength) + " characters", 1)
          ]))
        : _createCommentVNode("", true),
      (_unref(requirements).maxLength < _unref(INT_MAX_VALUE))
        ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
            _createElementVNode("input", {
              class: "ui-check-pswd",
              type: "checkbox",
              disabled: "",
              checked: meetsMaxLength.value
            }, null, 8, _hoisted_13),
            _createElementVNode("span", null, "Maximum " + _toDisplayString(_unref(requirements).maxLength) + " characters", 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})