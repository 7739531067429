<template>
	<teleport to="body">
		<transition name="refresh-sw" appear>
			<div v-if="open" class="refresh-sw-prompt">
				<div>App update available!</div>
				<button class="btn btn-fill-app" :disabled="loading" @click="refresh()">
					<span v-if="!loading">
						Update
					</span>
					<span class="is-loading dots" v-else>
						<span class="dot-1"></span>
						<span class="dot-2"></span>
						<span class="dot-3"></span>
					</span>
				</button>
			</div>
		</transition>
	</teleport>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const $store = useStore();
const loading = ref(false);
const open = computed(() => $store.state.serviceWorkerUpdate);

function refresh() {
	loading.value = true;
	location.reload();
}
</script>
