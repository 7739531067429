import EstimateFieldOption from '@/models/EstimateFieldOption';
import { AxiosResponse } from 'axios';
import { fetchWrap, offlineResponse } from '../_helpers';

interface Model {
    id: string;
}

export default {
    async update(model: Model) {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/estimatefields/option/' + model.id, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model),
            });
        } catch {
            return offlineResponse();
        }
        if (response?.status < 300 && response?.status >= 200) {
            const data = response.data;
            return new EstimateFieldOption(data);
        } else {
            return response;
        }
    },
};
