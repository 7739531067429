import { getDb } from '@/idbInit';
import EstimateField from '@/models/EstimateField';
import { fieldInfo } from '@/models/EstimateFields';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
    async getAll() {
        const map: { [key: string]: any } = {};
        const baseData = Object.keys(fieldInfo).map((id) => {
            const y = new EstimateField({ id: id });
            map[y.id ?? ''] = y;
            return y;
        });
        let response;
        try {
            response = await fetchWrap('/api/estimatefields');
        } catch {
            const idb = await getDb();
            const data: { id: string; [key: string]: any }[] = await idb.getAll('estimate-fields');
            for (let i = 0; i < data.length; i++) {
                const model = map[data[i].id];
                if (model) {
                    model.setDynamicData(data[i]);
                }
            }
            return baseData;
        }
        if (response?.status < 300 && response?.status >= 200) {
            const data = response.data;
            const idb = await getDb();
            await idb.clear('estimate-fields');
            for (let i = 0; i < data.length; i++) {
                await idb.put('estimate-fields', data[i], data[i].id);
                const model = map[data[i].id];
                if (model) {
                    model.setDynamicData(data[i]);
                }
            }
            return baseData;
        } else {
            return response;
        }
    },
    async importFile(formData: any) {
        let response = null;
        try {
            response = await fetchWrap('/api/estimatefields', {
                method: 'post',
                body: formData,
            });
        } catch (e) {
            response = offlineResponse();
        }
        return response;
    },
    async importFileFromFileProvider() {
        let response = null;
        try {
            response = await fetchWrap('/api/estimatefields/import', { method: 'post' });
        } catch (e) {
            response = offlineResponse();
        }
        return response;
    },
};
