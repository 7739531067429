import { url as urlHelper } from '@/helpers';
import { getDb } from '@/idbInit';
import EstimateSection from '@/models/EstimateSection';
import EstimateSections from '@/models/EstimateSections';
import { AxiosResponse } from 'axios';
import { fetchWrap, offlineResponse } from '../_helpers';

interface QueryParams {
    workflowId?: number;
}

export default {
    async getAll({ workflowId }: QueryParams = {}) {
        const map: { [key: number]: EstimateSection } = {};
        const baseData = EstimateSections.list.map((x) => {
            const y = new EstimateSection({ id: x.id });
            map[y.id] = y;
            return y;
        });
        const query: { workflowId?: number } = {};
        if (typeof workflowId === 'number') {
            query.workflowId = workflowId;
        }
        const url = urlHelper('/api/estimatesections', query);
        let response: AxiosResponse;
        try {
            response = await fetchWrap(url);
        } catch {
            const idb = await getDb();
            const data = await idb.getAll('estimate-sections');
            for (let i = 0; i < data.length; i++) {
                const model = map[data[i].id];
                if (model) {
                    model.setDynamicData(data[i]);
                }
            }
            return baseData;
        }
        if (response?.status < 300 && response?.status >= 200) {
            const idb = await getDb();
            const data = response.data;
            await idb.clear('estimate-sections');
            for (let i = 0; i < data.length; i++) {
                await idb.put('estimate-sections', data[i], data[i].id);
                const model = map[data[i].id];
                if (model) {
                    model.setDynamicData(data[i]);
                }
            }
            return baseData;
        } else {
            return response;
        }
    },

    /**
     * Update an estimate section
     * @param {model} EstimateSection estimate section to update.
     * @returns (async) Returns the updated EstimateSection if the request was successful, otherwise a Response.
     */
    async update(model: EstimateSection) {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/estimatesections/' + model.id, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        if (response?.status < 300 && response?.status >= 200) {
            const data = JSON.parse(JSON.stringify(model));
            const idb = await getDb();
            await idb.put('estimate-sections', data, data.id);
            return new EstimateSection(data);
        } else {
            return response;
        }
    },
};
