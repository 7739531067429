const MessageType = {
    success: 'success',
    error: 'error',
    warning: 'warning',
    info: 'info',
    isValid: function (type: string) {
        return type === this.success || type === this.error || type === this.warning || type === this.info;
    },
} as const;

export type MessageTypeKey = keyof typeof MessageType;
export type MessageTypeValue = (typeof MessageType)[MessageTypeKey];

export default MessageType;
