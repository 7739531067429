<script setup>
    import { computed, defineProps } from 'vue';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    // import { formatCurrency } from '@/helpers';
    // import { calculateChimneys } from '@/helpers/estimator/roofing/05_chimneys';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    // import { emptyFields, EstimateCostValues } from '../mocks';
    // import { SectionProps, TypeEstimate } from './types';
    import { emptyFields } from '../mocks.ts';
    // const chimneyFlashingColorRecommended = computed(() =>
    //     calculateChimneys(
    //         props?.modelValues.chimneyFlashing,
    //         props?.model.chimneyFlashing.field,
    //         props?.model.chimneyFlashingColor.field.id,
    //         props?.getRecommendedConversion,
    //         props?.setRecommendedItem
    //     )
    // );
    // const labelChimneyChaseCap = 'CHIMNEY CHASE CAP';

    // const costChimneyChaseCap = computed(() => {
    //     return props?.fieldsEstimateItems?.find((e: TypeEstimate) => e?.label?.toUpperCase() === labelChimneyChaseCap);
    // });

    // const estimateCost: EstimateCostValues = [
    //     {
    //         id: 3,
    //         type: 'Cost',
    //         estimateField: () => {
    //             if (!costChimneyChaseCap?.value?.material) {
    //                 return '--';
    //             }
    //             return `${formatCurrency(costChimneyChaseCap?.value?.material)}`;
    //         },
    //     },
    //     {
    //         id: 4,
    //         type: 'Cost',
    //         estimateField: () => {
    //             if (!costChimneyChaseCap?.value?.labor) {
    //                 return '--';
    //             }
    //             return `${formatCurrency(costChimneyChaseCap?.value?.labor)}`;
    //         },
    //     },
    //     {
    //         id: 5,
    //         type: 'Cost',
    //         estimateField: () => {
    //             if (!costChimneyChaseCap?.value?.total) {
    //                 return '--';
    //             }
    //             return `${formatCurrency(costChimneyChaseCap?.value?.total)}`;
    //         },
    //     },
    //     {
    //         id: 5,
    //         label: '--',
    //     },
    // ];

    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: 'chimneyFlashing',
                title: 'Chimney Flashing',
                isFieldArray: true,
                setModelValue: (i) => {
                    return `chimneyFlashing[${i}].quantity`;
                },
                quantity: (i) => {
                    return props?.modelValues.chimneyFlashing[i].quantity;
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'flashing',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `chimneyFlashing[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `chimneyFlashing[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            // {
            //     id: 2,
            //     name: 'chimneyFlashingColor',
            //     title: 'Chimney Flashing Color',
            //     isFieldArray: true,

            //     setModelValue: (i) => {
            //         return `chimneyFlashingColor[${i}].quantity`;
            //     },
            //     quantity: (i) => {
            //         return props?.modelValues.chimneyFlashingColor[i].quantity;
            //     },
            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Color',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Dropdown',
            //                     name: (i) => {
            //                         return `chimneyFlashingColor[${i}].value`;
            //                     },
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 2,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Feet',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Quantity',
            //                     name: (i) => {
            //                         return `chimneyFlashingColor[${i}].quantity`;
            //                     },
            //                     recommended: () => {
            //                         return chimneyFlashingColorRecommended.value;
            //                     },
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
            // {
            //     id: 3,
            //     name: 'chimneyExtras',
            //     title: 'Chimney Extras',
            //     isFieldArray: false,
            //     setModelValue: () => {
            //         return ``;
            //     },
            //     quantity: () => {
            //         return 0;
            //     },
            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Quantity',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Quantity',
            //                     name: () => 'chimneyChaseCap.quantity',
            //                 },
            //                 ...estimateCost,
            //             ],
            //         },
            //         {
            //             id: 2,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Chimney chase cap',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Dropdown',
            //                     name: () => 'chimneyChaseCap.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
            // {
            //     id: 4,
            //     name: 'tuckPointChimney',
            //     title: 'Chimney Extras - Tuck point',
            //     isFieldArray: true,
            //     setModelValue: (i) => {
            //         return `tuckPointChimney[${i}].quantity`;
            //     },
            //     quantity: (i) => {
            //         return props?.modelValues.tuckPointChimney[i].quantity;
            //     },
            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Tuck point',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Dropdown',
            //                     name: (i) => {
            //                         return `tuckPointChimney[${i}].value`;
            //                     },
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Quantity',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Quantity',
            //                     name: (i) => {
            //                         return `tuckPointChimney[${i}].quantity`;
            //                     },
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
            // {
            //     id: 5,
            //     name: 'masonryseale',
            //     title: 'Masonry seale',
            //     isFieldArray: false,
            //     setModelValue: () => {
            //         return ``;
            //     },
            //     quantity: () => {
            //         return 0;
            //     },
            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Masonry sealer',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Quantity',
            //                     name: () => {
            //                         return 'chimneyMasonrySealer.quantity';
            //                     },
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
            {
                id: 6,
                name: 'removeChimney',
                title: 'Remove Chimney',
                isFieldArray: true,
                setModelValue: (i) => {
                    return `removeChimney[${i}].quantity`;
                },
                quantity: (i) => {
                    return props?.modelValues.removeChimney[i].quantity;
                },
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Remove chimney',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => {
                                    return `removeChimney[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: (i) => {
                                    return `removeChimney[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
