type CredentialDTO = {
    provider: string;
    body: object;
    authorizeUrl: string;
};

export default class Credential {
    provider: string;
    body: object;
    authorizeUrl: string;

    constructor(dto: Partial<CredentialDTO>) {
        this.provider = dto.provider ?? '';
        this.body = dto.body ?? {};
        this.authorizeUrl = dto.authorizeUrl ?? '';
    }
}
