import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks';
    import { SectionProps } from '../roofing/types';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'Gutter03ExteriorLighting',
  props: {
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    },
  setup(__props) {

    const props = __props;
    const listSections = computed((): SectionProps[] => {
        return [
            {
                id: 1,
                name: 'soffitLightingType',
                title: 'Soffit Lighting Type',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.soffitLightingType?.[Number(i)].quantity,
                setModelValue: (i) => `soffitLightingType[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Soffit Lighting Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `soffitLightingType[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `soffitLightingType[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'trackLightingType',
                title: 'Track Lighting Type',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.trackLightingType[i].quantity,
                setModelValue: (i) => `trackLightingType[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Track Lighting Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `trackLightingType[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `trackLightingType[${i}].quantity`;
                                },
                            },
                        ],
                    },
                ],
            },
        ];
    });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ExcelFields, {
    modelValues: props?.modelValues,
    setModelValue: props?.setModelValue,
    sections: listSections.value,
    errors: props?.errors
  }, null, 8, ["modelValues", "setModelValue", "sections", "errors"]))
}
}

})