interface ZohoUserDto {
    id?: string;
    active?: boolean;
    email?: string;
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
    accountWithEmailExists?: boolean;
    accountLinked?: boolean;
}

export default class ZohoUser {
    id: string;
    active: boolean;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    accountWithEmailExists: boolean;
    accountLinked: boolean;

    constructor(dto: Partial<ZohoUserDto> = {}) {
        this.id = dto.id ?? '';
        this.active = dto.active ?? false;
        this.email = dto.email ?? '';
        this.phoneNumber = dto.phoneNumber ?? '';
        this.firstName = dto.firstName ?? '';
        this.lastName = dto.lastName ?? '';
        this.accountWithEmailExists = dto.accountWithEmailExists ?? false;
        this.accountLinked = dto.accountLinked ?? false;
    }

    get name() {
        return (this.firstName + ' ' + this.lastName).trim();
    }

    get initials() {
        const fi = this.firstName ? this.firstName.substr(0, 1) : '?';
        const li = this.lastName ? this.lastName.substr(0, 1) : '?';
        return (fi + li).toUpperCase();
    }

    get firstNameLastInitial() {
        const li = this.lastName ? this.lastName.substr(0, 1) : '?';
        return this.firstName + ' ' + li.toUpperCase() + '.';
    }
}
