import { DateTime } from 'luxon';

export interface LeadDTO {
    id?: number;
    externalId?: string;
    userId?: number | null;
    additionalSalesmanId?: number | null;
    userExternalId?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    phone?: string;
    mobilePhone?: string;
    email?: string;
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    description?: string;
    projectType?: string;
    projectTypes?: object;
    leadStatus?: string;
    customerNumber?: string;
    projectFolderLink?: string;
    projectFolderId?: string;
    contractLink?: string;
    contractFolderId?: string;
    estimationLink?: string;
    estimationFolderId?: string;
    productionLink?: string;
    productionFolderId?: string;
    meetingDate?: string | null;
    createdTime?: string | null;
    modifiedTime?: string | null;
    hoverJobId?: number | null;
    cameraUrl?: string;
}

export default class Lead {
    id: number;
    externalId: string;
    userId: number | null;
    user: any;
    additionalSalesmanId: number | null;
    additionalSalesman: any;
    userExternalId: string;
    firstName: string;
    lastName: string;
    company: string;
    phone: string;
    mobilePhone: string;
    email: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
    description: string;
    projectType: string;
    projectTypes: object;
    leadStatus: string;
    customerNumber: string;
    projectFolderLink: string;
    projectFolderId: string;
    contractLink: string;
    contractFolderId: string;
    estimationLink: string;
    estimationFolderId: string;
    productionLink: string;
    productionFolderId: string;
    meetingDate: DateTime | null;
    createdTime: DateTime | null;
    modifiedTime: DateTime | null;
    hoverJobId: number | null;
    cameraUrl: string;

    constructor(dto: Partial<LeadDTO> = {}) {
        this.id = dto.id ?? 0;
        this.externalId = dto.externalId ?? '';
        this.userId = dto.userId ?? null;
        this.user = null;
        this.additionalSalesmanId = dto.additionalSalesmanId ?? null;
        this.additionalSalesman = null;
        this.userExternalId = dto.userExternalId ?? '';
        this.firstName = dto.firstName ?? '';
        this.lastName = dto.lastName ?? '';
        this.company = dto.company ?? '';
        this.phone = dto.phone ?? '';
        this.mobilePhone = dto.mobilePhone ?? '';
        this.email = dto.email ?? '';
        this.street = dto.street ?? '';
        this.city = dto.city ?? '';
        this.state = dto.state ?? '';
        this.zipCode = dto.zipCode ?? '';
        this.description = dto.description ?? '';
        this.projectType = dto.projectType ?? '';
        this.projectTypes = dto.projectTypes ?? {};
        this.leadStatus = dto.leadStatus ?? '';
        this.customerNumber = dto.customerNumber ?? '';
        this.projectFolderLink = dto.projectFolderLink ?? '';
        this.projectFolderId = dto.projectFolderId ?? '';
        this.contractLink = dto.contractLink ?? '';
        this.contractFolderId = dto.contractFolderId ?? '';
        this.estimationLink = dto.estimationLink ?? '';
        this.estimationFolderId = dto.estimationFolderId ?? '';
        this.productionLink = dto.productionLink ?? '';
        this.productionFolderId = dto.productionFolderId ?? '';
        this.meetingDate = dto.meetingDate ? DateTime.fromISO(dto.meetingDate) : null;
        this.createdTime = dto.createdTime ? DateTime.fromISO(dto.createdTime) : null;
        this.modifiedTime = dto.modifiedTime ? DateTime.fromISO(dto.modifiedTime) : null;
        this.hoverJobId = dto.hoverJobId ?? null;
        this.cameraUrl = dto.cameraUrl ?? '';
    }

    hasProjectFolders() {
        return !!(this.projectFolderLink || this.contractLink || this.estimationLink || this.productionLink);
    }
}
