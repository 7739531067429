<script setup>
    import { computed, defineProps } from 'vue';

    // import { useStore } from 'vuex';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimteCostValues } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    // const $store = useStore();

    const paintMaterialTypeRecommended = computed(() => {
        return Array.isArray(props?.modelValues.paintMaterialType)
            ? props?.modelValues.paintMaterialType.map((x, i) => {
                  const feet = props?.modelValues.laborType.map((x) => x.quantity).reduce((a, b) => a + b, 0);

                  const galPerSqFtWall = props?.getRecommendedConversion(
                      1,
                      props?.model.paintMaterialType.field,
                      props?.modelValues.paintMaterialType[i].value
                  );
                  if (galPerSqFtWall === 0) return 0;
                  const recommended = Math.ceil(feet / galPerSqFtWall);
                  props?.setRecommendedItem(props?.model.paintMaterialType.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: '98fc0ef0-03cd-4954-9822-f6df9a96d085',
                name: 'laborType',
                quantity: (i) => props?.modelValues.laborType[i].quantity,
                setModelValue: (i) => `laborType[${i}].quantity`,
                title: 'Paint',
                isFieldArray: true,
                rows: [
                    {
                        id: 'dbbda3c9-5b38-4f5a-a480-4c887b3f8f90',
                        columns: [
                            {
                                id: '193c169c-a69f-4d10-ae53-5e828bc7ab5d',
                                label: 'Painting Labor Type',
                            },
                            {
                                id: '54ce9a3e-08cd-416e-acef-920d8c010da8',
                                type: 'Dropdown',
                                name(i) {
                                    return `laborType[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'edd279df-0e1b-4743-ae91-698f61f9ccc7',
                        columns: [
                            {
                                id: '51985c93-3687-484b-805a-408b2a32818c',
                                label: 'Square Feet',
                            },
                            {
                                id: '3723992a-7ee3-4eed-8b82-2374e0105312',
                                type: 'Quantity',
                                name(i) {
                                    return `laborType[${i}].quantity`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                ],
            },
            {
                id: 'de8fc3b9-b82c-4fcf-b8f9-d788e0e3eed1',
                name: 'additionalLaborType',
                quantity: (i) => props?.modelValues.additionalLaborType[i].quantity,
                setModelValue: (i) => `additionalLaborType[${i}].quantity`,
                title: 'Additional labor type',
                isFieldArray: true,
                rows: [
                    {
                        id: 'edd9a01c-30b9-4010-a69d-0a3dcc1b451d',
                        columns: [
                            {
                                id: 'd746034e-17d6-4eac-8724-72b11bf4a1d7',
                                label: 'Additional Labor Type',
                            },
                            {
                                id: '8401b26f-b2bb-4e2b-83b8-85e6e4cda705',
                                type: 'Dropdown',
                                name(i) {
                                    return `additionalLaborType[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: '465dd5a2-85b2-4c13-9189-285d8df27c63',
                        columns: [
                            {
                                id: '77e5d344-d1a5-47fc-ae10-9c04a87f26ee',
                                label: 'Linear Feet',
                            },
                            {
                                id: 'ab06a4ff-18c7-4d0e-bc6a-cdb5a8044468',
                                type: 'Quantity',
                                name(i) {
                                    return `additionalLaborType[${i}].quantity`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                ],
            },
            {
                id: '239adbf0-e0af-4f5a-9602-a9f6d3c2b8e4',
                name: 'paintExtras',
                quantity: (i) => props?.modelValues.paintExtras[i].quantity,
                setModelValue: (i) => `paintExtras[${i}].quantity`,
                title: 'Paint Extras',
                isFieldArray: true,
                rows: [
                    {
                        id: 'c92cdb28-7ba1-4f03-9a33-786620db1fa7',
                        columns: [
                            {
                                id: '2ee9d30b-512b-4906-a150-0fa9d389dc63',
                                label: 'Paint Extras',
                            },
                            {
                                id: 'c093380a-ffbe-4bb9-a414-bd4ff0b3ed6f',
                                type: 'Dropdown',
                                name(i) {
                                    return `paintExtras[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'fedb83fc-a6a0-4cd4-839a-4128639eafd9',
                        columns: [
                            {
                                id: '9165770c-f1e8-44b5-a2db-025f94744c28',
                                label: 'Quantity',
                            },
                            {
                                id: '6892e3c3-1743-46f4-900e-6c207914faac',
                                type: 'Quantity',
                                name(i) {
                                    return `paintExtras[${i}].quantity`;
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                ],
            },
            {
                id: 'bdb027dd-4371-48bb-a81f-121414b8f060',
                name: 'paintMaterialType',
                quantity: (i) => props?.modelValues.paintMaterialType[i].quantity,
                setModelValue: (i) => `paintMaterialType[${i}].quantity`,
                title: 'Paint Material Type',
                isFieldArray: true,
                rows: [
                    {
                        id: '5832ec1c-8928-474f-b3c2-d4d090c54a40',
                        columns: [
                            {
                                id: '51a17d05-fc1c-492a-b88d-a6dda257cb20',
                                label: 'Material Type',
                            },
                            {
                                id: 'bdf31573-936e-4c3b-8875-d1ca28687eb2',
                                type: 'Dropdown',
                                name(i) {
                                    return `paintMaterialType[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 'c2c68110-7392-4c61-a702-04dbe539ba18',
                        columns: [
                            {
                                id: '504457be-d1e9-467f-a182-c58ffac69c59',
                                label: 'Quantity asdf',
                            },
                            {
                                id: '341dfb50-0688-4bed-9d98-5ba527007bea',
                                type: 'Quantity',
                                name(i) {
                                    return `paintMaterialType[${i}].quantity`;
                                },
                                recommended(i) {
                                    return paintMaterialTypeRecommended.value[Number(i)];
                                },
                            },
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
