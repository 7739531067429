<!-- eslint-disable no-unused-vars -->
<template>
    <div
        class="account-nav-contain"
        :class="{ expanded: isOpen }"
        v-bind="$attrs"
        ref="accountNavWidget"
        v-on="focusListeners"
        tabindex="0"
    >
        <button type="button" class="account-nav-toggle heading" @click="isOpen = !isOpen">
            <svg class="mr-quarter icon fill-white stroke-white">
                <use xlink:href="#account"></use>
            </svg>
            <span class="mt-quarter">
                {{ user.initials }}
            </span>
        </button>
        <nav class="account-nav">
            <router-link to="/account/companies?select=true">
                <svg class="icon">
                    <use xlink:href="#compare"></use>
                </svg>
                Select company
            </router-link>
            <hr />
            <router-link to="/account/manage">
                <svg class="icon">
                    <use xlink:href="#account-manage"></use>
                </svg>
                Manage account
            </router-link>
            <button type="button" @click="onLogout">
                <svg class="icon">
                    <use xlink:href="#leave"></use>
                </svg>
                Logout
            </button>
            <div class="app-version">
                App Version: <span class="semi-bold">{{ version }}</span>
            </div>
        </nav>
    </div>
</template>

<script lang="ts">
    import api from '@/api';
    import { getFocus } from '@/composables/focus';
    import { safeComputed } from '@/composables/safeComputed';
    import MessageType from '@/models/MessageType';
    import { inject, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';

    export default {
        inheritAttrs: false,
        setup() {
            interface ConfirmDialog {
                [x: string]: any;
                openDialog: (message: string, options?: { type?: string; confirmText?: string }) => Promise<boolean>;
                closeDialog: () => void;
            }
            const $store = useStore();
            const $router = useRouter();
            const accountNavWidget = ref(null);
            const { isOpen, focusListeners } = getFocus(accountNavWidget);
            const user = safeComputed(() => $store.state.auth.user, true);
            const confirmDialog = inject<ConfirmDialog>('confirmDialog');

            async function onLogout() {
                if ($store.state.unsyncedChanges > 0) {
                    const confirmed = await confirmDialog?.value.openDialog(
                        `You have ${$store.state.unsyncedChanges} unsynced change(s). Are you sure you want to logout? You will lose any unsynced changes.`,
                        {
                            type: 'warning',
                            confirmText: 'Logout',
                        }
                    );
                    if (!confirmed) return;
                }
                if (confirmDialog?.value && confirmDialog?.value.isOpen) {
                    confirmDialog.value.closeDialog();
                }
                const response = await api.users.logout();
                if (response?.status >= 200 && response?.status < 300) {
                    await $store.dispatch('refresh');
                    await $store.dispatch('addMessage', {
                        message: 'You have been logged out.',
                        autoClose: true,
                        type: MessageType.success,
                    });
                    $router.push('/');
                } else {
                    return api.helpers.handleHttpError(response);
                }
            }

            return {
                isOpen,
                user,
                focusListeners,
                accountNavWidget,
                onLogout,
                version: process.env.VUE_APP_VERSION,
            };
        },
    };
</script>
