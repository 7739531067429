<script setup>
    import {
        calcuateHiddenRecommend,
        calculateHiddenVentArea,
        calculatePorchSoffRecommend,
        calculatePorchSofftArea,
        calculateStandardRecommend,
        calculateStandardSofft,
    } from '@/helpers/estimator/siding/07_soffit';
    import { computed, defineProps, watch } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';
    // import { SectionProps } from "../roofing/types";

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const soffitRecommended = computed(() => {
        return Array.isArray(props?.modelValues.soffit)
            ? props?.modelValues.soffit.map((x, i) => {
                  const feetPerUnit = props?.getRecommendedConversion(
                      1,
                      props?.model.soffit.children.soffitType.field,
                      props?.modelValues.soffit[i].children.soffitType.value
                  );

                  if (feetPerUnit === 0) return 0;

                  const standardArea = (props?.modelValues.standardSoffit ?? []).reduce(calculateStandardSofft, {});
                  const hiddenVentArea = (props?.modelValues.hiddenVentSoffit ?? []).reduce(
                      calculateHiddenVentArea,
                      {}
                  );
                  const porchSoffitArea = (props?.modelValues.porchSoffit ?? []).reduce(calculatePorchSofftArea, {});

                  const recommendedStandard = Object.values(standardArea).reduce((acc, x) => {
                      const { solidQty, ventedQty } = calculateStandardRecommend(x, feetPerUnit);
                      return acc + solidQty + ventedQty;
                  }, 0);
                  const recommendedHiddenVent = Object.values(hiddenVentArea).reduce((acc, x) => {
                      const { qty } = calcuateHiddenRecommend(x, feetPerUnit);

                      return acc + qty;
                  }, 0);

                  const recommendedPorchSoffit = Object.values(porchSoffitArea)?.reduce((acc, x) => {
                      const { qty } = calculatePorchSoffRecommend(x, feetPerUnit);
                      return acc + qty;
                  }, 0);

                  const recommended = recommendedStandard + recommendedHiddenVent + recommendedPorchSoffit;

                  props?.setRecommendedItem(props?.model.soffit.field.id, recommended, i);
                  return recommended;
              })
            : [];
    });

    const compositeSoffitRecommended = computed(() => {
        if (!Array.isArray(props?.modelValues.compositeSoffit)) {
            return [];
        }
        return props?.modelValues.compositeSoffit.map((x, i) => {
            const feetPerUnit = props?.getRecommendedConversion(
                1,
                props?.model.compositeSoffit.children.compositeSoffitType.field,
                props?.modelValues.compositeSoffit[i].children.compositeSoffitType.value
            );
            if (feetPerUnit === 0) return 0;
            const length = x.children.compositeSoffitLength.quantity;
            const recommended = Math.ceil(length / feetPerUnit);
            props?.setRecommendedItem(
                props?.model.compositeSoffit.children.compositeSoffitType.field.id,
                recommended,
                i
            );
            return recommended;
        });
    });

    watch(
        () => props?.modelValues.porchSoffit,
        () => {
            props?.modelValues.porchSoffit.forEach((x, i) => {
                props?.setModelValue(
                    `porchSoffit[${i}].quantity`,
                    x.children.porchSoffitDepth.quantity * x.children.porchSoffitLength.quantity
                );
            });
        },
        { deep: true }
    );
    // const listSections = computed((): SectionProps[] => {
    const listSections = computed(() => {
        return [
            {
                id: 1,
                title: 'Standard Soffit',
                name: 'standardSoffit',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.standardSoffit[i].children.standardSoffitLength.quantity,
                setModelValue: (i) => `standardSoffit[${i}].children.standardSoffitLength.quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Depth',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `standardSoffit[${i}].children.standardSoffitDepth.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Length Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `standardSoffit[${i}].children.standardSoffitLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Text',
                                name(i) {
                                    return `standardSoffit[${i}].children.standardSoffitColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Labor',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `standardSoffit[${i}].children.standardSoffitLabor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                title: 'Hidden Vent Soffit',
                name: 'hiddenVentSoffit',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.hiddenVentSoffit[i].children.hiddenVentSoffitLength.quantity,
                setModelValue: (i) => `hiddenVentSoffit[${i}].children.hiddenVentSoffitLength.quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Depth Inches',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `hiddenVentSoffit[${i}].children.hiddenVentSoffitDepth.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Length Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `hiddenVentSoffit[${i}].children.hiddenVentSoffitLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Text',
                                name(i) {
                                    return `hiddenVentSoffit[${i}].children.hiddenVentSoffitColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Labor',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `hiddenVentSoffit[${i}].children.hiddenVentSoffitLabor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                title: 'Porch Soffit',
                name: 'porchSoffit',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.porchSoffit[i].children.porchSoffitLength.quantity,
                setModelValue: (i) => `porchSoffit[${i}].children.porchSoffitLength.quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Depth Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `porchSoffit[${i}].children.porchSoffitDepth.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Length Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `porchSoffit[${i}].children.porchSoffitLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Text',
                                name(i) {
                                    return `porchSoffit[${i}].children.porchSoffitColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Labor',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `porchSoffit[${i}].children.porchSoffitLabor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                title: 'Soffit Material',
                name: 'soffit',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.soffit[i].quantity,
                setModelValue: (i) => `soffit[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Soffit',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `soffit[${i}].quantity`;
                                },
                                recommended(i) {
                                    return soffitRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `soffit[${i}].children.soffitType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },

                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `soffit[${i}].children.soffitColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 5,
                title: 'JChannel Trim',
                name: 'jChannelTrim',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.jChannelTrim[i].quantity,
                setModelValue: (i) => `jChannelTrim[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'J channel',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `jChannelTrim[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `jChannelTrim[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 6,
                title: 'Composite Soffit',
                name: 'compositeSoffit',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.compositeSoffit[i].children.compositeSoffitType.quantity,
                setModelValue: (i) => `compositeSoffit[${i}].children.compositeSoffitType.quantity`,
                rows: [
                    {
                        columns: [
                            { id: '686590a5-c57f-4602-b9b3-ebfdabdb44b7', label: '--' },
                            {
                                id: 'a932656f-0669-47f1-9b48-eee5e431c180',
                                error(i) {
                                    if (
                                        (props?.modelValues.compositeSoffit[Number(i)].children.compositeSoffitLength
                                            .quantity > 0 &&
                                            props?.modelValues.compositeSoffit[Number(i)].children.compositeSoffitType
                                                .quantity === 0) ||
                                        (props?.modelValues.compositeSoffit[Number(i)].children.compositeSoffitLength
                                            .quantity === 0 &&
                                            props?.modelValues.compositeSoffit[Number(i)].children.compositeSoffitType
                                                .quantity > 0)
                                    ) {
                                        return '*';
                                    }
                                    return '';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2.1,
                        columns: [
                            {
                                id: 1,
                                label: 'Depth inches',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitDepth.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Length Feet',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitLength.quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: 'Labor ',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitLength.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitType.quantity`;
                                },
                                recommended(i) {
                                    return compositeSoffitRecommended.value[Number(i)];
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: 'Type',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitType.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 7,
                        columns: [
                            {
                                id: 1,
                                label: 'Color',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `compositeSoffit[${i}].children.compositeSoffitColor.value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 7,
                title: 'Cut Soffit Vent',
                name: 'cutSoffitVent',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.cutSoffitVent[i].quantity,
                setModelValue: (i) => `cutSoffitVent[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Cut in soffit vent',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name(i) {
                                    return `cutSoffitVent[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `cutSoffitVent[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>
<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
