<script setup>
    import { formatCurrency, round } from '@/helpers';
    import { computed, defineProps, onMounted, watch } from 'vue';
    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields, estimteCostValues } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    const woodReplacementRecommended = computed(() => {
        const feetPerUnit = props?.getRecommendedConversion(
            1,
            props?.model.woodReplacement.children.woodReplacementMaterial.field,
            props?.modelValues.woodReplacement.children.woodReplacementMaterial.value
        );
        if (feetPerUnit === 0) return 0;
        const length = props?.modelValues.woodReplacement.children.woodReplacementArea.quantity;
        const recommended = Math.ceil(length / feetPerUnit);
        props?.setRecommendedItem(props?.model.woodReplacement.children.woodReplacementMaterial.field.id, recommended);
        return recommended;
    });

    function updatePrices() {
        if (Array.isArray(props.modelValues.custom)) {
            for (let i = 0; i < props.modelValues.custom.length; i++) {
                const x = props.modelValues.custom[i];
                const materialMultiplier = props.getRecommendedConversion(
                    1,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                const laborMultiplier = props.getRecommendedConversion(
                    2,
                    props.model.custom.field,
                    props.modelValues.custom[i].value
                );
                props.setModelValue(`custom[${i}].materialPriceTemp`, round(x.materialCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].materialPrice`, round(x.materialCost * materialMultiplier, 2));
                props.setModelValue(`custom[${i}].laborPriceTemp`, round(x.laborCost * x.quantity, 2));
                props.setModelValue(`custom[${i}].laborPrice`, round(x.laborCost * laborMultiplier, 2));
            }
        }
    }

    watch(() => props.modelValues.custom, updatePrices, { deep: true });
    onMounted(async () => {
        updatePrices();
    });

    const WoodReplacementOne = computed(() => {
        return props?.fieldsEstimateItems?.find((e) => e?.label?.toUpperCase() === 'Wood Replacement'?.toUpperCase());
    });

    const WoodReplacementArea = computed(() => {
        return WoodReplacementOne?.value?.childItems?.find(
            (e) => e?.label?.toUpperCase() === 'Wood Replacement Area'?.toUpperCase()
        );
    });

    const woodReplacementMaterial = computed(() => {
        return WoodReplacementOne?.value?.childItems?.find(
            (e) => e?.label?.toUpperCase() === 'Wood Replacement Material'?.toUpperCase()
        );
    });
    const listSections = computed(() => {
        return [
            {
                id: 1,
                name: '',
                title: 'Replace Boards',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 0.5,
                        columns: [
                            {
                                id: 1,
                                label: 'TOTAL ',
                            },
                            {
                                id: 2,
                                label: '--',
                            },
                            {
                                id: 3,
                                type: 'Cost',
                                estimateField() {
                                    const material = WoodReplacementOne.value?.material;
                                    if (!material) {
                                        return '--';
                                    }
                                    return `${formatCurrency(material)}`;
                                },
                            },
                            {
                                id: 4,
                                type: 'Cost',
                                estimateField() {
                                    const labor = WoodReplacementOne.value?.labor;
                                    if (!labor) {
                                        return '--';
                                    }
                                    return `${formatCurrency(labor)}`;
                                },
                            },
                            {
                                id: 5,
                                type: 'Cost',
                                estimateField() {
                                    const total = WoodReplacementOne.value?.total;
                                    if (!total) {
                                        return '--';
                                    }
                                    return `${formatCurrency(total)}`;
                                },
                            },
                            {
                                id: 6,
                                label: '--',
                            },
                        ],
                    },
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Replace Boards',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'woodReplacement.children.woodReplacementArea.quantity';
                                },
                            },
                            // ...estimateTotalsWithKeyChild(props?.fieldsEstimateItems, 'Wood Replacement Area'),
                            {
                                id: 3,
                                type: 'Cost',
                                estimateField() {
                                    const material = WoodReplacementArea.value?.material;
                                    if (!material) {
                                        return '--';
                                    }
                                    return `${formatCurrency(material)}`;
                                },
                            },
                            {
                                id: 4,
                                type: 'Cost',
                                estimateField() {
                                    const labor = WoodReplacementArea.value?.labor;
                                    if (!labor) {
                                        return '--';
                                    }
                                    return `${formatCurrency(labor)}`;
                                },
                            },
                            {
                                id: 5,
                                type: 'Cost',
                                estimateField() {
                                    const total = WoodReplacementArea.value?.total;
                                    if (!total) {
                                        return '--';
                                    }
                                    return `${formatCurrency(total)}`;
                                },
                            },
                            {
                                id: 6,
                                label: '--',
                            },
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Material',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name() {
                                    return 'woodReplacement.children.woodReplacementMaterial.value';
                                },
                            },
                            {
                                id: 3,
                                type: 'Cost',
                                estimateField() {
                                    const material = woodReplacementMaterial.value?.material;
                                    if (!material) {
                                        return '--';
                                    }
                                    return `${formatCurrency(material)}`;
                                },
                            },
                            {
                                id: 4,
                                type: 'Cost',
                                estimateField() {
                                    const labor = woodReplacementMaterial.value?.labor;
                                    if (!labor) {
                                        return '--';
                                    }
                                    return `${formatCurrency(labor)}`;
                                },
                            },
                            {
                                id: 5,
                                type: 'Cost',
                                estimateField() {
                                    const total = woodReplacementMaterial.value?.total;
                                    if (!total) {
                                        return '--';
                                    }
                                    return `${formatCurrency(total)}`;
                                },
                            },
                            {
                                id: 6,
                                label: '--',
                            },
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Recommended pc',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name() {
                                    return 'woodReplacement.children.woodReplacementMaterial.quantity';
                                },
                                recommended: () => woodReplacementRecommended?.value,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 2,
                name: 'gutterAccessories',
                title: 'Gutter Accessories',
                isFieldArray: true,
                quantity: (i) => props?.modelValues.gutterAccessories[i].quantity,
                setModelValue: (i) => `gutterAccessories[${i}].quantity`,
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Gutter Accessories',
                            },
                            {
                                id: 2,
                                type: 'Dropdown',
                                name: (i) => `gutterAccessories[${i}].value`,
                            },
                            // ...emptyFields,
                            ...estimteCostValues(props?.fieldsEstimateItems),
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `gutterAccessories[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 3,
                name: '',
                title: '',
                isFieldArray: false,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Gutters permit',
                            },
                            {
                                id: 2,
                                type: 'Checkbox',
                                name() {
                                    return 'permit.value';
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            {
                id: 4,
                name: 'custom',
                title: 'Add On Services',
                addItem: {
                    title: 'Add On Services',
                    show: true,
                },
                isFieldArray: true,
                quantity: () => 0,
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Label / name',
                            },
                            {
                                id: 2,
                                type: 'Text',
                                name(i) {
                                    return `custom[${i}].value`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Quantity',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name(i) {
                                    return `custom[${i}].quantity`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: '$ material cost',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].materialCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 4,
                        columns: [
                            {
                                id: 1,
                                label: '$ labor cost',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].laborCost`;
                                },
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: '$ material price',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].materialPriceTemp`;
                                },
                                readonly: true,
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: '$ labor price',
                            },
                            {
                                id: 2,
                                type: 'Price',
                                name(i) {
                                    return `custom[${i}].laborPriceTemp`;
                                },
                                readonly: true,
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listSections"
        :errors="props?.errors"
    />
</template>
