/* eslint-disable no-unused-vars */
import { round, roundToStep } from '@/helpers';

interface Item {
    quantity: number;
    fieldId: string;
    children?: {
        tearOffLayer?: {
            value: string;
        };
        tearOffMaterial?: {
            value: string;
        };
    };
}

export const calculateTotalSquares = (data: Item[], fieldId: any) => {
    let result = 0;
    data.forEach((x) => {
        result += x.quantity;
    });

    const rounded = roundToStep(result, fieldId);

    return round(rounded, 3);
};

export const calculateMaxSquares = (membraneAreaQuantity: number, totalSquaresForMaterialQuantity: number) => {
    return membraneAreaQuantity + totalSquaresForMaterialQuantity;
};

export const calculateNumbOfStories = (items: Item[], fieldId: string = '1.3') => {
    return items.filter((x) => x.fieldId === fieldId).reduce((sum, x) => sum + x.quantity, 0);
};

export const calculateTearOffLayerField = (
    items: Item[],
    fieldId: string,
    callbackRecommendVersion: (arg1: number, arg2: string, arg3: string) => number | undefined
) => {
    return items.reduce((sum, x) => {
        const layerValue = x.children?.tearOffLayer?.value ?? '';
        const layerRecommended = callbackRecommendVersion(1, fieldId, layerValue) || 1;
        return sum + x.quantity * layerRecommended;
    }, 0);
};

export const calculateDumpsterWeight = (
    items: Item[],
    tearOffLayerId: string,
    tearOffMaterialId: string,
    callbackRecommendVersion: (id1?: number, id2?: string, id3?: string) => number | undefined
) => {
    return (
        items.reduce((sum, x) => {
            const layerValue = x.children?.tearOffLayer?.value ?? '';
            const materialValue = x.children?.tearOffMaterial?.value ?? '';

            const layerRecommended = callbackRecommendVersion(1, tearOffLayerId, layerValue) || 1;
            const materialRecommended = callbackRecommendVersion(1, tearOffMaterialId, materialValue) || 1;

            return sum + x.quantity * layerRecommended * materialRecommended;
        }, 0) / 2000
    );
};
