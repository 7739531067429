<script setup>
    import { computed, defineProps } from 'vue';

    import { watch } from 'vue';

    import ExcelFields from '../../atoms/ExcelFields.vue';
    import { emptyFields } from '../mocks.ts';

    const props = defineProps({
        modelValues: null,
        setModelValue: null,
        model: null,
        estimate: null,
        loading: null,
        getAnyRecommendedConversion: null,
        getRecommendedConversion: null,
        setRecommendedItem: null,
        fieldsEstimateItems: null,
        errors: null,
    });

    // const deckingRecommended = computed(() => {
    //     const recommended = calculateDeckingRecommend(
    //         props?.model.decking.field,
    //         props?.modelValues.decking.value,
    //         props?.modelValues.deckingStyle.quantity,
    //         props?.getRecommendedConversion
    //     );
    //     props?.setRecommendedItem?.(props?.model.decking.field.id, recommended);
    //     return recommended;
    // });

    watch(
        () => props?.modelValues.iceBarrierRows.value,
        () => {
            const iceBarrierRowsQuantity = {
                '1.36.1': 0,
                '1.36.2': 1,
                '1.36.3': 2,
                '1.36.4': 3,
            };

            props?.setModelValue?.(
                'iceBarrierRows.quantity',
                iceBarrierRowsQuantity[props?.modelValues.iceBarrierRows.value]
            );
        },
        { deep: true, immediate: true }
    );

    const listEstimateValues = computed(() => {
        return [
            // {
            //     id: 9,
            //     title: 'Decking Scope',
            //     isFieldArray: false,
            //     quantity: () => 0,
            //     name: 'deckingskope',
            //     setModelValue: () => '',
            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Decking Scope',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Dropdown',
            //                     name: () => 'deckingScope.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 2,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Area',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Quantity',
            //                     name: () => 'deckingStyle.quantity',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },

            // {
            //     id: 10,
            //     title: 'Decking',
            //     isFieldArray: false,
            //     quantity: () => 0,
            //     name: 'decking',
            //     setModelValue: () => '',
            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Material',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Dropdown',
            //                     name: () => 'deckingStyle.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 2,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Sheets',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Dropdown',
            //                     name: () => 'decking.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //         {
            //             id: 3,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Quantity',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Quantity',
            //                     name: () => 'decking.quantity',
            //                     recommended: () => deckingRecommended.value,
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
            {
                id: 11,
                title: 'Underlayment',
                isFieldArray: false,
                quantity: () => 0,
                name: 'underlmyn',
                setModelValue: () => '',
                rows: [
                    {
                        id: 1,
                        columns: [
                            {
                                id: 1,
                                label: 'Eave',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'eaveLength.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 2,
                        columns: [
                            {
                                id: 1,
                                label: 'Rake edge',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'rakeEdgeLength.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 3,
                        columns: [
                            {
                                id: 1,
                                label: 'Hip & ridge',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'hipRidgeLength.quantity',
                            },
                            ...emptyFields,
                        ],
                    },

                    {
                        id: 5,
                        columns: [
                            {
                                id: 1,
                                label: 'Valley',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'valleyLength.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 6,
                        columns: [
                            {
                                id: 1,
                                label: 'Step Flashing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'roofToWallFlashingLength.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                    {
                        id: 7,
                        columns: [
                            {
                                id: 1,
                                label: 'Flashing',
                            },
                            {
                                id: 2,
                                type: 'Quantity',
                                name: () => 'flashingLength.quantity',
                            },
                            ...emptyFields,
                        ],
                    },
                ],
            },
            // {
            //     id: 12,
            //     title: 'Feet of Ice and Water Barrier',
            //     isFieldArray: false,
            //     quantity: () => 0,
            //     name: 'feetoficeandwater',
            //     setModelValue: () => '',
            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Feet of Ice and Water Barrier',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Dropdown',
            //                     name: () => 'iceBarrierRows.value',
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
            // {
            //     id: 13,
            //     title: 'Replace Deck boards',
            //     isFieldArray: true,
            //     quantity: (i) => props?.modelValues.replaceDeckBoards[i].quantity,
            //     name: 'replaceDeckBoards',
            //     setModelValue: (i) => {
            //         return `replaceDeckBoards[${i}].quantity`;
            //     },
            //     rows: [
            //         {
            //             id: 1,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Feet',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Quantity',
            //                     name(i) {
            //                         return `replaceDeckBoards[${i}].quantity`;
            //                     },
            //                 },
            //                 ...estimteCostValues(props?.fieldsEstimateItems),
            //             ],
            //         },
            //         {
            //             id: 2,
            //             columns: [
            //                 {
            //                     id: 1,
            //                     label: 'Material',
            //                 },
            //                 {
            //                     id: 2,
            //                     type: 'Dropdown',
            //                     name(i) {
            //                         return `replaceDeckBoards[${i}].value`;
            //                     },
            //                 },
            //                 ...emptyFields,
            //             ],
            //         },
            //     ],
            // },
        ];
    });
</script>

<template>
    <ExcelFields
        :modelValues="props?.modelValues"
        :setModelValue="props?.setModelValue"
        :sections="listEstimateValues"
        :errors="errors"
    />
</template>
