import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-text" }

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InputIconTextField',
  props: {
	modelValue: {
		type: String,
		required: true,
	},
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const emits = __emit;
const props = __props;
const value = ref(props.modelValue);

const clearField = () => {
    value.value = '';
    emits('update:modelValue', '');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event))
    }, null, 512), [
      [_vModelText, value.value]
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "btn-link",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('update:modelValue', value.value)))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("svg", {
        width: "24px",
        height: "24px",
        class: "icon fill-green stroke-green"
      }, [
        _createElementVNode("use", { "xlink:href": "#save" })
      ], -1)
    ])),
    _createElementVNode("button", {
      type: "button",
      class: "btn-link",
      onClick: clearField
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("svg", {
        width: "24px",
        height: "24px",
        class: "icon fill-red stroke-red"
      }, [
        _createElementVNode("use", { "xlink:href": "#remove" })
      ], -1)
    ]))
  ]))
}
}

})