import { url as urlHelper } from '@/helpers';
import { clearDatabase, getDb } from '@/idbInit';
import User from '@/models/User';
import { AxiosResponse } from 'axios';
import { addCompanyIdToUrl, API_USER_SELF_CACHE_NAME, fetchMiddleware, fetchWrap, offlineResponse } from '../_helpers';

import $store from '@/store';
// Define types for parameters
type UserParams = {
    active?: boolean;
};

type UserModel = {
    id?: number;
    [key: string]: any;
};

export default {
    /**
     * Get all users, filtered by the following parameters.
     * @param {Object} params
     * @param {boolean} params.active
     * @returns Returns a Response if the request was made without network errors, or an array of User if using offline data.
     */
    async getAll({ active = undefined }: UserParams = {}): Promise<User[] | AxiosResponse> {
        const query: Record<string, any> = {};
        if (typeof active === 'boolean') {
            query.active = active;
        }
        const url = urlHelper('/api/users', query);
        let response: AxiosResponse;
        try {
            response = await fetchWrap(url);
        } catch {
            const idb = await getDb();
            let data = await idb.getAll('users');
            if (typeof active === 'boolean') {
                data = data.filter((x: UserModel) => x.active === active);
            }
            return data.map((x: UserModel) => new User(x));
        }
        if (response?.status >= 200 && response?.status < 300) {
            const data = await response?.data;
            const idb = await getDb();
            if (active === undefined) {
                await idb.clear('users');
            }
            for (let i = 0; i < data.length; i++) {
                await idb.put('users', data[i], data[i].id);
            }
            return data.map((x: UserModel) => new User(x));
        } else {
            return response;
        }
    },

    /**
     * Get a user by ID
     * @param id User ID
     * @returns Returns a User if successful, otherwise a Response or offline data.
     */
    async getById(id: number): Promise<User | AxiosResponse> {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/users/' + id);
        } catch {
            const idb = await getDb();
            const data = await idb.get('users', id);
            return data ? new User(data) : offlineResponse();
        }
        if (response?.status >= 200 && response?.status < 300) {
            const data = response.data;
            const idb = await getDb();
            await idb.put('users', data, data.id);
            return new User(data);
        } else {
            return response;
        }
    },

    /**
     * Create a new user
     * @param model User model to create.
     * @returns Returns the newly created User if successful, otherwise a Response.
     */
    async create(model: UserModel): Promise<User | AxiosResponse> {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/users/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        if (response?.status >= 200 && response?.status < 300) {
            const data = response.data;
            const idb = await getDb();
            await idb.put('users', data, data.id);
            return new User(data);
        } else {
            return response;
        }
    },

    /**
     * Update a user
     * @param model User model to update.
     * @returns Returns the updated User if successful, otherwise a Response.
     */
    async update(model: UserModel): Promise<User | AxiosResponse> {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/users/' + model.id, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        if (response?.status >= 200 && response?.status < 300) {
            const data = JSON.parse(JSON.stringify(model));
            const idb = await getDb();
            await idb.put('users', data, data.id);
            return new User(data);
        } else {
            return response;
        }
    },

    /**
     * Delete a user by ID
     * @param id User ID to delete.
     * @returns Returns true if successful, false if not, or a Response.
     */
    async deleteById(id: number): Promise<boolean | AxiosResponse> {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/users/' + id, { method: 'DELETE' });
        } catch {
            response = offlineResponse();
        }
        if ((response?.status >= 200 && response?.status < 300) || response.status === 404) {
            const idb = await getDb();
            await idb.delete('users', id);
            return true;
        } else if (response.status === 409) {
            return false;
        } else {
            return response;
        }
    },

    async resendInvite(id: number): Promise<boolean | AxiosResponse> {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/users/' + id + '/resendsetupemail', {
                method: 'POST',
            });
        } catch {
            response = offlineResponse();
        }
        if (response?.status >= 200 && response?.status < 300) {
            return true;
        } else {
            return response;
        }
    },

    async sendPendingInvites(): Promise<boolean | AxiosResponse> {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/users/sendsetuppendingemails', {
                method: 'POST',
            });
        } catch {
            response = offlineResponse();
        }
        if (response?.status >= 200 && response?.status < 300) {
            return true;
        } else {
            return response;
        }
    },

    async getSelf(): Promise<User | null> {
        let response: any, data: any;
        const request = addCompanyIdToUrl('/api/users/self');
        try {
            response = await fetchMiddleware(request);
            if (response?.status >= 200 && response?.status < 300) {
                data = response.data;
                if (!(data.id > 0) || !(data.companyId > 0)) {
                    await clearDatabase();
                }
            }
        } catch {
            try {
                const cache = await caches.open(API_USER_SELF_CACHE_NAME);
                response = (await cache.match(request, { ignoreMethod: true })) || offlineResponse();
                if (!response) {
                    response = offlineResponse();
                }
                if (response?.status >= 200 && response?.status < 300) {
                    data = response.data;
                }
            } catch {
                response = offlineResponse();
            }
        }
        return data ? new User(data) : null;
    },

    async login(model: UserModel) {
        await clearDatabase();
        let response: AxiosResponse;
        try {
            response = await fetchMiddleware('/api/users/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                // body: model,
                data: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        return response;
    },

    async logout(): Promise<AxiosResponse> {
        let response: any;
        try {
            response = await fetchMiddleware('/api/users/logout', { method: 'POST' });
        } catch {
            response = offlineResponse();
        }
        if (response?.status >= 200 && response?.status < 300) {
            await clearDatabase();
            const broadcast = new BroadcastChannel('auth');
            broadcast.postMessage({ type: 'logout' });
            broadcast.close();
            await $store.dispatch('clear');
            const cookies = document.cookie.split(';');

            for (let i = 0; i < cookies.length; i++) {
                const spcook = cookies[i].split('=');
                const d = new Date();
                d.setDate(d.getDate() - 1);
                document.cookie = `${spcook[0]}=; expires=${d.toUTCString()}; path=/`;
            }
        }
        return response;
    },

    async requestPasswordReset(model: UserModel): Promise<AxiosResponse> {
        await clearDatabase();
        let response: any;
        try {
            response = await fetchMiddleware('/api/users/forgotpassword', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        return response;
    },

    async resetPassword(model: UserModel): Promise<AxiosResponse> {
        await clearDatabase();
        let response: AxiosResponse;
        try {
            response = await fetchMiddleware('/api/users/resetpassword', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        return response;
    },

    async updateSelf(model: UserModel): Promise<User | AxiosResponse> {
        let response: AxiosResponse, data: any;
        try {
            response = await fetchMiddleware(addCompanyIdToUrl('/api/users/self'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(model),
            });
            if (response?.status >= 200 && response?.status < 300) {
                data = response.data;
            } else if (response.status === 401) {
                await clearDatabase();
            }
        } catch {
            response = offlineResponse();
        }
        if (response?.status >= 200 && response?.status < 300) {
            return new User(data);
        }
        return response;
    },

    async changePassword(model: UserModel): Promise<AxiosResponse> {
        let response: AxiosResponse;
        try {
            response = await fetchMiddleware('/api/users/changepassword', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        if (response.status === 401) {
            await clearDatabase();
        }
        return response;
    },

    async setup(model: UserModel): Promise<AxiosResponse> {
        await clearDatabase();
        let response: AxiosResponse;
        try {
            response = await fetchMiddleware('/api/users/setup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(model),
            });
        } catch {
            response = offlineResponse();
        }
        return response;
    },

    async usageReport(startDate: string, endDate: string): Promise<AxiosResponse> {
        const query = { startDate, endDate };
        const url = urlHelper('/api/users/usagereport', query);
        let response: AxiosResponse;
        try {
            response = await fetchWrap(url);
        } catch {
            response = offlineResponse();
        }
        return response;
    },
};
