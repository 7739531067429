import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot } from "vue"

import { useFieldArray } from 'vee-validate';
    import { inject, onMounted, toRef } from 'vue';

    // Definimos las propiedades del componente
    interface FieldArrayItem {
        id?: string;
        name?: string;
        value?: any;
    }

    interface Model {
        field: {
            id: string;
        };
    }

    // Inyección de dependencias con tipado
    
export default /*@__PURE__*/_defineComponent({
  __name: 'FieldArray',
  props: {
    name: {}
  },
  setup(__props: any, { expose: __expose }) {

    const props = __props;

    // Tipos para los items y funciones inyectadas
    const getNewFieldArrayItem = inject<(name: string) => FieldArrayItem>('getNewFieldArrayItem');
    const modelLookup = inject<(name: string) => Model>('modelLookup');
    const modelValues = inject<Record<string, any>>('modelValues');
    const recommendedItems = inject<Record<string, Record<string, number>>>('recommendedItems');

    if (!getNewFieldArrayItem || !modelLookup) {
        throw new Error('Missing required inject dependencies');
    }

    const model = modelLookup(props.name);
    const {
        remove: removeItem,
        push: pushItem,
        insert: insertItem,
        fields: items,
    } = useFieldArray(toRef(props, 'name'));

    const addItem = () => {
        pushItem(getNewFieldArrayItem(props.name));
    };

    const addItemAfter = (i: number) => {
        insertItem(i + 1, getNewFieldArrayItem(props.name));
    };

    onMounted(() => {
        if (recommendedItems && recommendedItems[model.field.id]) {
            const values = recommendedItems[model.field.id];
            const keys = Object.keys(values);

            const sortedKeys = keys.sort((a, b) => values[b] - values[a]);

            if (items.value.length === sortedKeys.length) {
                let count = 0;
                sortedKeys.forEach((key, i) => {
                    if (values[key] === 0 && items.value.length > 1) {
                        removeItem(i - count);
                        count++;
                    }
                });
            }
        }
    });

    __expose({
        items,
        removeItem,
        addItem,
        addItemAfter,
    });

return (_ctx: any,_cache: any) => {
  return _renderSlot(_ctx.$slots, "default", {
    items: _unref(items),
    removeItem: _unref(removeItem),
    addItem: addItem,
    addItemAfter: addItemAfter
  })
}
}

})