<template>
	<label class="label-error" v-if="errorMessage">{{ errorMessage }}</label>
</template>

<script setup lang="ts">
import { toRef, defineProps } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
	name: {
		type: String,
		required: true,
	},
});
const { errorMessage } = useField(toRef(props, 'name'));
</script>
