import FileReference from '@/models/FileReference';
import { AxiosResponse } from 'axios';
import { fetchWrap, offlineResponse } from '../_helpers';

export default {
    async getById(id: number) {
        let response: AxiosResponse;
        try {
            response = await fetchWrap('/api/files/' + id);
        } catch {
            return offlineResponse();
        }
        if (response?.status < 300 && response?.status >= 200) {
            const data = response.data;
            return new FileReference(data);
        } else {
            return response;
        }
    },

    async upload(file: File) {
        const formData = new FormData();
        formData.append('formFile', file);
        try {
            const response = await fetchWrap('/api/files', {
                method: 'POST',
                body: formData,
            });
            if (response?.status < 300 && response?.status >= 200) {
                const data = response.data;
                return new FileReference(data);
            } else {
                return response;
            }
        } catch (e) {
            return offlineResponse();
        }
    },
};
