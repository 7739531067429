import companyId from '@/api/modules/companyId';
import { getDateTimeFromDto as getDate, getValueFromDto as getValue } from './_helper';
import { DateTime } from 'luxon';

type DocumentSignatureDTO = {
    url: string;
    timestamp: string | null;
};

export default class DocumentSignature {
    url: string;
    data: any;
    timestamp: DateTime | null;

    constructor(dto: DocumentSignatureDTO) {
        this.url = getValue(dto, 'url', 'string', '');
        this.data = null;
        this.timestamp = getDate(dto, 'timestamp', null);
    }

    static getUrl(documentId: number, key: string) {
        return `/api/Documents/${documentId}/DownloadSignature?companyId=${companyId.get()}&key=${encodeURIComponent(
            key
        )}`;
    }
}
