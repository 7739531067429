import MessageType, { MessageTypeValue } from './MessageType';

export default class Message {
    message: string;
    id: number;
    type: MessageTypeValue | string;
    autoClose: boolean;

    static AUTO_CLOSE_DELAY = 5000;

    constructor() {
        this.message = '';
        this.id = 0;
        this.type = MessageType.info;
        this.autoClose = false;
    }
}
